import DepotFile from "../../../../../lib/granule/DepotFile";

class DepotFileCdo {
  id: string; //
  name: string;
  size: number;
  sequence: number; //
  versionRetained: boolean;
  retainedVersion: number;
  depotId: string;
  vaultFileId: string; //
  ownerId: string; //

  constructor(
    id: string,
    name: string,
    size: number,
    sequence: number,
    versionRetained: boolean,
    retainedVersion: number,
    depotId: string,
    vaultFileId: string,
    ownerId: string
  ) {
    this.id = id;
    this.name = name;
    this.size = size;
    this.sequence = sequence;
    this.versionRetained = versionRetained;
    this.retainedVersion = retainedVersion;
    this.depotId = depotId;
    this.vaultFileId = vaultFileId;
    this.ownerId = ownerId;
  }

  static fromModel(domain: DepotFile, versionRetained: boolean, retainedVersion: number): DepotFileCdo {
    if (!domain.retainedVersion) {
      throw new DOMException('DepotFileCdo', 'Retained version should not be null.');
    }

    return new DepotFileCdo(
      domain.id,
      domain.name,
      domain.size,
      domain.sequence,
      versionRetained,
      retainedVersion,
      domain.depotId,
      domain.vaultFileId,
      domain.ownerId,
    );
  }

}

export default DepotFileCdo;
