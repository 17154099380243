import axios from "axios";
import { domainURL } from "../../../conlab";
import { checkpointSecureCommonFlowFunction, secureFlowURL } from "./Function";
import { IssueType } from "../../../lib/type/api/common-flow/api-model/vo/IssueType";

export const emailDuplicationCheck = async (
    email: string
  ) => {
    const domainUrl = domainURL;

    const data = {
        email,
        domainUrl
    }

    let valid = await checkpointSecureCommonFlowFunction('/email-duplication-check', data);
    return valid.queryResult;
}

export const idDuplicationCheckFuc = async (data: any) => {
    const valid = await checkpointSecureCommonFlowFunction('/id-duplication-check', data);
    return valid.queryResult;
}

export const confirmEmailVerification = async (email: string, secretCode: string) => {

    const res = await axios.get(secureFlowURL+`/confirm-email-verification?email=${email}&secretCode=${secretCode}`);
    return res.data;
}

export const issueEmailVerification = async (
    email: string,
    minuteLimit: number,
    displayName: string,
    issueType: IssueType = IssueType.Default
  ) => {
  
    const res = await axios.get(secureFlowURL+`/issue-email-verification?email=${email}&minuteLimit=${minuteLimit}&displayName=${displayName}&issueType=${issueType}`);
    return res.data;
  }