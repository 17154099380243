import makeExtendedObservable from "../../../../../lib/decorator/makeExtendedObservable";
import validationUtils from "../../../../../lib/module/validationUtils/validationUtils";
import Name from "../../../../shared/api-model/Name";
import CitizenIdentity from "../vo/CitizenIdentity";

class CitizenIdentityCdo {
  citizenId: string;
  usid: string;
  name: Name | null = null;
  email: string;
  castleId: string;
  primaryDivision: string;
  marketingTime: number;
  marketingYn: boolean;

  constructor(citizenId: string, usid: string, name: Name | null, email: string, castleId: string, primaryDivision: string, marketingTime: number, marketingYn: boolean) {
    this.citizenId = citizenId;
    this.usid = usid;
    this.name = name;
    this.email = email;
    this.castleId = castleId;
    this.primaryDivision = primaryDivision;
    this.marketingTime = marketingTime;
    this.marketingYn = marketingYn;
    makeExtendedObservable(this);
  }

  static fromModel(domain: CitizenIdentity) {
    const params = validationUtils.checkNullableParams<CitizenIdentity, keyof CitizenIdentity>(
      domain,
      [
        'usid',
        'name',
        'email',
        'castleId',
      ]
    );

    return new CitizenIdentityCdo(
      params.citizenId,
      params.usid,
      params.name,
      params.email,
      params.castleId,
      params.primaryDivision,
      params.marketingTime,
      params.marketingYn,
    );
  }

}

export default CitizenIdentityCdo;
