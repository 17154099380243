import React from 'react';


interface Step5Props {
  routeAction: () => void;
  step: number;
}

export const JoinComplete = (props: Step5Props ) => {
  const { routeAction, step } = props;

  return (
    <>
      <div className="step-wrap">
        {
          step === 5
          ? <>
            <div className="step step-4">
                <div className="step-content">
                    <div className="member-complete mt-100">
                        <img src="/rms-conlab/images/done.png" />
                        <h4><b>회원가입</b>이 완료되었습니다.</h4>
                        <div className="done-comment mt-30">
                            메인에서 로그인 후 사용해주세요.
                        </div>
                    </div>
                </div>
                <div className="tomain-wrap">
                    <button className="btn-tomain" onClick={routeAction}>메인으로</button>
                </div>
            </div>
          </>
          : <>
              <div className="step step-4">
                <div className="step-content">
                    <div className="member-complete mt-100">
                        <img src="/rms-conlab/images/done.png" />
                        <h4><b>회원가입</b>이 완료되었습니다.</h4>
                        <div className="done-comment mt-30">
                            <b>기업회원</b>은 신청 후 <b>승인</b>이 필요합니다.<br/>
                            승인이 완료된 후 로그인하여 사용해주세요.
                        </div>
                    </div>
                </div>
                <div className="tomain-wrap">
                    <button className="btn-tomain" onClick={routeAction}>메인으로</button>
                </div>
              </div>
            </>
        }
      </div>
    </>
  );
};
