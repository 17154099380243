import axios from "axios";

export async function registerUsage(cid: string, actorId: string, email: string, cineroomId: string, cineroomName: string, contentsType: string, eventType: string) {
  await axios.post("/api/keyword/flow/usage-flow/command/register-usage", {
    actorId: actorId ?? "0@0:0:0-0",
    cid: cid,
    cineroomId: cineroomId ?? "",
    cineroomName: cineroomName ?? "",
    contentsType: contentsType,
    eamil: email ?? "",
    eventType: eventType,
  });
}