import React, { ContextType, createRef } from "react";
import "../../css/common.css";
import ThemeContentsList from "./ThemeContentsList";
import CommonContext from "../../context/CommonContext";

interface Props {
  modalType?:string,
  onCloseModal: () => void,
  onClickThemeCloseModal: () => void
  themeDetailObject: {actorId:string, audienceId:string, bannerImgPath:string, children:string, cineroomId:string, cineroomName:string, citizenId:string, cmsCategoryId:string, elementTypeId:string, entityVersion:string,
    existChild:boolean, folderType:boolean, id:string, idPaths:[], imagePath:string, langUsed:boolean, lastUpdateTime:number, modificationTime:number, lastUpdater: {id:string, name:string}, marketingType:string,
    name:string, registrationTime:number, type:string, used:boolean, writer:{id:string, name:string} , pavilionId: string, parentId: string, originCategoryId: string, originFolderId: string, seq: number, publicType: boolean
  },
  themeDetailList:object[],
}

interface State {
  isSelected: boolean;
  viewWidth: number | undefined;
}

class ModalView extends React.Component<Props, State> {
  modalRef = createRef<HTMLDivElement>();
  
  static contextType = CommonContext;
  static defaultProps: any = {};

  context!: ContextType<typeof CommonContext>;

  state: State = {
    isSelected: false,
    viewWidth: window.innerWidth
  };

  constructor(props:any) {
    super(props);
    this.setSelected = this.setSelected.bind(this);
    this.modalRef = React.createRef();
    addEventListener("resize", (event) => {this.changeViewWidth()});
  }

  componentDidMount(){}

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
    snapshot?: any
  ): void {}

  renderModalView() {
    switch(this.props.modalType) {
      case 'theme':
        return this.renderThemeListView();
    }
  }

  renderThemeListView() {
    const { themeDetailObject, themeDetailList, onCloseModal, onClickThemeCloseModal } = this.props;
    const { isSelected, viewWidth } = this.state;

    return (
      <> 
        <ThemeContentsList
          onCloseModal={onCloseModal}
          onClickThemeCloseModal={onClickThemeCloseModal}
          cardType='list'
          themeDetailList={themeDetailList}
          themeDetailObject={themeDetailObject}
          eventSetSelected={this.setSelected}
          isSelected={isSelected}
          viewWidth={viewWidth}
        />
      </>
    )
  }

  changeViewWidth(){
    this.setState({viewWidth : this.modalRef.current?.offsetWidth});
  }

  setSelected(){
    this.setState({isSelected: !this.state.isSelected});
  }

  render() {
    const { isSelected, viewWidth } = this.state;
    const { onCloseModal, onClickThemeCloseModal } = this.props;

    return (
      <div> 
        {/* @ts-ignore */}
        <div className={`recommend_detail_page ${isSelected && 1024 >= viewWidth ? undefined : 'm_detail_open'}`} style={{display: 'block'}}>
          <div className="center" style={{paddingTop: 'unset'}}>
            <button className="btn_close" onClick={() => {onCloseModal(); onClickThemeCloseModal()}}>
              <img src="/rms-conlab/images/btn_close.png" alt="btn_close" />
            </button>
            <div className="scroll_area">
              {this.renderModalView()}
            </div>
          </div>
          <div className="bg" ref={this.modalRef}></div>
        </div>
      </div>
    );
  }
}

export default ModalView;
