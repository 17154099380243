import React, { ContextType, createRef } from "react";
import "../../css/common.css";
import CardView from "./CardView";
import { ContentType } from "../../model/type/ContentType";
import { CircularProgress } from "@mui/material";
import axios from "axios";
import saveAs from "file-saver";
import moment from "moment";
import ImageSize from "../../shared/ImageSize";
import ThemeContentsDetails from "./ThemeContentsDetails";
import CommonContext from "../../context/CommonContext";
import { registerUsage } from "../../shared/Function/util/RegisterUtil";
import { SearchType } from "../../model/type/SearchType";
import { UsageType } from "../../model/type/UsageType";
import { themePdf } from "../ThemePdfDownload";
import InterestsType from "../../model/type/interestsType";

interface Props {
  themeDetailObject: {
    actorId: string, audienceId: string, bannerImgPath: string, children: string, cineroomId: string, cineroomName: string, citizenId: string, cmsCategoryId: string, elementTypeId: string, entityVersion: string,
    existChild: boolean, folderType: boolean, id: string, idPaths: [], imagePath: string, langUsed: boolean, lastUpdateTime: number, modificationTime: number, lastUpdater: { id: string, name: string }, marketingType: string,
    name: string, registrationTime: number, type: string, used: boolean, writer: { id: string, name: string }, pavilionId: string, parentId: string, originCategoryId: string, originFolderId: string, seq: number, publicType: boolean
  },
  themeDetailList: object[],
  cardType?: string,
  onCloseModal: () => void,
  onClickThemeCloseModal: () => void;
  eventSetSelected: () => void,
  isSelected: boolean;
  viewWidth: number | undefined;
  categoryId: string;
  categoryCid: string;
  contentsType: string;
  hideImg?: boolean; // 대표 이미지 반응형
  interestsType: InterestsType;
}

interface State {
  isloading: boolean;
  pdfloading: boolean;
  selectedCid: string;
  selectedContentType: ContentType;
  showShareAlert: boolean;
  minWidth: number;
  hideTage: boolean;  // 좌측 대표 이미지

  scrollActive: boolean,
  scrollY: number | undefined,
  stoppedTotal: number,
  stoppedCount: number
}

class ThemeContentsList extends React.Component<Props, State> {
  static defaultProps: any = {};
  static contextType = CommonContext;

  context!: ContextType<typeof CommonContext>;
  boxRef = createRef<HTMLDivElement>();

  constructor(props: any) {
    super(props);
    this.showContentDetail = this.showContentDetail.bind(this);
    this.showImage = this.showImage.bind(this);

    this.boxRef = React.createRef();
    this.watchScroll = this.watchScroll.bind(this);
    this.setPdfLoading = this.setPdfLoading.bind(this);
    this.downloadPDF = this.downloadPDF.bind(this);
    this.downloadThemePDF = this.downloadThemePDF.bind(this);
  }

  //@ts-ignore
  state: State = {
    isloading: false,
    pdfloading: false,
    selectedCid: '',
    showShareAlert: false,
    minWidth: 0,
    hideTage: false,

    scrollActive: false,
    scrollY: 0,
    stoppedTotal: 0,
    stoppedCount: 0
  };

  componentDidMount() {
    this.updateViewCount();
    this.deployedStoppedCount();
  }

  watchScroll() {
    this.setState({ scrollY: this.boxRef.current?.scrollTop })
    // @ts-ignore
    if (this.state.scrollY > 210) {
      this.setState({ scrollActive: true })
    } else {
      this.setState({ scrollActive: false })
    }
  }

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
    snapshot?: any
  ): void {
    if (prevProps.themeDetailObject?.id !== this.props.themeDetailObject?.id) {
      this.updateViewCount();
      this.deployedStoppedCount();
    }
    if (prevProps.themeDetailList !== this.props.themeDetailList) {
      this.initModal();
    }
    this.boxRef.current?.addEventListener("scroll", this.watchScroll);
  }

  updateViewCount() {
    const { themeDetailObject } = this.props;
    const { actorIdentity, citizenUser, accessibleCinerooms } = this.context;
    if (themeDetailObject?.id == "") {
      return;
    }
    const currentActorId = actorIdentity?.id;
    const email = actorIdentity?.email;
    const cineroomId = citizenUser?.cineroomIds[0];
    const cineroomName = accessibleCinerooms[0]?.name;
    registerUsage(themeDetailObject?.id, currentActorId, email, cineroomId, cineroomName, SearchType.Theme, UsageType.ViewCount);
  }

  updateDownloadCount(downloadType: UsageType) {
    const { themeDetailObject } = this.props;
    const { actorIdentity, citizenUser, accessibleCinerooms } = this.context;
    if (themeDetailObject?.id == "") {
      return;
    }
    const currentActorId = actorIdentity?.id;
    const email = actorIdentity?.email;
    const cineroomId = citizenUser?.cineroomIds[0];
    const cineroomName = accessibleCinerooms[0]?.name;
    registerUsage(themeDetailObject.id, currentActorId, email, cineroomId, cineroomName, SearchType.Theme, downloadType);
  }

  deployedStoppedCount = async () => {
    const { themeDetailObject } = this.props;
    const url = '/api/naomi/flow/marketing-category-contents-flow/query/find-count-by-category-id';
    const payload = {
      responseName: 'response',
      categoryId: themeDetailObject.id,
      offset: {
        offset: 0,
        limit: 10,
        totalCount: 0,
        totalCountRequested: true,
        sortDirection: 'DESCENDING',
        sortingField: 'registrationTime'
      }
    };
    try {
      const response = await axios.post(url, payload);
      this.setState({stoppedTotal: response.data.queryResult.totalCount});
      this.setState({stoppedCount: response.data.queryResult.deployedStoppedCount});
    } catch (error) {
      console.error('Error:', error);
    }
  };

  initModal() {
    const { themeDetailList } = this.props;
    if (themeDetailList.length > 0) {
      // @ts-ignore
      this.showContentDetail(themeDetailList[0].cid, themeDetailList[0].type);
    }
  }

  showContentDetail(cid: string, selectedContentType: ContentType) {
    const { eventSetSelected } = this.props
    this.setState({ selectedCid: cid, selectedContentType: selectedContentType });
    eventSetSelected()
  }

  showImage() {
    this.setState({ hideTage: false });
  }

  renderListCard() {
    const { viewWidth } = this.props;
    const cardAttribute = this.props.themeDetailList;
    const cardList = cardAttribute?.map((obj: any, i: any) =>
      <li key={i} className="recommend_view_bt" onClick={() => { this.showContentDetail(obj.cid, obj.type as ContentType); this.setState({ hideTage: !this.state.hideTage }) }}>
        <a>
          <div className="thum">
            {obj.imagePathList.length >= 1 ? (this.renderListCardView(i)) : <img src="/rms-conlab/images/alternative.svg" />}
          </div>
          <div className="info">
            <div className="tag_list">
              {obj.type === 'FormalContents' && <span><i className="tag tag_info">관광정보</i></span>}
              {obj.type === 'CasualContents' && <span><i className="tag tag_info">기사</i></span>}
              {obj.type === 'VideoContents' && <span><i className="tag tag_info">동영상</i></span>}
              {obj.type === 'ImageContents' && <span><i className="tag tag_info">이미지</i></span>}
            </div>
            <h3>{obj.contentsName}</h3>
            <p>{obj.baseCategoryName ? obj.baseCategoryName + ' | ' : null}{obj.address}</p>
          </div>
        </a>

      </li>
    );
    return (
      <div className="recommend_list_wrap">
        <ul className="recommend_list" style={(viewWidth && viewWidth < 1024) ? {} : {}}>
          {cardList}
        </ul>
      </div>
    );
  }

  renderListCardView(index: number) {
    const { cardType } = this.props
    //@ts-ignore
    const cardAttribute = this.props.themeDetailList[index].imagePathList
    return (
      <CardView
        cardType={cardType}
        cardAttribute={cardAttribute}
      >
      </CardView>
    );
  }

  renderThemeList() {
    const { themeDetailObject, themeDetailList, eventSetSelected, onClickThemeCloseModal, hideImg, onCloseModal, isSelected, viewWidth } = this.props;
    const { isloading, pdfloading, selectedCid, selectedContentType, showShareAlert, hideTage, stoppedCount } = this.state;

    var registrationTime = ''

    if (!(themeDetailObject && themeDetailList)) {
      return;
    }
    registrationTime = moment(themeDetailObject.registrationTime).format('YYYY.MM.DD');

    if (themeDetailObject.bannerImgPath?.substring(0, 1) === '|') {
      themeDetailObject.bannerImgPath = themeDetailObject.bannerImgPath.substring(1);
      if (themeDetailObject.bannerImgPath.includes('|')) {
        themeDetailObject.bannerImgPath = themeDetailObject.bannerImgPath.substring(0, themeDetailObject.bannerImgPath.indexOf('|')).replace('image3_1', 'image2_1');
      }
    }

    return (
      <>

        <div className="list_wrap" ref={this.boxRef}>
          <div className={`left rd_list ${this.state.scrollActive ? 'fixed' : null}`} style={this.props.contentsType === 'ThemeContents' ? { height: '100%' } : { height: '100%' }}>
            <div className="popup_box">
              <>
                <div className="video_area" onContextMenu={(e) => (e.preventDefault())}
                  style={hideImg !== undefined ? { zIndex: 9999, width: "520px", height: "300px" } : {}}
                >
                  <img
                    style={{ height: '300px' }}
                    className="layout-card-img"
                    src={`${themeDetailObject?.bannerImgPath}/${ImageSize.it12}`}
                    alt=""
                  />
                  <div className="va_top_bts" style={{ width: '130px' }}>

                    {isloading == true ? <CircularProgress style={{ width: 32, height: 32, color: '#eaeaea', position: 'relative' }} /> :
                      <button className="excel" onClick={() => this.downloadExel()}></button>}
                    <span className="hidetext">엑셀 다운받기</span>

                    {pdfloading == true ? <CircularProgress style={{ width: 32, height: 32, color: '#eaeaea', position: 'relative'}} /> :
                      <button className="pdf" onClick={() => {this.downloadThemePDF();}}></button>}
                    <span className="hidetext">PDF 다운받기</span>
                    
                    <button className="share" onClick={() => this.onClickShare()}></button>
                    {
                      showShareAlert && <div className="share_copy_theme"><span>URL이 복사되었습니다. 원하는 곳에 붙여 넣으세요.</span></div>
                    }
                  </div>
                  <div className="va_info">
                    <h3>{themeDetailObject.name}</h3>
                    <p className="date">{registrationTime}</p>
                    {stoppedCount > 0 ?
                      <div className="count">
                        <h5>{themeDetailObject.cineroomName}</h5>
                        <span>(총 {themeDetailList.length}건 / 미개방 {stoppedCount}건)</span>
                      </div> :
                      <div className="count">
                        <h5>{themeDetailObject.cineroomName}</h5>
                        <span>(총 {themeDetailList.length}건)</span>
                      </div>
                      }
                  </div>
                </div>
              </>
              {this.renderListCard()}
            </div>
          </div >
        </div >
        <>
          <ThemeContentsDetails showImage={this.showImage} contentsType={this.props.contentsType} cid={selectedCid} selectedContentType={selectedContentType} onCloseModal={onCloseModal} onClickThemeCloseModal={onClickThemeCloseModal} eventSetSelected={eventSetSelected} isSelected={isSelected} viewWidth={viewWidth} />
        </>
      </>
    )
  }

  async downloadExel() {
    const { themeDetailObject, themeDetailList } = this.props;
    const { openLoginModal, isLogin } = this.context;

    if (!isLogin) {
      openLoginModal();
      return;
    }

    this.setState({ isloading: true });

    await axios.post('/api/naomi/v2/file/download-theme-excel', {
      'category': themeDetailObject,
      'marketingContentsDetails': themeDetailList
    }, {
      responseType: 'blob',
    }).then((res) => {
      saveAs.saveAs(res.data, `${themeDetailObject.name}.xlsx`);
      this.setState({ isloading: false });
    }).catch((Error) => {
      console.log(Error);
      this.setState({ isloading: false });
    });
    this.updateDownloadCount(UsageType.ExcelDownloaded);
  }

  async downloadThemePDF() {
    const { themeDetailObject, themeDetailList } = this.props;
    const { openLoginModal, isLogin } = this.context;
    if (!isLogin) {
      openLoginModal();
      return;
    }
    this.setPdfLoading(true);
    themePdf(themeDetailObject, themeDetailList, this.setPdfLoading);
  }


  async downloadPDF() {
    const { themeDetailObject, themeDetailList } = this.props;
    const { openLoginModal, isLogin } = this.context;

    if (!isLogin) {
      openLoginModal();
      return;
    }
    this.setState({ pdfloading: true });

    await axios.post('/api/naomi/v2/file/download-theme-pdf', {
      'category': themeDetailObject,
      'marketingContentsDetails': themeDetailList
    }, {
      responseType: 'blob',
    }).then((res) => {
      saveAs.saveAs(res.data, `${themeDetailObject.name}.pdf`);
      this.setPdfLoading(false);
    }).catch((Error) => {
      console.log(Error);
      this.setPdfLoading(false);
    });
    this.updateDownloadCount(UsageType.PdfDownloaded);
  }

  setPdfLoading(pdfloading: boolean) {
    this.setState({ pdfloading });
  }

  onClickShare() {
    const { themeDetailObject, interestsType } = this.props;

    const { actorIdentity, citizenUser, accessibleCinerooms } = this.context;

    const currentActorId = actorIdentity?.id;
    const email = actorIdentity?.email;
    const cineroomId = citizenUser?.cineroomIds[0];
    const cineroomName = accessibleCinerooms[0]?.name;

    const { selectedCid } = this.state;
    const textarea = document.createElement('textarea');
    document.body.appendChild(textarea);

    const base_url = window.document.location.host;
    let urlValue = '';
    urlValue = `${base_url}/rms-conlab/detail?` + "categoryId=" + `${themeDetailObject.id}` + "&type=" + ContentType.ThemeContents;

    textarea.value = urlValue;
    textarea.select();
    document.execCommand('copy');
    document.body.removeChild(textarea);

    this.setState({ showShareAlert: true });
    setTimeout(() => this.setState({ showShareAlert: false }), 2000);

    registerUsage(selectedCid, currentActorId, email, cineroomId, cineroomName, interestsType, UsageType.LinkCreated);
  }

  render() {
    return (
      <>
        {this.renderThemeList()}
      </>
    )
  }
}

export default ThemeContentsList;
