import axios from "axios";
import NotieWSSpec from "../../../comp/api/pigeon/notie/api-model/sdo/NotieWSSpec";
import Notie from "../../../comp/api/pigeon/notie/api-model/Notie";
import SockJS from 'sockjs-client';
import { CompatClient, IMessage, Stomp } from '@stomp/stompjs';
import { apiURL } from "..";

const url = apiURL+'/api/pigeon';
const client = '/public/notie';

export type NotieClientType = { stompClient: CompatClient; webSocketSpec: NotieWSSpec };

export const findNotieWebSocketSpec = async () => {
    const data = {

    };

    const res: NotieWSSpec = await axios.post(url+client+'/find-notie-ws-spec', data);

    return res;
}

export const connectNotieWebSocket = (
    webSocketSpec: NotieWSSpec,
    ssl: boolean,
    subscribedUrls: string[],
    onNotified: (notie: Notie) => void,
    options?: any
) => {
    const { endpoint } = webSocketSpec;
    const hostname = window.location.hostname;
    const port = window.location.port ? ':9093' : '';
    const url = `${ssl ? 'https' : 'http'}://${hostname}${port}${endpoint}`;
  
    const socket = new SockJS(url);
  
    const onConnect = () => {
      console.log('######## NOTIE : CONNECTED TO ' + url);
  
      subscribedUrls.forEach(subUrl => {
        stompClient.subscribe(subUrl, (message: IMessage) => {
          const notie = Notie.fromDomain(JSON.parse(message.body));
  
          console.log('######## NOTIE : MESSAGE', notie);
          onNotified(notie);
        });
      });
  
      if (options && options.onConnect && typeof options.onConnect === 'function') {
        console.log('######## NOTIE : EXECUTE CUSTOM ONCONNECT FUNCTION');
        options.onConnect();
      }
    };
  
    const onStompError = () => {
      console.log('######## NOTIE : ERROR');
      if (options && options.onError && typeof options.onError === 'function') {
        console.log('######## NOTIE : EXECUTE CUSTOM ONERROR FUNCTION');
        options.onError();
      }
    };
  
    const onWebSocketClose = () => {
      console.log('######## NOTIE : CLOSED');
      if (options && options.onClose && typeof options.onClose === 'function') {
        console.log('######## NOTIE : EXECUTE CUSTOM ONCLOSE FUNCTION');
        options.onClose();
      }
    };
  
    const stompClient = Stomp.over(socket);
  
    stompClient.connect({}, onConnect, onStompError, onWebSocketClose);
  
    return { stompClient, webSocketSpec };
}
