import React, { ContextType } from "react";
import "../../css/common.css";
import ContentBuilder from "../../model/builder/ContentBuilder";
import CommonContext from "../../context/CommonContext";
import { registerUsage } from "../../shared/Function/util/RegisterUtil";
import { SearchType } from "../../model/type/SearchType";
import { UsageType } from "../../model/type/UsageType";
import { ContentType } from "../../../src/model/type/ContentType";
import { downloadPDF } from "..";
import InterestsType from "../../model/type/interestsType";
import { CircularProgress } from "@mui/material";

interface Props {
    cid: string;
    interestsType: InterestsType;
    upDateCasualSummaryDate: {
      cid: string,
      contentsName: string,
      tid: string,
      tourismName: string,
      categoryPath: string,
      imagePath: string
    }[];
    onCloseModal: () => void;
    onClickThemeCloseModal: () => void;
    eventSetSelected: () => void;
    viewWidth: number | undefined;
    noPrev?: boolean;
    contentsType: string;
    showImage?: () => void;
    scrollToTop?: () => void;
  }
  
  interface State {
    contentDetailData: any;
    showShareAlert: boolean;
    selectedContentsName: string;
    isloading: boolean;
    modifyDate: string;
    lastStatusUpdaterCineroomName: string;
  }

class CasualContentsDetails extends React.Component<Props, State> {

  static defaultProps: any = {
    noPrev: false
  };
  static contextType = CommonContext;
  context!: ContextType<typeof CommonContext>;

  constructor(props: any) {
    super(props);
    this.initContentDetailDataByCid = this.initContentDetailDataByCid.bind(this);
    this.updateViewCount = this.updateViewCount.bind(this);
    this.onClickShare = this.onClickShare.bind(this);
    this.downloadPDF = this.downloadPDF.bind(this);
    this.setLoading = this.setLoading.bind(this);
  }

  state: State = {
    contentDetailData: {},
    showShareAlert: false,
    selectedContentsName: '',
    isloading: false,
    modifyDate: '',
    lastStatusUpdaterCineroomName: '',
  };

  componentDidMount() {
    this.initContentDetailDataByCid(this.props.cid);
    this.updateViewCount();
  }

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
    snapshot?: any
  ): void {
    if (prevProps.cid !== this.props.cid) {
        this.initContentDetailDataByCid(this.props.cid);
        this.updateViewCount();
    }

    if(prevProps.upDateCasualSummaryDate !== this.props.upDateCasualSummaryDate){
      this.setState({contentDetailData : {}})
   }
  }

  async initContentDetailDataByCid(cid: string) {
    const { scrollToTop } = this.props;
    const contentBuilder = new ContentBuilder(cid);
    await contentBuilder.buildCasualContent();
    const contentDetailData = contentBuilder.getContentDetailInfo();
    this.setState({
      contentDetailData: contentDetailData, 
      selectedContentsName: contentBuilder.getContentDetail().element.name
    });

    await contentBuilder.findLastStatusCineroomName();
    console.log('lastStatusUpdaterCineroomName: ', contentBuilder.lastStatusUpdaterCineroomName);
    this.setState({ lastStatusUpdaterCineroomName: contentBuilder.lastStatusUpdaterCineroomName });
    const modifyDate = contentBuilder.contentDetail.element.modificationTime;
    if (modifyDate) {
      const currentDate = new Date(modifyDate);
      const year = currentDate.getFullYear();
      const month = String(currentDate.getMonth() + 1).padStart(2, '0');
      const day = String(currentDate.getDate()).padStart(2, '0');
      this.setState({modifyDate: `${year}년 ${month}월 ${day}일`});
    }
    if (scrollToTop) {
      scrollToTop();
    }
  }

  updateViewCount() {
    const { cid } = this.props;
    const { actorIdentity, citizenUser, accessibleCinerooms } = this.context;
    if (!cid) {
      return;
    }
    const currentActorId = actorIdentity?.id;
    const email = actorIdentity?.email;
    const cineroomId = citizenUser?.cineroomIds[0];
    const cineroomName = accessibleCinerooms[0]?.name;
    registerUsage(cid, currentActorId, email, cineroomId, cineroomName, SearchType.Casual, UsageType.ViewCount);
  }

  onClickShare(){

    const { actorIdentity, citizenUser, accessibleCinerooms } = this.context;
    const { cid, interestsType } = this.props; 

    const currentActorId = actorIdentity?.id;
    const email = actorIdentity?.email;
    const cineroomId = citizenUser?.cineroomIds[0];
    const cineroomName = accessibleCinerooms[0]?.name;

    const textarea = document.createElement('textarea');
    document.body.appendChild(textarea);

    const base_url = window.document.location.host;
    let urlValue = '';
    urlValue = `${base_url}/rms-conlab/detail?` + "cid=" + this.props.cid + "&type=" + ContentType.CasualContents;

    textarea.value = urlValue;
    textarea.select();
    document.execCommand('copy');
    document.body.removeChild(textarea);

    this.setState({ showShareAlert: true });
    setTimeout(() => this.setState({ showShareAlert: false }), 2000);

    registerUsage(cid, currentActorId, email, cineroomId, cineroomName, interestsType, UsageType.LinkCreated);
  }

  // pdf download strat
  downloadPDF = async () => {
    const { openLoginModal, isLogin } = this.context;

    if (!isLogin) {
      openLoginModal();
      return;
    }
    this.setLoading(true);
    downloadPDF(this.setLoading, this.props.cid, ContentType.CasualContents);
  };

  setLoading(isLoading: boolean) {
    this.setState({ isloading: isLoading })
  }
  // pdf download end

  render() {
    const { contentDetailData, showShareAlert, selectedContentsName, isloading, modifyDate, lastStatusUpdaterCineroomName } = this.state;
    const { viewWidth, noPrev, eventSetSelected, onCloseModal, onClickThemeCloseModal, showImage } = this.props;

    if (Object.keys(contentDetailData).length === 0) {
        return;
    }
    return(
        <>
          <div className="rd_view_item">
            {/* @ts-ignore */}
            {viewWidth === undefined ? null :
              <div className="m_head">
              { noPrev === false && (<button className="rd_view_prev_bt" onClick={showImage ? ()=>{eventSetSelected(); showImage();} : () => (eventSetSelected())}><img src="/rms-conlab/images/icon_rd_view_prev.png" alt="close_icon"/></button>)}
                  {this.props.contentsType === 'ThemeContents' ? null :
                      <button className="close_bt rd_view_close_bt" onClick={() => {onCloseModal(); onClickThemeCloseModal();}}><img src="/rms-conlab/images/icon_menu_close.svg" alt="close_icon"/></button>
                  }
              </div>
              }

              <div className="contents_detail_page">
                <div className="cdp_tit_area" style={{justifyContent: 'end'}}>
                  <h2 className="cdp_tit" style={{marginTop: '30px', marginLeft:'20px'}}>{selectedContentsName}</h2>
                  <div className="bts" style={{position: 'relative',top: '30px', right: '2px'}}>
                      {isloading ?
                        <CircularProgress className="pdf-progress" style={{marginTop:'-4px'}}/> :
                        <button className="pdf-btn" style={{marginTop:'-4px'}} onClick={() => {this.downloadPDF()}}></button>
                      }
                      <div className='content-modify-date' style={{display: 'none'}}>{modifyDate}</div>
                      <div className='content-cineroom-name' style={{display: 'none'}}>{lastStatusUpdaterCineroomName}</div>
                      <button className="share" onClick={() => this.onClickShare()}></button>
                      {
                          showShareAlert && <div className="share_copy_casual"><span>URL이 복사되었습니다. 원하는 곳에 붙여 넣으세요.</span></div>
                      }
                  </div>
                </div>
                <div className="onclone-div" style={{fontSize: 22, marginTop: '16px', display: 'none'}}>onclone div</div>
                <div style={{marginTop:'60px'}} dangerouslySetInnerHTML={{ __html: contentDetailData.baseInfo.value.body.value }} onContextMenu={(e) => (e.preventDefault())}></div>
              </div>
          </div>
        </>
    )
  }
}

export default CasualContentsDetails;
