import PasswordResetInfo from "../Password/PasswordResetInfo";
import { LoginIdType } from "../type/LoginIdType";

class CitizenUser {
  loginId: string;
  displayName: string;
  encryptedPassword: string;
  email: string;
  citizenId: string;
  domainUrl: string;
  idType: LoginIdType | null = null;
  cineroomIds: string[] = [];
  loggedin: boolean;
  additionalInformation: Object | null = null;
  passwordResetInfo: PasswordResetInfo | null = null;

  accessToken: string = '';

  constructor(loginId: string, displayName: string, citizenId: string = '', encryptedPassword: string = '', email: string = '', domainUrl: string = '', loggedin: boolean = true) {
    this.loginId = loginId;
    this.displayName = displayName;
    this.encryptedPassword = encryptedPassword;
    this.email = email;
    this.citizenId = citizenId;
    this.domainUrl = domainUrl;
    this.loggedin = loggedin;
  }

  static new(): CitizenUser {
    return new CitizenUser('', '', '', '', '', '', false);
  }
}

export default CitizenUser;
