import React, { Component } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import App from './App';
import { HelmetProvider } from 'react-helmet-async';
import ReactGA from 'react-ga4';
import axios from 'axios';
import { checkProxy } from './shared/Function/checkpoint/Function';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const GA_TRACKING_ID = process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID;

interface State {
  gaTagLoad: boolean;
}

class RootComponent extends Component<{}, State> {
  constructor(props: {}) {
    super(props);
    this.state = {
      gaTagLoad: false,
    };
  }

  async componentDidMount() {
    const data = await checkProxy();
    if (!data) {
      if (data === false) {
        this.setState({ gaTagLoad: true });
      }
    }
  }

  componentDidUpdate(prevProps: {}, prevState: State) {
    if (this.state.gaTagLoad && !prevState.gaTagLoad) {
      // Google Analytics 초기화
      ReactGA.initialize(GA_TRACKING_ID as string);
    }
  }

  render() {
    return (
      <HelmetProvider>
        <React.StrictMode>
          <App />
        </React.StrictMode>
      </HelmetProvider>
    );
  }
}

root.render(<RootComponent />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
