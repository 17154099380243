import React from 'react';
import axios from 'axios';
import { PieChart, Pie, Cell, Legend } from 'recharts';

interface Props {
    regionCode : number
    startYearMonth : string;
    endYearMonth : string;
    period : string;
    clickCount: number;
    viewWidth: number | undefined
}

interface State {
    chartData : [],
    forceRender: number;
}

class RegionCategoryChartMobile extends React.Component<Props, State> {

    state: State = {
        chartData : [],
        forceRender: 0
    };

    componentDidMount() {
        this.regionCategoryChart();
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
        if (this.props.period != prevProps.period || this.props.regionCode != prevProps.regionCode || this.props.clickCount != prevProps.clickCount){
            this.regionCategoryChart();
        } 
    }

    async regionCategoryChart() {
        try {
            const response = await axios.get('/api/naomi/v2/statistics/region-category-chart',{
                params : {
                    period : this.props.period,
                    beginYM : this.props.startYearMonth,
                    endYM : this.props.endYearMonth,
                    code : this.props.regionCode
                }
            });
            this.setState({chartData: response.data.queryResult, forceRender: this.state.forceRender + 1});
        } catch (e) {
            console.error(e);
        }
    }

    //@ts-ignore
    renderCustomizedLabel({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) {
    const RADIAN = Math.PI / 180;
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
            <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
                {`${(percent * 100).toFixed(0)}%`}
            </text>
    )};

    render() {

        const COLORS = ['#5fc6c5', '#5487fd'];

        //@ts-ignore
        const combine = this.props.viewWidth

        return(
            <div className='region-category-chart-view-mobile' style={{display: 'flex', justifyContent: 'center', paddingTop: '30px'}}>
                <PieChart width={combine} height={combine}>
                    <Pie data={this.state.chartData} labelLine={false} label={this.renderCustomizedLabel} outerRadius={110} fill="#8884d8" dataKey="value">
                        {this.state.chartData.map((entry, index) => (<Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]}/>))}</Pie>
                        <Legend verticalAlign="bottom" iconType='circle' iconSize={11}/>
                </PieChart>

                {/* {
                this.props.viewWidth <= 360 ? 
                <PieChart width={350} height={300}>
                <Pie data={this.state.chartData} labelLine={false} label={this.renderCustomizedLabel} outerRadius={110} fill="#8884d8" dataKey="value">
                    {this.state.chartData.map((entry, index) => (<Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]}/>))}</Pie>
                    <Legend verticalAlign="bottom" iconType='circle' iconSize={11}/>
                </PieChart>
                : 
                    (this.props.viewWidth <= 482 ? 
                        <PieChart width={350} height={300}>
                        <Pie data={this.state.chartData} labelLine={false} label={this.renderCustomizedLabel} outerRadius={110} fill="#8884d8" dataKey="value">
                            {this.state.chartData.map((entry, index) => (<Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]}/>))}</Pie>
                            <Legend verticalAlign="bottom" iconType='circle' iconSize={11}/>
                    </PieChart>
                    : 
                    <PieChart width={350} height={300}>
                    <Pie data={this.state.chartData} labelLine={false} label={this.renderCustomizedLabel} outerRadius={110} fill="#8884d8" dataKey="value">
                        {this.state.chartData.map((entry, index) => (<Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]}/>))}</Pie>
                        <Legend verticalAlign="bottom" iconType='circle' iconSize={11}/>
                </PieChart>
                    )
                } */}
            </div>
        )
    }
    }

export default RegionCategoryChartMobile;

