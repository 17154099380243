import React from "react";
import "../../css/common.css";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, Autoplay } from 'swiper/modules';
import SwiperCore from 'swiper';
import axios from "axios";
import CardView from "../main-theme/CardView";
import { ContentType } from "../../model/type/ContentType";

interface Props {
  listBannerObject?: {id: string , title:string, subTitle:string, type: string ,seq: number, moreLink: string, moreLinkYn: string}
  bannerTitle?:string,
  bannerId?:string,
  navButton?:boolean,
  cardType?:string,
  cardAlign?:string,
  countContent?:boolean,
  bannerImage?: { image: string; }[],
  onClickDetailModal?: (modalType: string, categoryId: string) => void,
  activeCategoryTab?:string,
  categoryObject?: {address: string, baseCategoryName:string, categoryId:string, categoryPath:string, cid:string, contentsName: string, id:string, imagePathList:[], introduction:string, tagNamesList:[], type:string}[]
  recommendThemeObject?:object[],
  themeDetailList?:object[],
  totalCount?: number,
  renderObject?:{ id: string, image1:string, image2: string, image3: string, linkId:string, linkUrl:string, seq: number }[]
  categoryPat?: any;
  showContentDetail?:(cid: string, selectedContentType: ContentType) => void;
  noTitle?: boolean;
  boxInfo?: boolean;
}

interface State {
  listBannerList?: {
    id:string,
    bannerName: string,
    categoryName: string,
    cineroomName: string,
    contentAddr:string,
    entityVersion:number,
    imagePathList:[],
    linkId: string,
    linkTarget:string,
    linkType:string,
    linkUrl:string,
    cid: string,
    categoryId:string,
    description:string,
    cardType: string,
  }[],
}

class CardBannerView extends React.Component<Props, State> {
  static defaultProps: any = {
    noTitle: false
  };

  constructor(props:any) {
    super(props);
    this.showContentDetail = this.showContentDetail.bind(this);
  }

  state: State = {}

  componentDidMount() {
    this.getListBannerDetail();
  }

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
    snapshot?: any
  ): void {}

  //@ts-ignore
  state: State = {};

  async getListBannerDetail() {
    try {
      const {listBannerObject} = this.props
      if (listBannerObject?.id) {
        const response = await axios.get('/api/naomi/flow/banner-flow/query/find-list-banner-detail', {
          params: {
            id: listBannerObject?.id
          }
        });
        this.setState({listBannerList : response.data.queryResult.listBannerList})
      }

    } catch (e) {
        console.error(e);
    }
  }

  renderCardContainerView() {
    switch(this.props.cardType) {
      case 'theme3':
        return this.renderFirstCardView();
      case 'theme4':
        return this.renderSecondCardView();
    }
  }

  showContentDetail(cid: string, contentType: ContentType){
    //@ts-ignore
    this.props.showContentDetail(cid, contentType);
  }
  handleMoreButtonClick = (moreLink: string) => {
    // 더보기 버튼 클릭 시 해당 링크로 이동
    window.location.href = moreLink;
  }

  // theme:3, sequence:2
  renderThemeThreeSeqTwo() {
    SwiperCore.use([Navigation, Pagination, Autoplay]);
    const { listBannerObject, noTitle = false } = this.props
    return (
        <div className="recommend_slide_wrap">
            {noTitle === false && (
              <>
                <div style={{ display: 'flex' }}>
                  <div style={{ flex: 1 }}><h2 className="cb_tit">{listBannerObject?.title}</h2></div>
                  <div style={{ flex: 1, textAlign: 'right' }}>
                    {listBannerObject?.moreLinkYn === 'Y' && (
                        <button className="detailsBtn" onClick={() => this.handleMoreButtonClick(listBannerObject.moreLink)}>더보기<img src="/rms-conlab/images/icon_move_newtab.png" alt="icon" /></button>
                    )}
                  </div>
                </div>
                <p className="cb_tit">{listBannerObject?.subTitle}</p>
              </>
            )}
            <Swiper
                //@ts-ignore
                modules={[Navigation, Pagination, Scrollbar]}
                pagination={{clickable: true}}
                className="recommend_slide"
                slidesPerView={1}
                navigation = {{
                  nextEl: ".swiper-button-next",
                  prevEl: ".swiper-button-prev",
                }}
                style={{paddingRight: '5px'}}
                breakpoints={{
                  1500: {slidesPerView: 4},
                  1440: {slidesPerView: 3},
                  1441: {slidesPerView: 4},
                  1400: {slidesPerView: 3},
                  1300: {slidesPerView: 3},
                  1200: {slidesPerView: 3},
                  1100: {slidesPerView: 3}, 
                  1025: {slidesPerView: 3},
                  1000: {slidesPerView: 2},
                  900 : {slidesPerView: 2},
                  800 : {slidesPerView: 2},
                  700 : {slidesPerView: 2},
                  600 : {slidesPerView: 2},
                  500 : {slidesPerView: 2},
                  400 : {slidesPerView: 2},
                  300 : {slidesPerView: 2, spaceBetween: 10},
                  200 : {slidesPerView: 2, width: 245},
                }}
              >
                  {this.renderFirstCardSlideView()}
            </Swiper>
              <div className="swiper-button-next"><img src="/rms-conlab/images/move_next_L.svg" alt="move_next"/></div>
              <div className="swiper-button-prev"><img src="/rms-conlab/images/move_previous_L.svg" alt="move_previous"/></div>
        </div>
    )
  }


  // theme:3, sequence:2, card
  renderCardThemeThreeSeqTwo(){
    const {onClickDetailModal, cardType, listBannerObject} = this.props
    const cardAttribute = this.state.listBannerList
    const cardList = cardAttribute?.map((obj:any, i:any) =>
      <SwiperSlide key={i}>
            <CardView
              listBannerObject={listBannerObject}
              cardType={cardType}
              image={obj.imagePathList[0].imageUrl}
              contentTitle= {obj.bannerName}
              cineroomName={obj.cineroomName}
              onClickDetailModal = {onClickDetailModal}
              contentPath={obj.cineroomName}
              contentAddress={obj.contentAddr}
              id={obj.id}
              cid={obj.cid}
              categoryId={obj.categoryId}
              themeDescription={obj.description}
              registrationTime={obj.registrationTime}
              boxInfo={this.props.boxInfo}
              >
            </CardView>
    </SwiperSlide>

    );
    return (
      cardList
    );
  }
  // theme:3, sequence:4
  renderThemeThreeSeqFour() {
    const { listBannerObject, noTitle = false } = this.props
    return (
      <>
        {noTitle === false && (
          <>
            <div style={{ display: 'flex' }}>
              <div style={{ flex: 1 }}><h2 className="cb_tit">{listBannerObject?.title}</h2></div>
              <div style={{ flex: 1, textAlign: 'right' }}>
                {listBannerObject?.moreLinkYn === 'Y' && (
                    <button className="detailsBtn" onClick={() => this.handleMoreButtonClick(listBannerObject.moreLink)}>더보기<img src="/rms-conlab/images/icon_move_newtab.png" alt="icon" /></button>
                )}
              </div>
            </div>
            <p className="cb_tit">{listBannerObject?.subTitle}</p>
          </>
        )}
          <ul className='public_relations_list' style={{paddingLeft: '5px'}}>
            {this.renderFirstCardSlideView()}
          </ul>
      </>
    )
  }
  // theme:3, sequence:4, card
  renderCardThemeThreeSeqFour(){
    const {onClickDetailModal, cardType, listBannerObject} = this.props
    const cardAttribute = this.state.listBannerList
    const cardList = cardAttribute?.map((obj:any, i:any) =>
          <CardView
            key={i}
            listBannerObject={listBannerObject}
            cardType={cardType}
            image={obj.imagePathList[0].imageUrl}
            contentTitle= {obj.bannerName}
            cineroomName={obj.cineroomName}
            onClickDetailModal = {onClickDetailModal}
            contentPath={obj.cineroomName}
            contentAddress={obj.contentAddr}
            id={obj.id}
            cid={obj.cid}
            categoryId={obj.categoryId}
            themeDescription={obj.description}
            registrationTime={obj.registrationTime}
            >
          </CardView>
    );
    return (
      cardList
    );
  }

  renderFirstCardView() {
    const { listBannerObject } = this.props
    if(listBannerObject?.seq === 2 && listBannerObject?.type === 'theme3') {
      return (
        this.renderThemeThreeSeqTwo()
      )
    } else if((listBannerObject?.seq === 4 || listBannerObject?.seq === 5) && listBannerObject?.type === 'theme3') {
      return (
        this.renderThemeThreeSeqFour()
      )
    }
  }

  renderFirstCardSlideView() {
    const { listBannerObject } = this.props
    if(listBannerObject?.seq === 2 && listBannerObject?.type === 'theme3') {
      return(
        this.renderCardThemeThreeSeqTwo()
      )
    } else if((listBannerObject?.seq === 4 || listBannerObject?.seq === 5) && listBannerObject?.type === 'theme3'){
      return(
        this.renderCardThemeThreeSeqFour()
      )
    }
  }

  renderSecondCardView() {
    const {listBannerObject} = this.props
    return (
      <>
        <div style={{ display: 'flex' }}>
          <div style={{ flex: 1 }}><h2 className="cb_tit">{listBannerObject?.title}</h2></div>
          <div style={{ flex: 1, textAlign: 'right' }}>
            {listBannerObject?.moreLinkYn === 'Y' && (
                <button className="detailsBtn" onClick={() => this.handleMoreButtonClick(listBannerObject.moreLink)}>더보기<img src="/rms-conlab/images/icon_move_newtab.png" alt="icon" /></button>
            )}
          </div>
        </div>
        <p className="cb_tit">{listBannerObject?.subTitle}</p>
          <ul className="public_relations_list" style={{paddingLeft: '5px'}}>
            {this.renderSecondCardSlideView()}
          </ul>
      </>
    )
  }
  
  // theme:4, card
  renderSecondCardSlideView() {
    const {onClickDetailModal, cardType} = this.props
    const cardAttribute = this.state.listBannerList

    const cardList = cardAttribute?.map((obj:any, i:any) =>
        <CardView
          key={i}
          cardType={cardType}
          image={obj.imagePathList[0].imageUrl}
          contentTitle= {obj.bannerName}
          cineroomName={obj.cineroomName}
          onClickDetailModal = {onClickDetailModal}
          contentPath={obj.cineroomName}
          contentAddress={obj.contentAddr}
          id={obj.id}
          cid={obj.cid}
          categoryId={obj.categoryId}
          themeDescription={obj.description}
          registrationTime={obj.registrationTime}
          >
        </CardView>
      );
    return (
      cardList
    );
  }

  render() {
    return (
      <>
        {this.renderCardContainerView()}
      </>
    );
  }
}

export default CardBannerView;
