/**
 * XGrid operator types
 */
enum XGridOperator {
  Contains = 'contains',
  Equals = 'equals',
  StartsWith = 'startsWith',
  EndsWith = 'endsWith',
}

export default XGridOperator;
