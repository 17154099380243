/* eslint-disable camelcase */
import React, { createRef } from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import _ from 'lodash';
import Marker from './Marker';
import LocInfo from '../model/LocInfo';

interface Props {
  clickMarker: LocInfo[];
}

interface State {
  map: any;
  marker: any | null;
  clickedMarker: LocInfo[];
}

class KakaoMultiMap extends React.Component<Props, State> {

  private defaultLevel = 8;

  mapRef = createRef<HTMLDivElement>();

  constructor(props:any) {
    super(props);
    this.mapRef = React.createRef();
  }

  state = {
    map: {} as any,
    marker: null,
    clickedMarker: [LocInfo.newGyeongbokgung(5)],
  };

  componentDidMount() {
    //
    return this.init();
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
    const { clickMarker } = this.props;

    if (clickMarker !== prevProps.clickMarker) {
      return this.init();
    }

    return undefined;
  }

  makeOverListener(map: any, marker: any, infowindow: any) {
    return () => {
      infowindow.open(map, marker);
    };
  }

  makeOutListener(infowindow: any) {
    return () => {
      infowindow.close();
    };
  }

  calculateCenter(locations: LocInfo[]) {
    if (locations.length === 0) {
      throw new Error('위치 데이터가 비어 있습니다.');
    }

    const total = locations.reduce(
      (accumulator: any, location: any) => {
        accumulator.lat += Number(location.lat);
        accumulator.lng += Number(location.lng);
        return accumulator;
      },
      { lat: 0, lng: 0 }
    );

    const avgLat = total.lat / locations.length;
    const avgLng = total.lng / locations.length;

    return {
      lat: avgLat,
      lng: avgLng,
    };
  }

  init() {
    const { clickMarker } = this.props;

    const script = document.createElement('script');

    script.src = '//dapi.kakao.com/v2/maps/sdk.js?appkey=30730ac4e3d67c522312a032d1986a3b&autoload=false&libraries=services';
    document.head.appendChild(script);

    script.onload = () => {
      if (clickMarker.length > 0) {

        kakao.maps.load(() => {
          const container = this.mapRef.current;

          if (!container) {
              return;
          }
          // 중심 좌표값 계산
          const center = this.calculateCenter(clickMarker);
          const level = this.defaultLevel;

          const options = {
            center: new kakao.maps.LatLng(center.lat, center.lng),
            level,
          };
          // const container = document.querySelector('#map');

          const positions = [];

          // 지도 level
          const bounds = new kakao.maps.LatLngBounds();
          // 선
          const lineList = [];
          for (let i=0; i < clickMarker.length; i++) {
            if (clickMarker[i].lat && clickMarker[i].lng) {
              const obj = {
                content: '',
                latlng: ''
              };
              const centerLoc = new kakao.maps.LatLng(Number(clickMarker[i].lat), Number(clickMarker[i].lng));
              bounds.extend(centerLoc);
              lineList.push(centerLoc);
              obj.content = clickMarker[i].placeName;
              obj.latlng = centerLoc;
              positions.push(obj);
            }
          }

          // 선 생성
          const line = new kakao.maps.Polyline({
            path: lineList,
            strokeWeight: 3,
            strokeColor: '#FF0000', // 선 색상 (빨간색)
            strokeOpacity: 0.7,
            strokeStyle: 'solid',
          });

          // eslint-disable-next-line no-new
          const map = new kakao.maps.Map(container, options);
          // 지도 level setting
          map.setBounds(bounds);
          // 선 지도에 추가
          line.setMap(map);

          const colorPalette = this.divideRgbByLength(positions.length);

          for (let i = 0; i < positions.length; i++) {
            const svgString = encodeURIComponent(renderToStaticMarkup(<Marker color={`rgb(${colorPalette[i].red}, ${colorPalette[i].green}, ${colorPalette[i].blue})`} number={i+1} width={29} height={42}/>));
            const dataUri = `data:image/svg+xml,${svgString}`;
            const imageSrc = dataUri; // 마커이미지의 주소입니다
            const imageSize = new kakao.maps.Size(29, 42); // 마커이미지의 크기입니다
            const imageOption = {offset: new kakao.maps.Point(16, 36)}; // 마커이미지의 옵션입니다. 마커의 좌표와 일치시킬 이미지 안에서의 좌표를 설정합니다.
            // 마커의 이미지정보를 가지고 있는 마커이미지를 생성합니다
            const markerImage = new kakao.maps.MarkerImage(imageSrc, imageSize, imageOption);

            // 마커를 생성
            const marker = new kakao.maps.Marker({
              map,
              position: positions[i].latlng, // 마커의 위치
              image: markerImage // 마커이미지 설정
            });

            // 마커에 표시할 인포윈도우 생성
            const infowindow = new kakao.maps.InfoWindow({
              content: positions[i].content,
            });

            kakao.maps.event.addListener(marker, 'mouseover', this.makeOverListener(map, marker, infowindow));
            kakao.maps.event.addListener(marker, 'mouseout', this.makeOutListener(infowindow));
          }

          kakao.maps.event.addListener(map, 'zoom_changed', () => {
            this.onChangeMapLevel(map.getLevel());
          });

          this.setState({ map });
        });
      }
    };

    return () => script.remove();
  }

  onChangeMapLevel(level: number) {
    //
    this.setState(({ clickedMarker: prevClickedMarker }) => ({
      clickedMarker: _.set(prevClickedMarker, 'level', level),
    }));
  }

  divideRgbByLength(length: number) {
    const maxRed = 230;
    const minRed = 7;
    const maxGreen = 56;
    const minGreen = 7;
    const maxBlue = 230;
    const minBlue = 7;
    const rangeOfRed = maxRed - minRed;
    const rangeOfGreen = maxGreen - minGreen;
    const rangeOfBlue = maxBlue - minBlue;
    const dividedRed = Math.floor(rangeOfRed / length);
    const dividedGreen = Math.floor(rangeOfGreen / length);
    const dividedBlue = Math.floor(rangeOfBlue / length);
    const returnDividedRGb: any = [];
    let redColor = maxRed;
    let greenColor = minGreen;
    let blueColor = minBlue;

    for (let i = 0; i < length; i++) {
      redColor -= dividedRed;
      greenColor += dividedGreen;
      blueColor += dividedBlue;

      let returnRgb = {};

      if (i === 0) {
        returnRgb = {
          red: maxRed,
          green: minGreen,
          blue: minBlue
        };
      } else if (i === length - 1) {
        returnRgb = {
          red: minRed,
          green: maxGreen,
          blue: maxBlue
        };
      } else {
        returnRgb = {
          red: redColor,
          green: greenColor,
          blue: blueColor
        };
      }

      returnDividedRGb.push(returnRgb);
    }

    return returnDividedRGb;
  }

  render() {

    return (
      <div id="map" style={{ width: '720px', height: '480px' }} ref={this.mapRef}/>
    );
  }
}

export default KakaoMultiMap;
