import React from "react";

interface Props {}

interface State {}

class IntroductionPage extends React.Component<Props, State> {
  static defaultProps: any = {};

  componentDidMount() {}

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
    snapshot?: any
  ): void {}

  //@ts-ignore
  state: State = { ...this.props };

  render() {
    return (
      <>
        <div id="contents" className="intro">
          <section className="intro_visual_wrap">
            <div className="center nav_wrap">
              <ul className="nav">
                <li>
                  <i className="icon home"></i>
                </li>
                <li>
                  소개
                  {/* <i className="icon down_circle"></i> */}
                </li>
                <li>
                  <span>한국관광콘텐츠랩이란?</span>
                  {/* <i className="icon up_circle"></i> */}
                </li>
              </ul>
            </div>
            <div className="center">
              <div className="intro_visual">
                <h1>
                    <span className="intro-tit">한국관광콘텐츠랩은</span><br/>
                    <span className="intro_des" style={{fontWeight: 700}}>
                      다양한 유형의 관광콘텐츠를 한 곳에 모아,<br/> 
                      관광업계 종사자 모두가 쉽고 편리하게<br/> 
                      관광콘텐츠를 이용할 수 있도록 지원하는<br/> 
                      디지털 관광콘텐츠 통합·개방 플랫폼입니다.
                    </span>
                </h1>
                <img
                  className="m_bg_img"
                  src="/rms-conlab/images/intro_visual_bg_m768.png"
                  alt=""
                />
                <div className="links">
                  {/* <button className="st_point">
                    관광콘텐츠 얼라이언스란?{" "}
                    <img src="/rms-conlab/images/icon_link_download1.png" alt="icon" />
                  </button>
                  <button className="st_basic">
                    한국관광 디지털 얼라이언스란?{" "}
                    <img src="/rms-conlab/images/icon_link_download2.png" alt="icon" />
                  </button> */}
                </div>
              </div>

              <div className="overview">
                <ul>
                  <li>
                    <span className="icon">
                      <img
                        src="/rms-conlab/images/icon_overview_access.png"
                        alt="icon"
                      />
                    </span>
                    <h2>Open Access</h2>
                    <p>
                      워크스페이스에서는 누구나 쉽게 참여하여 오픈형 콘텐츠를 만들 수 있습니다. 한국관광공사, 지자체 및 RTO, 관광업계, 일반사용자 등 다양한 분야의 사람들과 함께 콘텐츠를 관리하고 활용해보세요.
                    </p>
                  </li>
                  <li>
                    <span className="icon">
                      <img
                        src="/rms-conlab/images/icon_overview_content.png"
                        alt="icon"
                      />
                    </span>
                    <h2>Open Content</h2>
                    <p>
                      다양한 관점과 능력을 가진 사람들이 함께 콘텐츠를 만들 수 있습니다. 한국관광공사에서 제공하는 콘텐츠를 활용하거나 원하는대로 나만의 콘텐츠를 구성하여 등록하고 관리해보세요.
                    </p>
                  </li>
                  <li>
                    <span className="icon">
                      <img
                        src="/rms-conlab/images/icon_overview_licence.png"
                        alt="icon"
                      />
                    </span>
                    <h2>Open Licence</h2>
                    <p>
                      한국관광콘텐츠랩에서 개방하고 있는 관광콘텐츠는 공공저작물 정책에 의거 지정된 유형에 따라 자유롭게 이용할 수 있습니다.
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </section>
        </div>
      </>
    );
  }
}

export default IntroductionPage;
