import React, { createRef } from 'react';
import "../../css/style.min.css";
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import autobind from '../../lib/decorator/autobind/autobindDecorator';
import { observer } from 'mobx-react';
import FileItemModel from '../../comp/state/Depot/FileBox/model/FileItemModel';
import AddressFormModel from '../../model/Adress/AddressFormModel';
import OrgProposal from '../api-model/OrgProposal';
import { OrgType } from '../types/OrgType';
import XGridFilter from '../../model/xGridUtils/XGridFilter';
import XGridStateKeeper from '../../model/xGridUtils/keeper/XGridStateKeeper';
import ReactComponent from '../../lib/module/ReactComponent/ReactComponent';
import ServiceInjector from '../../lib/module/ServiceInjector/Serviceinjector';
import CommonModal from '../../conlab/CommonModal';

type ExtractFunction = (object: any, index: number, xGridFilter: XGridFilter | null, editRows: string[], totalLength: number) => {}

interface Props {
  orgProposal: OrgProposal;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onChangeLicenceId: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onSubmit: (event: React.MouseEvent) => void;
  
  validateEmail: boolean;

  orgType: OrgType;

  renderAddressField: () => React.ReactNode;
  handleAddress: (data: AddressFormModel) => void;
  onClickCancel: () => void;
  openAddressForm: boolean;

  onClickLicenceIdCheck: (licenceId: string) => void;
  isValidLicenceId: boolean;
  isLicenceIdChecked: boolean;

  onUploadFile: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onClickRemove: (selectedFileIds: string[]) => void;
  onClickRowRemove: (selectRowFile: FileItemModel) => void;
  fileBoxFiles: FileItemModel[];

  submitBtn: boolean;
}

interface State {
  validateEmail: boolean;
  isLicenceIdChecked: boolean;
  isValidLicenceId: boolean;
  selectedFileIds: string[];
  fileDatas: Object[];
  openModal: boolean;
  modalText: string;
}

interface InjectedProps {
  xGridStateKeeper: XGridStateKeeper;
}

@autobind
@observer
class OrgProposalFormView extends ReactComponent<Props, State, InjectedProps> {

  mngRef = createRef<HTMLInputElement>();
  mngTelRef = createRef<HTMLInputElement>();
  mngEmailRef = createRef<HTMLInputElement>();
  orgNameRef = createRef<HTMLInputElement>();
  orgTelRef = createRef<HTMLInputElement>();
  licenceRef = createRef<HTMLInputElement>();

  state = {
    open: false,
    openRequiredAlert: false,
    alertText: '',
    validateEmail: true,
    isLicenceIdChecked: false,
    isValidLicenceId: false,
    selectedFileIds: [],
    fileDatas: [],
    openModal: false,
    modalText: ''
  };

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
    const { fileBoxFiles: prevFileBoxFiles } = prevProps;
    if (prevFileBoxFiles !== this.props.fileBoxFiles) {
      this.setState({ fileDatas: [] });
      this.getRows();
    }
  }

  openModal(txt: string) {
    this.setState({ openModal: true, modalText: txt });
  }

  closeModal() {
    this.setState({ openModal: false, modalText: '' });
  }

  isCheck(selectionModel: any[]) {

    this.setState({ selectedFileIds: selectionModel });
  }

  getLicenceIdHelperText() {
    const { orgProposal, isValidLicenceId, isLicenceIdChecked } = this.props;

    if (!orgProposal.licenceId || !isLicenceIdChecked) {
      return undefined;
    }
    else if (isValidLicenceId) {
      return(
        <div style={{ fontSize: '12px'}}>사용 가능한 사업자 번호입니다.</div>
      );
    }
    else {
      return(
        <div style={{ fontSize: '12px', color: 'crimson'}}>이미 사용중인 사업자 번호이므로 가입 할 수 없습니다. 다시 확인해 보세요.</div>
      );
    }
  }

  onSubmit(event: React.MouseEvent) {
    const { onSubmit, orgProposal, isLicenceIdChecked, isValidLicenceId, fileBoxFiles } = this.props;
    if (!orgProposal.managerEmail) {
      this.openModal('이메일은 필수입력 항목입니다. \n 이메일을 입력해주세요.');
      window.scrollTo({ top: 10, behavior: 'smooth' });
    }
    else if (!this.validateEmail(orgProposal.managerEmail)) {
      this.openModal('입력하신 이메일이 이메일 형식에 맞지 않습니다.');
      this.mngEmailRef.current?.scrollIntoView({ behavior: 'smooth' });
      this.mngEmailRef.current?.focus();
    }
    else if (!orgProposal.manager) {
      this.openModal('담당자 성명은 필수입력 항목입니다. \n 담당자 성명을 입력해주세요.');
      this.mngRef.current?.scrollIntoView({ behavior: 'smooth' });
      this.mngRef.current?.focus();
    }
    else if (!orgProposal.managerPhone) {
      this.openModal('연락처는 필수입력 항목입니다. \n 연락처를 입력해주세요.');
      this.mngTelRef.current?.scrollIntoView({ behavior: 'smooth' });
      this.mngTelRef.current?.focus();
    }
    else if (!this.validatePhone(orgProposal.managerPhone)) {
      this.openModal('연락처 형식에 맞게 입력해주세요.');
      this.mngTelRef.current?.scrollIntoView({ behavior: 'smooth' });
      this.mngTelRef.current?.focus();
    }
    else if (!orgProposal.name) {
      this.openModal('기업명은 필수입력 항목입니다. \n 기업명을 입력해주세요.');
      this.orgNameRef.current?.scrollIntoView({ behavior: 'smooth' });
      this.orgNameRef.current?.focus();
    } else if(!orgProposal.orgTel?.displayNumber) {
      this.openModal('기업 연락처는 필수입력 항목입니다. \n 기업 연락처를 입력해주세요.');
      this.orgTelRef.current?.scrollIntoView({ behavior: 'smooth' });
      this.orgTelRef.current?.focus();
    } else if (orgProposal.address?.city === '' && orgProposal.address.country === '' && orgProposal.address.state === '' && orgProposal.address.street === '') {
      this.openModal('주소는 필수입력 항목입니다. \n 주소를 입력해주세요.');
    }
    else if(orgProposal.licenceId === '' || !orgProposal.licenceId || !isValidLicenceId || !isLicenceIdChecked) {
      this.openModal('사업자 번호 & 단체 등록번호는 필수입력 항목입니다. \n 사업자 번호 & 단체 등록번호를 입력해주세요.');
      this.licenceRef.current?.scrollIntoView({ behavior: 'smooth' });
      this.licenceRef.current?.focus();
    } else if (fileBoxFiles.length === 0) {
      this.openModal('증빙 서류는 필수입력 항목입니다. \n 증빙 서류를 첨부해주세요.');
    }
    else {
      onSubmit(event);
    }
  }

  validateEmail(inputEmail: string): boolean {
    const pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);

    const validateEmail = pattern.test(inputEmail);

    this.setState({ validateEmail });
    return validateEmail;

  }

  validateLicenceId(licenceId: string) {

    const pattern = new RegExp(/^(\d{3})+[-]+(\d{2})+[-]+(\d{5})/i);

    const isValidLicenceId = pattern.test(licenceId);

    this.setState({ isValidLicenceId });
    return isValidLicenceId;
  }

  validatePhone(phone: string) {

    const pattern1 = new RegExp(/^\d{2,3}-\d{3,4}-\d{4}$/i);
    const pattern2 = new RegExp(/^\d{4}-\d{4}$/i);


    return pattern1.test(phone) || pattern2.test(phone);
  }


  checkId(id: string): boolean {
    const pattern = new RegExp(/^[A-Za-z0-9_\-.]{6,15}$/);
    const isValid = pattern.test(id);

    if (!isValid) {
      this.openModal('아이디 형식에 맞게 입력해주세요.');
      return false;
    }
    return true;
  }

  defaultExtractFunction (object: any, index: number, xGridFilter: XGridFilter | null, editRows: string[], totalLength: number): ExtractFunction {
    const keys = Object.keys(object) || [];
    const editKeys = editRows || [];
    const obj: any = {};
    const page = xGridFilter ? xGridFilter.page : 0;
    const pageSize = xGridFilter ? xGridFilter.pageSize : 10;
    const totalCount = xGridFilter ? xGridFilter.totalCount : 0;
  
    keys.forEach((key) => {
      if (key === "size") {
        const size: number = Number(object[key]);
        obj[key] = Math.floor(size) > 1000000 ? Math.floor((size / 1000000)) + 'MB' : Math.floor((size / 1000)) + 'KB'
      } else if (key === "time") {
        if (object[key]) {
          obj[key] = '업로드 완료';
        } else {
          obj[key] = '업로드 대기';
        }
      } else {
        obj[key] = object[key];
      }
    });

    obj.no = page * pageSize + index + 1;
    obj.descNo = totalCount - obj.no + 1;
    obj.domain = object;
  
    if (!obj.id) {
      obj.id = index;
    }
  
    editKeys.forEach(key => obj[key] = object);

    this.setState((prevState) => ({
      fileDatas: [...prevState.fileDatas, obj],
    }));

    return obj;
  };

  rows (data: any[], xGridFilter: XGridFilter | null, editRows?: string[]) {
    data.map((obj, idx) => this.defaultExtractFunction(obj, idx, xGridFilter, editRows || [], data.length));
  }  

  getRows() {
    const { xGridStateKeeper } = this.injected;
    const { xGridFilter } = xGridStateKeeper;
    const { fileBoxFiles } = this.props;
    const rowData = Array.from(fileBoxFiles);

    rowData.forEach((file, index) =>
      (file.id ? file.id : index)
    );

    return this.rows(rowData, xGridFilter);
  }

  render() {

    const {
      orgProposal,
      onChange,
      onClickLicenceIdCheck,
      isValidLicenceId,
      isLicenceIdChecked,
      onChangeLicenceId,
      onClickCancel,
      onUploadFile,
      onClickRemove,
    } = this.props;
    const { selectedFileIds, fileDatas, openModal, modalText } = this.state;


    const columns: GridColDef[] = [
      { field: 'id', headerName: 'No', width: 70 },
      { field: 'name', headerName: '파일명', width: 110 },
      { field: 'uploadStatus', headerName: '업로드상태', width: 130, 
        valueGetter: (params) =>
        (params.row.time ? '업로드 완료' : '업로드 대기') 
      },
      { field: 'size', headerName: '용량', width: 130},
    ];

    return (
      <>
        {
          openModal ? <CommonModal message={modalText} close={this.closeModal} /> : null
        }
        <div className="step step-1">
          <div className="step-title">
            <h2>개인정보</h2>
          </div>
          <div className="step-content">
              <div className="info-list">
                  <label>담당자 성명 <span className="required"></span></label>
                  <input
                    type="text" placeholder="이름을 입력하세요." className="input-box"
                    value={orgProposal.manager}
                    onChange={onChange}
                    id="manager"
                    name="manager"
                    maxLength={16}
                    required
                    ref={this.mngRef}
                  />
              </div>
              <div className="info-list">
                  <label>연락처 <span className="required"></span></label>
                  <input
                    type={'tel'}
                    id="managerTel"
                    name="managerTel"
                    value={orgProposal.managerTel?.displayNumber}
                    onChange={onChange}
                    placeholder="연락처를 입력 하세요."
                    maxLength={13}
                    required
                    className="input-box"
                    ref={this.mngTelRef}
                  />
              </div>
          </div>

          <div className="step-title  mt-50">
              <h2>기업 & 단체 정보</h2>
          </div>
          <div className="step-content">
            <div className="info-list">
                <label>기업 & 단체명 <span className="required"></span></label>
                <div className="input-group">
                    <input 
                      type="text" placeholder="기업 & 단체명을 입력하세요." className="input-box"
                      value={orgProposal.name}
                      onChange={onChange}
                      id="name"
                      name="name"
                      maxLength={30}
                      required
                      ref={this.orgNameRef}
                    />
                </div>
            </div>
            <div className="info-list">
                <label>단체 연락처 <span className="required"></span></label>
                <input 
                  type="tel" placeholder="'-'없이 숫자만 입력하세요" className="input-box"
                  value={orgProposal.orgTel?.displayNumber}
                  onChange={onChange}
                  id="orgTel"
                  name="orgTel"
                  maxLength={13}
                  required
                  ref={this.orgTelRef}
                />
            </div>
            {this.props.renderAddressField()}
            <div className="info-list">
                <label>홈페이지 주소</label>
                <input 
                  type="text" placeholder="ex.co.kr" className="input-box"
                  onChange={onChange}
                  id="homePageUrl"
                  name="homePageUrl"
                />
            </div>
            <div className="info-list">
                <label>사업자 번호 & 단체 등록번호 <span className="required"></span></label>
                <div className="input-group">
                    <input 
                      type="tel" placeholder="'-'없이 숫자만 입력하세요" className="input-box with-btn"
                      value={orgProposal.formattedLicenceId}
                      // error={!!orgProposal.licenceId && !(isValidLicenceId && isLicenceIdChecked)}
                      onChange={onChangeLicenceId}
                      id="licenceId"
                      name="licenceId"
                      maxLength={12}
                      required
                      ref={this.licenceRef}
                    />
                    <button 
                      className="btn-search" disabled={(isLicenceIdChecked && isValidLicenceId)} 
                      onClick={() => onClickLicenceIdCheck(orgProposal.licenceId)}
                    >
                      중복확인
                    </button>
                    <div style={{ fontSize: '14px'}}>
                      { isLicenceIdChecked && this.getLicenceIdHelperText() }
                    </div>
                </div>
            </div>
        </div>

        <div className="file-import-wrap">
          <div className="step-title mt-50">
              <h2>증빙서류<span className="required" style={{position:'absolute', left:'90px'}}></span></h2>
          </div>
          <div className="step-content">
            <div className="add-text mt-20">기업 및 단체를 증명할 수 있는 증명서류를 이미지(png, gif, jpg, jpeg )파일로 첨부하세요.</div>
            <div className="info-list">
              <div className="file-btns mt-30 mb-20">
                  <button className="btn-delete" style={{ backgroundColor: '#d3d3d357' }} onClick={ () => onClickRemove(selectedFileIds) }>선택삭제</button>
                  <label className="btn-attach" htmlFor="contained-button-file" style={{ textAlign: 'center'}}>
                    파일첨부
                    <input
                        style={{ display: 'none' }}
                        type="file"
                        multiple
                        accept="image/jpeg, image/jpg, image/gif, image/png"
                        id="contained-button-file"
                        onChange={onUploadFile}
                    />
                </label>
              </div>
            </div>
          </div>
          <div className="grid-contents-wrap simple border space-x-none">
          <div style={{ height: 400, width: '100%' }}>
              <DataGrid
                rowSelectionModel={selectedFileIds}
                rows={fileDatas !== undefined ? fileDatas : []}
                columns={columns}
                initialState={{
                pagination: {
                    paginationModel: { page: 0, pageSize: 5 },
                },
                }}
                pageSizeOptions={[5, 10]}
                checkboxSelection
                onRowSelectionModelChange={selectionModel => this.isCheck(selectionModel)}
              />
              </div>
          <div className="txt-helper light">
              ※ 단체를 증명할 수 있는 증빙서류를 이미지(png,
              gif, jpg, jpeg) 파일로 첨부하세요
          </div>
          </div>
        </div>
        <div className="step-btns">
            <button className="btn-outline" onClick={onClickCancel}>취소</button>
            <button className="btn-default" onClick={this.onSubmit} disabled={this.props.submitBtn}>다음</button>
        </div>
        </div>
      </>
    );
  }
}

export default ServiceInjector.withContext(
  XGridStateKeeper,
)(OrgProposalFormView);
