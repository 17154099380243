enum ImageSize {
  it1 = 'it1',
  it2 = 'it2',
  it3 = 'it3',
  it4 = 'it4',
  it5 = 'it5',
  it6 = 'it6',
  it7 = 'it7',
  it8 = 'it8',
  it9 = 'it9',
  it10 = 'it10',
  it11 = 'it11',
  it12 = 'it12',
  it13 = 'it13',
  it14 = 'it14',
  fit1 = 'fit1',
  fit2 = 'fit2'
}

export default ImageSize;
