enum InterestsType {
    Formal = 'Formal',
    Image = 'Image',
    File = 'File',
    Video = 'Video',
    Casual = 'Casual',
    Course = 'Course'
  }
  
export default InterestsType;
  