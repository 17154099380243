export enum NotieTitleType {
  Alarm001 = '단체개설검토요청',
  Alarm003 = '단체개설승인요청',
  Alarm004 = '단체개설요청',
  Alarm008 = '단체회원가입',
  Alarm009 = '단체회원 가입',
  Alarm011 = '단체활동 제한알림',
  Alarm012 = '단체폐쇄신청',
  Alarm014 = '단체폐쇄완료',
  Alarm015 = '단체폐쇄 완료',
  Alarm016 = '회원탈퇴',
  Alarm022 = '1:1문의 등록',
  Alarm023 = '1:1문의 접수',
  Alarm024 = '1:1문의 답변완료',
  Alarm025 = '저작권문의/신고 등록',
  Alarm026 = '저작권문의/신고 접수',
  Alarm027 = '저작권문의/신고 답변완료',
  Alarm028 = '관광지 승인요청',
  Alarm029 = '관광지 승인완료',
  Alarm030 = '관광지 승인완료',
  Alarm031 = '관광지 승인완료',
  Alarm032 = '관광지 승인반려',
  Alarm033 = '관광지 발행중단',
  Alarm034 = '관광지 삭제',
  Alarm035 = '다국어 관광지명 승인요청',
  Alarm036 = '다국어 관광지명 승인완료',
  Alarm037 = '다국어 관광지명 승인반려',
  Alarm038 = '다국어 관광지명 발행중단',
  Alarm039 = '다국어 관광지명 삭제',
  Alarm040 = '필드 표준전환',
  Alarm041 = '필드그룹 표준전환',
  Alarm042 = '콘텐츠 등록검토요청',
  Alarm043 = '콘텐츠 재검토요청',
  Alarm044 = '콘텐츠 수정검토요청',
  Alarm045 = '콘텐츠 승인요청',
  Alarm046 = '콘텐츠 진행알림',
  Alarm047 = '콘텐츠 검토반려알림',
  Alarm048 = '콘텐츠 배포요청',
  Alarm049 = '콘텐츠 진행알림',
  Alarm050 = '콘텐츠 승인반려알림',
  Alarm051 = '콘텐츠 배포알림',
  Alarm052 = '콘텐츠 변경알림',
  Alarm053 = '콘텐츠 중단알림',
  Alarm054 = '콘텐츠파일 검토요청',
  Alarm055 = '콘텐츠파일 재검토요청',
  Alarm056 = '콘텐츠파일 승인요청',
  Alarm057 = '콘텐츠파일 진행알림',
  Alarm058 = '콘텐츠파일 검토반려알림',
  Alarm059 = '콘텐츠파일 배포요청',
  Alarm060 = '콘텐츠파일 진행알림',
  Alarm061 = '콘텐츠파일 승인반려알림',
  Alarm062 = '콘텐츠파일 배포알림',
  Alarm063 = '콘텐츠파일 중단알림',

  RtoAlarm002 = '초대회원 가입완료',
}
