import React from 'react';
import axios from 'axios';
import { Area, XAxis, YAxis, CartesianGrid, Tooltip, AreaChart } from 'recharts';

interface Props {
    viewWidth: number | undefined;
}

interface State {
    chartData : [],
    lastYear: string
    yearMonthJan : string
    yearMonthFeb : string
}

class CategoryYearChartMobile extends React.Component<Props, State> {

    state: State = {
        chartData : [],
        lastYear: '',
        yearMonthJan : '',
        yearMonthFeb : ''
    };

    constructor(props: any) {
        super(props);
        this.renderCustomText = this.renderCustomText.bind(this);
    }

    componentDidMount() {
        this.categoryYearChart();
    }
    
    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
    }

    async categoryYearChart() {
        try {
            const response = await axios.get('/api/naomi/v2/statistics/category-year-chart',{});
            this.setState({chartData: response.data.queryResult, lastYear: response.data.queryResult[response.data.queryResult.length-1].yearMonth});
        } catch (e) {
            console.error(e);
        }
    }

    renderCustomText(props : any){
        const {chartData} = this.state;
        const {width, height, payload, x, y} = props;

        if(chartData.length - 1 == props.index) {
            props.fill = '#1240da';
        }


        return (
            <text orientation="bottom" style={chartData.length - 1 == props.index ? {fontWeight: 'bold'} : undefined} width={width} height={height} type="category" x={x} y={y} stroke="none" fill={props.fill} className="recharts-text recharts-cartesian-axis-tick-value" textAnchor="middle">
                <tspan x={x} dy="0.71em" fill={props.fill}>
                    {payload.value}
                </tspan>
            </text>
        );
    }
    
        render() {
            const { viewWidth } = this.props;

            //@ts-ignore
            const combine = this.props.viewWidth - 40

            return(
                <div className='category-year-chart' style={{display: 'flex', justifyContent:'center'}}>

                    <AreaChart width={combine} height={combine} data={this.state.chartData} margin={{top: 10, right: 30, left: 0, bottom: 0,}}>
                        <CartesianGrid strokeDasharray="3 3"/>
                        <XAxis dataKey='yearMonth' tick={this.renderCustomText}/>
                        <YAxis tickFormatter={tick => {return tick.toLocaleString();}} tickCount={7}/>
                        <Tooltip formatter={(value : any) => value && value.toLocaleString()}/>
                        <Area name ="관광정보" type="monotone" dataKey="tourism" stackId="1" stroke="#5fc6c5" fill="#5fc6c5"/>
                        <Area name ="이미지" type="monotone" dataKey="image" stackId="1" stroke="#5587fd" fill="#5587fd"/>
                        <Area name ="동영상" type="monotone" dataKey="video" stackId="2" stroke="#eb7182" fill="#eb7182"/>
                    </AreaChart>

                    {/* {viewWidth <= 597 ? 
                    <AreaChart width={360} height={420} data={this.state.chartData} margin={{top: 10, right: 30, left: 0, bottom: 0,}}>
                        <CartesianGrid strokeDasharray="3 3"/>
                        <XAxis dataKey='yearMonth' tick={this.renderCustomText}/>
                        <YAxis tickFormatter={tick => {return tick.toLocaleString();}} tickCount={7}/>
                        <Tooltip formatter={(value : any) => value && value.toLocaleString()}/>
                        <Area name ="관광정보" type="monotone" dataKey="tourism" stackId="1" stroke="#5fc6c5" fill="#5fc6c5"/>
                        <Area name ="이미지" type="monotone" dataKey="image" stackId="1" stroke="#5587fd" fill="#5587fd"/>
                        <Area name ="동영상" type="monotone" dataKey="video" stackId="2" stroke="#eb7182" fill="#eb7182"/>
                    </AreaChart> :
                    <AreaChart width={600} height={420} data={this.state.chartData} margin={{top: 10, right: 30, left: 0, bottom: 0,}}>
                        <CartesianGrid strokeDasharray="3 3"/>
                        <XAxis dataKey='yearMonth' tick={this.renderCustomText}/>
                        <YAxis tickFormatter={tick => {return tick.toLocaleString();}} tickCount={7}/>
                        <Tooltip formatter={(value : any) => value && value.toLocaleString()}/>
                        <Area name ="관광정보" type="monotone" dataKey="tourism" stackId="1" stroke="#5fc6c5" fill="#5fc6c5"/>
                        <Area name ="이미지" type="monotone" dataKey="image" stackId="1" stroke="#5587fd" fill="#5587fd"/>
                        <Area name ="동영상" type="monotone" dataKey="video" stackId="2" stroke="#eb7182" fill="#eb7182"/>
                    </AreaChart>
                    }  */}
                </div>
            )
        }
    }

export default CategoryYearChartMobile;

