import React from 'react';
import axios from 'axios';
import { Bar, BarChart, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ComposedChart, Area, Line } from 'recharts';
import moment, { Moment } from 'moment';

interface Props {
    setLanguageName : (languageName: string) => void
    setRenewalContent : (renewalContent : string) => void
    languageName : string;
    viewWidth: number | undefined
}

interface State {
    chartData : []
    chartXAxisStroke : string
}

class LanguageContentChartMobile extends React.Component<Props, State> {

    state: State = {
        chartData : [],
        chartXAxisStroke : '한국어'
    };

    constructor(props: any) {
        super(props);
        this.renderCustomText = this.renderCustomText.bind(this);
    }

    componentDidMount() {
        this.languageContentChart();
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
    }


    setRenewalContent(renewalContent : any){
        const content = this.renewalContentCode(renewalContent);
        this.props.setRenewalContent(content)
    }

    renewalContentCode(renewalContent : string){

        switch(renewalContent){
            case '한국어':
                return 'modifyKo'
            case '영어':
                return 'modifyEn'
            case '일어':
                return 'modifyJa'
            case '중어간체':
                return 'modifyZh_CN'
            case '중어번체':
                return 'modifyZh_TW'
            case '불어':
                return 'modifyFr'
            case '서어':
                return 'modifyEs'
            case '독어':
                return 'modifyDe'
            case '노어':
                return 'modifyRu'
            default : 
                return ''
        }
    }

    setLanguageName(langName: any){
        const lang = this.langNameToLangCode(langName);
        this.props.setLanguageName(lang);
        this.setState({chartXAxisStroke : langName})
    }

    langNameToLangCode(langName : string){

        switch(langName){
            case '한국어':
                return 'ko'
            case '영어':
                return 'en'
            case '일어':
                return 'ja'
            case '중어간체':
                return 'zh_CN'
            case '중어번체':
                return 'zh_TW'
            case '불어':
                return 'fr'
            case '서어':
                return 'es'
            case '독어':
                return 'de'
            case '노어':
                return 'ru'
            default : 
                return ''
        }
    }

    async languageContentChart(){
        try {
            const response = await axios.get('/api/naomi/v2/statistics/language-content-chart',{});
            this.setState({chartData: response.data.queryResult});

        } catch (e) {
            console.error(e);
        }
    }

    renderCustomizedLabel(props : any) {
        const { x, y, value, width } = props;
        
        return (
                <text x={x + 10 + width / 1} y={y + 10} fill="black" textAnchor="start" dominantBaseline="middle">
                    <tspan>{value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')+ '건'}</tspan>
                </text>
        );
    };

        customTooltip (props : any) {
            const { payload } = props;
            let textColor = '#000';
            let arrow = '';

                if(payload.length > 0 && (Math.sign(payload[0].payload.gap)) > 0){ //양수
                    textColor = '#63c963';
                    arrow = '';
                } else if(payload.length > 0 && (Math.sign(payload[0].payload.gap)) < 0){ //음수
                    textColor = '#ef0707';
                    arrow = '';
                }


        return (
            <div className="custom-tooltip" style={{height: '100%'}}>
                {payload.length > 0 ? <p className="label" style={{color:'#8886d8'}}>{`${'전년동기'} : ${payload[0].payload.lastYearValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`}</p>: undefined}
                {payload.length > 0 ? <p className="label" style={{color:'#82ca9d'}}>{`${'기준일'} : ${payload[0].payload.thisYearValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`}</p>: undefined}
                {payload.length > 0 ? <p className="label" style={{color:'#5587fd'}}>{`${'증감건수'} : ${arrow + payload[0].payload.gap.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`}</p>: undefined}
            </div>
        )
    };

        customLegend (props : any) {
            const { payload } = props;

        return (

            <ul className="test row mt-40px" style={{position: 'relative', top: '10px', left:'25px'}}>
                <li>
                    <i className="color-circle cFF28F1"></i>
                    <span>{payload[0].value + '(' + moment().subtract(1,'year').subtract(1,'month').format('YYYY-MM') + ')'}</span>
                </li>
                <li>
                    <i className="color-circle cFF2F2"></i>
                    <span>{payload[1].value +  '(' + moment().subtract(1,'month').format('YYYY-MM') + ')'}</span>
                </li>
            </ul>
        )
    };

    renderCustomText(props: any) {
        const { x, y, payload, width, height, } = props;
        
    
        // 문자열을 개별 문자로 분리하고 역순으로 배열
        const characters = payload.value.split('')

        // 각 문자에 대한 tspan 요소 생성
        const tspans = characters.map((char: any, index:any) => (
            <tspan x={x} y={y + index * 10}  dy="0.71em" key={index}>
                {char}
            </tspan>
        ));
    
        return (
            <text className="recharts-text recharts-cartesian-axis-tick-value" fill={props.fill} width={width} height={height} x={x} y={y} type="category" stroke="none" orientation="bottom" textAnchor="middle">
                {tspans}
            </text>
        );
    }
        render() {

            const { viewWidth } = this.props;

            //@ts-ignore
            const combine = this.props.viewWidth

            return(

                <div className='language-content-bar-chart' style={{paddingTop: "40px"}}>

                <BarChart
                    width={combine}
                    height={combine}
                    data={this.state.chartData}
                    margin={{
                    top: 20,
                    right: 50,
                    left: 20,
                    bottom: 20,
                    }}
                    >
                    <CartesianGrid strokeDasharray="3 3"/>

                    <XAxis dataKey="langname" fontSize={10} interval={0} style={{cursor:"pointer"}} tick={this.renderCustomText} 
                    onClick={(event : any) => {
                        this.setLanguageName(event.value)
                        this.setRenewalContent(event.value)}}/>
                    <YAxis tickCount={7} tickFormatter={tick => {return tick.toLocaleString();}}/>

                    <Legend verticalAlign="bottom" iconType='circle' iconSize={11} content={this.customLegend}/>
                    <Tooltip formatter={(value : any) => value && value.toLocaleString()}/>
                    <Bar dataKey="lastYearValue" name ="전년동기" fill="#8885d8" 
                    onClick={(event) => {
                        this.setLanguageName(event.langname)
                        this.setRenewalContent(event.langname)}} style={{cursor:"pointer"}}/>
                    <Bar dataKey="thisYearValue" name ="기준일" fill="#82ca9d" 
                    onClick={(event) => {
                        this.setLanguageName(event.langname)
                        this.setRenewalContent(event.langname)}} style={{cursor:"pointer"}}/>
                    </BarChart>  

                    {/* {
                    viewWidth <= 360 ? 
                    <BarChart
                    width={350}
                    height={440}
                    data={this.state.chartData}
                    margin={{
                    top: 20,
                    right: 50,
                    left: 20,
                    bottom: 20,
                    }}
                    >
                    <CartesianGrid strokeDasharray="3 3"/>

                    <XAxis dataKey="langname" fontSize={10} interval={0} style={{cursor:"pointer"}} tick={this.renderCustomText} 
                    onClick={(event : any) => {
                        this.setLanguageName(event.value)
                        this.setRenewalContent(event.value)}}/>
                    <YAxis tickCount={7} tickFormatter={tick => {return tick.toLocaleString();}}/>

                    <Legend verticalAlign="bottom" iconType='circle' iconSize={11} content={this.customLegend}/>
                    <Tooltip formatter={(value : any) => value && value.toLocaleString()}/>
                    <Bar dataKey="lastYearValue" name ="전년동기" fill="#8885d8" 
                    onClick={(event) => {
                        this.setLanguageName(event.langname)
                        this.setRenewalContent(event.langname)}} style={{cursor:"pointer"}}/>
                    <Bar dataKey="thisYearValue" name ="기준일" fill="#82ca9d" 
                    onClick={(event) => {
                        this.setLanguageName(event.langname)
                        this.setRenewalContent(event.langname)}} style={{cursor:"pointer"}}/>
                    </BarChart>  
                    : 
                        (viewWidth <= 482 ? 
                            <BarChart
                            width={450}
                            height={440}
                            data={this.state.chartData}
                            margin={{
                            top: 20,
                            right: 50,
                            left: 20,
                            bottom: 20,
                            }}
                            >
                            <CartesianGrid strokeDasharray="3 3"/>
        
                            <XAxis dataKey="langname" fontSize={10} interval={0} style={{cursor:"pointer"}} tick={this.renderCustomText} 
                            onClick={(event : any) => {
                                this.setLanguageName(event.value)
                                this.setRenewalContent(event.value)}}/>
                            <YAxis tickCount={7} tickFormatter={tick => {return tick.toLocaleString();}}/>
        
                            <Legend verticalAlign="bottom" iconType='circle' iconSize={11} content={this.customLegend}/>
                            <Tooltip formatter={(value : any) => value && value.toLocaleString()}/>
                            <Bar dataKey="lastYearValue" name ="전년동기" fill="#8885d8" 
                            onClick={(event) => {
                                this.setLanguageName(event.langname)
                                this.setRenewalContent(event.langname)}} style={{cursor:"pointer"}}/>
                            <Bar dataKey="thisYearValue" name ="기준일" fill="#82ca9d" 
                            onClick={(event) => {
                                this.setLanguageName(event.langname)
                                this.setRenewalContent(event.langname)}} style={{cursor:"pointer"}}/>
                        </BarChart> 
                        : 
                        <BarChart
                            width={550}
                            height={440}
                            data={this.state.chartData}
                            margin={{
                            top: 5,
                            right: 30,
                            left: 20,
                            bottom: 5,
                            }}
                            >
                            <CartesianGrid strokeDasharray="3 3"/>
                            <XAxis dataKey="langname" fontSize={10} interval={0} style={{cursor:"pointer"}} tick={this.renderCustomText} 
                            onClick={(event : any) => {
                                this.setLanguageName(event.value)
                                this.setRenewalContent(event.value)}}/>
                            <YAxis tickCount={7} tickFormatter={tick => {return tick.toLocaleString();}}/>
                            <Legend verticalAlign="bottom" iconType='circle' iconSize={11} content={this.customLegend}/>
                            <Tooltip formatter={(value : any) => value && value.toLocaleString()}/>
                            <Bar dataKey="lastYearValue" name ="전년동기" fill="#8885d8" 
                            onClick={(event) => {
                                this.setLanguageName(event.langname)
                                this.setRenewalContent(event.langname)}} style={{cursor:"pointer"}}/>
                            <Bar dataKey="thisYearValue" name ="기준일" fill="#82ca9d" 
                            onClick={(event) => {
                                this.setLanguageName(event.langname)
                                this.setRenewalContent(event.langname)}} style={{cursor:"pointer"}}/>
                        </BarChart>
                        )
                    } */}
                </div>    
            )
        }
    }

export default LanguageContentChartMobile;
