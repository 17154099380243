import React, { createRef } from "react";
import { ContentType } from "../../model/type/ContentType";
import FormalContentsDetails from "./FormalContentsDetails";
import CasualContentsDetails from "./CasualContentsDetails";
import ImageContentsDetails from "./ImageContentsDetails";
import VideoContentsDetail from "./VideoContentsDetail";
import CourseContentsDetails from "./CourseContentsDetails";
import ThemeContentsList from "./ThemeContentsList";
import axios from "axios";

interface Props {}

interface State {
  queryCid: string;
  queryType: string;
  queryCategory: string;
  themeDetailList: object[];
  themeDetailObject: {
    actorId: string;
    audienceId: string;
    bannerImgPath: string;
    children: string;
    cineroomId: string;
    cineroomName: string;
    citizenId: string;
    cmsCategoryId: string;
    elementTypeId: string;
    entityVersion: string;
    existChild: boolean;
    folderType: boolean;
    id: string;
    idPaths: [];
    imagePath: string;
    langUsed: boolean;
    lastUpdateTime: number;
    modificationTime: number;
    lastUpdater: { id: string; name: string };
    marketingType: string;
    name: string;
    registrationTime: number;
    type: string;
    used: boolean;
    writer: { id: string; name: string };
    pavilionId: string;
    parentId: string;
    originCategoryId: string;
    originFolderId: string;
    seq: number;
    publicType: boolean;
  };
  modalType: string;
  categoryId: string;
  isSelected: boolean;
  viewWidth: number | undefined;
  openDetailModal: undefined | "detail";
  openThemeModal: undefined | "detail";
  windowWidth: number;
  queryRefineLang: string;
}

class ContentsDetailPage extends React.Component<Props, State> {
  themeDetailPageRef = createRef<HTMLDivElement>();
  formalDetailPageRef = createRef<HTMLDivElement>();
  imageDetailPageRef = createRef<HTMLDivElement>();
  casualDetailPageRef = createRef<HTMLDivElement>();
  videoDetailPageRef = createRef<HTMLDivElement>();
  courseDetailPageRef = createRef<HTMLDivElement>();

  state: State = {
    queryCid: "",
    queryType: "",
    queryCategory: "",
    themeDetailList: [],
    themeDetailObject: {
      actorId: "",
      audienceId: "",
      bannerImgPath: "",
      children: "",
      cineroomId: "",
      cineroomName: "",
      citizenId: "",
      cmsCategoryId: "",
      elementTypeId: "",
      entityVersion: "",
      existChild: false,
      folderType: false,
      id: "",
      idPaths: [],
      imagePath: "",
      langUsed: false,
      lastUpdateTime: 0,
      modificationTime: 0,
      lastUpdater: { id: "", name: "" },
      marketingType: "",
      name: "",
      registrationTime: 0,
      type: "",
      used: false,
      writer: { id: "", name: "" },
      pavilionId: "",
      parentId: "",
      originCategoryId: "",
      originFolderId: "",
      seq: 0,
      publicType: false,
    },
    modalType: "",
    categoryId: "",
    isSelected: false,
    openDetailModal: undefined,
    openThemeModal: undefined,
    viewWidth: window.innerWidth,
    windowWidth: window.innerWidth,
    queryRefineLang: '',
  };

  constructor(props: any) {
    super(props);
    this.themeDetailPageRef = React.createRef();
    this.formalDetailPageRef = React.createRef();
    this.imageDetailPageRef = React.createRef();
    this.casualDetailPageRef = React.createRef();
    this.videoDetailPageRef = React.createRef();
    this.courseDetailPageRef = React.createRef();
    this.handleResize = this.handleResize.bind(this);
    
    addEventListener("resize", (event) => {this.changeThemeViewWidth()});
    addEventListener("resize", (event) => {this.changeFormalViewWidth()});
    addEventListener("resize", (event) => {this.changeImageViewWidth()});
    addEventListener("resize", (event) => {this.changeCasualViewWidth()});
    addEventListener("resize", (event) => {this.changeVideoViewWidth()});
    addEventListener("resize", (event) => {this.changeCourseViewWidth()});
  }

  async componentDidMount() {
    // this.urlCombine();
    await this.initQueryString();
    this.getThemeDetail();
    this.getThemeDetailList();
    this.headerControl();
    window.addEventListener('resize', this.handleResize);
  }

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
    snapshot?: any
  ): void {}

  headerControl() {
    const header = document.getElementsByTagName('header');
    header[0].style.cssText = "position: relative;z-index: 999999999;"
  }

  handleResize() {
    this.setState({ windowWidth: window.innerWidth });
  }

  async getThemeDetail() {
    try {
      const response = await axios
        .post("/api/data/flow/4592b5e8-9db0-4719-ada3-ffe812093317", {
          responseName: "response",
          categoryId: this.state.queryCategory
        })
        .then((res) => {
          this.setState({ themeDetailObject: res.data.queryResult });
        });
    } catch (e) {
      console.error(e);
    }
  }

  async getThemeDetailList() {
    try {
      const instance = axios.create();
      await instance
        .post(
          "/api/data/flow/c736c5e9-8dd9-4e1f-a9a6-542f29e02017",
          {
            responseName: "response",
            categoryId: this.state.queryCategory,
          },
          {}
        )
        .then((res) => {
          this.setState({ themeDetailList: res.data.queryResult });
        });
    } catch (e) {
      console.error(e);
    }
  }

  changeThemeViewWidth(){
    this.setState({viewWidth : this.themeDetailPageRef.current?.offsetWidth});
  }

  changeFormalViewWidth(){
    this.setState({viewWidth : this.formalDetailPageRef.current?.offsetWidth});
  }

  changeImageViewWidth(){
    this.setState({viewWidth : this.imageDetailPageRef.current?.offsetWidth});
  }

  changeCasualViewWidth(){
    this.setState({viewWidth : this.casualDetailPageRef.current?.offsetWidth});
  }

  changeVideoViewWidth(){
    this.setState({viewWidth : this.videoDetailPageRef.current?.offsetWidth});
  }

  changeCourseViewWidth(){
    this.setState({viewWidth : this.courseDetailPageRef.current?.offsetWidth});
  }

  onClickCloseModal = () => {
    this.setState({ openDetailModal: undefined });
  }

  onClickThemeCloseModal = () => {
    this.setState({ openThemeModal: undefined });
  }

  initQueryString() {
    const queryString = window.location.search;
    const querySplit = queryString.split("&");
    const queryRefineCategoryId = querySplit[0].substring(12);
    const queryRefineCid = querySplit[0].substring(5);
    const queryRefineType = querySplit[1].substring(5);
    if (querySplit[2]) {
      const queryRefineLang = querySplit[2].substring(5);
      this.setState({queryRefineLang: queryRefineLang});
    }
    this.setState({queryCategory: queryRefineCategoryId});
    this.setState({ queryCid: queryRefineCid });
    this.setState({ queryType: queryRefineType });
  }

  urlCombine = () => {
    const { queryCid, queryType, queryCategory } = this.state;

    const cid = queryCid;
    const type = queryType;
    const categoryId = queryCategory

    let searchUrl = "/rms-conlab/detail?";
    searchUrl += "cid=" + encodeURIComponent(cid);

    if(categoryId){
      searchUrl += "categoryId=" + encodeURIComponent(categoryId);
    }

    if (type) {
      searchUrl += "&type=" + encodeURIComponent(type);
    }

    window.location.href = searchUrl;
  };

  setSelected = () => {
    this.setState({isSelected: !this.state.isSelected});
  }

  renderContentsDetailPage() {
    const { queryCid, queryType, isSelected, viewWidth, windowWidth } = this.state;

    const cid = queryCid;
    const type = queryType;

    switch (type) {
      case ContentType.FormalContents:
        return (
          <>
            <div className='detail_page_bg' style={{width:'100%', height:'100%', zIndex: -2, position:'absolute'}}></div>
            <div ref={this.formalDetailPageRef}>
              {/* @ts-ignore */}
              <div className={`recommend_detail_page ${isSelected && 1016 >= viewWidth ? undefined : 'm_detail_open'}`} style={{display: 'block', position: 'relative', paddingTop:'120px', paddingBottom:'50px'}}>
                  <div className="center_search" style={{width: '60%', boxShadow: 'unset'}}>
                    <div className="scroll_area" style={{background:'unset'}}>            
                      <div className="right rd_view_content" style={{minHeight: '100%', position: 'relative'}}>
                        <FormalContentsDetails cid={cid} selectedLang={this.state.queryRefineLang}/>
                      </div>
                    </div>
                  </div>
              </div>
              <div className="bg" style={{position: 'fixed', top: '0', left: '0', width: '100%', height: '100%', overflow: 'hidden', zIndex: -1, background: 'rgb(0 0 0 / 19%)'}}></div>
            </div>
          </>
        );
      case ContentType.ImageContents:
        return (
          <>
            <div className='detail_page_bg' style={{width:'100%', height:'100%', zIndex: -2, position:'absolute'}}></div>
            <div ref={this.imageDetailPageRef}>
              {/* @ts-ignore */}
              <div className={`recommend_detail_page ${isSelected && 1016 >= viewWidth ? undefined : 'm_detail_open'}`} style={{display: 'block', position: 'relative', paddingTop:'120px', paddingBottom:'50px'}}>
                <div className="center_search" style={{width: '60%', boxShadow: 'unset'}}>
                  <div className="scroll_area" style={{background:'unset'}}>            
                    <div className="right rd_view_content" style={{minHeight: '100%', position: 'relative'}}>
                      <ImageContentsDetails cid={cid} />
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg" style={{position: 'fixed', top: '0', left: '0', width: '100%', height: '100%', overflow: 'hidden', zIndex: -1, background: 'rgb(0 0 0 / 19%)'}}></div>
            </div>
          </>
        );
      case ContentType.CasualContents:
        return (
          <>
            <div className='detail_page_bg' style={{width:'100%', height:'100%', zIndex: -2, position:'absolute'}}></div>
            <div ref={this.casualDetailPageRef}>
              {/* @ts-ignore */}
              <div className={`recommend_detail_page ${isSelected && 1016 >= viewWidth ? undefined : 'm_detail_open'}`} style={{display: 'block', position: 'relative', paddingTop:'120px', paddingBottom:'50px'}}>
                <div className="center_search" style={{width: '60%', boxShadow: 'unset'}}>
                  <div className="scroll_area" style={{background:'unset'}}>            
                    <div className="right rd_view_content" style={{minHeight: '100%', position: 'relative'}}>
                      <CasualContentsDetails cid={cid} contentsType={type}/>
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg" style={{position: 'fixed', top: '0', left: '0', width: '100%', height: '100%', overflow: 'hidden', zIndex: -1, background: 'rgb(0 0 0 / 19%)'}}></div>
            </div>
          </>
        );
      case ContentType.VideoContents:
        return (
          <>
            <div className='detail_page_bg' style={{width:'100%', height:'100%', zIndex: -2, position:'absolute'}}></div>
            <div ref={this.videoDetailPageRef}>
               {/* @ts-ignore */}
              <div className={`recommend_detail_page ${isSelected && 1016 >= viewWidth ? undefined : 'm_detail_open'}`} style={{display: 'block', position: 'relative', paddingTop:'120px', paddingBottom:'50px'}}>
                <div className="center_search" style={{width: '60%', boxShadow: 'unset'}}>
                  <div className="scroll_area" style={{background:'unset'}}>            
                    <div className="right rd_view_content" style={{minHeight: '100%', position: 'relative'}}>
                      <VideoContentsDetail cid={cid} />
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg" style={{position: 'fixed', top: '0', left: '0', width: '100%', height: '100%', overflow: 'hidden', zIndex: -1, background: 'rgb(0 0 0 / 19%)'}}></div>
            </div>
          </>
        );
      case ContentType.CourseContents:
        return (
          <>
          <div className='detail_page_bg' style={{width:'100%', height:'100%', zIndex: -2, position:'absolute'}}></div>
          <div ref={this.courseDetailPageRef}>
            {/* @ts-ignore */}
            <div className={`recommend_detail_page ${isSelected && 1016 >= viewWidth ? undefined : 'm_detail_open'}`} style={{display: 'block', position: 'relative', paddingTop:'120px', paddingBottom:'50px'}}>
              <div className="center_search" style={{width: '60%', boxShadow: 'unset'}}>
                <div className="scroll_area" style={{background:'unset'}}>            
                  <div className="right rd_view_content" style={{minHeight: '100%', position: 'relative'}}>
                    <CourseContentsDetails cid={cid} />
                  </div>
                </div>
              </div>
            </div>
            <div className="bg" style={{position: 'fixed', top: '0', left: '0', width: '100%', height: '100%', overflow: 'hidden', zIndex: -1, background: 'rgb(0 0 0 / 19%)'}}></div>
          </div>
          </>
        );
      case ContentType.ThemeContents:
        return (
          <>
            <div className='detail_page_bg' style={{width:'100%', height:'100%', zIndex: -2, position:'absolute'}}></div>
            <div ref={this.themeDetailPageRef}>
            {/* @ts-ignore */}
            <div className={`recommend_detail_page ${isSelected && 1024 >= viewWidth ? undefined : 'm_detail_open'}`} style={{display: 'block', position: 'relative', paddingTop: '120px', paddingBottom: '150px'}}>
              <div className="center" style={{paddingTop: 'unset', boxShadow: 'unset'}}>
                
                <div className="scroll_area" style={{height: '1000px'}}>
                  <ThemeContentsList 
                    themeDetailObject={this.state.themeDetailObject}
                    themeDetailList={this.state.themeDetailList}
                    cardType='list'
                    onCloseModal={this.onClickCloseModal}
                    onClickThemeCloseModal={this.onClickThemeCloseModal}
                    eventSetSelected={this.setSelected}
                    isSelected={isSelected}
                    viewWidth={viewWidth}
                    contentsType={type}
                    hideImg={(windowWidth && windowWidth < 1024) ? false : true}
                  />
                </div>
              </div>
            </div>
            <div className="bg" style={{position: 'fixed', top: '0', left: '0', width: '100%', height: '100%', overflow: 'hidden', zIndex: -1, background: 'rgb(0 0 0 / 19%)'}}></div>
          </div>
          </>
        )
      // File 추가 작업 필요함
      // case ContentType.FileContents:
      //   return <FileContentsDetails/>
    }
  }

  render() {
    return <>{this.renderContentsDetailPage()}</>;
  }
}

export default ContentsDetailPage;
