export const BYTES_PER_CHUNK = 1024 * 1024 * 50; // 50 MB

export const EXTENSION_WHITELIST = 'bmp|jpg|png|jpeg|gif|wmv|wav|wma|mp3|aac|mp4|avi|mkv|wmv|mov|xlsx|xls|xlsm|ppt|pptx|pdf|doc|docx|hwp|zip';

export function download(downloadPath: string, fileName: string) {
  const agent = navigator.userAgent;
  if ((agent.includes('Android') && agent.includes('GoogleApp')) || 
    (agent.includes('iPhone') && agent.includes('NAVER'))) {
    bolobDownload(downloadPath, fileName);
  } else {
    hrefDownload(downloadPath, fileName);
  }
}
export function hrefDownload(downloadPath: string, fileName: string) {
  const element = document.createElement('a');

  element.setAttribute('href', downloadPath);
  element.setAttribute('download', fileName);
  element.style.display = 'none';
  element.target = '_blank';
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
}

export async function bolobDownload(downloadPath: string, fileName: string) {
  const response = await fetch(downloadPath);
  const blob = await response.blob();
  const url = window.URL.createObjectURL(blob);
  const element = document.createElement('a');

  element.href = url;
  element.setAttribute('download', fileName);
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
  window.URL.revokeObjectURL(url);
}
