import axios from 'axios';
import { apiURL } from '..';

const url = apiURL+'/api/profile';
const flowPersonalProfileQuerURl = '/flow/personal-profile-flow/query';


export const findProfileByCitizenId = async (citizenId: string) => {
  const data = {
    citizenId
  }
  
  const result = await axios.post(url+flowPersonalProfileQuerURl+'/find-by-citizen-id', data);
  return result.data.queryResult;  
}