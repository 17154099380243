import CommandResponse from "../../../lib/cqrs/command/CommandResponse";
import OrgProposal from "../../../OrgProposalForm/api-model/OrgProposal";

import React, { ReactNode } from 'react';
import _ from 'lodash';

import { OrgType } from "../../../OrgProposalForm/types/OrgType";
import ServiceInjector from "../../../lib/module/ServiceInjector/Serviceinjector";
import autobind from "../../../lib/decorator/autobind/autobindDecorator";
import AddressFormModel from "../../../model/Adress/AddressFormModel";
import { IssueType } from "../../../lib/type/api/common-flow/api-model/vo/IssueType";
import AddressOn from "../../../lib/granule/AddressOn";
import ReactComponent from "../../../lib/module/ReactComponent/ReactComponent";
import OrgProposalCdo from "../../../OrgProposalForm/api-model/sdo/OrgProposalCdo";
import OrgProposalStateKeeper from "../../../comp/state/orgProposal/keeper/OrgProposalStateKeeper";
import AddressRegistrationContainer from "../../../comp/view/Address/AddressContainer";
import Address from "../../../lib/granule/Address";
import FileboxStateKeeper from "../../../comp/state/Depot/FileBox/keeper/FileboxStateKeeper";
import UserInfo from "../../../comp/api/filebox/model/UserInfo";
import FileboxCdo from "../../../comp/api/filebox/api-model/sdo/FileboxCdo";
import FileItemModel from "../../../comp/state/Depot/FileBox/model/FileItemModel";
import CommonModal from "../../../conlab/CommonModal";
import KntoPolicyText from "../../../PolicyViewText/KntoPolicyText";
import CopyRightText from "../../../PolicyViewText/CopyRightText";
import SelectOrgTypeView from "../../../OrgProposalForm/view/SelectOrgTypeView";
import UsingRoleText from "../../../PolicyViewText/UsingRoleText";
import PolicyView from "../../../OrgProposalForm/view/PolicyView";
import MailAuthFormView from "../../../OrgProposalForm/view/MailAuthFormView";
import { IndividualFormView } from "../../../OrgProposalForm/view/IndividualFormView";
import OrgProposalFormView from "../../../OrgProposalForm/view/OrgProposalFormView";
import { confirmEmailVerification, issueEmailVerification } from "../../../shared/Function/checkpoint/SecureCommonFlow";
import { requestOrgProposal } from "../../../shared/Function/onboarding/Function";
import { checkLicenceId, checkRequesterEmail, findOrgByLicenceId } from "../../../shared/Function/onboarding/OrgProposalQueryFlow";
import { addOrgCitizen, ExtraordinaryType, findDCCCineroom } from "../../../shared/Function/metro/Function";
import { StageRoleType } from "../../../shared/nara/StageRoleType";
import { findActorsByRole, genKeysFromActorId, notieToDccAccountReviewer } from "../../../shared/Function/stage/Function";
import { registerFileBox, removeDepotFile, removeFile, uploadFile } from "../../../shared/Function/depot/Function";
import { geoCoding } from "../../../shared/Function/address/Function";
import { JoinComplete } from "../../../OrgProposalForm/view/JoinComplete";
import OriginOrgProposalFormView from "../../../OrgProposalForm/view/OriginOrgProposalFormView";
import Phone from "../../../lib/granule/Phone";
import CitizenInvitationCdo from "../../../comp/api/pavillion/api-model/sdo/CitizenInvitationCdo";
import { failSsoId, getOrgType, getSsoId, removeSessionTopPopup, removeOrgType, topSessionclear } from "../../../conlab";
import { orgData } from "../../../comp/api/onboarding/api-model/OrgData";
import { SelectChangeEvent } from "@mui/material";
import { JoinStep } from "../../../OrgProposalForm/view/JoinStep";
import { findCitizenInvitationStateReady } from "../../../shared/Function/metro/FlowSignUpQuery";
import { emailDuplicationCheck } from "../../../shared/Function/checkpoint/SecureCommonFlow";


type Step = 0 | 1 | 2 | 3 | 4 | 5 | 6;

interface State {
  //
  step: Step;
  naraTokenId: string;
  fileBoxFiles: FileItemModel[];
  deleteFileIds: string[];
  groupName: string;
  manager: string;
  code: string;
  isLicenceIdChecked: boolean;
  isValidLicenceId: boolean;
  emailKey: string;
  validateEmail: boolean;
  showTimeOption: 'default' | 'timer' | 'timeOver';
  originLicenceIdChecked: 'default' | 'pass' | 'fail';
  tempEmail: string;
  open: boolean;
  isSent: boolean;
  openModal: boolean;
  modalText: string;
  emailCheck: 'default' | 'pass' | 'fail';
  ssoId: string;
  orgType: orgData[]; // 기존 기업 가입 > 기업명 list
  selectOrg: string | undefined;
  submitBtn: boolean;
}

interface InjectedProps {
  orgProposalStateKeeper: OrgProposalStateKeeper;
  fileboxStateKeeper: FileboxStateKeeper;
}

@autobind
class TopRegisterPage extends ReactComponent<{}, State, InjectedProps> {
  
  state: State = {
    step: 0,
    open: false,
    isSent: false,
    fileBoxFiles: [],
    deleteFileIds: [],
    naraTokenId: '',
    groupName: '',
    tempEmail: '',
    manager: '',
    emailKey: '',
    code: '',
    isLicenceIdChecked: false,
    // validatePwd: false,
    isValidLicenceId: false,
    validateEmail: true,
    showTimeOption: 'default',
    openModal: false,
    modalText: '',
    originLicenceIdChecked: 'default',
    emailCheck: 'default',
    ssoId: '',
    orgType: [],
    selectOrg: undefined,
    submitBtn: false
  };

  componentDidMount() {
    //
    this.init();
  }

  init() {
    //
    const { orgProposalStateKeeper } = this.injected;
    orgProposalStateKeeper.init();
    
    const ssoId = getSsoId();
    const orgType = getOrgType();

    if (!ssoId || !orgType || !Object.values(OrgType).includes(orgType as OrgType)) {
      this.setState({ openModal: true, modalText: failSsoId });
      return;
    }
    
    orgProposalStateKeeper.setOrgProposalProp('orgType', orgType);

    this.setState({ ssoId: ssoId });

    this.forceUpdate(); // 강제 업데이트
  }

  componentDidUpdate(prevState: Readonly<State>, snapshot?: any) {
    const { ssoId: prevSsoId } = prevState;
    const { ssoId } = this.state;
    
    if (prevSsoId !== ssoId) {
      removeSessionTopPopup();
    }
  }

  policyNextStep() {
    // 중간에 session을 삭제한 경우
    if (!getSsoId()) {
      this.openModal(failSsoId);
      return;
    }

    const { orgProposalStateKeeper } = this.injected;
    const { orgProposal } = orgProposalStateKeeper;

    if (!orgProposal?.orgType) {
      this.openModal(failSsoId);
      return;
    } else if (orgProposal?.orgType === OrgType.None) {
      removeOrgType();
      this.setState({ step: this.state.step + 2 as Step });
    } else {
      removeOrgType();
      this.setState({ step: this.state.step + 1 as Step });
    }
  }

  nextStepTo(to: Step) {
    this.setState({ step: to });
  }

  prevStep() {
    this.setState({ step: this.state.step - 1 as Step });
  }

  onSuccess() {
    //
    this.openModal('단체 개설이 성공적으로 요청 되었습니다.');
  }


  onFail() {
    this.openModal('실패 되었습니다.');
  }

  checkOrgType() {
    this.openModal(failSsoId);
  }

  async onClickStepPrivate() {
    this.goNextStep(3);
  }

  async checkValidate(orgProposal: OrgProposal): Promise<boolean> {
    const isKto = orgProposal.orgType === OrgType.Kto;

    const emailCheck = await emailDuplicationCheck(orgProposal.managerEmail);

    if (!emailCheck) {
      //
      this.openModal('중복 된 이메일입니다.');
      return false;
    }
    
    if (!await checkRequesterEmail(orgProposal.managerEmail) || await findCitizenInvitationStateReady(orgProposal.managerEmail)) {
      //
      this.openModal('가입 대기 중인 이메일입니다.');
      return false;
    }

    if (!isKto && this.state.fileBoxFiles.length === 0) {
      //
      this.openModal('증빙 서류는 필수입력 항목입니다. \n 증빙 서류를 첨부해주세요.');
      return false;
    }
    return true;
  }
  
  closeModal() {
    this.setState({ openModal: false, modalText: '' });
    if (this.state.modalText === failSsoId) {
      this.failSsocheck();
    }
  }

  failSsocheck() {
    window.location.href = "/rms-conlab";
  }

  openModal(txt: string) {
    this.setState({ openModal: true, modalText: txt });
  }

  async onSuccessOrgProposal(groupName: string, manager: string) {
    //
    this.setState({ groupName, manager });

    const result = await findDCCCineroom('1:1', ExtraordinaryType.DCC_CINEROOM);

    const response = await findActorsByRole(StageRoleType.AccountReviewer, `${result.data.queryResult.id}-1`);
    const res = response.data.queryResult;

    const ids = _.chain(res).map((idName) => genKeysFromActorId(idName.id).citizenId).uniq().value();
    // notieToDccAccountReviewer의 findNotieWebSocketSpec 수정 필요
    await notieToDccAccountReviewer(this.state.groupName, this.state.manager, ids);
  }

  onFailOrgProposal() {
    this.openModal('가입에 실패했습니다. 관리자에게 문의하세요.');
  }

  goNextStep(num: number) {
    const step = this.state.step;

    this.setState({ step: step + num as Step});
  }

  async onSaveFiles() {
    const { fileBoxFiles } = this.state;

    const newFiles = fileBoxFiles.filter((file) => file.depotId === '');

    if (newFiles && newFiles.length) {

      await this.uploadFiles(newFiles);
    }
  }

  async onRemoveFiles() {
    const { orgProposalStateKeeper } = this.injected;
    const { deleteFileIds } = this.state;

    if (deleteFileIds && deleteFileIds.length) {
      orgProposalStateKeeper.deleteFileIds(deleteFileIds);
      await Promise.all(deleteFileIds.map(async id =>
        await removeDepotFile(id),
      ));
    }
    this.setState({ deleteFileIds: []});
  }

  async uploadFiles(newFiles: FileItemModel[]) {
    //
    const { fileboxStateKeeper, orgProposalStateKeeper } = this.injected;
    const { filebox } = fileboxStateKeeper;
    const { orgProposal } = orgProposalStateKeeper;

    let targetFileboxId = filebox ? filebox.name : '';

    if (!targetFileboxId && orgProposal) {
      const cdo = new FileboxCdo('', '123' as string, '', '123', orgProposal.fileboxId as string, null);
      const response = await registerFileBox(cdo);

      
      targetFileboxId = response.data.commandResponse.entityIds[0];
      orgProposalStateKeeper.setOrgProposalProp('fileboxId', targetFileboxId);
    }
    
    await Promise.all(newFiles.map(async newFile => {
      newFile.ownerId = '123';
      const userInfo = UserInfo.new();
      const response = await uploadFile(newFile, targetFileboxId, userInfo);
      
      this.onUploadSuccess(response.data.commandResponse.entityIds[0]);
    }));
  }

  onUploadSuccess(fileId: string) {
    const { orgProposalStateKeeper } = this.injected;

    orgProposalStateKeeper.setFileId(fileId);
  }

  async registerOrgProposal(orgProposal: OrgProposal): Promise<CommandResponse> {
    //
    const { orgProposalStateKeeper } = this.injected;

    if (!orgProposal.requesterId) {
      orgProposal.requesterId = orgProposal.managerEmail;
    }

    if (orgProposal.marketingYn === null || orgProposal.marketingYn === undefined) {
      orgProposal.marketingYn = false;
      orgProposal.marketingTime = Date.now();
    }

    orgProposalStateKeeper.setOrgProposalProp('password', null);

    const ssoId = getSsoId();
    
    const commandResponse = await requestOrgProposal(OrgProposalCdo.fromModel(orgProposal), ssoId);

    return commandResponse;
  }

  async onClickSubmit() {
    const { orgProposalStateKeeper, fileboxStateKeeper } = this.injected;
    const { orgProposal } = orgProposalStateKeeper;

    if (!orgProposal) {
      return;
    }

    try {

      if (await this.checkValidate(orgProposal)) {
        this.closeModal();

        await this.onSaveFiles();
        await this.onRemoveFiles();
        
        // 중간에 session을 삭제한 경우
        const ssoId = getSsoId();
        if (!ssoId || !orgProposal.orgType) {
          this.openModal(failSsoId);
          return;
        }

        this.setState({ submitBtn: true });
        const commandResponse = await this.registerOrgProposal(orgProposal);
        topSessionclear();


        if (commandResponse.data.commandResponse.entityIds[0]) {
          // 가입 신청 완료 -> 계정 검토자에게 알람
          this.setState({ fileBoxFiles: [],  code: '' });
          // /find-notie-ws-spec 에러 수정 필요함
          // await this.onSuccessOrgProposal(orgProposal.groupName, orgProposal.manager);
          this.goNextStep(2);
          orgProposalStateKeeper.init();
        } else {
          const failureMessage = commandResponse.data.commandResponse.failureMessage.exceptionMessage;
          this.onFailOrgProposal();
        }
      }
    }
    catch (e) {
      if (e instanceof Error) {
        console.log(e.message);
        this.onFailOrgProposal();
        if (orgProposal && orgProposal.fileboxId) {
          await removeFile(orgProposal.fileboxId);
        }
      }
    }
  }

  async onClickOriginSubmit() {
    const { orgProposalStateKeeper } = this.injected;
    const { orgProposal } = orgProposalStateKeeper;

    try {
      if (!orgProposal) {
        return;
      }

      const emailCheck = await emailDuplicationCheck(orgProposal.managerEmail);

      if (!emailCheck) {
        //
        this.openModal('중복 된 이메일입니다.');
        return;
      }
      
      if (!await checkRequesterEmail(orgProposal.managerEmail) || await findCitizenInvitationStateReady(orgProposal.managerEmail)) {
        //
        this.openModal('가입 대기 중인 이메일입니다.');
        return;
      }

      this.closeModal();
        
      // 중간에 session을 삭제한 경우
      const ssoId = getSsoId();
      if (!ssoId || !orgProposal.orgType) {
        this.openModal(failSsoId);
        return;
      }

      const managerTel = orgProposal.managerTel?.displayNumber.split('-');
      let phone: Phone = {
        //
        countryCode: '',
        carrierCode: '',
        frontNumber: '',
        backNumber: '',
        // fullNumber: '',
        displayNumber: ''
      }
      if (managerTel) {
        phone = {
          //
          countryCode: '82',
          carrierCode: managerTel[0].substring(1),
          frontNumber: managerTel[1],
          backNumber: managerTel[2],
          // fullNumber: '',
          displayNumber: orgProposal.managerTel?.displayNumber || ''
        }
      }

      const citizen = new CitizenInvitationCdo(
        orgProposal.managerEmail, '1:1', new Date().toISOString(), '', 
        orgProposal.marketingTime, orgProposal.marketingYn,
        ssoId, orgProposal.manager, phone
      );
      
      this.setState({ submitBtn: true });
      const res = await addOrgCitizen(citizen, orgProposal.id);
      topSessionclear();
      this.goNextStep(3);
    }
    catch (e) {
      if (e instanceof Error) {
        console.log(e.message);
        this.onFailOrgProposal();
      }
    }
  }

  onChange(event: React.ChangeEvent<HTMLInputElement>) {
    //
    const { orgProposalStateKeeper } = this.injected;

    
    const name = event.target.name as keyof OrgProposal;
    const value = event.target.value;
    const checked = event.currentTarget.checked;
    
    if (name === 'address') {
      orgProposalStateKeeper.setAddressProp('zipAddress', value); // 상세주소
      return;
    }
    else if (name === 'licenceId') {
      orgProposalStateKeeper.setOrgProposalProp(name, value.replaceAll('-', ''));
      if (this.state.step === 3) {
        orgProposalStateKeeper.setOrgProposalProp('address', '');
        orgProposalStateKeeper.setOrgProposalProp('name', '');
        orgProposalStateKeeper.setOrgProposalProp('orgTel', '');
        orgProposalStateKeeper.setOrgProposalProp('id', '');
        this.setState( { isLicenceIdChecked: false, originLicenceIdChecked: 'default', orgType: [] });
      } else {
        this.setState( { isLicenceIdChecked: false });
      }
      return;
    }
    else if (event.currentTarget.name === 'newsChecked') {
      if (checked) {
        orgProposalStateKeeper.setOrgProposalProp('marketingYn', true);
      } else {
        orgProposalStateKeeper.setOrgProposalProp('marketingYn', false);
      }
      orgProposalStateKeeper.setOrgProposalProp('marketingTime', Date.now());
    }
    
    orgProposalStateKeeper.setOrgProposalProp(name, value);
    if (name === 'managerEmail') {
      this.validateEmail(value);
    }
  }
      
  async onChangeLicenceId() {
    //
    const { orgProposalStateKeeper } = this.injected;
    const { orgProposal } = orgProposalStateKeeper;
    
    orgProposalStateKeeper.setOrgProposalProp('address', '');
    orgProposalStateKeeper.setOrgProposalProp('name', '');
    orgProposalStateKeeper.setOrgProposalProp('orgTel', '');
    orgProposalStateKeeper.setOrgProposalProp('id', '');
    this.setState({ selectOrg: undefined });
    
    const licenceId = orgProposal?.licenceId;
    if (!licenceId) {
      this.failCheckLience();
      return;
    }
    if (!this.validateLicenceId(licenceId)) {
      this.failCheckLience();
      return;
    }

    try {
      const res = await findOrgByLicenceId(licenceId || '');
      if (res.length === 0) {
        this.setState({ orgType: res, originLicenceIdChecked: 'fail' });
        return;
      }
      this.setState({ orgType: res, originLicenceIdChecked: 'pass' });
    } catch(e) {
      this.setState({ originLicenceIdChecked: 'fail', orgType: [] });
    };
  }

  failCheckLience() {
    this.setState({ originLicenceIdChecked: 'fail', orgType: [] });
    this.openModal('사업자 번호 형식에 맞게 입력해주세요.');
  }

  validateEmail(inputEmail: string) {
    const pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);

    const validateEmail = pattern.test(inputEmail);

    this.setState({ validateEmail, tempEmail: inputEmail });
  }

  validateLicenceId(licenceId: string) {

    const pattern = new RegExp(/^(\d{3})+[-]+(\d{2})+[-]+(\d{5})/i);

    const isValidLicenceId = pattern.test(this.formatLicenceId(licenceId));

    this.setState({ isValidLicenceId });
    return isValidLicenceId;
  }

  formatLicenceId(value: string ) {

    const formattedLicenceId = value.replace(/[^0-9]/g, '')
      .replace(/(^[0-9]{3})([0-9]+)?([0-9]{5})$/, '$1-$2-$3')
      .replace('--', '-');

    return formattedLicenceId;
  }

  onChangeInfo(event: React.ChangeEvent<HTMLInputElement>) {
    //
    const { orgProposalStateKeeper } = this.injected;

    const name = event.target.name as keyof OrgProposal;
    const value = event.target.value;
    const checked = event.currentTarget.checked;

    if (name === 'address') {
      orgProposalStateKeeper.setAddressProp('zipAddress', value); // 상세주소
      return;
    }
    else if (name === 'licenceId') {
      orgProposalStateKeeper.setOrgProposalProp(name, value.replaceAll('-', ''));
      this.setState( { isLicenceIdChecked: false });
      return;
    }
    else if (event.currentTarget.name === 'newsChecked') {
      if (checked) {
        orgProposalStateKeeper.setOrgProposalProp('marketingYn', true);
      } else {
        orgProposalStateKeeper.setOrgProposalProp('marketingYn', false);
      }
      orgProposalStateKeeper.setOrgProposalProp('marketingTime', Date.now());
    }

    orgProposalStateKeeper.setOrgProposalProp(name, value);
    if (name === 'managerEmail') {
      this.validateEmail(value);
    }
  }

  async onClickLicenceIdCheck() {
    const { orgProposalStateKeeper } = this.injected;
    const { orgProposal } = orgProposalStateKeeper;

    if (!orgProposal) {
      return;
    }
    const licenceId = orgProposal.licenceId;

    if (!this.validateLicenceId(licenceId)) {
      this.openModal('사업자 번호 형식에 맞게 입력해주세요.');
      return;
    }
    const booleanMessage = await checkLicenceId(licenceId);
    const result = booleanMessage.queryResult.result;
    this.setState({ isValidLicenceId: result, isLicenceIdChecked: true });
  }

  show() {
    this.setState({ open: true });
  }

  hide() {
    this.setState({ open: false });
  }

  handleAddress(data: AddressFormModel) {

    const { orgProposalStateKeeper } = this.injected;


    orgProposalStateKeeper.setAddressProp('zipCode', data.zonecode);
    orgProposalStateKeeper.setAddressProp('city', data.sido);
    orgProposalStateKeeper.setAddressProp('state', data.sigungu);
    orgProposalStateKeeper.setAddressProp('street', data.address);

    this.hide();
  }

  async onClickAddress(address: any, onChange: (name: string, value: string) => void) {
    // axios
    const geocodedAddress = await geoCoding(address);

    if (geocodedAddress && geocodedAddress.geoCoordinate) {
      onChange('latitude', geocodedAddress.geoCoordinate.latitude);
      onChange('longitude', geocodedAddress.geoCoordinate.longitude);
    }

    onChange('zipCode', geocodedAddress.zipCode);

    onChange('zipAddress', geocodedAddress.state + " " + geocodedAddress.city + " " + geocodedAddress.streetAddress.street+ " " + geocodedAddress.streetAddress.building);
    onChange('city', geocodedAddress.city);
    onChange('state', geocodedAddress.state);
    onChange('country', geocodedAddress.countryCode);
  }

  renderAddressFieldFunc(orgProposal: OrgProposal, onChange: (name: any, value: any) => void) {
    return(<>
      <AddressRegistrationContainer
        zipCode={orgProposal.zipCode}
        streetAddress={orgProposal.zipAddress}
        additionalAddress={orgProposal.street}
        onClickAddress={(address: Address) => this.onClickAddress(address, onChange)}
        onChange={(event: any) => onChange('street', event.target.value)}
      />
    </>);
  }

  renderAddressField() {
    //
    const { orgProposalStateKeeper } = this.injected;
    const { orgProposal } = orgProposalStateKeeper;

    if (!orgProposal) {
      return null;
    }

    return this.renderAddressFieldFunc(orgProposal, this.onChangeAddressProps);
  }

  onChangeAddressProps(name: keyof AddressOn, value: any) {
    //
    const { orgProposalStateKeeper } = this.injected;

    orgProposalStateKeeper.setAddressProp(name, value);
  }

  onUploadFile(event: React.ChangeEvent<HTMLInputElement>) {

    const { fileBoxFiles } = this.state;

    if (event.target.files && event.target.files.length) {
      const files: File[] = Array.from(event.target.files);
      
      files.forEach((file) => {
        if (file && this.onCheckFile(file)) {
          fileBoxFiles.push(FileItemModel.fromFile(file));
        }
      });
      
      if (fileBoxFiles && fileBoxFiles.length) {
        const newFiles = Array.from(fileBoxFiles);

        this.setState({ fileBoxFiles: newFiles });
      }
    }
  }

  onCheckFile(file: File): boolean {

    const { fileBoxFiles } = this.state;
    const maxLength = 5;

    const maxSize = 10 * 1000 * 1000;
    const extensionName = ['image/jpeg', 'image/jpg', 'image/gif', 'image/png', 'application/pdf'];

    if (fileBoxFiles.length >= maxLength) {
      this.openModal(`파일첨부는 ${maxLength}개를 초과할 수 없습니다.`);
      return false;
    }
    if (extensionName.indexOf(file.type) === -1) {
      this.openModal('파일첨부를 지원하지 않는 확장자입니다.');
      return false;
    }
    else if (file.size > maxSize) {
      this.openModal(`첨부된 파일의 용량이 ${10}MB를 초과했습니다.`);
      return false;
    }
    else {
      return true;
    }
  }

  onClickRowRemove(selectRowFile: FileItemModel) {
    const { fileBoxFiles, deleteFileIds } = this.state;

    if (selectRowFile.depotId !== '') {
      deleteFileIds.push(selectRowFile.id);
    }
    fileBoxFiles.splice(fileBoxFiles.indexOf(fileBoxFiles.find(value => value.id === selectRowFile.id) as FileItemModel), 1);
    const newFiles = Array.from(fileBoxFiles);

    this.setState({ fileBoxFiles: newFiles, deleteFileIds });
  }

  onClickRemove(selectedFileIds: string[]) {
    const { fileBoxFiles, deleteFileIds } = this.state;

    const files = fileBoxFiles.filter((value) => value.depotId !== '');

    const fileIds: string[] = [];

    files.forEach((file) => ( selectedFileIds.find((id) => id === file.id) ? fileIds.push(file.id) : ''));
    deleteFileIds.push(...fileIds);

    selectedFileIds.forEach(value => fileBoxFiles.splice(fileBoxFiles.indexOf(fileBoxFiles.find((file) => file.id === value) as FileItemModel), 1));

    const newFiles = Array.from(fileBoxFiles);

    this.setState({ fileBoxFiles: newFiles, deleteFileIds });
  }

  onResetFiles() {
    this.setState({ fileBoxFiles: []});
  }

  async issueEmailVerification(email: string, minuteLimit: number, displayName: string, issueType: IssueType) {
    await issueEmailVerification(email, minuteLimit, '', IssueType.SignUp);
  }

  async sendCodeToEmail() {
    //
    const { tempEmail, showTimeOption, isSent } = this.state;

    this.issueEmailVerification(tempEmail, 3, '', IssueType.SignUp);


    if (isSent) {
      this.openModal(`입력하신 이메일 주소로\n인증번호가 재 발송되었습니다.`);
    }
    else {
      this.openModal(`입력하신 이메일 주소로\n인증번호가 발송되었습니다.`);
    }

    if (showTimeOption === 'timer') {
      this.setState({ showTimeOption: 'default' });
    }
    this.setState({ showTimeOption: 'timer', isSent: true });
  }

  onChangeMail(event: React.ChangeEvent<HTMLInputElement>) {
    //
    this.validateEmail(event.target.value);
  }

  onChangeCode(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) {
    this.setState({ code: e.target.value });
  }

  async onSendMail() {
    // Step2
    const { tempEmail } = this.state;
    
    if (!await emailDuplicationCheck(tempEmail)) {
      this.openModal('중복 된 이메일입니다.');
      return;
    }
    if (!await checkRequesterEmail(tempEmail) || await findCitizenInvitationStateReady(tempEmail)) {
      this.openModal('가입 대기 중인 이메일입니다.');
      return;
    }

    this.injected.orgProposalStateKeeper.setOrgProposalProp('managerEmail', '');
    this.injected.orgProposalStateKeeper.setOrgProposalProp('code', '');
    this.injected.orgProposalStateKeeper.setOrgProposalProp('emailKey', '');

    await this.sendCodeToEmail();

  }

  onTimeOver() {
    this.openModal(`유효 시간이 만료되었습니다.\n재 발송 후 다시 시도해주세요.`);
    this.setState({ showTimeOption: 'timeOver' });
  }
  
  async validationCodeInEmail(email: string) {
    //
    const { code, showTimeOption } = this.state;
    
    if (showTimeOption === 'timeOver') {
      this.openModal(`유효 시간이 만료되었습니다.\n재 발송 후 다시 시도해주세요.`);
      return false;
    }
    
    if (!code.trim()) {
      this.openModal('인증 번호를 확인해 주세요.');
      return false;
    }
    
    try {
      const booleanMessage = await confirmEmailVerification(email, code);
      
      if (!booleanMessage) {
        this.openModal('인증 번호를 확인해 주세요.');
        return false;
      }
      
      if (!booleanMessage.result) {
        this.openModal('인증 번호를 확인해 주세요.');
        return false;
      }
      
      if (!booleanMessage.message.trim()) {
        this.openModal('올바르지 않은 접근입니다.');
        return false;
      }
      this.setState({ emailKey: booleanMessage.message});
      this.setState({ showTimeOption: 'default', isSent: false, emailCheck: 'pass' });
      this.injected.orgProposalStateKeeper.setOrgProposalProp('managerEmail', email);
      this.injected.orgProposalStateKeeper.setOrgProposalProp('code', this.state.code);
      this.injected.orgProposalStateKeeper.setOrgProposalProp('emailKey', booleanMessage.message);
      this.setState({ code: '' });
    } catch (e) {
      console.log(e);
      this.openModal('인증 번호를 확인해 주세요.');
      return false;
    }
    return true;
  }

  cancelIndivi() {
    const { orgProposalStateKeeper } = this.injected;
    this.setState({ emailCheck: 'default', tempEmail: '', isSent: false, code: '', step: this.state.step - 2 as Step });
    orgProposalStateKeeper.init();
    orgProposalStateKeeper.setOrgProposalProp('orgType', OrgType.None);
  }

  cancelOriginOrg() {
    const { orgProposalStateKeeper } = this.injected;
    orgProposalStateKeeper.init();
    this.setState({ emailCheck: 'default', tempEmail: '', isSent: false, code: '', orgType: [], originLicenceIdChecked: 'default' });
    orgProposalStateKeeper.setOrgProposalProp('orgType', OrgType.General);
    if (this.state.step === 3) {
      this.setState({ step: this.state.step - 2 as Step });
    } else {
      this.setState({ step: this.state.step - 3 as Step });
    }
  }

  onChangeOrg(event: SelectChangeEvent<string>, child: ReactNode) {
    const { orgProposalStateKeeper } = this.injected;
    const { orgType } = this.state;

    const value = event.target.value;
    const org = orgType[parseInt(value)];
    this.setState({ selectOrg: value });

    const phone = org.orgTel.displayNumber;

    orgProposalStateKeeper.setOrgProposalProp('address', org.address);
    orgProposalStateKeeper.setOrgProposalProp('name', org.name);
    orgProposalStateKeeper.setOrgProposalProp('orgTel', phone);
    orgProposalStateKeeper.setOrgProposalProp('id', org.cineroomId);
    if (org.orgType === 'Kto') {
      orgProposalStateKeeper.setOrgProposalProp('orgType', org.orgType);
    }
  }

  renderContent() {
    const { step, fileBoxFiles, isValidLicenceId, tempEmail, isSent, code } = this.state;
    const { orgProposalStateKeeper } = this.injected;
    const { orgProposal } = orgProposalStateKeeper;

    switch (step) {
      case 0:
        // 약관동의
        return (
          <PolicyView
            onNextStep={this.policyNextStep}
            renderPolicy={{
              kntoPolicy: <KntoPolicyText />,
              copyright: <CopyRightText />,
              usingRole: <UsingRoleText />,
            }}
          />
        );
      case 1:
        // 회원 유형 선택
        return (
          <SelectOrgTypeView
            onPrevStep={this.prevStep}
            goNextStep={this.goNextStep}
          />
        );
      case 2:
        // 개인회원 가입
        return (
          <IndividualFormView
            onNextStep={this.onClickStepPrivate}
            onPrevStep={this.cancelIndivi}
            validateEmail={this.state.validateEmail}
            orgProposal={orgProposal!}
          />
        );
      case 3:
        // 기업회원 기존 회원가입
        return (
          <>
            <OriginOrgProposalFormView
              submitBtn={this.state.submitBtn}
              selectOrg={this.state.selectOrg}
              orgList={this.state.orgType}
              onChangeOrg={this.onChangeOrg}
              originLicenceIdChecked={this.state.originLicenceIdChecked}
              checkLicenceId={this.onChangeLicenceId}
              onChangeLicenceId={this.onChange}
              orgProposal={orgProposal!}
              orgType={OrgType.General}
              onChange={this.onChange}
              onSubmit={this.onClickOriginSubmit}
              validateEmail={this.state.validateEmail}
              onClickCancel={this.cancelOriginOrg}
              changeForm
              // cancelEmail={this.cancelEmail}
              emailCheck={this.state.emailCheck}
              reEmail={() => this.clearEmail}
              validationCodeInEmail={this.validationCodeInEmail}
              tempEmail={tempEmail}
              isSent={isSent}
              onChangeMail={this.onChangeMail}
              onChangeCode={this.onChangeCode}
              onSubmitMail={this.onSendMail}
              code={code}
              showTimeOption={this.state.showTimeOption}
              onTimeOver={this.onTimeOver}
            />
          </>
        );
      case 4:
        // 기업회원 신규 가입
        return (
          <>
            <OrgProposalFormView
              submitBtn={this.state.submitBtn}
              orgProposal={orgProposal!}
              orgType={OrgType.General}
              onChange={this.onChange}
              onChangeLicenceId={this.onChange}
              onClickLicenceIdCheck={this.onClickLicenceIdCheck}
              isLicenceIdChecked={this.state.isLicenceIdChecked}
              isValidLicenceId={isValidLicenceId}
              onSubmit={this.onClickSubmit}
              validateEmail={this.state.validateEmail}
              handleAddress={this.handleAddress}
              openAddressForm={this.state.open}
              renderAddressField={this.renderAddressField}
              onClickCancel={this.cancelOriginOrg}
              onUploadFile={this.onUploadFile}
              onClickRemove={this.onClickRemove}
              onClickRowRemove={this.onClickRowRemove}
              fileBoxFiles={fileBoxFiles}
            />
          </>
        );
      case 5:
        return (
          <JoinComplete step={5}
            routeAction={() => window.location.href='/rms-conlab'}
          />
        );
      case 6:
        return (
          <JoinComplete step={6}
            routeAction={() => window.location.href='/rms-conlab'}
          />
        );
      default:
        return null;
    }
  }

  clearEmail() {
    this.setState({ isSent: true, emailCheck: 'default', code: '' });
    this.injected.orgProposalStateKeeper.setOrgProposalProp('managerEmail', '');
    this.injected.orgProposalStateKeeper.setOrgProposalProp('code', '');
    this.injected.orgProposalStateKeeper.setOrgProposalProp('emailKey', '');
  }

  render() {

    const { orgProposalStateKeeper } = this.injected;
    const { openModal, modalText, tempEmail, isSent, step, code } = this.state;
    const { orgProposal } = orgProposalStateKeeper;

    if (!orgProposal) {
      return null;
    }

    return (
      <>
        <div id="contents" className="tourism_contents background_none_pd_bottom0">
          { 
            openModal ? 
              <CommonModal 
                message={modalText} 
                close={this.closeModal}
                confirmed={ modalText === failSsoId ? { btn1: '확인', btn2: undefined } : undefined}
                onClickCofirmedBtn={ modalText === failSsoId ? { btn1: this.failSsocheck, btn2: () => {} } : undefined}
                title={
                  modalText === '입력하신 이메일 주소로\n인증번호가 발송되었습니다.' || modalText === '입력하신 이메일 주소로\n인증번호가 재 발송되었습니다.'
                  ? '인증번호가\n발송되었습니다.' : undefined
                }
              /> : null 
          }
          <JoinStep step={step} orgType={orgProposal.orgType}/>
          {
            step === 2 || step === 4
            ? <MailAuthFormView
              // cancelEmail={this.cancelEmail}
              emailCheck={this.state.emailCheck}
              reEmail={() => this.clearEmail}
              validationCodeInEmail={this.validationCodeInEmail}
              tempEmail={tempEmail}
              isSent={isSent}
              onChangeMail={this.onChangeMail}
              onChangeCode={this.onChangeCode}
              onSubmit={this.onSendMail}
              validateEmail={this.state.validateEmail}
              code={code}
              showTimeOption={this.state.showTimeOption}
              onTimeOver={this.onTimeOver}
              onChange={this.onChange}
            />
            : null
          }
          {
            this.renderContent()
          }
        </div>
      </>
    );
  }
}

export default ServiceInjector.withContext(
    OrgProposalStateKeeper, 
    FileboxStateKeeper,
  )(TopRegisterPage);