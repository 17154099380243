import React from "react";

interface Props {}

interface State {
	activeTab: TabValue
}

enum TabValue {
	WORKSPACE = "WORKSPACE",
	DEVCENTER = "DEVCENTER"
}

class UserGuidePage extends React.Component<Props, State> {
  static defaultProps: any = {};

  constructor(props: any) {
    super(props);
    this.onClickTab = this.onClickTab.bind(this);
  }

  componentDidMount() {}

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
    snapshot?: any
  ): void {}

  //@ts-ignore
  state: State = {
	activeTab: TabValue.WORKSPACE
  };

  onClickTab(value: TabValue) {
    this.setState({activeTab: value});
  }

  render() {
	const { activeTab } = this.state;
    return (
		<>
			<div id="contents" className="usage_guide">
				<div className="center nav_wrap">
					<ul className="nav">
						<li><i className="icon home"></i></li>
						<li>소개
							{/* <i className="icon down_circle"></i> */}
							</li>
						<li><span>이용가이드</span>
						{/* <i className="icon up_circle"></i> */}
						</li>
					</ul>
				</div>
				<section className="page_tit_area">
					<div className="center">
						<h1>이용 가이드</h1>
						<p>한국관광콘텐츠랩에서는 누구나 쉽게 이용할 수 있는 워크스페이스와 개발자센터를 제공합니다.<br />워크스페이스와 개발자센터는 한국관광콘텐츠랩 회원가입 후 이용이 가능합니다.</p>
					</div>
				</section>

				<section className="ug_area">
					<div className="center ug_content tab_menu_wrap">
						<ul className="tab_menu">
							<li className={`tab_bt ${activeTab === TabValue.WORKSPACE && 'on'}`} onClick={() => this.onClickTab(TabValue.WORKSPACE)}>
								<a>
									<img src="/rms-conlab/images/my_icon_Pencil.png" alt="icon"/>
									<h4>워크스페이스</h4>
								</a>
							</li>
							{/* TODO 개발자센터 완료 시 까지 임시 주석처리 */}
							{/* <li className="tab_bt">
								<a>
									<img src="/rms-conlab/images/my_icon_Server.png" alt="icon"/>
									<h4>개발자센터</h4>
								</a>
							</li> */}
						</ul>
						<div className="tab_contents">
							{/*<!-- 워크스페이스 -->*/}
							<div className={`tab_con ${activeTab === TabValue.WORKSPACE && 'on'}`}>
								{/* <div className="guide_box">
									<div className="exp_box">
										<span className="icon"><img src="/rms-conlab/images/guide_icon_post_add.png" alt="icon"/></span>
										<h3>콘텐츠 등록 및 관리</h3>
										<p>손쉽게 콘텐츠를 직접 등록하고 관리 할 수 있어요!</p>
									</div>
									<div className="exp_img_box">
										<img src="/rms-conlab/images/guide_img00.png" alt="손쉽게 콘텐츠를 직접 등록하고 관리 할 수 있어요!"/>
									</div>
								</div> */}
								<div className="guide_box">
									<div className="exp_box">
										<span className="icon"><img src="/rms-conlab/images/guide_icon_bookmarks.png" alt="icon"/></span>
										<h3>관심콘텐츠</h3>
										<p>관심 콘텐츠 카테고리를 활용하여 <br />콘텐츠를 관리 할 수 있어요!</p>
									</div>
									<div className="exp_img_box">
										<img src="/rms-conlab/images/guide_img02.png" alt="관심 콘텐츠 카테고리를 활용하여 콘텐츠를 관리 할 수 있어요!"/>
									</div>
								</div>
								<div className="guide_box">
									<div className="exp_box">
										<span className="icon"><img src="/rms-conlab/images/guide_icon_theme_add.png" alt="icon"/></span>
										<h3>테마 생성</h3>
										<p>테마로 묶어 관광지를 홍보할 수 있어요!</p>
									</div>
									<div className="exp_img_box">
										<img src="/rms-conlab/images/guide_img03.png" alt="테마로 묶어 관광지를 홍보할 수 있어요!"/>
									</div>
								</div>

								{/* TODO 메뉴얼 문서 작성 완료 시 까지 주석처리 */}
								{/* <div className="download_area">
									<button className="st_point">메뉴얼 다운로드 <img src="/rms-conlab/images/icon_link_download1.png" alt="icon"/></button>
								</div> */}
							</div>
							{/*<!-- 워크스페이스 끝 -->*/}

							{/*<!-- 개발자센터 -->*/}
							<div className="tab_con">
								<div className="guide_box">
									<div className="exp_box">
										<span className="icon"><img src="/rms-conlab/images/guide_icon_component.png" alt="icon"/></span>
										<h3>컴포넌트 스토리북 배포</h3>
										<p>누구나 손쉽게 사용할 수 있는 컴포넌트 스토리북을 활용해보세요!</p>
									</div>
									<div className="exp_img_box">
										<img src="/rms-conlab/images/guide_img00.png" alt="누구나 손쉽게 사용할 수 있는 컴포넌트 스토리북을 활용해보세요!"/>
									</div>
								</div>
								<div className="guide_box">
									<div className="exp_box">
										<span className="icon"><img src="/rms-conlab/images/guide_icon_password.png" alt="icon"/></span>
										<h3>API 사용 키 인증 및 등록</h3>
										<p>API 사용 키 인증과 등록을 진행하여 오픈API를 이용해보세요!</p>
									</div>
									<div className="exp_img_box">
										<img src="/rms-conlab/images/guide_img02.png" alt="API 사용 키 인증과 등록을 진행하여 오픈API를 이용해보세요!"/>
									</div>
								</div>
								<div className="guide_box">
									<div className="exp_box">
										<span className="icon"><img src="/rms-conlab/images/guide_icon_shield_person.png" alt="icon"/></span>
										<h3>Webhook 계정 별 관리 등록</h3>
										<p>Webhook 계정 별 관리 등록하여 사용해 보세요!</p>
									</div>
									<div className="exp_img_box">
										<img src="/rms-conlab/images/guide_img03.png" alt="Webhook 계정 별 관리 등록하여 사용해 보세요!"/>
									</div>
								</div>
								<div className="guide_box">
									<div className="exp_box">
										<span className="icon"><img src="/rms-conlab/images/guide_icon_code_blocks.png" alt="icon"/></span>
										<h3>API 테스트 기능</h3>
										<p>API테스트 기능을 사용해 미리 테스트 해보세요!</p>
									</div>
									<div className="exp_img_box">
										<img src="/rms-conlab/images/guide_img03.png" alt="Webhook 계정 별 관리 등록하여 사용해 보세요!"/>
									</div>
								</div>

								<div className="download_area">
									<button className="st_point">메뉴얼 다운로드 <img src="/rms-conlab/images/icon_link_download1.png" alt="icon"/></button>
								</div>
							</div>
							{/*<!-- 개발자센터 끝 -->*/}
						</div>
					</div>
				</section>

			</div>
		</>
    );
  }
}

export default UserGuidePage;
