import AddressListContainer from './AddressListContainer';
import ContentContainer from './sub-comp/ContentContainer';



type AddressListComponent = typeof AddressListContainer & {
  Content: typeof ContentContainer;
};

const AddressList = AddressListContainer as AddressListComponent;

AddressList.Content = ContentContainer;

export default AddressList;