
enum SearchType {
  All = 'All',
  Formal = 'Formal',
  Image = 'Image',
  File = 'File',
  Theme = 'Theme',
  Casual = 'Casual',
  Video = 'Video',
  Course = 'Course'
}

enum SearchTextType {
  All = '전체',
  Formal = '관광정보',
  Image = '이미지',
  File = '파일',
  Theme = '테마'
}

enum SortDirection {
  Relevance = "Relevance",
  Latest = "Latest",
}

export {
  SearchType,
  SearchTextType,
  SortDirection,
};
