import { metroFlowSignUpPostFunction } from './Function';
import { domainURL } from '../../../conlab';

export const findCitizenInvitationStateReady = async (email: string) => {
    const data = {
        email,
        domainUrl: domainURL
    }

    const res = await metroFlowSignUpPostFunction('/find-citizen-invitation-state-ready', data);
    return res.queryResult.result;
}