import React, { createRef } from "react";
import FormalContentsDetails from "../../conlab/main-theme/FormalContentsDetails";

interface Props {
  onCloseModal: () => void;
  onClickCloseModal: () => void
  onClickThemeCloseModal: () => void;
  selectedCid: string;
  selectedTab: string;
  selectedCategoryId: string;
  upDateFormalSummaryDate: {
      nature: {
        list: any[],
        score: number,
        totalCount: number
      },
      history: {
        list: any[],
        score: number,
        totalCount: number
      },
      experience: {
        list: any[],
        score: number,
        totalCount: number
      },
      culture: {
        list: any[],
        score: number,
        totalCount: number
      },
      festival: {
        list: any[],
        score: number,
        totalCount: number
      },
      food: {
        list: any[],
        score: number,
        totalCount: number
      },
      accommodation: {
        list: any[],
        score: number,
        totalCount: number
      },
      shopping: {
        list: any[],
        score: number,
        totalCount: number
      },
      leisure: {
        list: any[],
        score: number,
        totalCount: number
      }
    };
    upDateImageSummaryDate: {
        categoryPath: string,
        cid: string,
        contentsName: string,
        imagePath: string,
        imageType: string,
        koglType: string,
        modificationTime: string,
        score: number,
        tid: string
      }[];
    upDateCourseSummaryDate: {
        cid: string,
        contentsName: string,
        totalDistance: string,
        duration: string,
        imagePath: string
      }[];
      upDateCasualSummaryDate: {
        cid: string,
        contentsName: string,
        tid: string,
        tourismName: string,
        categoryPath: string,
        imagePath: string
      }[];
      upDateVideoSummaryDate: {
        cid: string,
        tid: string,
        contentsName: string,
        thumbnailPath: string,
        koglType: string,
        categoryPath: string,
        modificationTime: string
      }[];
      upDateFileSummaryDate: {
        cid: string,
        rowNumber: 1,
        tourismName: string;
        contentsName: string;
        fileType: string;
        cineroomName: string;
        registrationTime: string
      }[];
}

interface State {
    isSelected: boolean;
    viewWidth: number | undefined;
}

enum TabValue {
    TOURISM = "관광정보",
    CASUAL = "여행기사",
    IMAGE = "이미지",
    VIDEO = "동영상",
    FILE = "파일",
    THEME = "테마",
    COURSE = "코스"
  }

class SearchModalView extends React.Component<Props, State> {
    modalRef = createRef<HTMLDivElement>();

  state: State = {
    isSelected: false,
    viewWidth: window.innerWidth
  };

  constructor(props:any) {
    super(props);
    this.setSelected = this.setSelected.bind(this);
    this.modalRef = React.createRef();
    addEventListener("resize", (event) => {this.changeViewWidth()});
  }

  renderContentDetail() {
    const { selectedCid, selectedTab, onClickThemeCloseModal, onCloseModal, upDateFormalSummaryDate } = this.props;
    const { viewWidth } = this.state;

    let detailView = null

    if(selectedCid){
      detailView = <FormalContentsDetails cid={selectedCid} upDateFormalSummaryDate={upDateFormalSummaryDate} viewWidth={viewWidth} onCloseModal={onCloseModal} onClickThemeCloseModal={onClickThemeCloseModal} noPrev={true}/>
    }
      return (
      <>
        {detailView}
      </>
    )
  }

  changeViewWidth(){
    this.setState({viewWidth : this.modalRef.current?.offsetWidth});
  }

  setSelected(){
    this.setState({isSelected: !this.state.isSelected});
  }

  componentDidMount(): void {}

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
    snapshot?: any
  ): void {}

  render() {

    const { isSelected, viewWidth } = this.state
    const  { onCloseModal,  onClickThemeCloseModal, onClickCloseModal } = this.props

    return (
      <div> 
        {/* @ts-ignore */}
        <div className={`recommend_detail_page ${isSelected && 1024 >= viewWidth ? 'm_detail_open' : undefined}`} style={{display: 'block'}}>
          <div className="center_search" style={{width:'60%'}}>
            <button className="btn_close" onClick={() => {onClickCloseModal()}}>
                <img src="/rms-conlab/images/btn_close.png" alt="btn_close" />
            </button>
            <div className="scroll_area" style={{background:'unset'}}>
            {/* @ts-ignore */}
            <div className="right rd_view_content" style={isSelected && 1024 >= viewWidth ? {display: 'none'} : undefined}>
                {this.renderContentDetail()}
            </div>
            </div>
          </div>
          <div className="bg" ref={this.modalRef}></div>
        </div>
      </div>
    );
  }
}

export default SearchModalView;
