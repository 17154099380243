import React, { createRef } from "react";

interface Props {}

interface State {
  activeTab: TabValue;
  viewWidth: number;
  mobileImageSrc: string;
}

enum TabValue {
  TOURISM = "TOURISM",
  CASUAL = "CASUAL",
  THEME = "THEME",
  COURSE = "COURSE",
  IMAGE = "IMAGE",
  VIDEO = "VIDEO"
}

class ContentByTypePage extends React.Component<Props, State> {
  static defaultProps: any = {};
  tabMobileRef = createRef<HTMLDivElement>();

  constructor(props: any) {
    super(props);
    this.onClickTab = this.onClickTab.bind(this);
    this.setMobileImageSource = this.setMobileImageSource.bind(this);
    this.onChangeViewWidth = this.onChangeViewWidth.bind(this);
    this.routeDetails = this.routeDetails.bind(this);
    addEventListener("resize", (event) => {this.onChangeViewWidth(event)});
    this.tabMobileRef = React.createRef();
  }

  componentDidMount() {}

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
    snapshot?: any
  ): void {}

  state: State = {
    activeTab: TabValue.TOURISM,
    viewWidth: window.innerWidth,
    mobileImageSrc: "/rms-conlab/images/m_tourism_contents01.png"
  };

  onClickTab(value: TabValue) {
    const { viewWidth } = this.state;
    this.setState({activeTab: value});

    if (viewWidth <= 1024) {
      const html = document.getElementById("html-root");
      const tabContentsM = this.tabMobileRef.current;
      html?.classList.add("sc_stop");
      tabContentsM?.classList.add("active");
      let imgSrc: string = '';
      switch(value) {
        case TabValue.TOURISM:
          imgSrc = "/rms-conlab/images/m_tourism_contents01.png";
          break;
        case TabValue.CASUAL:
          imgSrc = "/rms-conlab/images/m_tourism_contents02.png";
          break;
        case TabValue.THEME:
          imgSrc = "/rms-conlab/images/m_tourism_contents03.png";
          break;
        case TabValue.COURSE:
          imgSrc = "/rms-conlab/images/m_tourism_contents04.png";
          break;
        case TabValue.IMAGE:
          imgSrc = "/rms-conlab/images/m_tourism_contents05.png";
          break;
        case TabValue.VIDEO:
          imgSrc = "/rms-conlab/images/m_tourism_contents06.png";
          break;
      };

      this.setMobileImageSource(imgSrc);
    }
  }

  onChangeViewWidth(event: any) {
    this.setState({viewWidth: event.target.innerWidth});
  }

  setMobileImageSource(value: string) {
    this.setState({mobileImageSrc: value});
  }

  closeContent() {
    const html = document.getElementById("html-root");
    const tabContentsM = this.tabMobileRef.current;
    html?.classList.remove("sc_stop");
    tabContentsM?.classList.remove("active");
  }

  routeDetails() {
    const { activeTab } = this.state;
    switch(activeTab) {
      case TabValue.TOURISM:
        window.location.href = "/rms-conlab/search?keyword=창덕궁과%20후원&lang=ko&marketingType=ALL&searchTab=TOURISM";
        break;
      case TabValue.CASUAL:
        window.location.href = "/rms-conlab/search?keyword=손탁커피&lang=ko&marketingType=ALL&searchTab=CASUAL";
        break;
      case TabValue.THEME:
        window.location.href = "/rms-conlab/search?keyword=인생샷&lang=ko&marketingType=ALL&searchTab=THEME";
        break;
      case TabValue.COURSE:
        window.location.href = "/rms-conlab/search?keyword=꽃과%20나무가%20반기는%20길&lang=ko&marketingType=ALL&searchTab=COURSE";
        break;
      case TabValue.IMAGE:
        window.location.href = "/rms-conlab/search?keyword=수원화성_성곽&lang=ko&marketingType=ALL&searchTab=IMAGE";
        break;
      case TabValue.VIDEO:
        window.location.href = "/rms-conlab/search?keyword=SEOUL&lang=ko&marketingType=ALL&searchTab=VIDEO";
        break;
    };
  }

  render() {
    const { activeTab, mobileImageSrc } = this.state;
    return (
      <>
        <div id="contents" className="tourism_contents">
          <div className="center nav_wrap">
            <ul className="nav">
              <li>
                <i className="icon home"></i>
              </li>
              <li>
                관광콘텐츠
                {/* <i className="icon down_circle"></i> */}
              </li>
              <li>
                <span>관광콘텐츠 유형</span>
                {/* <i className="icon up_circle"></i> */}
              </li>
            </ul>
          </div>
          <section className="page_tit_area">
            <div className="center">
              <h1>관광콘텐츠 유형</h1>
              <p>
                한국관광콘텐츠랩에서는 6가지 유형의 다양한 관광콘텐츠를 제공합니다.<br />
              </p>
            </div>
          </section>

          <section className="ug_area">
            <div className="center ug_content tab_menu_wrap">
              <ul className="tab_menu">
                <li className={`tab_bt ${activeTab === TabValue.TOURISM && 'on'}`} onClick={() => this.onClickTab(TabValue.TOURISM)}>
                  <a>
                    <img src="/rms-conlab/images/icon_3d_location.png" alt="icon" />
                    <h4>관광지 정보</h4>
                  </a>
                </li>
                <li className={`tab_bt ${activeTab === TabValue.CASUAL && 'on'}`} onClick={() => this.onClickTab(TabValue.CASUAL)}>
                  <a>
                    <img
                      src="/rms-conlab/images/icon_3d_document_layout.png"
                      alt="icon"
                    />
                    <h4>여행기사</h4>
                  </a>
                </li>

                <li className={`tab_bt ${activeTab === TabValue.THEME && 'on'}`} onClick={() => this.onClickTab(TabValue.THEME)}>
                  <a>
                    <img src="/rms-conlab/images/icon_3d_ropeway.png" alt="icon" />
                    <h4>여행테마</h4>
                  </a>
                </li>
                <li className={`tab_bt ${activeTab === TabValue.COURSE && 'on'}`} onClick={() => this.onClickTab(TabValue.COURSE)}>
                  <a>
                    <img
                      src="/rms-conlab/images/icon_3d_direction_board.png"
                      alt="icon"
                    />
                    <h4>여행코스</h4>
                  </a>
                </li>
                <li className={`tab_bt ${activeTab === TabValue.IMAGE && 'on'}`} onClick={() => this.onClickTab(TabValue.IMAGE)}>
                  <a>
                    <img src="/rms-conlab/images/icon_3d_Image.png" alt="icon" />
                    <h4>이미지</h4>
                  </a>
                </li>
                <li className={`tab_bt ${activeTab === TabValue.VIDEO && 'on'}`} onClick={() => this.onClickTab(TabValue.VIDEO)}>
                  <a>
                    <img src="/rms-conlab/images/icon_3d_video_camera.png" alt="icon" />
                    <h4>동영상</h4>
                  </a>
                </li>
              </ul>
              <div className="tab_contents">
                <button className="detailsBtn" onClick={this.routeDetails}>
                  더보기
                  <img src="/rms-conlab/images/icon_move_newtab.png" alt="icon"/>
                </button>
                {/*<!-- 관광지 정보 -->*/}
                <div className={`tab_con ${activeTab === TabValue.TOURISM && 'on'}`}>
                  {/* <h4>
                    관광정보의 경우 9개 표준분류에 따라 입력 필드가 조금씩
                    다르며,<br />
                    상세정보에서는 기본정보/이용안내/상세설명을 확인할 수 있습니다. <br />
                  </h4> */}
                  <img
                    src="/rms-conlab/images/tourism_contents01.png"
                    alt="preview"
                    className="preview"
                  />
                </div>
                {/*<!-- 관광지 정보 끝 -->*/}

                {/*<!-- 여행기사 -->*/}
                <div className={`tab_con ${activeTab === TabValue.CASUAL && 'on'}`}>
                  {/* <h4>
                    기사형 콘텐츠의 자유로운 편집과 수정, 작성기능을 이용해보세요.{" "}
                  </h4> */}
                  <img
                    src="/rms-conlab/images/tourism_contents02.png"
                    alt="preview"
                    className="preview"
                  />
                </div>
                {/*<!-- 여행기사 끝 -->*/}

                {/*<!-- 여행테마 -->*/}
                <div className={`tab_con ${activeTab === TabValue.THEME && 'on'}`}>
                  {/* <h4>
                    원하는 주제의 콘텐츠를 자유롭게 재구성하여 나만의 테마를 생성해보세요.<br></br>
                    워크스페이스에서 회원가입/로그인 하신 후 사용하실 수 있습니다.
                  </h4> */}
                  <img
                    src="/rms-conlab/images/tourism_contents03.png"
                    alt="preview"
                    className="preview"
                  />
                </div>
                {/*<!-- 여행테마 끝 -->*/}


                {/*<!-- 코스 -->*/}
                <div className={`tab_con ${activeTab === TabValue.COURSE && 'on'}`}>
                  {/* <h4>
                  여행코스를 통해 효과적으로 여행 루트를 보여줄 수 있습니다.{" "}
                  </h4> */}
                  <img
                    src="/rms-conlab/images/tourism_contents04.png"
                    alt="preview"
                    className="preview"
                  />
                </div>
                {/* <!-- 이미지 끝 -->*/}


                {/*<!-- 이미지 -->*/}
                <div className={`tab_con ${activeTab === TabValue.IMAGE && 'on'}`}>
                  {/* <h4>
                  한국관광콘텐츠랩에서는 공공누리 저작권 정책에 의거 다양한 관광지 이미지를 제공하고 있습니다.{" "}
                    <br />
                  상세정보에서 제공하는 이미지 정보에 따라 이용 가능합니다.
                  </h4> */}
                  <img
                    src="/rms-conlab/images/tourism_contents05.png"
                    alt="preview"
                    className="preview"
                  />
                </div>
                {/* <!-- 이미지 끝 -->*/}

                {/*<!-- 동영상 -->*/}
                <div className={`tab_con ${activeTab === TabValue.VIDEO && 'on'}`}>
                  {/* <h4>
                    상세정보에서 기본정보/동영상 정보/태그 정보를 확인할 수
                    있습니다. <br />
                    해당 영상의 URL 로 바로 이동이 가능합니다.
                  </h4> */}
                  <img
                    src="/rms-conlab/images/tourism_contents06.png"
                    alt="preview"
                    className="preview"
                  />
                </div>
                {/*<!-- 동영상 끝 -->*/}
              </div>

              <div className="tab_contents_m" ref={this.tabMobileRef}>
                <div className="head">
                  <button className="close_bt" onClick={() => this.closeContent()}>
                    <img src="/rms-conlab/images/icon_menu_close.svg" alt="close_icon" />
                  </button>
                </div>
                <div className="con">
                  {/* <h4>
                    관광정보의 경우 9개 표준분류에 따라 입력 필드가 조금씩
                    다르며, 상세정보에서는 <br />
                    기본정보/이용안내/상세설명을 확인할 수 있습니다. <br />
                    첨부된 사진이 존재하는 경우, 관광정보의 사진을 확인하고
                    다운로드 할 수 있습니다.
                  </h4> */}
                  <img
                    src={mobileImageSrc}
                    alt="preview"
                    className="preview"
                  />
                </div>
              </div>
            </div>
          </section>
        </div>
      </>
    );
  }
}

export default ContentByTypePage;
