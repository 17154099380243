import React from 'react';
import axios from 'axios';
import { Line, LineChart, XAxis, YAxis, CartesianGrid, Tooltip } from 'recharts';

interface Props {
    languageName : string;
    languageRenewalContent : string;
    viewWidth: number | undefined
}

interface State {
    languageRegistrationChartData : any;
    languageYearMonth : any;
    setLegendLang : string
    setLegendColor : string
    setRenewalContent : string
    setRenewalContentColor : string

    chartUpData : {
        yearmonth : string, 
        ko : number, 
        en : number, 
        ja : number, 
        zh_CN : number, 
        zh_TW : number,     
        fr : number,
        es : number,
        de : number,
        ru : number,
        modifyKo: number,
        modifyEn: number,
        modifyJa: number,
        modifyZh_CN: number,
        modifyZh_TW: number,
        modifyFr: number,
        modifyEs: number,
        modifyDe: number,
        modifyRu: number,
    }[]; 

    changeChartData : {
        yearmonth : string, 
        ko : number, 
        en : number, 
        ja : number, 
        zh_CN : number, 
        zh_TW : number,     
        fr : number,
        es : number,
        de : number,
        ru : number,
        modifyKo: number,
        modifyEn: number,
        modifyJa: number,
        modifyZh_CN: number,
        modifyZh_TW: number,
        modifyFr: number,
        modifyEs: number,
        modifyDe: number,
        modifyRu: number,
    }[];
}

class LanguageRegistrationChartMobile extends React.Component<Props, State> {

    state: State = {
        languageRegistrationChartData : [],
        languageYearMonth : [],
        setLegendLang : '',
        setLegendColor : '',
        setRenewalContent : '',
        setRenewalContentColor : '',        

        chartUpData : [
            {
                "yearmonth": "",
                "ko": 0,
                "en": 0,
                "ja": 0,
                "zh_CN": 0,
                "zh_TW": 0,
                "fr": 0,
                "es": 0,
                "de": 0,
                "ru": 0,
                "modifyKo": 0,
                "modifyEn": 0,
                "modifyJa": 0,
                "modifyZh_CN": 0,
                "modifyZh_TW": 0,
                "modifyFr": 0,
                "modifyEs": 0,
                "modifyDe": 0,
                "modifyRu": 0,
            },
            {
                "yearmonth": "",
                "ko": 0,
                "en": 0,
                "ja": 0,
                "zh_CN": 0,
                "zh_TW": 0,
                "fr": 0,
                "es": 0,
                "de": 0,
                "ru": 0,
                "modifyKo": 0,
                "modifyEn": 0,
                "modifyJa": 0,
                "modifyZh_CN": 0,
                "modifyZh_TW": 0,
                "modifyFr": 0,
                "modifyEs": 0,
                "modifyDe": 0,
                "modifyRu": 0,
            },
            {
                "yearmonth": "",
                "ko": 0,
                "en": 0,
                "ja": 0,
                "zh_CN": 0,
                "zh_TW": 0,
                "fr": 0,
                "es": 0,
                "de": 0,
                "ru": 0,
                "modifyKo": 0,
                "modifyEn": 0,
                "modifyJa": 0,
                "modifyZh_CN": 0,
                "modifyZh_TW": 0,
                "modifyFr": 0,
                "modifyEs": 0,
                "modifyDe": 0,
                "modifyRu": 0,
            },
            {
                "yearmonth": "",
                "ko": 0,
                "en": 0,
                "ja": 0,
                "zh_CN": 0,
                "zh_TW": 0,
                "fr": 0,
                "es": 0,
                "de": 0,
                "ru": 0,
                "modifyKo": 0,
                "modifyEn": 0,
                "modifyJa": 0,
                "modifyZh_CN": 0,
                "modifyZh_TW": 0,
                "modifyFr": 0,
                "modifyEs": 0,
                "modifyDe": 0,
                "modifyRu": 0,
            },
            {
                "yearmonth": "",
                "ko": 0,
                "en": 0,
                "ja": 0,
                "zh_CN": 0,
                "zh_TW": 0,
                "fr": 0,
                "es": 0,
                "de": 0,
                "ru": 0,
                "modifyKo": 0,
                "modifyEn": 0,
                "modifyJa": 0,
                "modifyZh_CN": 0,
                "modifyZh_TW": 0,
                "modifyFr": 0,
                "modifyEs": 0,
                "modifyDe": 0,
                "modifyRu": 0,
            },
            {
                "yearmonth": "",
                "ko": 0,
                "en": 0,
                "ja": 0,
                "zh_CN": 0,
                "zh_TW": 0,
                "fr": 0,
                "es": 0,
                "de": 0,
                "ru": 0,
                "modifyKo": 0,
                "modifyEn": 0,
                "modifyJa": 0,
                "modifyZh_CN": 0,
                "modifyZh_TW": 0,
                "modifyFr": 0,
                "modifyEs": 0,
                "modifyDe": 0,
                "modifyRu": 0,
            }
        ],

        changeChartData : [
            {
                "yearmonth": "",
                "ko": 0,
                "en": 0,
                "ja": 0,
                "zh_CN": 0,
                "zh_TW": 0,
                "fr": 0,
                "es": 0,
                "de": 0,
                "ru": 0,
                "modifyKo": 0,
                "modifyEn": 0,
                "modifyJa": 0,
                "modifyZh_CN": 0,
                "modifyZh_TW": 0,
                "modifyFr": 0,
                "modifyEs": 0,
                "modifyDe": 0,
                "modifyRu": 0,
            },
            {
                "yearmonth": "",
                "ko": 0,
                "en": 0,
                "ja": 0,
                "zh_CN": 0,
                "zh_TW": 0,
                "fr": 0,
                "es": 0,
                "de": 0,
                "ru": 0,
                "modifyKo": 0,
                "modifyEn": 0,
                "modifyJa": 0,
                "modifyZh_CN": 0,
                "modifyZh_TW": 0,
                "modifyFr": 0,
                "modifyEs": 0,
                "modifyDe": 0,
                "modifyRu": 0,
            },
            {
                "yearmonth": "",
                "ko": 0,
                "en": 0,
                "ja": 0,
                "zh_CN": 0,
                "zh_TW": 0,
                "fr": 0,
                "es": 0,
                "de": 0,
                "ru": 0,
                "modifyKo": 0,
                "modifyEn": 0,
                "modifyJa": 0,
                "modifyZh_CN": 0,
                "modifyZh_TW": 0,
                "modifyFr": 0,
                "modifyEs": 0,
                "modifyDe": 0,
                "modifyRu": 0,
            },
            {
                "yearmonth": "",
                "ko": 0,
                "en": 0,
                "ja": 0,
                "zh_CN": 0,
                "zh_TW": 0,
                "fr": 0,
                "es": 0,
                "de": 0,
                "ru": 0,
                "modifyKo": 0,
                "modifyEn": 0,
                "modifyJa": 0,
                "modifyZh_CN": 0,
                "modifyZh_TW": 0,
                "modifyFr": 0,
                "modifyEs": 0,
                "modifyDe": 0,
                "modifyRu": 0,
            },
            {
                "yearmonth": "",
                "ko": 0,
                "en": 0,
                "ja": 0,
                "zh_CN": 0,
                "zh_TW": 0,
                "fr": 0,
                "es": 0,
                "de": 0,
                "ru": 0,
                "modifyKo": 0,
                "modifyEn": 0,
                "modifyJa": 0,
                "modifyZh_CN": 0,
                "modifyZh_TW": 0,
                "modifyFr": 0,
                "modifyEs": 0,
                "modifyDe": 0,
                "modifyRu": 0,
            },
            {
                "yearmonth": "",
                "ko": 0,
                "en": 0,
                "ja": 0,
                "zh_CN": 0,
                "zh_TW": 0,
                "fr": 0,
                "es": 0,
                "de": 0,
                "ru": 0,
                "modifyKo": 0,
                "modifyEn": 0,
                "modifyJa": 0,
                "modifyZh_CN": 0,
                "modifyZh_TW": 0,
                "modifyFr": 0,
                "modifyEs": 0,
                "modifyDe": 0,
                "modifyRu": 0,
            }
        ]
    };

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
        if (this.props.languageName != prevProps.languageName && this.props.languageRenewalContent != prevProps.languageRenewalContent ){
            this.setState({chartUpData : this.state.changeChartData}, () => {this.setState({chartUpData : this.state.languageRegistrationChartData})})
            this.languageRegistrationChart();
        }

        if(prevProps.viewWidth !== this.props.viewWidth){
            this.props.viewWidth
        }
    }

    componentDidMount() {
        this.languageRegistrationChart();
    }

    async languageRegistrationChart(){
        try {
            const response = await axios.get('/api/naomi/v2/statistics/language-registration-year-chart',{});
            let filterData = []

            for (let i = 0; i < response.data.queryResult.length; i++) {
                let languageRegistration_json = {}
                //@ts-ignore
                languageRegistration_json.yearmonth = response.data.queryResult[i].yearmonth
                //@ts-ignore
                languageRegistration_json[this.props.languageName] = response.data.queryResult[i][this.props.languageName]
                //@ts-ignore
                languageRegistration_json[this.props.languageRenewalContent] = response.data.queryResult[i][this.props.languageRenewalContent]
                filterData.push(languageRegistration_json)

                if(this.props.languageName == 'ko'){
                    this.setState({setLegendLang : '등록건수', setLegendColor : '#5687fe'})
                } else if(this.props.languageName == 'en'){
                    this.setState({setLegendLang : '등록건수', setLegendColor : '#5687fe'})
                } else if(this.props.languageName == 'ja'){
                    this.setState({setLegendLang : '등록건수', setLegendColor : '#5687fe'})
                } else if(this.props.languageName == 'zh_CN'){
                    this.setState({setLegendLang : '등록건수', setLegendColor : '#5687fe'})
                } else if(this.props.languageName == 'zh_TW'){
                    this.setState({setLegendLang : '등록건수', setLegendColor : '#5687fe'})
                } else if(this.props.languageName == 'fr'){
                    this.setState({setLegendLang : '등록건수', setLegendColor : '#5687fe'})
                } else if(this.props.languageName == 'es'){
                    this.setState({setLegendLang : '등록건수', setLegendColor : '#5687fe'})
                } else if(this.props.languageName == 'de'){
                    this.setState({setLegendLang : '등록건수', setLegendColor : '#5687fe'})
                } else if(this.props.languageName == 'ru'){
                    this.setState({setLegendLang : '등록건수', setLegendColor : '#5687fe'})
                }

                if(this.props.languageRenewalContent == 'modifyKo'){
                    this.setState({setRenewalContent : '갱신건수', setRenewalContentColor : '#f941b9'})
                } else if(this.props.languageRenewalContent == 'modifyEn'){
                    this.setState({setRenewalContent : '갱신건수', setRenewalContentColor : '#f941b9'})
                } else if(this.props.languageRenewalContent == 'modifyJa'){
                    this.setState({setRenewalContent : '갱신건수', setRenewalContentColor : '#f941b9'})
                } else if(this.props.languageRenewalContent == 'modifyZh_CN'){
                    this.setState({setRenewalContent : '갱신건수', setRenewalContentColor : '#f941b9'})
                } else if(this.props.languageRenewalContent == 'modifyZh_TW'){
                    this.setState({setRenewalContent : '갱신건수', setRenewalContentColor : '#f941b9'})
                } else if(this.props.languageRenewalContent == 'modifyFr'){
                    this.setState({setRenewalContent : '갱신건수', setRenewalContentColor : '#f941b9'})
                } else if(this.props.languageRenewalContent == 'modifyEs'){
                    this.setState({setRenewalContent : '갱신건수', setRenewalContentColor : '#f941b9'})
                } else if(this.props.languageRenewalContent == 'modifyDe'){
                    this.setState({setRenewalContent : '갱신건수', setRenewalContentColor : '#f941b9'})
                } else if(this.props.languageRenewalContent == 'modifyRu'){
                    this.setState({setRenewalContent : '갱신건수', setRenewalContentColor : '#f941b9'})
                }
            }
            //@ts-ignore
            this.setState({chartUpData: filterData});
        } catch (e) {
            console.error(e);
        }
    }

        render() {
            const { viewWidth } = this.props;

            return(

                <div>
                {
                //@ts-ignore
                viewWidth <= 360 ? 
                <LineChart width={360} height={386} data={this.state.chartUpData}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="yearmonth" padding={{ left: 30, right: 30 }}/>
                    <YAxis yAxisId="left" tickFormatter={tick => {return tick.toLocaleString();}} tickCount={7} stroke="#5687fe"/>
                    <YAxis yAxisId="right" orientation="right" tickFormatter={tick => {return tick.toLocaleString();}} tickCount={7} stroke="#f941b9"/>
                    <Tooltip formatter={(value : any) => value && value.toLocaleString()}/>
                    <Line yAxisId="left" type="monotone" name ="등록건수" dataKey="ko" stroke="#5687fe"/>
                    <Line yAxisId="left" type="monotone" name ="등록건수" dataKey="en" stroke="#5687fe"/> 
                    <Line yAxisId="left" type="monotone" name ="등록건수" dataKey="ja" stroke="#5687fe"/>
                    <Line yAxisId="left" type="monotone" name ="등록건수" dataKey="zh_CN" stroke="#5687fe"/>
                    <Line yAxisId="left" type="monotone" name ="등록건수" dataKey="zh_TW" stroke="#5687fe"/>
                    <Line yAxisId="left" type="monotone" name ="등록건수" dataKey="fr" stroke="#5687fe"/>
                    <Line yAxisId="left" type="monotone" name ="등록건수" dataKey="es" stroke="#5687fe"/>
                    <Line yAxisId="left" type="monotone" name ="등록건수" dataKey="de" stroke="#5687fe"/>
                    <Line yAxisId="left" type="monotone" name ="등록건수" dataKey="ru" stroke="#5687fe"/>

                    <Line yAxisId="right" type="monotone" name ="갱신건수" dataKey="modifyKo" stroke="#f941b9"/>
                    <Line yAxisId="right" type="monotone" name ="갱신건수" dataKey="modifyEn" stroke="#f941b9"/> 
                    <Line yAxisId="right" type="monotone" name ="갱신건수" dataKey="modifyJa" stroke="#f941b9"/>
                    <Line yAxisId="right" type="monotone" name ="갱신건수" dataKey="modifyZh_CN" stroke="#f941b9"/>
                    <Line yAxisId="right" type="monotone" name ="갱신건수" dataKey="modifyZh_TW" stroke="#f941b9"/>
                    <Line yAxisId="right" type="monotone" name ="갱신건수" dataKey="modifyFr" stroke="#f941b9"/>
                    <Line yAxisId="right" type="monotone" name ="갱신건수" dataKey="modifyEs" stroke="#f941b9"/>
                    <Line yAxisId="right" type="monotone" name ="갱신건수" dataKey="modifyDe" stroke="#f941b9"/>
                    <Line yAxisId="right" type="monotone" name ="갱신건수" dataKey="modifyRu" stroke="#f941b9"/>
                </LineChart> 
                : 
                    //@ts-ignore
                    (viewWidth <= 482 ? 
                    <LineChart width={450} height={386} data={this.state.chartUpData}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="yearmonth" padding={{ left: 30, right: 30 }}/>
                        <YAxis yAxisId="left" tickFormatter={tick => {return tick.toLocaleString();}} tickCount={7} stroke="#5687fe"/>
                        <YAxis yAxisId="right" orientation="right" tickFormatter={tick => {return tick.toLocaleString();}} tickCount={7} stroke="#f941b9"/>
                        <Tooltip formatter={(value : any) => value && value.toLocaleString()}/>
                        <Line yAxisId="left" type="monotone" name ="등록건수" dataKey="ko" stroke="#5687fe"/>
                        <Line yAxisId="left" type="monotone" name ="등록건수" dataKey="en" stroke="#5687fe"/> 
                        <Line yAxisId="left" type="monotone" name ="등록건수" dataKey="ja" stroke="#5687fe"/>
                        <Line yAxisId="left" type="monotone" name ="등록건수" dataKey="zh_CN" stroke="#5687fe"/>
                        <Line yAxisId="left" type="monotone" name ="등록건수" dataKey="zh_TW" stroke="#5687fe"/>
                        <Line yAxisId="left" type="monotone" name ="등록건수" dataKey="fr" stroke="#5687fe"/>
                        <Line yAxisId="left" type="monotone" name ="등록건수" dataKey="es" stroke="#5687fe"/>
                        <Line yAxisId="left" type="monotone" name ="등록건수" dataKey="de" stroke="#5687fe"/>
                        <Line yAxisId="left" type="monotone" name ="등록건수" dataKey="ru" stroke="#5687fe"/>

                        <Line yAxisId="right" type="monotone" name ="갱신건수" dataKey="modifyKo" stroke="#f941b9"/>
                        <Line yAxisId="right" type="monotone" name ="갱신건수" dataKey="modifyEn" stroke="#f941b9"/> 
                        <Line yAxisId="right" type="monotone" name ="갱신건수" dataKey="modifyJa" stroke="#f941b9"/>
                        <Line yAxisId="right" type="monotone" name ="갱신건수" dataKey="modifyZh_CN" stroke="#f941b9"/>
                        <Line yAxisId="right" type="monotone" name ="갱신건수" dataKey="modifyZh_TW" stroke="#f941b9"/>
                        <Line yAxisId="right" type="monotone" name ="갱신건수" dataKey="modifyFr" stroke="#f941b9"/>
                        <Line yAxisId="right" type="monotone" name ="갱신건수" dataKey="modifyEs" stroke="#f941b9"/>
                        <Line yAxisId="right" type="monotone" name ="갱신건수" dataKey="modifyDe" stroke="#f941b9"/>
                        <Line yAxisId="right" type="monotone" name ="갱신건수" dataKey="modifyRu" stroke="#f941b9"/>
                    </LineChart> 
                    : 
                    <LineChart width={550} height={386} data={this.state.chartUpData}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="yearmonth" padding={{ left: 30, right: 30 }}/>
                        <YAxis yAxisId="left" tickFormatter={tick => {return tick.toLocaleString();}} tickCount={7} stroke="#5687fe"/>
                        <YAxis yAxisId="right" orientation="right" tickFormatter={tick => {return tick.toLocaleString();}} tickCount={7} stroke="#f941b9"/>
                        <Tooltip formatter={(value : any) => value && value.toLocaleString()}/>
                        <Line yAxisId="left" type="monotone" name ="등록건수" dataKey="ko" stroke="#5687fe"/>
                        <Line yAxisId="left" type="monotone" name ="등록건수" dataKey="en" stroke="#5687fe"/> 
                        <Line yAxisId="left" type="monotone" name ="등록건수" dataKey="ja" stroke="#5687fe"/>
                        <Line yAxisId="left" type="monotone" name ="등록건수" dataKey="zh_CN" stroke="#5687fe"/>
                        <Line yAxisId="left" type="monotone" name ="등록건수" dataKey="zh_TW" stroke="#5687fe"/>
                        <Line yAxisId="left" type="monotone" name ="등록건수" dataKey="fr" stroke="#5687fe"/>
                        <Line yAxisId="left" type="monotone" name ="등록건수" dataKey="es" stroke="#5687fe"/>
                        <Line yAxisId="left" type="monotone" name ="등록건수" dataKey="de" stroke="#5687fe"/>
                        <Line yAxisId="left" type="monotone" name ="등록건수" dataKey="ru" stroke="#5687fe"/>

                        <Line yAxisId="right" type="monotone" name ="갱신건수" dataKey="modifyKo" stroke="#f941b9"/>
                        <Line yAxisId="right" type="monotone" name ="갱신건수" dataKey="modifyEn" stroke="#f941b9"/> 
                        <Line yAxisId="right" type="monotone" name ="갱신건수" dataKey="modifyJa" stroke="#f941b9"/>
                        <Line yAxisId="right" type="monotone" name ="갱신건수" dataKey="modifyZh_CN" stroke="#f941b9"/>
                        <Line yAxisId="right" type="monotone" name ="갱신건수" dataKey="modifyZh_TW" stroke="#f941b9"/>
                        <Line yAxisId="right" type="monotone" name ="갱신건수" dataKey="modifyFr" stroke="#f941b9"/>
                        <Line yAxisId="right" type="monotone" name ="갱신건수" dataKey="modifyEs" stroke="#f941b9"/>
                        <Line yAxisId="right" type="monotone" name ="갱신건수" dataKey="modifyDe" stroke="#f941b9"/>
                        <Line yAxisId="right" type="monotone" name ="갱신건수" dataKey="modifyRu" stroke="#f941b9"/>
                    </LineChart>
                    )
                }
                    <ul className="registration_color-guide" style={{display: 'flex', justifyContent: 'center', paddingTop: '10px'}}>
                        <li>                            
                        <i className="color-circle cFF58F9" style={{backgroundColor: `${this.state.setLegendColor}`}}></i>
                        <span>{this.state.setLegendLang}</span>
                    </li>
                        <li>                            
                        <i className="color-circle cFF58F9" style={{backgroundColor: `${this.state.setRenewalContentColor}`}}></i>
                        <span>{this.state.setRenewalContent}</span>
                        </li>
                    </ul>
                </div>    
            )
        }
    }

export default LanguageRegistrationChartMobile;

