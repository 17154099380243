import React from "react";
import CountUp from "react-countup";
import axios from "axios";

interface Props {
  swiperClickCount?: number;
}

interface State {
  chartData: [];
  changeTotalCardData: { title: string; value: number }[];
  countUpData: { title: string; value: number }[];
}

class ContentTotalCardView extends React.Component<Props, State> {
  state: State = {
    chartData: [],
    changeTotalCardData: [
      {
        title: "전체 콘텐츠",
        value: 0,
      },
      {
        title: "관광정보",
        value: 0,
      },
      {
        title: "이미지",
        value: 0,
      },
      {
        title: "동영상",
        value: 0,
      },
      {
        title: "자료",
        value: 0,
      },
    ],
    countUpData: [
      {
        title: "전체 콘텐츠",
        value: 0,
      },
      {
        title: "관광정보",
        value: 0,
      },
      {
        title: "이미지",
        value: 0,
      },
      {
        title: "동영상",
        value: 0,
      },
      {
        title: "자료",
        value: 0,
      },
    ],
  };

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
    snapshot?: any
  ): void {}

  componentDidMount() {
    this.contentTotalView();
    setInterval(() => {
      this.rerollCountup();
    }, 60000);
  }

  async contentTotalView() {
    try {
      const response = await axios.get(
        "/api/naomi/v2/statistics/content-total-card-view",
        {}
      );
      this.setState({
        chartData: response.data.queryResult,
        countUpData: response.data.queryResult,
      });
    } catch (e) {
      console.error(e);
    }
  }

  rerollCountup() {
    this.setState({ countUpData: this.state.changeTotalCardData }, () => {
      this.setState({ countUpData: this.state.chartData });
    });
  }

  render() {
    const { countUpData } = this.state;    

    const fullContentTitle = countUpData[0].title;
    const fullContentValue = countUpData[0].value;
    const touristInformationTitle = countUpData[1].title;
    const touristInformationValue = countUpData[1].value;
    const imageTitle = countUpData[2].title;
    const imageValue = countUpData[2].value;
    const mediaTitle = countUpData[3].title;
    const mediaValue = countUpData[3].value;
    const fileTitle = countUpData[4].title;
    const fileValue = countUpData[4].value;

    return (
      <>
        <div className="statistics_list" style={{ width: "1500px" }}>
          <div
            className="statistics_item"
            style={{ width: "270px", flex: "unset" }}
          >
            <h3>
              {fullContentTitle}
              <img
                src="/rms-conlab/images/icon_statistics_all_black.png"
                alt="icon"
              />
            </h3>
            <p>
              <span>
                <span>
                  <CountUp end={fullContentValue} suffix="건" separator="," />
                </span>
              </span>
            </p>
          </div>
          <div
            className="statistics_item"
            style={{ width: "270px", flex: "unset" }}
          >
            <h3>
              {touristInformationTitle}
              <img
                src="/rms-conlab/images/icon_statistics_info.png"
                alt="icon"
              />
            </h3>
            <p>
              <span>
                <span>
                  <CountUp
                    end={touristInformationValue}
                    suffix="건"
                    separator=","
                  />
                </span>
              </span>
            </p>
          </div>
          <div
            className="statistics_item"
            style={{ width: "270px", flex: "unset" }}
          >
            <h3>
              {imageTitle}
              <img
                src="/rms-conlab/images/icon_statistics_photo.png"
                alt="icon"
              />
            </h3>
            <p>
              <span>
                <span>
                  <CountUp end={imageValue} suffix="건" separator="," />
                </span>
              </span>
            </p>
          </div>
          <div
            className="statistics_item"
            style={{ width: "270px", flex: "unset" }}
          >
            <h3>
              {mediaTitle}
              <img
                src="/rms-conlab/images/icon_statistics_video.png"
                alt="icon"
              />
            </h3>
            <p>
              <span>
                <span>
                  <CountUp end={mediaValue} suffix="건" separator="," />
                </span>
              </span>
            </p>
          </div>
          <div
            className="statistics_item"
            style={{ width: "270px", flex: "unset" }}
          >
            <h3>
              {fileTitle}
              <img
                src="/rms-conlab/images/icon_statistics_file.png"
                alt="icon"
              />
            </h3>
            <p>
              <span>
                <span>
                  <CountUp end={fileValue} suffix="건" separator="," />
                </span>
              </span>
            </p>
          </div>
        </div>
      </>
    );
  }
}
export default ContentTotalCardView;
