import React from "react";
import axios from "axios";
import moment from "moment";

interface Props {}

interface State {
  notice: {
    title: string;
    readCount: number;
    contents: any
    fileIds : string
    // 다른 필요한 속성들을 추가할 수 있습니다.
  };
  queryParams : {
    selectedPostId: string,
    startingTime:string,
    registrationTime:string,
    searchType: string,
    searchValue: string,
  };
  
  registrationTime: string; // 또는 Date 형식에 맞게 수정

  pastPostData : {
    pastTitle : string,
    pastStartingTime : string,
    pastRegistrationTime : string,
    pastId : string
  }
  latestPostData : {
    latestTitle : string,
    latestStartingTime : string,
    latestRegistrationTime : string,
    latestId : string
  }
  selectedPostId: string;
  startingTime: string;
  fileId : string;
  id : string;
  name : string;
}

class AnnouncementDetailPage extends React.Component<Props, State> {
  static defaultProps: any = {};

  componentDidMount() {
    this.initQueryString();
  }

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
    snapshot?: any
  ): void {}

  //@ts-ignore
  state: State = { 
    notice: {
      title: '',
      readCount: 0,
      contents :'',
      fileIds :''
    },
    selectedPostId: '',
    registrationTime: new Date().toString(),
    startingTime: '',
    pastPostData : {
      pastTitle : '',
      pastStartingTime : '',
      pastRegistrationTime : '',
      pastId : ''
    },
    latestPostData : {
      latestTitle : '',
      latestStartingTime : '',
      latestRegistrationTime : '',
      latestId : ''
    },
    fileId : '',
    id : '',
    name : '',
    queryParams : {
      selectedPostId: '',
      startingTime:'',
      registrationTime:'',
      searchType: '',
      searchValue: '',
    }
  };

  checkFixedPost(currentTime: number, startingTime: number, endingTime: number, title: string): string {
    const isFixed = currentTime > startingTime && currentTime < endingTime;
    if (isFixed) {
      title = `[공지] ${title}`;
    }
    return title;
  }

  async getNotice() {
    const { queryParams } = this.state

    await axios.post('/api/board/flow/posts-flow/detail-post-portal', {
  
      //  "responseName":"response",
       "postId": queryParams.selectedPostId,
       "startingTime": queryParams.startingTime,
       "registrationTime": queryParams.registrationTime,
       "role": "nonMember",
       "searchValue" : queryParams.searchValue,
       "searchType" : queryParams.searchType,
     })
     .then((res) => {
      const notice = res.data.queryResult.now;
      const currentTime = Date.now();
      notice.title = this.checkFixedPost(currentTime, notice.startingTime, notice.endingTime, notice.title);
      const registrationTime = notice.registrationTime
      const fileId = notice.fileIds[0]
      this.setState({notice, registrationTime , fileId}, ()=> {
        this.getfileIds(fileId);
      })

      const past = res.data.queryResult?.after;
      let pastPostData = {
        pastTitle : '',
        pastStartingTime : '',
        pastRegistrationTime : '',
        pastId : ''
      };
      let latestPostData = {
        latestTitle : '',
        latestStartingTime : '',
        latestRegistrationTime : '',
        latestId : ''
      }
      
      if (past) {
        pastPostData = {
          pastTitle : past.title,
          pastStartingTime : past.startingTime,
          pastRegistrationTime : past.registrationTime,
          pastId : past.id
        }
      }
      this.setState({ pastPostData : pastPostData });
      
      const latest = res.data.queryResult?.before;
      if (latest) {
        latestPostData = {
          latestTitle : latest.title,
          latestStartingTime : latest.startingTime,
          latestRegistrationTime : latest.registrationTime,
          latestId : latest.id
        }
      }
      
      this.setState({latestPostData : latestPostData});
      //  this.getPastNotice(registrationTime);
      //  this.getLatestNotice(registrationTime);
     });
 }


  async getfileIds(fileIds : any){
    const{ fileId } = this.state;
    if (fileIds) {
    await axios.post('/api/depot/secure/depot-file/query/',
    // await axios.post('/api/depot/secure/depot-file/query/',==> 공지사항 파일 다운로 예시
    {
      "responseName":"response",
    "depotFileId":fileId
    })
    .then((res) => {
      const id = res.data.queryResult.id;
      const name = res.data.queryResult.name;

      this.setState({id , name})
    })
  }
  }

  async fileDownload() {
    const {id,fileId, name} = this.state; 
    axios.post('/api/depot/secure/depot-flow/query/download-file' ,
     {"responseName":"response",
      "depotFileId":fileId
    }, {
      responseType: 'blob'
    })
    .then((res) => {
      const url = window.URL
      .createObjectURL(new Blob([res.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', name);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    })

  }

  async getPastNotice(registrationTime: any) {
  
    await axios.post('/api/board/flow/posts-flow/past-post',
    {"responseName":"response",
    "startingTime":0,
    "registrationTime":registrationTime,
    "role":"nonMember",
    "scope":"portalScope"
  })
  .then((res) => {
    const  pastPostData = {
      pastTitle : res.data.queryResult?.title,
      pastStartingTime : res.data.queryResult?.startingTime,
      pastRegistrationTime : res.data.queryResult?.registrationTime,
      pastId : res.data.queryResult?.id
    }
    if (pastPostData.pastTitle !== null) {
      this.setState({ pastPostData : pastPostData });
    }

  })
  }

  //
  async getLatestNotice(registrationTime: any) {
  
    await axios.post('/api/board/flow/posts-flow/latest-post',
    {"responseName":"response",
    "startingTime":0,
    "registrationTime":registrationTime,
    "role":"nonMember",
    "scope":"portalScope"
  })
  .then((res) => {
   const latestPostData = {
      latestTitle : res.data.queryResult?.title,
      latestStartingTime : res.data.queryResult?.startingTime,
      latestRegistrationTime : res.data.queryResult?.registrationTime,
      latestId : res.data.queryResult?.id
    }
    this.setState({latestPostData : latestPostData})
  })
  }

  initQueryString() {
    let queryString = window.location.search; //?keyword=경복궁&lang=ko&marketingType=ALL

    if (queryString) {
      queryString = queryString.substring(1); //keyword=경복궁&lang=ko&marketingType=ALL
      
      let queryParams = {
        selectedPostId: '',
        startingTime:'',
        registrationTime:'',
        searchType: '',
        searchValue: '',
      };


      queryString.split("&").forEach(function(item) { // [keyword=경복궁, lang=ko, marketingType=ALL]
        let keyValue: string[] = item.split("="); // [keyword, 경복궁]
        let key: string = decodeURIComponent(keyValue[0]);
        let value: string = decodeURIComponent(keyValue[1]);
        // @ts-ignore
        queryParams[key] = value;
      });
      // {
      //   selectedPostId: 267ab5fa-dbe1-437d-806a-5439136b82fd
      // };

      this.setState({
        queryParams: queryParams,

      }, () => {
        this.getNotice();
      });
    }
  }

  render() {
    const { notice } = this.state;
    const { pastPostData , latestPostData, queryParams } = this.state;
    const { registrationTime } = this.state;
    const { name } = this.state;
    const pastId = `/rms-conlab/announcement-detail?selectedPostId=${pastPostData.pastId}&startingTime=${pastPostData.pastStartingTime}&registrationTime=${pastPostData.pastRegistrationTime}&searchType=${queryParams.searchType}&searchValue=${queryParams.searchValue}`;
    const latestId = `/rms-conlab/announcement-detail?selectedPostId=${latestPostData.latestId}&startingTime=${latestPostData.latestStartingTime}&registrationTime=${latestPostData.latestRegistrationTime}&searchType=${queryParams.searchType}&searchValue=${queryParams.searchValue}`;

    return (
      <>
        <div id="contents" className="notice notice_view">
          <div className="center nav_wrap">
            <ul className="nav">
              <li>
                <i className="icon home"></i>
              </li>
              <li>
                고객센터
                {/* <i className="icon down_circle"></i> */}
              </li>
              <li>
                <span>공지사항</span>
                {/* <i className="icon down_circle"></i> */}
              </li>
            </ul>
          </div>
          <section className="board_area">
            <div className="center">
              <h1 className="board_tit">공지사항</h1>

              <div className="board_wrap">
                <div className="board_view_top">
                  <a href={`/rms-conlab/announcement?searchType=${queryParams.searchType}&searchValue=${queryParams.searchValue}`}>

                  <button className="st_clear prev" >
                    
                    <img src="/rms-conlab/images/icon_move_previous_s.png" alt="icon" />{" "}
                    목록으로
                  </button>
                  </a>
                  <h2>{notice.title}</h2>
                  <ul className="board_info">
                    <li>{moment(registrationTime).format("YYYY-MM-DD HH:mm:ss")}</li>
                    <li>{notice.readCount}</li>
                  </ul>
                </div>
                <div className="board_contents" dangerouslySetInnerHTML={{ __html: notice.contents}} >
                </div>
                <div className="files">
                  {notice.fileIds.length > 0 && (
                    <button className="st_line2 down" onClick={() => this.fileDownload()}>
                      {name}
                      <img src="/rms-conlab/images/icon_download_s.png" alt="icon" />
                    </button>
                  )}
                </div>

                <div className="board_view_bottom">
                <a href={`/rms-conlab/announcement?searchType=${queryParams.searchType}&searchValue=${queryParams.searchValue}`}>
                  <button className="st_clear prev">
                    <img src="/rms-conlab/images/icon_move_previous_s.png" alt="icon" />{" "}
                    목록으로
                  </button>
                  </a>
                  <div className="board_move">
                    
                {pastPostData.pastId && (
                    <a href={pastId} className="prev">
                      <span>
                        <img
                          src="/rms-conlab/images/icon_move_previous_ss.png"
                          alt="icon"
                        />
                        이전글
                      </span>{" "}
                      <span>{pastPostData.pastTitle}</span>
                    </a>)}
                    {latestPostData.latestId && 
                    (<a href={latestId} className="next">
                      <span>{latestPostData.latestTitle}</span>
                      <span>
                        다음글
                        <img src="/rms-conlab/images/icon_move_next_ss.png" alt="icon" />
                      </span>{" "}
                    </a>)}
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </>
    );
  }
}

export default AnnouncementDetailPage;
