import React, { ChangeEvent } from "react";
import "../../css/style.min.css";
import CommonModal from "../../conlab/CommonModal";
import autobind from "../../lib/decorator/autobind/autobindDecorator";
import { failSsoId, getSsoId } from "../../conlab";

interface Props {
  goNextStep: (num: number) => void;
  onPrevStep: () => void;
}

interface State {
  step: number;
  modalText: string;
  openFailModal: boolean;
  orgType: string;
  originOrgChecked: boolean;
  newOrgChecked: boolean;
}

@autobind
class SelectOrgTypeView extends React.Component<Props, State> {

  state : State = {
    step : 1,
    modalText: '',
    openFailModal: false,
    orgType: '',
    originOrgChecked: false,
    newOrgChecked: false
  }

  closeFailModal() {
    this.setState({ openFailModal: false });
  }

  onSelectOrg() {
    const { goNextStep } = this.props;
    const { orgType } = this.state;
    const ssoId = getSsoId();
    if (!ssoId) {
      this.setState({ openFailModal: true, modalText: failSsoId });
      return;
    }
    if (orgType === 'origin') {
      goNextStep(2);
    } else if (orgType === 'new') {
      goNextStep(3);
    }
  }

  onChangeOrgType = (event: ChangeEvent<HTMLInputElement>) => {
    const checked = event.target.value;
    this.setState({
      orgType: checked,
    });
    if (checked === 'origin') {
      this.setState({ originOrgChecked: true, newOrgChecked: false });
    } else {
      this.setState({ originOrgChecked: false, newOrgChecked: true });
    }
  };

  failSsocheck() {
    window.location.href = "/rms-conlab";
  }

  render() {

    const { openFailModal, modalText, originOrgChecked, newOrgChecked } = this.state;
    const { onPrevStep } = this.props;

    return (
      <>
        { 
          openFailModal ? 
            <CommonModal 
              message={modalText} 
              close={this.closeFailModal}
              confirmed={ modalText === failSsoId ? { btn1: '확인', btn2: undefined } : undefined}
              onClickCofirmedBtn={ modalText === failSsoId ? { btn1: this.failSsocheck, btn2: () => {} } : undefined}
            /> : null 
        }
        <section>
          <div className="step step-2">
            <div className="step-content">
                <ul className="member-company">
                    <li>
                        <div className="member-box">
                            <input type="checkbox" name="orgType" 
                              id="checkbox-1" className="checkbox checkbox-1"
                              onChange={this.onChangeOrgType} value={'origin'}
                              checked={originOrgChecked}
                            />
                            <label htmlFor="checkbox-1">등록된 기업</label>
                            <img src="/rms-conlab/images/company_old.png" />
                        </div>
                    </li>
                    <li>
                        <div className="member-box">
                            <input type="checkbox" name="orgType" 
                              id="checkbox-2" className="checkbox checkbox-2"
                              onChange={this.onChangeOrgType} value={'new'}
                              checked={newOrgChecked}
                            />
                            <label htmlFor="checkbox-2">신규 등록 기업</label>
                            <img src="/rms-conlab/images/company_new.png" />
                        </div>
                    </li>
                </ul>
            </div>
            <div className="step-btns">
                <button className="btn-outline" onClick={onPrevStep}>취소</button>
                <button className="btn-default" onClick={this.onSelectOrg}>다음</button>
            </div>
        </div>
      </section>
    </>
    );
  }
}

export default SelectOrgTypeView;
