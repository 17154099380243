import React from "react";

interface Props {}

interface State {}

class OfflineServicePage extends React.Component<Props, State> {
  static defaultProps: any = {};

  componentDidMount() {}

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
    snapshot?: any
  ): void {}

  //@ts-ignore
  state: State = { ...this.props };

  render() {
    return (
      <>
        <div id="contents" className="online_service">
          <div className="center nav_wrap">
            <ul className="nav">
              <li>
                <i className="icon home"></i>
              </li>
              <li>
                활용사례
                {/* <i className="icon down_circle"></i> */}
              </li>
              <li>
                <span>오프라인 서비스</span>
                {/* <i className="icon up_circle"></i> */}
              </li>
            </ul>
          </div>
          <section className="page_tit_area">
            <div className="center">
              <h1>오프라인 서비스</h1>
              <p>
                한국관광콘텐츠랩에서 제공하는 콘텐츠를 이용하여 오프라인
                서비스에 활용할 수 있습니다.
              </p>
            </div>
          </section>

          <section className="os_list">
            <div className="center right_side_content">
              <ul className="service_link_list">
                <li className="service_link offline_service01">
                  <div className="info_box">
                    <h3>2021 한국관광 포스터</h3>
                    <ul className="dot_list">
                      <li>단체명 : 디지털콘텐츠팀</li>
                      <li>등록일 : 2022.12.07</li>
                    </ul>
                    <div className="bts">
                      <button className="st_point">
                        <img src="/rms-conlab/images/icon_move_newtab.png" alt="icon" />{" "}
                        다운로드{" "}
                        <img
                          src="/rms-conlab/images/icon_link_download1.png"
                          alt="icon"
                        />
                      </button>
                      <button className="st_clear offline_detail_view_bt">
                        자세히 보기
                      </button>
                    </div>
                  </div>
                  <div className="img_box">
                    <img
                      src="/rms-conlab/images/online_service_img01.png"
                      alt="강원 댕댕여지도"
                    />
                  </div>
                </li>
                <li className="service_link offline_service02">
                  <div className="info_box">
                    <h3>KTO Partner Travel Agencies</h3>
                    <ul className="dot_list">
                      <li>단체명 : 해외디지털마케팅팀</li>
                      <li>등록일 : 022-05-27</li>
                    </ul>
                    <div className="bts">
                      <button className="st_point">
                        <img src="/rms-conlab/images/icon_move_newtab.png" alt="icon" />{" "}
                        다운로드{" "}
                        <img
                          src="/rms-conlab/images/icon_link_download1.png"
                          alt="icon"
                        />
                      </button>
                      <button className="st_clear offline_detail_view_bt">
                        자세히 보기
                      </button>
                    </div>
                  </div>
                  <div className="img_box">
                    <img
                      src="/rms-conlab/images/online_service_img02.png"
                      alt="한국 투어패스"
                    />
                  </div>
                </li>
              </ul>
            </div>
          </section>
        </div>
        {/*<<!-- 서비스 상세 팝업 -->*/}

        <div className="service_detail_popup service1">
          <div className="center">
            <div className="scroll_area">
              <button className="btn_close">
                <img src="/rms-conlab/images/btn_close.png" alt="btn_close" />
              </button>
              <h2>2021 한국관광 포스터</h2>

              <div className="sdp_con">
                <div className="img_box">
                  <img src="/rms-conlab/images/osdp_img01.png" alt="img" />
                </div>
                <div className="visit_info_wrap">
                  <ul className="visit_info">
                    <li>
                      <i className="icon copyright"></i>
                      <div className="ex">
                        <h5>제공기관</h5>
                        <p>한국관광공사</p>
                      </div>
                    </li>
                    <li>
                      <i className="icon interests"></i>
                      <div className="ex">
                        <h5>제공유형</h5>
                        <p>포스터</p>
                      </div>
                    </li>
                    <li>
                      <i className="icon calendar_add_on"></i>
                      <div className="ex">
                        <h5>등록일</h5>
                        <p>2022.12.07</p>
                      </div>
                    </li>
                    <li>
                      <i className="icon provider"></i>
                      <div className="ex">
                        <h5>등록자</h5>
                        <p>문수현</p>
                      </div>
                    </li>
                  </ul>
                  <div className="service_intro">
                    <h4 className="cdi_tit">기본 설명</h4>
                    <p className="exp">2021 한국관광 포스터 입니다.</p>
                  </div>
                  <div className="service_intro">
                    <h4 className="cdi_tit">저작권</h4>
                    <p className="exp">
                      본 저작물은 "공공누리" 제1유형:출처표시 조건에 따라 이용
                      할 수 있습니다.
                    </p>
                    <img
                      className="copyright_img"
                      src="/rms-conlab/images/mark2.png"
                      alt="copyright"
                    />
                  </div>

                  <div className="bts">
                    <button className="st_point">
                      다운로드{" "}
                      <img src="/rms-conlab/images/icon_link_download1.png" alt="icon" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="bg"></div>
        </div>

        <div className="service_detail_popup service2">
          <div className="center">
            <div className="scroll_area">
              <button className="btn_close">
                <img src="/rms-conlab/images/btn_close.png" alt="btn_close" />
              </button>
              <h2>KTO Partner Travel Agencies</h2>

              <div className="sdp_con">
                <div className="img_box">
                  <img src="/rms-conlab/images/osdp_img02.png" alt="img" />
                </div>
                <div className="visit_info_wrap">
                  <ul className="visit_info">
                    <li>
                      <i className="icon copyright"></i>
                      <div className="ex">
                        <h5>제공기관</h5>
                        <p>한국관광공사</p>
                      </div>
                    </li>
                    <li>
                      <i className="icon interests"></i>
                      <div className="ex">
                        <h5>제공유형</h5>
                        <p>포스터</p>
                      </div>
                    </li>
                    <li>
                      <i className="icon calendar_add_on"></i>
                      <div className="ex">
                        <h5>등록일</h5>
                        <p>2022.12.07</p>
                      </div>
                    </li>
                    <li>
                      <i className="icon provider"></i>
                      <div className="ex">
                        <h5>등록자</h5>
                        <p>문수현</p>
                      </div>
                    </li>
                  </ul>
                  <div className="service_intro">
                    <h4 className="cdi_tit">기본 설명</h4>
                    <p className="exp">2021 한국관광 포스터 입니다.</p>
                  </div>
                  <div className="service_intro">
                    <h4 className="cdi_tit">저작권</h4>
                    <p className="exp">
                      본 저작물은 "공공누리" 제1유형:출처표시 조건에 따라 이용
                      할 수 있습니다.
                    </p>
                    <img
                      className="copyright_img"
                      src="/rms-conlab/images/mark2.png"
                      alt="copyright"
                    />
                  </div>

                  <div className="bts">
                    <button className="st_point">
                      다운로드{" "}
                      <img src="/rms-conlab/images/icon_link_download1.png" alt="icon" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="bg"></div>
        </div>
      </>
    );
  }
}

export default OfflineServicePage;
