import { currentUrlCheck } from "../../../conlab";

export async function topLogin() {
    currentUrlCheck();
    // await axios.post("https://conlab.visitkorea.or.kr/api/checkpoint/sso/top/site-id")
    // .then((response) => {
    //   console.log(response.data.queryResult);
    //   console.log('linkSiteApiId : ', response.data.queryResult);

    //   const reqUrl = 'https://pass.knto.or.kr/front/login/login?linkSiteApiId=';
    //   const linkSiteApiId = response.data.queryResult;

    //   //투어원패스 팝업 셋팅
    //   const uri = reqUrl + linkSiteApiId;
    //   const popupName = '_blank';
    //   const options = 'width=560, height=600'; //팝업사이즈

    //   //투어원패스 팝업 호출
    //   const win = window.open(uri, popupName, options);
    //   return win;
    // });
    const reqUrl = "https://pass.knto.or.kr/front/login/login?linkSiteApiId=";

    //투어원패스 팝업 셋팅
    const uri = reqUrl + "NTVsTUyyO8O8WueBigtM5A";
    const popupName = "_blank";
    const options = "width=560, height=600"; //팝업사이즈

    //투어원패스 팝업 호출
    const win = window.open(uri, popupName, options);
    return win;
}
