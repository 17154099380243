import { orgProposalQueryFlowFc } from "./Function";

export const checkRequesterEmail = async (email: string) => {
    const data = {
      email,
    }
  
    try {
      const res = await orgProposalQueryFlowFc('/requester-email-check', data);
      return res.queryResult;
    } catch(error: any) {
      return false;
    }
}

export const findOrgByLicenceId = async (licenceId: string) => {
    const data = {
      licenceId,
    }
  
    try {
      const res = await orgProposalQueryFlowFc('/find-org-by-licence-id', data);
      return res;
    } catch(error: any) {
      return false;
    }
}

export const checkLicenceId = async (licenceId: string) => {
    const data = {
      licenceId,
    }
    
    const res = await orgProposalQueryFlowFc('/licence-id-check', data);
    return res;
}

export const findOrgBySsoId = async (ssoId: string) => {
    const data = {
      ssoId,
    }
    
    const res = await orgProposalQueryFlowFc('/find-org-by-sso-id', data);
    return res.queryResult;
}