
class LocInfo {
  placeName: string;
  lat: string;
  lng: string;
  level: number;
  roadAddress: string;
  zipAddress: string;

  constructor(placeName: string, lat: string, lng: string, level: number, roadAddress: string, zipAddress: string) {
    this.placeName = placeName;
    this.lat = lat;
    this.lng = lng;
    this.level = level;
    this.roadAddress = roadAddress;
    this.zipAddress = zipAddress;
  }

  static new() {
    //
    return new LocInfo(
      '',
      '',
      '',
      0,
      '',
      ''
    );
  }

  static newDefault(lat: string = '', lng: string = '', level: number = 0) {
    //
    const defaultLocInfo = LocInfo.new();

    defaultLocInfo.lat = lat;
    defaultLocInfo.lng = lng;
    defaultLocInfo.level = level;

    return defaultLocInfo;
  }

  static newGyeongbokgung(level: number = 0) {
    //
    return new LocInfo(
      '경복궁',
      '37.57968076497504',
      '126.97702490622629',
      level,
      '서울 종로구 사직로 161 경복궁',
      '',
    );
  }
}

export default LocInfo;
