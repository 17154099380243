import React, { ContextType } from "react";
import { IconButton } from "@mui/material";
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import axios from "axios";
import CommonContext from "../context/CommonContext";
import { getAudienceId } from "../conlab";
import InterestsType from "../model/type/interestsType";
import { registerUsage } from "../shared/Function/util/RegisterUtil";
import { SearchType } from "../model/type/SearchType";
import { UsageType } from "../model/type/UsageType";

interface Props {
  cid: string;
  interestsType: InterestsType;
}

interface State {
  interested: boolean;
  contentsInterests: {
    id: string,
    entityVersion: number,
    registrationTime: number,
    modificationTime: number,
    pavilionId: string,
    citizenId: string,
    cineroomId: string,
    audienceId: string,
    actorId: string,
    memberId: string,
    cid: string,
    interestsType: InterestsType | null
  }
}

class BookMarkButton extends React.Component<Props, State> {
  //@ts-ignore
  static defaultProps: Props = {
    cid : ''
  };
  static contextType = CommonContext;

  context!: ContextType<typeof CommonContext>;

  //@ts-ignore
  state: State = {
    interested: false,
    contentsInterests: {
      id: '',
      entityVersion: 0,
      registrationTime: 0,
      modificationTime: 0,
      pavilionId: '',
      citizenId: '',
      cineroomId: '',
      audienceId: '',
      actorId: '',
      memberId: '',
      cid: '',
      interestsType: null
    }
  };

  async componentDidMount() {
    const { isLogin } = this.context;
    if (isLogin) {
      await this.init();
    }
  }

  async componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
    const { isLogin } = this.context;

    if (prevProps.cid !== this.props.cid && isLogin) {
      await this.init();
      }
  }

  async init() {
    const { cid } = this.props;
    const memberId = this.getMemberId();

    await axios.post("/api/interests/aggregate/contents/contents-interests/query/dynamic-single", {
      "responseName": "response",
      "queryParams": {
        "queryParams": [
          {
            "fieldName": "memberId",
            "operation": "Equal",
            "value": memberId,
            "connector": "And"
          },
          {
            "fieldName": "cid",
            "operation": "Equal",
            "value": cid,
            "connector": "End"
          }
        ]
      },
      "offset": {
        "offset": 0,
        "limit": 10,
        "totalCount": 0,
        "totalCountRequested": false,
        "sortDirection": "ASCENDING",
        "sortingField": "id"
      }
    }).then((response) => {
      if (response.data.failureMessage === null) {
        this.setState({contentsInterests: response.data.queryResult, interested: true});
      } else {
        const initData = {
          id: '',
          entityVersion: 0,
          registrationTime: 0,
          modificationTime: 0,
          pavilionId: '',
          citizenId: '',
          cineroomId: '',
          audienceId: '',
          actorId: '',
          memberId: '',
          cid: '',
          interestsType: null
        }
        this.setState({contentsInterests: initData, interested: false});
      }
    }).catch((error) => {
      console.error(error);
    });
  }

  onClickInterest() {
    //
    const { openLoginModal, isLogin } = this.context;
    const { interested } = this.state;

    if (!isLogin) {
      openLoginModal();
      return;
    }

    if (interested) {
      this.removeContentsInterest();
    }
    else {
      this.registerContentsInterest();
    }
  }

  async registerContentsInterest() {
    //
    const { cid, interestsType } = this.props;
    const { actorIdentity, citizenUser, accessibleCinerooms } = this.context;
    const currentActorId = actorIdentity?.id;
    const email = actorIdentity?.email;
    const cineroomId = citizenUser?.cineroomIds[0];
    const cineroomName = accessibleCinerooms[0]?.name;

    const memberId = this.getMemberId();
    const actorId = this.getActorId();

    await axios.post("/api/interests/aggregate/contents/contents-interests/command", {
      "responseName": "response",
      "cqrsBaseCommandType": "Register",
      "contentsInterestsCdo": {
        "memberId": memberId, // "1:1:g"
        "cid": cid,
        "actorKey": actorId, // "zj@1:1:g-21"
        "interestsType": interestsType
      },
      "contentsInterestsCdos": [],
      "multiCdo": null,
      "contentsInterestsId": null,
      "nameValues": null
    }).then(async (response) => {
      if (response.data.failureMessage === null) {
        this.setState({interested: true});
        registerUsage(cid, currentActorId, email, cineroomId, cineroomName, interestsType, UsageType.InterestAdded);
        await this.init();
      }
    }).catch((error) => {
      console.error(error);
    });
  }

  async removeContentsInterest() {
    //
    const { interestsType } = this.props;
    const { contentsInterests } = this.state;

    if (!contentsInterests.cid) {
      return;
    }

    const { actorIdentity, citizenUser, accessibleCinerooms } = this.context;
    const currentActorId = actorIdentity?.id;
    const email = actorIdentity?.email;
    const cineroomId = citizenUser?.cineroomIds[0];
    const cineroomName = accessibleCinerooms[0]?.name;

    await axios.post("/api/interests/aggregate/contents/contents-interests/command", {
      "responseName": "response",
      "cqrsBaseCommandType": "Remove",
      "contentsInterestsCdo": null,
      "contentsInterestsCdos": [],
      "multiCdo": null,
      "contentsInterestsId": contentsInterests.id,
      "nameValues": null
    }).then(async (response) => {
      if (response.data.failureMessage === null) {
        this.setState({interested: false});
        registerUsage(contentsInterests.cid, currentActorId, email, cineroomId, cineroomName, interestsType, UsageType.InterestRemoved);
        await this.init();
      }
    }).catch((error) => {
      console.error(error);
    });
  }

  getMemberId() {
    const audienceId: string = getAudienceId();
    const cineroomId = audienceId.split("@")[1];

    return cineroomId;
  }

  getActorId() {
    const { actorIdentity } = this.context;
    
    return actorIdentity.id;
  }

  render() {
    const { isLogin } = this.context;
    const { interested } = this.state;

    return (
      <>
        {(isLogin && interested) ? (
          <IconButton onClick={() => this.onClickInterest()} color="primary">
            <BookmarkIcon/>
          </IconButton>
        ) : (
          <IconButton onClick={() => this.onClickInterest()}>
            <BookmarkBorderIcon/>
          </IconButton>
        )}
      </>
    );
  }
}

export default BookMarkButton;
