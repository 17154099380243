import React, { createRef } from "react";
import _ from "lodash";
import LocInfo from "../model/LocInfo";

declare global {
    let kakao: any;
}

type KakaoMapResultType = {
    address_name: string;
    category_group_code: string;
    category_group_name: string;
    category_name: string;
    distance: string;
    id: string;
    phone: string;
    place_name: string;
    place_url: string;
    road_address_name: string;
    x: string;
    y: string;
};

type KakaoMapLocationType = {
    address: {
        address_name: string;
        main_address_no: string;
        mountain_yn: string;
        region_1depth_name: string;
        region_2depth_name: string;
        region_3depth_name: string;
        sub_address_no: string;
        zip_code: string;
    };
    road_address: {
        address_name: string;
        building_name: string;
        main_building_no: string;
        region_1depth_name: string;
        region_2depth_name: string;
        region_3depth_name: string;
        road_name: string;
        sub_building_no: string;
        underground_yn: string;
        zone_no: string;
    };
};
interface Props {
    latitude: string;
    longitude: string;
}

interface State {
  clickedMarker: LocInfo;
  map: any;
  marker: any | null;
}

class KakaoMap extends React.Component<Props, State> {

  mapRef = createRef<HTMLDivElement>();

  constructor(props:any) {
    super(props);
    this.mapRef = React.createRef();
  }

  static defaultProps: any = {};

  state: State = {
    clickedMarker: LocInfo.newGyeongbokgung(5),
    map: {} as any,
    marker: null,
  };

  componentDidMount() {
    this.initMap();
  }

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
    snapshot?: any
  ): void {
    if (prevProps.latitude !== this.props.latitude || prevProps.longitude !== this.props.longitude) {
        this.initMap();
    }
  }

  initMap() {
    const { latitude, longitude } = this.props;
    // defaultProps 설정
    const mapClickEvent = true;
    const clickMarker = LocInfo.newGyeongbokgung(5);
    if (latitude && longitude) {
        clickMarker.lat = latitude;
        clickMarker.lng = longitude;
    }
    const defaultLevel = 5;

    const script = document.createElement("script");

    script.src = "//dapi.kakao.com/v2/maps/sdk.js?appkey=30730ac4e3d67c522312a032d1986a3b&autoload=false&libraries=services";
    document.head.appendChild(script);

    script.onload = () => {
      kakao.maps.load(() => {
        const container = this.mapRef.current;

        if (!container) {
            return;
        }

        container.setAttribute('style', 'height: 250px;');

        let centerLoc = new kakao.maps.LatLng(
          Number(clickMarker.lat),
          Number(clickMarker.lng)
        );

        if (clickMarker.lat && clickMarker.lng) {
          centerLoc = new kakao.maps.LatLng(
            Number(clickMarker.lat),
            Number(clickMarker.lng)
          );
        } else {
          this.searchingKeyword(clickMarker.roadAddress);
        }

        const options = {
          center: centerLoc,
          level: defaultLevel,
        };

        // eslint-disable-next-line no-new
        const map = new kakao.maps.Map(container, options);

        const marker = new kakao.maps.Marker({
          position: centerLoc,
        });

        marker.setMap(map);
        this.setMarker(marker);

        if (mapClickEvent) {
          kakao.maps.event.addListener(map, "click", (mouseEvent: any) => {
            // 클릭한 위도, 경도 정보를 가져옵니다
            const latlng = mouseEvent.latLng;
            // 마커 위치를 클릭한 위치로 옮깁니다
            const marker = new kakao.maps.Marker({
              position: new kakao.maps.LatLng(latlng.Ma, latlng.La),
            });

            marker.setMap(map);
            this.setMarker(marker);

            const geocoder = new kakao.maps.services.Geocoder();
            const coord = new kakao.maps.LatLng(latlng.Ma, latlng.La);

            geocoder.coord2Address(
              coord.getLng(),
              coord.getLat(),
              (results: KakaoMapLocationType[], status: any) => {
                if (status === kakao.maps.services.Status.OK) {
                  const { address, road_address } = results[0];

                  this.setState({
                    clickedMarker: new LocInfo(
                      (road_address && road_address.building_name) ||
                        address.address_name,
                      latlng.Ma,
                      latlng.La,
                      map.getLevel(),
                      (road_address && road_address.address_name) || "",
                      (address && address.address_name) || ""
                    ),
                  });
                }
              }
            );
          });
        }

        kakao.maps.event.addListener(map, "zoom_changed", () => {
          this.onChangeMapLevel(map.getLevel());
        });

        this.setState({ map });
      });
    };

    return () => script.remove();
  }

  searchingKeyword(text: string) {
    const ps = new kakao.maps.services.Places();
    ps.keywordSearch(text, this.placesSearchAndMoveToPlace);
  }

  setMarker(newMarker: any) {
    //
    const { marker: prevMarker } = this.state;

    if (prevMarker) {
      (prevMarker as any).setMap(null);
    }

    this.setState({ marker: newMarker });
  }

  onChangeMapLevel(level: number) {
    //
    this.setState(({ clickedMarker: prevClickedMarker }) => ({
      clickedMarker: _.set(prevClickedMarker, "level", level),
    }));
  }

  placesSearchAndMoveToPlace(data: KakaoMapResultType[], status: any) {
    if (status === kakao.maps.services.Status.OK) {
      const { map } = this.state;

      if (!map) {
        return;
      }

      // 검색된 장소 위치를 기준으로 지도 범위를 재설정하기위해
      // LatLngBounds 객체에 좌표를 추가합니다

      const firstSearchResult = data[0];

      if (!data.length) {
        return;
      }

      const bounds = new kakao.maps.LatLngBounds();

      const marker = new kakao.maps.Marker({
        position: new kakao.maps.LatLng(
          firstSearchResult.y,
          firstSearchResult.x
        ),
      });

      marker.setMap(map);
      this.setMarker(marker);

      kakao.maps.event.addListener(marker, "click", () => {
        this.openModalAndShowInfoFromMarker(firstSearchResult);
      });

      for (let i = 0; i < data.length; i++) {
        bounds.extend(new kakao.maps.LatLng(data[i].y, data[i].x));
      }
      // 검색된 장소 위치를 기준으로 지도 범위를 재설정합니다
      map.setBounds(bounds);
    } else if (status === kakao.maps.services.Status.ZERO_RESULT) {
    }
  }

  openModalAndShowInfoFromMarker(location: KakaoMapResultType) {
    //
    this.setState(({ clickedMarker: prevClickedMarker }) => ({
      clickedMarker: new LocInfo(
        location.place_name,
        location.y,
        location.x,
        prevClickedMarker.level,
        location.road_address_name,
        location.address_name
      ),
    }));
  }

 

  render() {

    return(
        <div id="map" ref={this.mapRef}/>
    )
  }
}

export default KakaoMap;
