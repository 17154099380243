import { GridFilterItem, GridSortItem, GridLogicOperator } from '@mui/x-data-grid';
import XGridOperator from './XGridOperator';
import makeExtendedObservable from '../../lib/decorator/makeExtendedObservable';
import Offset from '../../lib/domain/offset/Offset';
import Operator from '../../lib/domain/cqrs/query/Operator';
import QueryParam from '../../lib/domain/cqrs/query/QueryParam';


class XGridFilter {
  filterInfo: GridFilterItem[] = [];
  sortInfo: GridSortItem = { field: 'id', sort: 'asc' };
  page: number;
  pageSize: number;
  linkOperator: GridLogicOperator | null = null;
  defaultSortInfo: GridSortItem = { field: 'id', sort: 'asc' };
  totalCount: number;

  constructor(filterInfo: GridFilterItem[], sortInfo: GridSortItem, page: number, pageSize: number) {
    //
    this.filterInfo = filterInfo;
    this.sortInfo = sortInfo;
    this.page = page;
    this.pageSize = pageSize;
    this.defaultSortInfo = sortInfo;
    this.totalCount = 0;
    makeExtendedObservable(this);
  }

  filterQueryParams(): QueryParam[] {
    const params: QueryParam[] = [];

    this.filterInfo.forEach(model => {
      const { operator: operatorValue, value, field: columnField } = model;

      let operator = this.getOperator(operatorValue as XGridOperator);
      let paramValue: string;

      if (value && operator) {

        switch (operator) {
          case Operator.Like:
            paramValue = `%${value}%`;
            operator = Operator.Like;
            break;
          case Operator.Equal:
            paramValue = `${value}`;
            operator = Operator.Equal;
            break;
          case Operator.GreaterThanOrEqual:
            paramValue = `${value}%`;
            operator = Operator.Like;
            break;
          case Operator.LessThanOrEqual:
            paramValue = `%${value}`;
            operator = Operator.Like;
            break;
          default:
            throw new DOMException('XGridStateKeeper.filterQueryParams.getOperator', `Invalid OperatorType : ${value}`);
        }

        // paramValue = operator === Operator.Like ? `%${value}%` : value;

        if (this.linkOperator && this.linkOperator === GridLogicOperator.Or) {
          params.push(QueryParam.orParam(columnField as string, operator, paramValue));
        }
        else {
          params.push(QueryParam.andParam(columnField as string, operator, paramValue));
        }
      }
    });

    return params;
  }

  filterOffset(): Offset {
    // first page is 0
    const page = this.page;
    const pageSize = this.pageSize;

    return this.addSortInfo(page ? page * pageSize : 0, pageSize);
  }


  private getOperator(value: XGridOperator) {
    switch (value) {
      case XGridOperator.Contains:
        return Operator.Like;

      case XGridOperator.Equals:
        return Operator.Equal;

      case XGridOperator.StartsWith:
        return Operator.GreaterThanOrEqual;

      case XGridOperator.EndsWith:
        return Operator.LessThanOrEqual;

      default:
        throw new DOMException('XGridStateKeeper.filterQueryParams.getOperator', `Invalid OperatorType : ${value}`);
    }
  }


  private addSortInfo(offset: number, limit: number): Offset {

    if (!this.sortInfo) {
      return this.defaultSortInfo.sort === 'asc' ?
        Offset.newAscending(offset, limit, this.defaultSortInfo.field)
        : Offset.newDescending(offset, limit, this.defaultSortInfo.field);
    }
    else {
      return this.sortInfo.sort === 'asc' ?
        Offset.newAscending(offset, limit, this.sortInfo.field)
        : Offset.newDescending(offset, limit, this.sortInfo.field);
    }
  }


}

export default XGridFilter;
