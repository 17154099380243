
import { set } from 'lodash';
import AddressCode from '../../OrgProposalForm/api-model/vo/AddressCode';
import NameValue from '../../model/NameValue/NameValue';
import NameValueList from '../../model/NameValue/NameValueList';
import makeExtendedObservable from '../decorator/makeExtendedObservable';
import DomainEntity from '../domain/ddd/DomainEntity';
import Field from './Field';
import GeoCoordinate from './GeoCoordinate';
import StreetAddress from './StreetAddress';


class Address extends DomainEntity {
geoCoordinate: GeoCoordinate | null = null;
countryCode: string;
streetAddress: StreetAddress | null = null;
city: string;
state: string;
zipCode: string;
field: Field | null = null;
fullAddress: string;
addressBookId: string;
userId: string;
addressCode: AddressCode | null = null;
emdNm: string;
liNm: string;
basicAddress: string;
roadAddr: string;

constructor(
    countryCode: string,
    city: string,
    state: string,
    zipCode: string,
    fullAddress: string,
    addressBookId: string,
    userId: string,
    emdNm: string,
    liNm: string,
    basicAddress: string,
    roadAddr: string) {
    super();
    this.countryCode = countryCode;
    this.city = city;
    this.state = state;
    this.zipCode = zipCode;
    this.fullAddress = fullAddress;
    this.addressBookId = addressBookId;
    this.userId = userId;
    this.emdNm = emdNm;
    this.liNm = liNm;
    this.basicAddress = basicAddress;
    this.roadAddr = roadAddr;
    makeExtendedObservable(this);
}

static fromDomain(domain: Address): Address {
    const address = new Address(
    domain.countryCode,
    domain.city,
    domain.state,
    domain.zipCode,
    domain.fullAddress,
    domain.addressBookId,
    domain.userId,
    domain.emdNm,
    domain.liNm,
    domain.basicAddress,
    domain.roadAddr
    );

    address.setDomainEntity(domain);
    address.geoCoordinate = domain.geoCoordinate ? GeoCoordinate.fromDomain(domain.geoCoordinate) : null;
    address.streetAddress = domain.streetAddress ? StreetAddress.fromDomain(domain.streetAddress) : null;
    address.addressCode = domain.addressCode ? AddressCode.fromDomain(domain.addressCode) : null;
    address.field = domain.field ? Field.fromDomain(domain.field) : null;
    return address;
}

static fromDomains(domains: Address[]): Address[] {
    return domains.map(domain => this.fromDomain(domain));
}

static asNameValues(model: Address): NameValueList {

    const nameValueList = NameValueList.fromModel(Address, model, {
    // geoCoordinate: JSON.stringify(GeoCoordinate),
    countryCode: String,
    // streetAddress: JSON.stringify(StreetAddress),
    city: String,
    state: String,
    zipCode: String,
    // field: JSON.stringify(Field),
    fullAddress: String,
    // geoParam: JSON.stringify(AddressCode)
    });

    const streetAddressNameValue = new NameValue('streetAddress', JSON.stringify(model.streetAddress));

    nameValueList.nameValues.push(streetAddressNameValue);

    return nameValueList;
}

static new(addressBookId: string, userId: string): Address {
    const address = new Address('', '', '', '', '', addressBookId, userId, '', '', '', '');

    address.streetAddress = new StreetAddress('', '');

    return address;
}

setGeoCoordinate(geoCoordinate: GeoCoordinate | null) {
    this.geoCoordinate = geoCoordinate;
}

setStreetAddress(streetAddress: StreetAddress | null) {
    this.streetAddress = streetAddress;
}

setField(field: Field | null) {
    this.field = field;
}

setAddressCode(addressCode: AddressCode | null) {
    this.addressCode = addressCode;
}

get roadAddress() {
    //
    /*const koReg = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/gi;
    // const roadAddress = [this.state, this.city, this.emdNm, this.streetAddress?.street, this.streetAddress?.building];
    const roadAddress = [this.state, this.city, this.emdNm, this.streetAddress?.street, this.streetAddress?.building];

    if (koReg.test(roadAddress.join(''))) {
    return roadAddress.join(' ') + ' ';
    }
    else {
    return roadAddress.reverse().join(', ') + ' ';
    }*/
    return this.roadAddr;
}

get basicRoadAddress() {
    //
    return `${this.state} ${this.city} ${this.streetAddress?.street} `;
}
}

export default Address;
