import React from 'react';
import { Button, Fade, Icon, Modal, TextField } from "@mui/material";
import autobind from '../../../lib/decorator/autobind/autobindDecorator';
import Address from '../../../lib/granule/Address';
import ReactComponent from '../../../lib/module/ReactComponent/ReactComponent';
import { observer } from 'mobx-react';
import AddressStateKeeper from '../../state/Address/keeper/AddressStateKeeper';
import AddressList from './AddressList';
import { geoCoding } from '../../../shared/Function/address/Function';


interface Props {
  zipCode: string;
  streetAddress: string;
  additionalAddress: string;
  onClickAddress: (address: Address) => void;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
}

@autobind
@observer
class AddressRegistrationContainer extends ReactComponent<Props> {
  //
  static defaultProps = {
    disabled: false,
  };

  state = {
    open: false,
    addressKeyword: '',
    street: '',
  };

  addressStateKeeper = AddressStateKeeper.instance;

  show() {
    //
    this.setState({ open: true });
  }

  handleClose() {
    this.hide();
  }

  hide() {
    //
    this.setState({ open: false });
  }

  async onClickAddress(address: Address) {
    //
    const { onClickAddress } = this.props;
    const geocodedAddress = await geoCoding(address);

    onClickAddress(geocodedAddress);
    this.hide();
  }

  render() {
    //
    const { streetAddress, additionalAddress, onChange } = this.props;
    const { disabled } = this.propsWithDefault;
    const { open } = this.state;

    return (
      <>
      <div className="step-content">
        <div className="info-list">
              <label>주소 <span className="required"></span></label>
              <div className="input-group">
                  <input 
                    type="text" placeholder="검색 버튼을 눌러 주소입력하기" className="input-box with-btn"
                    name="address"
                    id="address"
                    value={streetAddress}
                    readOnly={true}
                    onChange={onChange}
                  />
                  <button className="btn-search" disabled={disabled} onClick={this.show}>검색</button>
              </div>
              <input 
                type="text" placeholder="상세주소를 입력하세요" className="input-box"
                name="address"
                id="additionalAddress"
                value={additionalAddress}
                disabled={disabled}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => onChange(event)}
              />
          </div>


          {/* <div className="layout-flex layout-gap layout-mt-30">
            <TextField // 주소
              name="address"
              id="address"
              value={streetAddress}
              label="기관 주소"
              className="label-top input-large"
              InputProps={{ readOnly: true }}
              onChange={onChange}
              sx={{
                border: 'solid 1px #cdcdcd',
                borderRadius: '5px',
              }}
              InputLabelProps={{
                style: { color: 'black' },
                htmlFor: 'address',
                shrink: true
              }}
              fullWidth
              variant="outlined"
              autoComplete="off"
              style={{ backgroundColor: '#ddd' }}
            />
            <Button
              onClick={this.show}
              className="btn-search"
              disabled={disabled}
            >
              <Icon>search</Icon>
              <span>검색</span>
            </Button>
          </div>
          <div className="layout-flex layout-gap layout-mt-10">
            <TextField // 주소
              name="address"
              id="additionalAddress"
              value={additionalAddress}
              disabled={disabled}
              sx={{
                border: 'solid 1px #cdcdcd',
                borderRadius: '5px',
              }}
              InputLabelProps={{
                style: { color: 'black' },
                htmlFor: 'additionalAddress',
                shrink: true
              }}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => onChange(event)}
              label="상세주소"
              className="label-top input-large"
              fullWidth
              variant="outlined"
              autoComplete="off"
            />
          </div> */}
          <Modal
            className="modal-backdrop popper-public"
            open={open}
            onClose={this.hide}
            closeAfterTransition
          >
            <Fade in={open}>
              <div className="modal-wrap">
                <div className="modal-header small">
                  <div className="tit">
                    <strong className="b">주소 검색</strong>
                  </div>
                  <div>
                    <Button onClick={this.hide}>
                      <Icon>close</Icon>
                    </Button>
                  </div>
                </div>
                <div className="modal-inner">
                  <AddressList>
                    <AddressList.Content
                      onClick={(event: React.MouseEvent, address: Address) => this.onClickAddress(address)}
                    />
                  </AddressList>
                  <div className="modal-footer">
                    <div className="modal-btns" style={{ marginBottom: 0 }}>
                      <Button className="btn-init fill primary icon large space" onClick={this.hide}>
                        <Icon style={{ fontSize: 16 }}>clear</Icon>
                        <span>닫기</span>
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </Fade>
          </Modal>
        </div>
      </>
    );
  }
}

export default AddressRegistrationContainer;
