
class ActorIdentity {
    id: string;
    entityVersion: number;
    registrationTime: number;
    modificationTime: number;
    audienceId: string;
    name: string;
    email: string;
    orgRoleKeys: string;
    castId: string;
    troupeId: string;
    lifeCycle: string;
    stageRoleSet: {
        roleSet: string[]
    };
    taskRoleSet: {
        roleSet: string[]
    };
    dramaRoleSet: {
        roleSet: string[]
    }

  constructor(
    id: string, 
    entityVersion: number, 
    registrationTime: number,
    modificationTime: number, 
    audienceId: string, 
    name: string, 
    email: string,
    orgRoleKeys: string,
    castId: string,
    troupeId: string,
    lifeCycle: string
    ) {
    this.id = id;
    this.entityVersion = entityVersion;
    this.registrationTime = registrationTime;
    this.modificationTime = modificationTime;
    this.audienceId = audienceId;
    this.name = name;
    this.email= email;
    this.orgRoleKeys = orgRoleKeys;
    this.castId = castId;
    this.troupeId = troupeId;
    this.lifeCycle = lifeCycle;
    this.stageRoleSet = {
        roleSet: []
    };
    this.taskRoleSet = {
        roleSet: []
    };
    this.dramaRoleSet = {
        roleSet: []
    }
    
  }

  static new(): ActorIdentity {
    return new ActorIdentity('', 0, 0, 0, '', '', '', '', '', '', '');
  }

}

export default ActorIdentity;
