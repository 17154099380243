import React from 'react';
import { KoglType } from '../../../model/type/KoglType';

export function changeKoglCodeToKoglTypeKor(code: string) {
  switch (code) {
    case '30928':
      return '1유형';
    case '30929':
      return '2유형';
    case '30930':
      return '3유형';
    case '30931':
      return '4유형';
    default:
      return null;
  }
}

export function changeKoglCodeToKoglType(code: string) {
  switch (code) {
    case '30928':
      return KoglType.Type1;
    case '30929':
      return KoglType.Type2;
    case '30930':
      return KoglType.Type3;
    case '30931':
      return KoglType.Type4;
    default:
      return '';
  }
}

export function changeKogTypeToKoglTypeKorDetail(type: string) {
  switch (type) {
    case 'Type1':
      return '공공누리_제1유형';
    case 'Type2':
      return '공공누리_제2유형';
    case 'Type3':
      return '공공누리_제3유형';
    case 'Type4':
      return '공공누리_제4유형';
    default:
      return null;
  }
}

export function convertToKoglType (koglTypeString: string) {
  switch(koglTypeString) {
    case "Type1" :
      return KoglType.Type1;
    case "Type2":
      return KoglType.Type2;
    case "Type3":
      return KoglType.Type3;
    case "Type4":
      return KoglType.Type4;
    default:
      return null;
  }
}
