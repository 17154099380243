import axios from 'axios';
import OrgProposalCdo from '../../../OrgProposalForm/api-model/sdo/OrgProposalCdo';
import CommandResponse from '../../../lib/cqrs/command/CommandResponse';
import { apiURL } from '..';

const url = apiURL+'/api/onboarding';
const orgProposalQueryFlow = url+'/flow/org-proposal-query-flow';
const orgProposalFlow = '/flow/org-proposal-flow'
const aggregateOrgProfileQueryURL = '/aggregate/organization/org-profile/query'

export const orgProposalQueryFlowFc = async (requestUrl: string, data: any) => {
  const result = await axios.post(`${orgProposalQueryFlow+requestUrl}`, data);
  return result.data;
}

export const requestOrgProposal = async (orgProposalCdo: OrgProposalCdo, ssoId: String) => {
  const data = {
    orgProposalCdo,
    ssoId,
  }
  
  const commandResponse: CommandResponse = await axios.post(url+orgProposalFlow+'/request-org-proposal-ssoId', data);
    
  return commandResponse;  
}

export const closureCheck = async (cineroomId: string) => {
  const data = {
    cineroomId,
  }
  
  const res = await axios.post(url+aggregateOrgProfileQueryURL+'/check-group-state', data);
  return res.data.queryResult;
}