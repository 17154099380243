class UserInfo {
  actorId: string;
  actorName: string;
  actorEmail: string;
  cineroomId: string;
  cineroomName: string;

  constructor(
    actorId: string,
    actorName: string,
    actorEmail: string,
    cineroomId: string,
    cineroomName: string,
  ) {
    this.actorId = actorId;
    this.actorName = actorName;
    this.actorEmail = actorEmail;
    this.cineroomId = cineroomId;
    this.cineroomName = cineroomName;
  }

  static new() {
    return new UserInfo('', '', '', '', '');
  }

  static fromDomain(domain: UserInfo) {
    return new UserInfo(
      domain.actorId,
      domain.actorName,
      domain.actorEmail,
      domain.cineroomId,
      domain.cineroomName,
    );
  }

}

export default UserInfo;
