
import DepotCdo from '../../../depot/api-model/sdo/DepotCdo';
import UserInfo from '../../model/UserInfo';
import Filebox from '../Filebox';


class FileboxCdo extends DepotCdo {
  // name, spaceId 필수
  userInfo: UserInfo | null = null;

  constructor(
    id: string,
    name: string,
    valutId: string,
    ownerId: string,
    spaceId: string,
    userInfo: UserInfo | null,
  ) {
    super(id, name, valutId, ownerId, spaceId);
    this.userInfo = userInfo;
  }

  static fromModel(domain: Filebox): FileboxCdo {
    return new FileboxCdo(
      domain.id,
      domain.name,
      domain.vaultId,
      domain.ownerId,
      domain.spaceId,
      domain.userInfo
    );
  }


}

export default FileboxCdo;
