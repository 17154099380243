import autobind from "../../../lib/decorator/autobind/autobindDecorator";
import { hasAll, hasAny } from "../../shared/RoleFileter/RoleUtil";

@autobind
class DramaStateKeeper {
  actorId: string = '';
  roles: string[] = [];

  initHeaderInfo(actorId: string, roles: string[]) {
    this.setActorId(actorId);
    this.setRoles(roles);
  }

  setActorId(actorId: string) {
    this.actorId = actorId;
  }

  setRoles(roles: string[]) {
    this.roles = roles;
  }

  hasAllRoles(checkRoles: string[]) {
    return hasAll(this.roles, checkRoles);
  }

  hasAnyRoles(checkRoles: string[]) {
    return hasAny(this.roles, checkRoles);
  }
}

export default DramaStateKeeper;
