import React, { ContextType } from "react";
import CommonContext from "../context/CommonContext";
import LoginPannel from "./LoginPannel";

interface Props {
}

interface State {}

class LoginModal extends React.Component<Props, State> {
  static defaultProps: any = {};
  static contextType = CommonContext;

  context!: ContextType<typeof CommonContext>;

  componentDidMount() {}

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
    snapshot?: any
  ): void {}

  //@ts-ignore
  state: State = { ...this.props };

  render() {
    const { closeLoginModal } = this.context;

    return (
      <div className="login_page">
        <div className="login_box_wrap">
          
          <LoginPannel isModal={true} />
          <div className="bg" onClick={() => closeLoginModal()}></div>
        </div>
      </div>
    );
  }
}

export default LoginModal;
