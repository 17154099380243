import React, { createRef } from "react";
import axios from "axios";
import { LangType } from "../../model/type/LangType";
import { MarketingType } from "../../model/type/MarketingType";
import Card from "../../components/Card";
import InfoCard from "../../components/InfoCard";
import { Chip, CircularProgress } from "@mui/material";
import Pagination from '@mui/material/Pagination';
import Alert from "../../components/Alert";
import SearchModalView from "./SearchModalView";
import ModalView from "../../conlab/main-theme/ModalView";
import { SortDirection } from "../../model/type/SearchType";
import queryParam from "../../lib/domain/cqrs/query/QueryParam";

interface Props {
  cid: string;
  data: string;
}

interface State {
  data: string
  cids: string
  selectedCid: string;
  selectedCategoryId: string;
  selectedContentType: any;
  inputSearchValue: string;
  inputSearchValueTemp: string;
  selectedLanguage: string;
  selectedMarketingType: string;
  queryParams: {
    keyword: string;
    lang: string;
    marketingType: string;
    sortDirection: string;
  };
  searchTotalCount: {
    casualCount: number;
    fileCount: number;
    imageCount: number;
    tourismCount: number;
    videoCount: number;
    courseCount: number;
    marketingCount: number;
  };
  findedFormalSummary: {
    nature: {
      list: any[],
      score: number,
      totalCount: number
    },
    history: {
      list: any[],
      score: number,
      totalCount: number
    },
    experience: {
      list: any[],
      score: number,
      totalCount: number
    },
    culture: {
      list: any[],
      score: number,
      totalCount: number
    },
    festival: {
      list: any[],
      score: number,
      totalCount: number
    },
    food: {
      list: any[],
      score: number,
      totalCount: number
    },
    accommodation: {
      list: any[],
      score: number,
      totalCount: number
    },
    shopping: {
      list: any[],
      score: number,
      totalCount: number
    },
    leisure: {
      list: any[],
      score: number,
      totalCount: number
    }
  };
  findedImageSummary: {
    categoryPath: string,
    cid: string,
    contentsName: string,
    imagePath: string,
    imageType: string,
    koglType: string,
    modificationTime: string,
    score: number,
    tid: string
  }[];
  findedVideoSummary: {
    cid: string,
    tid: string,
    contentsName: string,
    thumbnailPath: string,
    koglType: string,
    categoryPath: string,
    modificationTime: string
  }[];
  findedThemeSummary: {
    id: string,
    contentsName: string,
    cineroomName: string,
    registrationTime: string,
    imagePath: string,
  }[];
  findedCasualSummary: {
    cid: string,
    contentsName: string,
    tid: string,
    tourismName: string,
    categoryPath: string,
    imagePath: string
  }[];
  selectedTab: string;
  selectedPage: number;
  isEmptyResult: boolean;
  themeTotalCount: number;
  themeDetailObject: {
    actorId: string, audienceId: string, bannerImgPath: string, children: string, cineroomId: string, cineroomName: string, citizenId: string, cmsCategoryId: string, elementTypeId: string, entityVersion: string,
    existChild: boolean, folderType: boolean, id: string, idPaths: [], imagePath: string, langUsed: boolean, lastUpdateTime: number, modificationTime: number, lastUpdater: { id: string, name: string }, marketingType: string,
    name: string, registrationTime: number, type: string, used: boolean, writer: { id: string, name: string }, pavilionId: string, parentId: string, originCategoryId: string, originFolderId: string, seq: number, publicType: boolean
  };
  themeDetailList: object[];
  findedCourseSummary: {
    cid: string,
    contentsName: string,
    totalDistance: string,
    duration: string,
    imagePath: string
  }[];
  findedFileSummary: {
    cid: string,
    rowNumber: 1,
    tourismName: string;
    contentsName: string;
    fileType: string;
    cineroomName: string;
    registrationTime: string
  }[];
  popularKeywords: {
    rank: number,
    keyword: string
  }[];
  recentSearchKeywords: [string, string, string, string, string];
  moreInfoTitle: string;
  moreInfoType: string;
  totalCount: number;
  listCount: any;
  isSelected: boolean;
  viewWidth: number | undefined;
  isEmptyKeyword: boolean;
  upDateImageSummaryDate: {
    categoryPath: string,
    cid: string,
    contentsName: string,
    imagePath: string,
    imageType: string,
    koglType: string,
    modificationTime: string,
    score: number,
    tid: string
  }[];
  upDateFormalSummaryDate: {
    nature: {
      list: any[],
      score: number,
      totalCount: number
    },
    history: {
      list: any[],
      score: number,
      totalCount: number
    },
    experience: {
      list: any[],
      score: number,
      totalCount: number
    },
    culture: {
      list: any[],
      score: number,
      totalCount: number
    },
    festival: {
      list: any[],
      score: number,
      totalCount: number
    },
    food: {
      list: any[],
      score: number,
      totalCount: number
    },
    accommodation: {
      list: any[],
      score: number,
      totalCount: number
    },
    shopping: {
      list: any[],
      score: number,
      totalCount: number
    },
    leisure: {
      list: any[],
      score: number,
      totalCount: number
    }
  };
  upDateCourseSummaryDate: {
    cid: string,
    contentsName: string,
    totalDistance: string,
    duration: string,
    imagePath: string
  }[];
  upDateCasualSummaryDate: {
    cid: string,
    contentsName: string,
    tid: string,
    tourismName: string,
    categoryPath: string,
    imagePath: string
  }[];
  upDateVideoSummaryDate: {
    cid: string,
    tid: string,
    contentsName: string,
    thumbnailPath: string,
    koglType: string,
    categoryPath: string,
    modificationTime: string
  }[];
  upDateFileSummaryDate: {
    cid: string,
    rowNumber: 1,
    tourismName: string;
    contentsName: string;
    fileType: string;
    cineroomName: string;
    registrationTime: string
  }[];
  openDetailModal: undefined | "detail";
  openThemeModal: undefined | "detail";
  modalType: string;
  categoryId: string;
  recommendThemeObject: object[];
  imageDetailBase: {
    copyright: string;
    imageProperty: string;
    provider: string;
    depotId: string;
    imagePath: string;
    enTitle: string;
    cid: string;
    tid: string;
  };
  categoryPath: [];
  categoryAddress: {};
  isProgress: boolean;
  isFormalList: boolean;
}

enum TabValue {
  TOURISM = "관광정보",
  CASUAL = "여행기사",
  IMAGE = "이미지",
  VIDEO = "동영상",
  FILE = "파일",
  THEME = "테마",
  COURSE = "코스"
}

class SearchMainPage extends React.Component<Props, State> {
  //메인 테마 모달창
  modalRef = createRef<HTMLDivElement>();
  contentsList = createRef<HTMLDivElement>();
  contentsDetail = createRef<HTMLDivElement>();
  searchBody = createRef<HTMLDivElement>();
  inputRef = createRef<HTMLInputElement>();
  formalListRef = createRef<HTMLAnchorElement>();

  static defaultProps: any = {};

  localStorageForKeyword = "recentSearch";

  constructor(props: any) {
    super(props);
    this.setSelectedCid = this.setSelectedCid.bind(this);
    this.setSortDirection = this.setSortDirection.bind(this);
    this.setSelectedCategoryId = this.setSelectedCategoryId.bind(this);
    this.contentsClickMoreView = this.contentsClickMoreView.bind(this);
    this.setSelected = this.setSelected.bind(this);
    this.onClickSearch = this.onClickSearch.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.onClickCloseModal = this.onClickCloseModal.bind(this);
    this.onClickDetailModal = this.onClickDetailModal.bind(this);
    this.onClickThemeCloseModal = this.onClickThemeCloseModal.bind(this);
    this.onClickThemeModal = this.onClickThemeModal.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handlePopState = this.handlePopState.bind(this);
    this.handleDocumentClick = this.handleDocumentClick.bind(this);
    this.contentsList = React.createRef();
    this.contentsDetail = React.createRef();
    this.searchBody = React.createRef();
    this.modalRef = React.createRef();
    this.inputRef = React.createRef();
    this.formalListRef = React.createRef();
    this.contentsDetail.current?.style.setProperty('display', 'none');
    addEventListener("resize", (event) => { this.changeViewWidth() });
  }

  componentDidMount() {
    this.initQueryString();
    this.findPopularKeyword();
    this.getRecentSearchKeywords();
    this.contentsDetail.current?.style.setProperty('display', 'none');
    document.addEventListener('keydown', this.handleKeyDown);
    document.addEventListener('click', this.handleDocumentClick);
    window.addEventListener('popstate', this.handlePopState);
  }

  handleDocumentClick = (event: any) => {
    const searchBodyElement = document.getElementById('search_page_body');

    if (!(searchBodyElement?.contains(event.target) || event.target.id === 'overall_search_input')){
      this.hideSearchInput();
    }
  };

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeyDown);
    document.removeEventListener('click', this.handleDocumentClick);
    window.removeEventListener('popstate', this.handlePopState);
  }

  handlePopState = (event: PopStateEvent) => {
    this.onClickCloseModal();
  };

  handleKeyDown = (event: KeyboardEvent) => {
    switch (event.key) {
      case 'Backspace':
        this.onClickCloseModal();
        break;
      case 'Escape':
        this.onClickCloseModal();
        break;
      default:
        break;
    }
  };

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
    snapshot?: any
  ): void { }

  //@ts-ignore
  state: State = {
    ...this.props,
    data: '',
    selectedCid: '',
    selectedCategoryId: '',
    inputSearchValue: '',
    inputSearchValueTemp: '',
    selectedLanguage: LangType.ko,
    selectedMarketingType: MarketingType.ALL,
    queryParams: {
      keyword: '',
      lang: '',
      marketingType: '',
      sortDirection: '',
    },
    searchTotalCount: {
      casualCount: 0,
      fileCount: 0,
      imageCount: 0,
      tourismCount: 0,
      videoCount: 0,
      courseCount: 0,
      marketingCount: 0
    },
    findedFormalSummary: {
      nature: {
        list: [],
        score: 0,
        totalCount: 0
      },
      history: {
        list: [],
        score: 0,
        totalCount: 0
      },
      experience: {
        list: [],
        score: 0,
        totalCount: 0
      },
      culture: {
        list: [],
        score: 0,
        totalCount: 0
      },
      festival: {
        list: [],
        score: 0,
        totalCount: 0
      },
      food: {
        list: [],
        score: 0,
        totalCount: 0
      },
      accommodation: {
        list: [],
        score: 0,
        totalCount: 0
      },
      shopping: {
        list: [],
        score: 0,
        totalCount: 0
      },
      leisure: {
        list: [],
        score: 0,
        totalCount: 0
      }
    },
    findedImageSummary: [],
    findedVideoSummary: [],
    findedThemeSummary: [],
    findedCasualSummary: [],
    findedFileSummary: [],
    selectedTab: TabValue.TOURISM,
    selectedPage: 1,
    isEmptyResult: false,
    themeTotalCount: 0,
    popularKeywords: [],
    recentSearchKeywords: ['', '', '', '', ''],
    moreInfoTitle: '',
    moreInfoType: '',
    totalCount: 0,
    listCount: [],
    isSelected: false,
    viewWidth: window.innerWidth,
    isEmptyKeyword: false,
    upDateImageSummaryDate: [],
    upDateCourseSummaryDate: [],
    upDateCasualSummaryDate: [],
    upDateVideoSummaryDate: [],
    upDateFileSummaryDate: [],
    upDateFormalSummaryDate: {
      nature: {
        list: [],
        score: 0,
        totalCount: 0
      },
      history: {
        list: [],
        score: 0,
        totalCount: 0
      },
      experience: {
        list: [],
        score: 0,
        totalCount: 0
      },
      culture: {
        list: [],
        score: 0,
        totalCount: 0
      },
      festival: {
        list: [],
        score: 0,
        totalCount: 0
      },
      food: {
        list: [],
        score: 0,
        totalCount: 0
      },
      accommodation: {
        list: [],
        score: 0,
        totalCount: 0
      },
      shopping: {
        list: [],
        score: 0,
        totalCount: 0
      },
      leisure: {
        list: [],
        score: 0,
        totalCount: 0
      },
    },
    openDetailModal: undefined,
    openThemeModal: undefined,
    modalType: "",
    categoryId: "",
    recommendThemeObject: [],
    imageDetailBase: {
      copyright: "",
      imageProperty: "",
      provider: "",
      depotId: "",
      imagePath: "",
      enTitle: "",
      cid: "",
      tid: "",
    },
    categoryPath: [],
    categoryAddress: {},
    isProgress: false,
    isFormalList: false,
  };

  async findPopularKeyword() {
    // 인기 검색어
    await axios.get("/api/keyword/elastic/popular/find-keyword-list")
      .then((response) => {
        this.setState({ popularKeywords: response.data.list });
      }).catch((error) => {
        console.log(error);
      });
  }

  async findFormalSummaryList() {
    const { inputSearchValue, selectedLanguage, queryParams, moreInfoType, selectedPage  } = this.state;

    await axios.get("/api/keyword/elastic/formal-summary/find-formal-summary-list", {
      params: {
        keyword: inputSearchValue || queryParams.keyword,
        currentPage: selectedPage,
        marketingType: moreInfoType,
        lang: selectedLanguage || queryParams.lang,
        sortDirection: queryParams.sortDirection,
      }
    })
      .then(async (response) => {
        await this.findFormalSummaryListWithDepotUrl(response.data.list);
        this.setState({ totalCount: response.data.totalCount })
      }).catch((error) => {
        console.log(error);
      });
  }

  async findFormalSummaryListWithDepotUrl(formalSummaryList: any[]) {
    await axios.post("/api/naomi/image-contents/search-result/find-depot-url-by-cid/list", {
      "list": formalSummaryList
    })
      .then(async (response) => {
        this.setState({ findedFormalSummary: response.data.queryResult });
        this.setState({ listCount: response.data.queryResult.length });
      }).catch((error) => {
        console.log(error);
      });
  }

  async findSearchSummaryTotalCount() {
    const { inputSearchValue, selectedLanguage, selectedMarketingType, queryParams } = this.state;
    await axios.get("/api/keyword/search-summary/find-search-summary-total-count", {
      params: {
        keyword: inputSearchValue || queryParams.keyword,
        marketingType: selectedMarketingType || queryParams.marketingType,
        lang: selectedLanguage || queryParams.lang,
        koglType: 'ALL'
      }
    })
      .then(async (response) => {
        this.setState({ searchTotalCount: response.data }, () => this.registerSearchHistory());
      }).catch((error) => {
        console.log(error);
      });

  }

  async findFormalSummary() {
    const { inputSearchValue, selectedLanguage, queryParams, selectedMarketingType } = this.state;
    await axios.get("/api/keyword/elastic/formal-summary/find-formal-summary-list-group-by-category", {
      params: {
        keyword: inputSearchValue || queryParams.keyword,
        marketingType: selectedMarketingType || queryParams.marketingType,
        lang: selectedLanguage || queryParams.lang
      }
    })
      .then(async (response) => {
        await this.findFormalSummaryWithDepotUrl(response.data);
      }).catch((error) => {
        console.log(error);
      });
  }

  async findFormalSummaryWithDepotUrl(formalSummaryList: any[]) {
    await axios.post("/api/naomi/image-contents/search-result/find-depot-url-by-cid/group", {
      "list": formalSummaryList
    })
      .then((response) => {
        if (response.status === 200) {
          this.setState({ isProgress: true })
        }
        const formalKeys = Object.keys(response.data.queryResult);

        let isEmpty = false;
        let emptyCount = 0;

        for (let i = 0; i < formalKeys.length; i++) {
          if (response.data.queryResult[formalKeys[i]].list.length === 0) {
            emptyCount += 1;
          }
        }

        if (emptyCount === formalKeys.length) {
          isEmpty = true;
        }

        this.setState({ findedFormalSummary: response.data.queryResult, isEmptyResult: isEmpty });
      }).catch((error) => {
        console.log(error);
      });
  }

  async findImageSummary() {
    const { inputSearchValue, selectedPage, queryParams } = this.state;
    await axios.get("/api/keyword/elastic/image-summary/find-image-list", {
      params: {
        keyword: inputSearchValue,
        sortDirection: queryParams.sortDirection,
        currentPage: selectedPage
      }
    }).then((response) => {
      if (response.status === 200) {
        this.setState({ isProgress: true })
      }
      if (response.data.list.length === 0) {
        this.setState({ isEmptyResult: true });
      } else {
        this.setState({ findedImageSummary: response.data.list });
      }
    }).catch((error) => {
      console.log(error);
    });
  }

  async findCasualSummary() {
    const { inputSearchValue, selectedLanguage, selectedPage, queryParams } = this.state;
    await axios.get("/api/keyword/elastic/casual/find-by-name-and-marketing-type", {
      params: {
        keyword: inputSearchValue,
        currentPage: selectedPage,
        sortDirection: queryParams.sortDirection,
        lang: selectedLanguage
      }
    }).then(async (response) => {
      if (response.status === 200) {
        this.setState({ isProgress: true })
      }
      await this.findCasualSummaryWithDepotUrl(response.data.list);
    }).catch((error) => {
      console.log(error);
    });
  }

  async findCasualSummaryWithDepotUrl(casualSummaryList: any[]) {
    await axios.post("/api/naomi/flow/contents-flow/query/search-result/find-depot-url-by-cid/casual", {
      "list": casualSummaryList
    })
      .then((response) => {
        if (response.data.queryResult.length === 0) {
          this.setState({ isEmptyResult: true });
        } else {
          this.setState({ findedCasualSummary: response.data.queryResult });
        }
      }).catch((error) => {
        console.log(error);
      });
  }

  async findVideoSummary() {
    const { inputSearchValue, selectedPage, queryParams } = this.state;
    await axios.get("/api/keyword/elastic/video-summary/find-video-summary-list", {
      params: {
        keyword: inputSearchValue,
        sortDirection: queryParams.sortDirection,
        currentPage: selectedPage
      }
    }).then((response) => {
      if (response.status === 200) {
        this.setState({ isProgress: true })
      }
      let isEmpty = false;

      if (response.data.list.length === 0) {
        isEmpty = true;
      }

      this.setState({ findedVideoSummary: response.data.list, isEmptyResult: isEmpty });
    }).catch((error) => {
      console.log(error);
    });
  }

  async findCourseSummary() {
    const { inputSearchValue, selectedLanguage, selectedPage, queryParams } = this.state;
    await axios.get("/api/keyword/elastic/course/find-course-summary-list", {
      params: {
        keyword: inputSearchValue,
        currentPage: selectedPage,
        sortDirection: queryParams.sortDirection,
        lang: selectedLanguage
      }
    })
      .then(async (response) => {
        if (response.status === 200) {
          this.setState({ isProgress: true })
        }
        await this.findCourseSummaryWithDepotUrl(response.data.list)
      }).catch((error) => {
        console.log(error);
      });
  }

  async findCourseSummaryWithDepotUrl(courseSummaryList: any[]) {

    await axios.post("/api/naomi/flow/contents-flow/query/search-result/find-depot-url-by-cid/course", {
      "list": courseSummaryList
    })
      .then((response) => {
        if (response.data.queryResult.length === 0) {
          this.setState({ isEmptyResult: true });
        } else {
          this.setState({ findedCourseSummary: response.data.queryResult });
        }
      }).catch((error) => {
        console.log(error);
      });
  }

  async findFileSummary() {
    const { inputSearchValue, selectedPage, queryParams } = this.state;

    await axios.get("/api/keyword/elastic/file-summary/find-file-summary-list", {
      params: {
        keyword: inputSearchValue,
        sortDirection: queryParams.sortDirection,
        currentPage: selectedPage
      }
    }).then((response) => {
      if (response.status === 200) {
        this.setState({ isProgress: true })
      }
      if (response.data.list.length === 0) {
        this.setState({ isEmptyResult: true });
      } else {
        this.setState({ findedFileSummary: response.data.list });
      }
    }).catch((error) => {
      console.log(error);
    });
  }

  async findThemeSummary() {
    const { inputSearchValue, selectedPage, queryParams } = this.state;

    await axios.get("/api/keyword/elastic/marketing-summary/find-marketing-summary", {
      params: {
        keyword: inputSearchValue,
        sortDirection: queryParams.sortDirection,
        currentPage: selectedPage
      }
    }).then((response) => {
      if (response.status === 200) {
        this.setState({ isProgress: true })
      }
      if (response.data.list.length === 0) {
        this.setState({ isEmptyResult: true });
      } else {
        this.setState({ findedThemeSummary: response.data.list, themeTotalCount: response.data.totalCount });
      }
    }).catch((error) => {
      console.log(error);
    });
  }

  // 검색내역 등록
  async registerSearchHistory() {
    const { searchTotalCount, inputSearchValue } = this.state;
    const url = "/api/keyword/search-summary/register-search-history";

    const actorId = localStorage.getItem("nara.actorIdentity") as any;
    const { name: writerName } = (actorId && JSON.parse(actorId)) || {};

    const actor = localStorage.getItem("nara.actor") as any;
    const { name: uuid } = (actor && JSON.parse(actor)) || {};

    const cineroom = localStorage.getItem("nara.cineroom") as any;
    const { id, name } = (cineroom && JSON.parse(cineroom)) || {};

    const reqBody = {
      tourismCount: searchTotalCount.tourismCount,
      imageCount: searchTotalCount.imageCount,
      videoCount: searchTotalCount.videoCount,
      fileCount: searchTotalCount.fileCount,
      keyword: inputSearchValue,
      writerName: writerName,
      uuid: uuid,
      cineroomName: name,
      cineroomId: id,
      org: id,
    };

    await axios.post(url, reqBody);
  };

  async getThemeDetail(category_id: string) {
    try {
      const response = await axios.post('/api/data/flow/4592b5e8-9db0-4719-ada3-ffe812093317', {
        "responseName": "response",
        "categoryId": category_id,
      }).then((res) => {
        this.setState({ themeDetailObject: res.data.queryResult });
      });

    } catch (e) {
      console.error(e);
    }
  }

  async getThemeDetailList(category_id: string) {

    try {
      await axios.post('/api/data/flow/c736c5e9-8dd9-4e1f-a9a6-542f29e02017', {
        "responseName": "response",
        "categoryId": category_id,
      },
        {
        }).then((res) => {
          this.setState({ themeDetailList: res.data.queryResult });
        });
    } catch (e) {
      console.error(e);
    }
  }

  handleSearchInputValue = (event: any) => {
    this.setState({ inputSearchValueTemp: event.target.value });
  }

  initQueryString() {
    let queryString = window.location.search;

    if (queryString) {
      queryString = queryString.substring(1);

      let queryParams: {
        keyword: string;
        lang: string;
        marketingType: string;
        sortDirection: string;
      } = {
        keyword: '',
        lang: '',
        marketingType: '',
        sortDirection: SortDirection.Relevance,
      };

      let searchTab: TabValue = TabValue.TOURISM;
      queryString.split("&").forEach(function (item) {
        let keyValue: string[] = item.split("=");
        let key: string = decodeURIComponent(keyValue[0]);
        let value: string = decodeURIComponent(keyValue[1]);
        if (key !== 'searchTab') {
          // @ts-ignore
          queryParams[key] = value;
        } else {
          switch (value) {
            case 'TOURISM':
              searchTab = TabValue.TOURISM;
              break;
            case 'CASUAL':
              searchTab = TabValue.CASUAL;
              break;
            case 'IMAGE':
              searchTab = TabValue.IMAGE;
              break;
            case 'VIDEO':
              searchTab = TabValue.VIDEO;
              break;
            case 'THEME':
              searchTab = TabValue.THEME;
              break;
            case 'COURSE':
              searchTab = TabValue.COURSE;
              break;
          }
        }
      });

      this.setState({
        queryParams: queryParams,
        inputSearchValue: queryParams.keyword,
        inputSearchValueTemp: queryParams.keyword,
        selectedLanguage: queryParams.lang,
        selectedMarketingType: queryParams.marketingType,
        selectedTab: searchTab,
      }, () => {
        this.onClickSearch();
      });
    }
  }

  onClickTab(tabName: string) {
    this.formalListRef.current!.style.display = "none";
    //totalCount 초기화
    this.setState({ isProgress : false, inputSearchValueTemp: this.state.inputSearchValue});
    this.setState({ totalCount: 0, selectedPage: 1 });

    // 2024.05.23 탭 클릭시 관련도순으로 초기화 되도록 설정
    this.setState({isFormalList: false});
    this.setState(prevState => ({
      queryParams: {
        ...prevState.queryParams,
        sortDirection: SortDirection.Relevance
      },
    }));
    this.setState({ selectedTab: tabName, selectedCid: '', isEmptyResult: false, moreInfoTitle: '' }, () => {
      this.findSearchSummary();

    });
  }

  async contentsClickMoreView(title: string, marketingType: string) {
    this.setState({ moreInfoTitle: title });
    this.setState({isFormalList: true});
    await this.setState({ moreInfoType: marketingType });
    await this.findFormalSummaryList()
  }

  onClickSearch() {
    const { inputSearchValueTemp } = this.state;
    if (inputSearchValueTemp === '') {
      this.inputRef.current?.blur();
      this.setState({ isEmptyKeyword: true });
      return;
    }
    this.saveRecentSearchKeyword(inputSearchValueTemp);
    this.setState({ isProgress: false, inputSearchValue: inputSearchValueTemp });
    this.setState({ isEmptyResult: false, selectedPage: 1 }, () => {
      this.findSearchSummary();
    });
  }

  async findSearchSummary() {
    const { selectedTab, isFormalList } = this.state;
    this.searchBody.current?.style.setProperty('display', 'none');
    await this.findSearchSummaryTotalCount();

    if (isFormalList && selectedTab === TabValue.TOURISM) {
      await this.findFormalSummaryList();
    } else {
      switch (selectedTab) {
        case TabValue.TOURISM:
          await this.findFormalSummary();
          break;
        case TabValue.CASUAL:
          await this.findCasualSummary();
          break;
        case TabValue.IMAGE:
          await this.findImageSummary();
          break;
        case TabValue.VIDEO:
          await this.findVideoSummary();
          break;
        case TabValue.THEME:
          await this.findThemeSummary();
          break;
        case TabValue.COURSE:
          await this.findCourseSummary();
          break;
        case TabValue.FILE:
          await this.findFileSummary();
          break;
      }
    }

    this.updateUrlForQueryParams();
  }

  updateUrlForQueryParams() {
    const { inputSearchValue, selectedLanguage, selectedMarketingType, queryParams } = this.state;
    const newTitle = "한국관광콘텐츠랩";
    let searchUrl = "/rms-conlab/search?";

    searchUrl += "keyword=" + encodeURIComponent(inputSearchValue);

    if (selectedLanguage) {
      searchUrl += "&lang=" + encodeURIComponent(selectedLanguage);
    }

    if (selectedMarketingType) {
      searchUrl += "&marketingType=" + encodeURIComponent(selectedMarketingType);
    }

    searchUrl += "&sortDirection=" + encodeURIComponent(queryParams.sortDirection);

    window.history.pushState({ path: searchUrl }, newTitle, searchUrl);
  }

  clearInputValue() {
    this.setState({ inputSearchValueTemp: '' });
    this.inputRef.current?.focus();
  }

  refreshAll() {
    this.setState({
      inputSearchValueTemp: '',
      selectedLanguage: LangType.ko,
      selectedMarketingType: MarketingType.ALL
    });
    this.inputRef.current?.focus();
  }

  setLanguageValue(value: string) {
    this.setState({ selectedLanguage: value });
  }

  setMarketingValue(value: string) {
    this.setState({ selectedMarketingType: value });
  }

  isNosearchResult() {
    const { selectedTab, findedImageSummary, findedFormalSummary, findedVideoSummary, findedCasualSummary, findedThemeSummary, findedFileSummary } = this.state;

    switch (selectedTab) {
      case TabValue.TOURISM:
        const foramlKeys = Object.keys(findedFormalSummary);
        foramlKeys.map((key: any, index: number) => {
          // @ts-ignore
          if (findedFormalSummary[key].list?.length > 0) {
            this.setState({ isEmptyResult: false });
          }
        });
        break;
      case TabValue.IMAGE:
        if (findedImageSummary.length > 0) {
          this.setState({ isEmptyResult: false });
        }
        break;
      case TabValue.CASUAL:
        if (findedCasualSummary.length > 0) {
          this.setState({ isEmptyResult: false });
        }
        break;
      case TabValue.FILE:
        if (findedFileSummary.length > 0) {
          this.setState({ isEmptyResult: false });
        }
        break;
      case TabValue.THEME:
        if (findedThemeSummary.length > 0) {
          this.setState({ isEmptyResult: false });
        }
        break;
      case TabValue.COURSE:
        if (findedThemeSummary.length > 0) {
          this.setState({ isEmptyResult: false });
        }
        break;
      case TabValue.VIDEO:
        if (findedVideoSummary.length > 0) {
          this.setState({ isEmptyResult: false });
        }
        break;
    }
  }

  renderFileList() {
    const { findedFileSummary } = this.state;
    const fileSummary = findedFileSummary.map((obj, index) => {
      return (
        <Card key={obj.cid} cid={obj.cid} findedFileSummary={findedFileSummary} setSelectedCid={this.setSelectedCid} />
      )
    });

    return (
      <>
        <ul className="oas_img_list" style={{ paddingTop: 'unset' }}>
          {fileSummary}
        </ul>
        {/* {this.themePagination()} */}
      </>
    );

  }

  renderThemeList() {
    const { findedThemeSummary, searchTotalCount, selectedPage, queryParams } = this.state;
    const totalPageCount = Math.ceil(searchTotalCount.marketingCount / 20)

    const sortDirection = queryParams.sortDirection;

    const themeSummary = findedThemeSummary.map((obj, index) => {

      console.log(obj.imagePath, 'Theme')

      return (
        <li key={obj.id}>
          <Card text={obj.contentsName} imagePath={obj.imagePath} findedThemeSummary={findedThemeSummary} categoryId={obj.id} setSelectedCategoryId={this.setSelectedCategoryId} onClickThemeModal={this.onClickThemeModal} />
        </li>
      )
    });

    return (
      <>
        <div>
          <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end'}}>
            <a className="st_icon"
                    style={{marginLeft: '20px', cursor: 'pointer', fontWeight: `${sortDirection === 'Relevance' ? 'bold' : ''}`}}
                    onClick={() => this.setSortDirection(SortDirection.Relevance)}>▼ 관련도순
            </a>
            <a className="st_icon"
                    style={{marginLeft: '20px', cursor: 'pointer', fontWeight: `${sortDirection === 'Latest' ? 'bold' : ''}`}}
                    onClick={() => this.setSortDirection(SortDirection.Latest)}>▼ 최신순
            </a>
          </div>
        </div>
        <ul className="oas_img_list">
          {themeSummary}
        </ul>
        <div className="paging">
          <Pagination
              color="primary"
              count={totalPageCount}
              page={selectedPage}
              onChange={(event: any, value: any) => (this.themeCurrentPage(event, value))}
              style={{position: 'absolute', bottom: '0px'}}
          />
        </div>
        <div className="m_board">
          {this.themePagination()}
        </div>
      </>
    );
  }

  renderSearchImageList() {
    const {findedImageSummary, searchTotalCount, selectedPage, queryParams} = this.state;
    const totalPageCount = Math.ceil(searchTotalCount.imageCount / 20);
    const sortDirection = queryParams.sortDirection;

    const imageSummary = findedImageSummary.map((obj, index) => {

      let imageSrc = ''

      if (obj.imagePath) {
        const originUrl = obj.imagePath;
        //it6 -> it7(치환)
        const newUrl = originUrl.replace("it6", "it7");
        console.log(newUrl, 'newUrl');
        imageSrc = newUrl;
      }

      return (
        <li key={obj.cid}>
          <Card text={obj.contentsName} imagePath={imageSrc} findedImageSummary={findedImageSummary} cid={obj.cid} setSelectedCid={this.setSelectedCid} onClickDetailModal={this.onClickDetailModal} />
        </li>
      )
    });

    return (
        <>
          <div>
            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end'}}>
              <a className="st_icon"
                      style={{marginLeft: '20px', cursor: 'pointer', fontWeight: `${sortDirection === 'Relevance' ? 'bold' : ''}`}}
                      onClick={() => this.setSortDirection(SortDirection.Relevance)}>▼ 관련도순
              </a>
              <a className="st_icon"
                      style={{marginLeft: '20px', cursor: 'pointer', fontWeight: `${sortDirection === 'Latest' ? 'bold' : ''}`}}
                      onClick={() => this.setSortDirection(SortDirection.Latest)}>▼ 최신순
              </a>
            </div>
          </div>
          <ul className="oas_img_list">
            {imageSummary}
          </ul>
          <div className="paging">
            <Pagination
                color="primary"
                count={totalPageCount}
                page={selectedPage}
                onChange={(event: any, value: any) => (this.imageCurrentPage(event, value))}
                style={{position: 'absolute', bottom: '0px'}}
            />
          </div>
          <div className="m_board">
            {this.imagePagination()}
          </div>
        </>
    );
  }

  renderSearchVideoList() {
    const {findedVideoSummary, searchTotalCount, selectedPage, queryParams} = this.state;
    const totalPageCount = Math.ceil(searchTotalCount.videoCount / 10);
    const sortDirection = queryParams.sortDirection;

    const videoSummary = findedVideoSummary.map((obj, index) => {
      return (
          <li key={obj.cid}>
            <Card text={obj.contentsName} imagePath={`${obj.thumbnailPath}/it7`} findedVideoSummary={findedVideoSummary}
                  cid={obj.cid} setSelectedCid={this.setSelectedCid} onClickDetailModal={this.onClickDetailModal}
                  />
          </li>
      )
    });

    return (
        <>
          <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end'}}>
            <a className="st_icon" style={{marginLeft: '20px', cursor: 'pointer', fontWeight: `${sortDirection === 'Relevance' ? 'bold' : ''}` }}
                    onClick={() => this.setSortDirection(SortDirection.Relevance)}>▼ 관련도순
            </a>
            <a className="st_icon" style={{marginLeft: '20px', cursor: 'pointer', fontWeight: `${sortDirection === 'Latest' ? 'bold' : ''}`}}
                    onClick={() => this.setSortDirection(SortDirection.Latest)}>▼ 최신순
            </a>
          </div>
          <ul className="oas_img_list oas_video_list">
            {videoSummary}
          </ul>
          <div className="paging">
            <Pagination
                color="primary"
                count={totalPageCount}
                page={selectedPage}
                onChange={(event: any, value: any) => (this.videoCurrentPage(event, value))}
                style={{position: 'absolute', bottom: '0px'}}
            />
          </div>
          <div className="m_board">
            {this.videoPagination()}
          </div>
        </>

    );
  }

  renderSearchCasualList() {
    const {findedCasualSummary, searchTotalCount, selectedPage, queryParams} = this.state;
    const totalPageCount = Math.ceil(searchTotalCount.casualCount / 10);
    const sortDirection = queryParams.sortDirection;

    return (
        <>
          <InfoCard type="type3" casualInfoObject={findedCasualSummary} title="여행기사"
                    setSelectedCid={this.setSelectedCid} onClickDetailModal={this.onClickDetailModal}
                    contentsClickMoreView={this.contentsClickMoreView} setSortDirection={this.setSortDirection} selectedSortDirection={sortDirection}/>
          <div className="paging">
            <Pagination
                color="primary"
                count={totalPageCount}
                page={selectedPage}
            onChange={(event: any, value: any) => (this.casualCurrentPage(event, value))}
            style={{ position: 'absolute', bottom: '0px' }}
          />
        </div>
        <div className="m_board">
          {this.casualPagination()}
        </div>
      </>
    );
  }

  renderSearchCourseList() {
    const { findedCourseSummary, searchTotalCount, selectedPage, queryParams } = this.state;
    const totalPageCount = Math.ceil(searchTotalCount.courseCount / 12);
    const sortDirection = queryParams.sortDirection;

    return (
      <>
        <InfoCard courseInfoObject={findedCourseSummary} setSelectedCid={this.setSelectedCid} onClickDetailModal={this.onClickDetailModal} contentsClickMoreView={this.contentsClickMoreView} setSortDirection={this.setSortDirection} selectedSortDirection={sortDirection}/>
        <div className="paging">
          <Pagination
            color="primary"
            count={totalPageCount}
            page={selectedPage}
            onChange={(event: any, value: any) => (this.courseCurrentPage(event, value))}
            style={{ position: 'absolute', bottom: '0px' }}
          />
        </div>
        <div className="m_board">
          {this.coursePagination()}
        </div>
      </>
    );
  }

  renderResultNotFound() {
    return (
      <div className="no_data_wrap">
        <div className="no_data">
          <img
            src="/rms-conlab/images/no_data.png"
            alt="검색 결과가 없습니다."
          />
          <p className="no_data_txt">검색 결과가 없습니다.</p>
        </div>
      </div>
    );
  }

  formalCurrentPage(event: React.ChangeEvent<unknown>, value: number) {
    this.setState({ selectedPage: value }, () => {
      this.findFormalSummaryList()
    });
  }

  formalPagination() {
    const { searchTotalCount, selectedPage } = this.state
    const totalPageCount = Math.ceil(searchTotalCount.tourismCount / 10);

    return (
      <div className="st_line4 more_board_bt">
        <Pagination
          color="primary"
          count={totalPageCount}
          page={selectedPage}
          onChange={(event: any, value: any) => (this.formalCurrentPage(event, value))}
          style={{ position: 'absolute', bottom: '0px' }}
        />
      </div>
    );
  }

  casualCurrentPage(event: React.ChangeEvent<unknown>, value: number) {
    this.setState({ selectedPage: value }, () => {
      this.findCasualSummary()
    });
  }

  casualPagination() {
    const { searchTotalCount, selectedPage } = this.state
    const totalPageCount = Math.ceil(searchTotalCount.casualCount / 10);

    return (
      <div className="st_line4 more_board_bt">
        <Pagination
          color="primary"
          count={totalPageCount}
          page={selectedPage}
          onChange={(event: any, value: any) => (this.casualCurrentPage(event, value))}
          // style={selectedPage === 1 ? {display: 'none'} : {position: 'absolute', bottom: '-50px', paddingBottom: '15px'}}
          style={{ position: 'absolute', bottom: '0px' }}
        />
      </div>
    );
  }

  imageCurrentPage(event: React.ChangeEvent<unknown>, value: number) {
    this.setState({ selectedPage: value }, () => {
      this.findImageSummary()
    });
  }

  imagePagination() {
    const { searchTotalCount, selectedPage } = this.state
    const totalPageCount = Math.ceil(searchTotalCount.imageCount / 30);

    return (
      <div className="st_line4 more_board_bt">
        <Pagination
          color="primary"
          count={totalPageCount}
          page={selectedPage}
          onChange={(event: any, value: any) => (this.imageCurrentPage(event, value))}
          // style={selectedPage === 1 ? {display: 'none'} : {position: 'absolute', bottom: '-50px', paddingBottom: '15px'}}
          style={{ position: 'absolute', bottom: '0px' }}
        />
      </div>
    );
  }

  videoCurrentPage(event: React.ChangeEvent<unknown>, value: number) {
    this.setState({ selectedPage: value }, () => {
      this.findVideoSummary()
    });
  }

  videoPagination() {
    const { searchTotalCount, selectedPage } = this.state
    const totalPageCount = Math.ceil(searchTotalCount.videoCount / 10);

    return (
      <div className="st_line4 more_board_bt">
        <Pagination
          color="primary"
          count={totalPageCount}
          page={selectedPage}
          onChange={(event: any, value: any) => (this.videoCurrentPage(event, value))}
          // style={selectedPage === 1 ? {display: 'none'} : {position: 'absolute', bottom: '-50px', paddingBottom: '15px'}}
          style={{ position: 'absolute', bottom: '0px' }}
        />
      </div>
    );
  }

  themeCurrentPage(event: React.ChangeEvent<unknown>, value: number) {
    this.setState({ selectedPage: value }, () => {
      this.findThemeSummary()
    });
  }

  themePagination() {
    const { searchTotalCount, selectedPage } = this.state
    const totalPageCount = Math.ceil(searchTotalCount.marketingCount / 10);

    return (
      <div className="st_line4 more_board_bt">
        <Pagination
          color="primary"
          count={totalPageCount}
          page={selectedPage}
          onChange={(event: any, value: any) => (this.themeCurrentPage(event, value))}
          // style={selectedPage === 1 ? {display: 'none'} : {position: 'absolute', bottom: '-50px', paddingBottom: '15px'}}
          style={{ position: 'absolute', bottom: '0px' }}
        />
      </div>
    );
  }

  courseCurrentPage(event: React.ChangeEvent<unknown>, value: number) {
    this.setState({ selectedPage: value }, () => {
      this.findCourseSummary()
    });
  }

  coursePagination() {
    const { searchTotalCount, selectedPage } = this.state
    const totalPageCount = Math.ceil(searchTotalCount.courseCount / 12);

    return (
      <div className="st_line4 more_board_bt">
        <Pagination
          color="primary"
          count={totalPageCount}
          page={selectedPage}
          onChange={(event: any, value: any) => (this.courseCurrentPage(event, value))}
          // style={selectedPage === 1 ? {display: 'none'} : {position: 'absolute', bottom: '-50px', paddingBottom: '15px'}}
          style={{ position: 'absolute', bottom: '0px' }}
        />
      </div>
    );
  }

  renderInfoCards() {
    const { findedFormalSummary, moreInfoTitle, queryParams } = this.state;
    const isArray = Array.isArray(findedFormalSummary)
    const { totalCount, selectedPage } = this.state
    const totalPageCount = Math.ceil(totalCount / 10);
    const sortDirection = queryParams.sortDirection;

    let infoCards;

    if (isArray) {
      infoCards = (
        // @ts-ignore
        <>
          <InfoCard type={"type1"} infoObject={findedFormalSummary} title={moreInfoTitle}
                    contentsClickMoreView={this.contentsClickMoreView} onClickDetailModal={this.onClickDetailModal}
                    setSelectedCid={this.setSelectedCid} moreInfoTitle={moreInfoTitle} setSortDirection={this.setSortDirection} selectedSortDirection={sortDirection}/>
          <div className="paging">
            <Pagination
                color="primary"
                count={totalPageCount}
                page={selectedPage}
                onChange={(event: any, value: any) => (this.formalCurrentPage(event, value))}
                style={{position: 'absolute', bottom: '0px'}}
            />
          </div>

          <div className="m_board">
            {this.formalPagination()}
          </div>
        </>
      );

    } else {
      const foramlKeys = Object.keys(findedFormalSummary);
      infoCards = foramlKeys.map((key: string, index: number) => {
        let cardType = "type1";
        let infoTitle = "";
        let totalCount = 0;

        if (key === "accommodation") {
          infoTitle = "숙박";
          totalCount = findedFormalSummary.accommodation.totalCount
        } else if (key === "culture") {
          infoTitle = "문화관광";
          totalCount = findedFormalSummary.culture.totalCount
        } else if (key === "experience") {
          infoTitle = "체험관광";
          totalCount = findedFormalSummary.experience.totalCount
        } else if (key === "festival") {
          infoTitle = "축제・공연・행사";
          totalCount = findedFormalSummary.festival.totalCount
        } else if (key === "food") {
          infoTitle = "음식";
          totalCount = findedFormalSummary.food.totalCount
        } else if (key === "history") {
          infoTitle = "역사관광";
          totalCount = findedFormalSummary.history.totalCount
        } else if (key === "leisure") {
          infoTitle = "레저스포츠";
          totalCount = findedFormalSummary.leisure.totalCount
        } else if (key === "nature") {
          infoTitle = "자연관광";
          totalCount = findedFormalSummary.nature.totalCount
        } else if (key === "shopping") {
          infoTitle = "쇼핑";
          totalCount = findedFormalSummary.shopping.totalCount
        }

        if (key === "food" || key === "accommodation" || key === "shopping") {
          cardType = "type2";
        }

        return (
          // @ts-ignore
          <InfoCard key={index} type={cardType} infoObject={findedFormalSummary[key].list} title={infoTitle} totalCount={totalCount} onClickDetailModal={this.onClickDetailModal} contentsClickMoreView={this.contentsClickMoreView} setSelectedCid={this.setSelectedCid} />
        );
      });
    }

    return (
      <>
        {infoCards}
      </>
    );
  }

  setSelectedCid(cid: string) {
    this.setState({ selectedCid: cid });
    // this.contentsList.current?.style.setProperty('display','none');
    // this.contentsDetail.current?.style.setProperty('display','block');
  }

  setSortDirection(sortDirection: string) {
    this.setState({selectedPage: 1});
    this.setState(prevState => ({
      queryParams: {
        ...prevState.queryParams,
        sortDirection: sortDirection
      },
    }), this.findSearchSummary);
  }

  async setSelectedCategoryId(categoryId: string) {
    this.setState({ selectedCategoryId: categoryId });
    await this.getThemeDetail(categoryId)
    await this.getThemeDetailList(categoryId)
    // this.contentsList.current?.style.setProperty('display','none');
    // this.contentsDetail.current?.style.setProperty('display','block');
  }

  // showList() {
  //   this.contentsList.current?.style.setProperty('display','block');
  //   this.contentsDetail.current?.style.setProperty('display','none');
  // }

  // tapShowList(){
  //   this.buttonRef.current?.style.setProperty('display','none');
  // }

  // tapShowList(){
  //   this.contentsList.current?.style.setProperty('display','block');
  //   this.contentsDetail.current?.style.setProperty('display','none');
  // }

  searchPopularKeyword(keyword: string) {
    this.setState({ inputSearchValueTemp: keyword }, () => {
      this.onClickSearch();
    });
  }

  saveRecentSearchKeyword(keyword: string) {
    const { recentSearchKeywords } = this.state;
    // --------
    let index = recentSearchKeywords.indexOf(keyword);

    if (index !== -1) {
      recentSearchKeywords.splice(index, 1);
      recentSearchKeywords.unshift(keyword);
    } else {
      recentSearchKeywords.unshift(keyword);
      recentSearchKeywords.pop();
    }
    // -------
    this.setState({ recentSearchKeywords: recentSearchKeywords });

    const arrString = JSON.stringify(recentSearchKeywords);
    window.localStorage.setItem(this.localStorageForKeyword, arrString);
  }

  getRecentSearchKeywords() {
    // @ts-ignore
    const recentSearchKeywords: [string, string, string, string, string] = JSON.parse(window.localStorage.getItem(this.localStorageForKeyword));
    if (recentSearchKeywords) {
      this.setState({ recentSearchKeywords: recentSearchKeywords });
    }
  }

  deleteRecentSearchKeyword(keyword: string) {
    const { recentSearchKeywords } = this.state;
    const index = recentSearchKeywords.indexOf(keyword);
    if (index !== -1) {
      recentSearchKeywords.splice(index, 1);
      recentSearchKeywords.push("");
      const arrString = JSON.stringify(recentSearchKeywords);
      window.localStorage.setItem(this.localStorageForKeyword, arrString);
      this.setState({ recentSearchKeywords: recentSearchKeywords });
    }
  }

  changeViewWidth() {
    this.setState({ viewWidth: this.modalRef.current?.offsetWidth });
  }

  setSelected() {
    this.setState({ isSelected: !this.state.isSelected });
  }

  closeModal() {
    this.inputRef.current?.focus();
    this.setState({ isEmptyKeyword: false });
  }

  upDateProps() {
    this.setState({ upDateImageSummaryDate: [] })
    this.setState({ upDateCourseSummaryDate: [] })
    this.setState({ upDateCasualSummaryDate: [] })
    this.setState({ upDateVideoSummaryDate: [] })
    this.setState({ upDateFileSummaryDate: [] })
    this.setState({
      upDateFormalSummaryDate: {
        nature: {
          list: [],
          score: 0,
          totalCount: 0
        },
        history: {
          list: [],
          score: 0,
          totalCount: 0
        },
        experience: {
          list: [],
          score: 0,
          totalCount: 0
        },
        culture: {
          list: [],
          score: 0,
          totalCount: 0
        },
        festival: {
          list: [],
          score: 0,
          totalCount: 0
        },
        food: {
          list: [],
          score: 0,
          totalCount: 0
        },
        accommodation: {
          list: [],
          score: 0,
          totalCount: 0
        },
        shopping: {
          list: [],
          score: 0,
          totalCount: 0
        },
        leisure: {
          list: [],
          score: 0,
          totalCount: 0
        }
      }
    })
  }

  onClickCloseModal() {
    this.setState({ openDetailModal: undefined });
    document.body.style.cssText = "overflow: scroll";
  }

  onClickDetailModal() {
    this.setState({ openDetailModal: "detail" });
    document.body.style.cssText = "overflow: hidden";
  }

  // 통합검색
  onClickThemeCloseModal() {
    this.setState({ openThemeModal: undefined });
    document.body.style.cssText = "overflow: scroll";
  }

  onClickThemeModal(modalType: string, category_id: string) {
    this.setState({
      openThemeModal: "detail",
      modalType: modalType,
      categoryId: category_id,
    });
    document.body.style.cssText = "overflow: hidden";
    switch (modalType) {
      case "theme":
        this.getThemeDetail(category_id);
        this.getThemeDetailList(category_id);
        break;
    }
  }

  renderModalView() {
    const { selectedCid, selectedTab, selectedCategoryId, upDateImageSummaryDate, upDateFormalSummaryDate, upDateCourseSummaryDate, upDateCasualSummaryDate, upDateVideoSummaryDate, upDateFileSummaryDate } = this.state
    return (
      <SearchModalView
        onCloseModal={this.onClickCloseModal}
        onClickThemeCloseModal={this.onClickThemeCloseModal}
        selectedCid={selectedCid}
        selectedTab={selectedTab}
        selectedCategoryId={selectedCategoryId}
        upDateImageSummaryDate={upDateImageSummaryDate}
        upDateFormalSummaryDate={upDateFormalSummaryDate}
        upDateCourseSummaryDate={upDateCourseSummaryDate}
        upDateCasualSummaryDate={upDateCasualSummaryDate}
        upDateVideoSummaryDate={upDateVideoSummaryDate}
        upDateFileSummaryDate={upDateFileSummaryDate}
        selectedLanguage={this.state.selectedLanguage}
      />
    )
  }


  renderThemeModal() {
    const { modalType, themeDetailObject, recommendThemeObject, themeDetailList, imageDetailBase, categoryPath, categoryAddress, categoryId } = this.state
    return (
      <ModalView
        onCloseModal={this.onClickCloseModal}
        onClickThemeCloseModal={this.onClickThemeCloseModal}
        modalType={modalType}
        themeDetailObject={themeDetailObject}
        recommendThemeObject={recommendThemeObject}
        themeDetailList={themeDetailList}
        imageDetailBase={imageDetailBase}
        categoryPath={categoryPath}
        categoryAddress={categoryAddress}
        categoryId={categoryId}
      />
    )
  }

  showSearchInput() {
    this.searchBody.current!.style.display = "block";
  }

  hideSearchInput() {
    this.searchBody.current!.style.display = "none";
  }

  render() {
    const { popularKeywords, totalCount, openThemeModal, openDetailModal, inputSearchValue, inputSearchValueTemp, searchTotalCount, selectedTab, selectedMarketingType, selectedLanguage, isEmptyResult, recentSearchKeywords, isEmptyKeyword } = this.state;

    return (
      <>
        <div id="contents" className="overall_search_page">
          <div className="center nav_wrap">
            <ul className="nav white">
              <li>
                <i className="icon home"></i>
              </li>
              <li>통합검색</li>
            </ul>
          </div>
          <section className="overall_search_area">
            <div className="center tab_menu_wrap">
              <div className="osa_left">
                <h1 className="osa_tit">통합검색</h1>
                <ul className="tab_menu">
                  <li className={`tab_bt ${selectedTab === TabValue.TOURISM && 'on'}`} onClick={() => { this.onClickTab(TabValue.TOURISM); }} style={{ cursor: 'pointer' }}>
                    <a>
                      <span className="line"></span>관광정보 ({searchTotalCount.tourismCount.toLocaleString('ko-KR')})
                    </a>
                  </li>
                  <li className={`tab_bt ${selectedTab === TabValue.CASUAL && 'on'}`} onClick={() => { this.onClickTab(TabValue.CASUAL); }} style={{ cursor: 'pointer' }}>
                    <a>
                      <span className="line"></span>여행기사 ({searchTotalCount.casualCount.toLocaleString('ko-KR')})
                    </a>
                  </li>
                  <li className={`tab_bt ${selectedTab === TabValue.IMAGE && 'on'}`} onClick={() => { this.onClickTab(TabValue.IMAGE) }} style={{ cursor: 'pointer' }}>
                    <a>
                      <span className="line"></span>이미지 ({searchTotalCount.imageCount.toLocaleString('ko-KR')})
                    </a>
                  </li>
                  <li className={`tab_bt ${selectedTab === TabValue.VIDEO && 'on'}`} onClick={() => { this.onClickTab(TabValue.VIDEO) }} style={{ cursor: 'pointer' }}>
                    <a>
                      <span className="line"></span>동영상 ({searchTotalCount.videoCount.toLocaleString('ko-KR')})
                    </a>
                  </li>
                  <li className={`tab_bt ${selectedTab === TabValue.COURSE && 'on'}`} onClick={() => { this.onClickTab(TabValue.COURSE) }} style={{ cursor: 'pointer' }}>
                    <a>
                      <span className="line"></span>여행코스 ({searchTotalCount.courseCount.toLocaleString('ko-KR')})
                    </a>
                  </li>
                  <li className={`tab_bt ${selectedTab === TabValue.THEME && 'on'}`} onClick={() => { this.onClickTab(TabValue.THEME) }} style={{ cursor: 'pointer' }}>
                    <a>
                      <span className="line"></span>테마 ({searchTotalCount.marketingCount})
                    </a>
                  </li>
                  {/* <li className={`tab_bt ${selectedTab === TabValue.FILE && 'on'}`} onClick={() => {this.onClickTab(TabValue.FILE); this.tapShowList()}} style={{cursor: 'pointer'}}>
                    <a>
                      <span className="line"></span>파일 ({searchTotalCount.fileCount.toLocaleString('ko-KR')})
                    </a>
                  </li> */}
                </ul>
              </div>

              <div className="osa_right">
                <div className="overall_search_box_cover">
                  <div className="overall_search_box_wrap">
                    {/* <label htmlFor="overall_search_input" onFocus={() => this.showSearchInput()} onBlur={() => this.hideSearchInput()}> */}
                    <label htmlFor="overall_search_input" onFocus={() => this.showSearchInput()}>
                      {/* 검색창 인풋 */}
                      <input ref={this.inputRef} type="text" id="overall_search_input" value={inputSearchValueTemp} onChange={this.handleSearchInputValue}
                        onKeyUp={(event) => {
                          if (event.key === "Enter") {
                            this.onClickSearch();
                          }
                        }} />
                      <button type="button" className="clear_bt" onClick={() => this.clearInputValue()}>
                        <img src="/rms-conlab/images/icon_os_clear.png" alt="icon" />
                      </button>
                    </label>
                    <button className="refresh_bt" onClick={() => this.refreshAll()}>
                      <img
                        src="/rms-conlab/images/icon_os_refresh.png"
                        alt="icon_search"
                      />
                    </button>
                    <button className="search_bt st_point" onClick={() => this.onClickSearch()}>
                      <img
                        src="/rms-conlab/images/icon_os_search.png"
                        alt="icon_search"
                      />
                      검색
                    </button>
                  </div>
                  <div className="search_page_body" id="search_page_body" ref={this.searchBody}>
                    <div className="center">
                      <div className="word_wrap">
                        <div className="hot">
                          <h3>인기 검색어</h3>
                          <div className="list_box">
                            {popularKeywords?.map((obj: any, index: number) => {
                              return (
                                <button key={index} className="st_point"><strong style={{ fontSize: '16px' }} onClick={() => this.searchPopularKeyword(obj.keyword)}>{obj.keyword}</strong></button>
                              );
                            })}
                          </div>
                        </div>
                        <div className="latest">
                          <h3>최근 검색어</h3>
                          <div className="list_box">
                            {recentSearchKeywords.map((obj: string, index: number) => {
                              if (!obj) {
                                return;
                              }
                              return (
                                // TODO 퍼블리싱과 스타일 맞춰줄것
                                // <button className="st_line2" style={{fontSize: '16px'}} onClick={() => this.searchPopularKeyword(obj)}>
                                //   {obj}
                                //   <img src="./images/button_X.png" alt="button_X" style={{display: 'inline-flex', verticalAlign: 'baseline', padding: '0 0 0 8px'}}/>
                                // </button>
                                <Chip key={index} label={obj} variant="outlined" onDelete={() => this.deleteRecentSearchKeyword(obj)} onClick={() => this.searchPopularKeyword(obj)} />
                              );
                            })}
                          </div>
                        </div>
                      </div>
                      <div className="key_word">
                        <div className="type">
                          <h4>유형별</h4>
                          <div className="list">
                            <button className={`st_basic ${selectedMarketingType === MarketingType.ALL ? 'on' : undefined}`} style={{ fontSize: '16px' }} onClick={() => this.setMarketingValue(MarketingType.ALL)}>전체</button>
                            <button className={`st_basic ${selectedMarketingType === MarketingType.NA ? 'on' : undefined}`} style={{ fontSize: '16px' }} onClick={() => this.setMarketingValue(MarketingType.NA)}>자연관광</button>
                            <button className={`st_basic ${selectedMarketingType === MarketingType.HS ? 'on' : undefined}`} style={{ fontSize: '16px' }} onClick={() => this.setMarketingValue(MarketingType.HS)}>역사관광</button>
                            <button className={`st_basic ${selectedMarketingType === MarketingType.EX ? 'on' : undefined}`} style={{ fontSize: '16px' }} onClick={() => this.setMarketingValue(MarketingType.EX)}>체험관광</button>
                            <button className={`st_basic ${selectedMarketingType === MarketingType.VE ? 'on' : undefined}`} style={{ fontSize: '16px' }} onClick={() => this.setMarketingValue(MarketingType.VE)}>문화관광</button>
                            <button className={`st_basic ${selectedMarketingType === MarketingType.EV ? 'on' : undefined}`} style={{ fontSize: '16px' }} onClick={() => this.setMarketingValue(MarketingType.EV)}>축제•공연•행사</button>
                            <button className={`st_basic ${selectedMarketingType === MarketingType.FD ? 'on' : undefined}`} style={{ fontSize: '16px' }} onClick={() => this.setMarketingValue(MarketingType.FD)}>음식</button>
                            <button className={`st_basic ${selectedMarketingType === MarketingType.AC ? 'on' : undefined}`} style={{ fontSize: '16px' }} onClick={() => this.setMarketingValue(MarketingType.AC)}>숙박</button>
                            <button className={`st_basic ${selectedMarketingType === MarketingType.SH ? 'on' : undefined}`} style={{ fontSize: '16px' }} onClick={() => this.setMarketingValue(MarketingType.SH)}>쇼핑</button>
                            <button className={`st_basic ${selectedMarketingType === MarketingType.LS ? 'on' : undefined}`} style={{ fontSize: '16px' }} onClick={() => this.setMarketingValue(MarketingType.LS)}>레저스포츠</button>
                          </div>
                        </div>
                        <div className="language">
                          <h4>언어별</h4>
                          <div className="list">
                            <button className={`st_basic ${selectedLanguage === LangType.ALL ? 'on' : undefined}`} style={{ fontSize: '16px' }} onClick={() => this.setLanguageValue(LangType.ALL)}>전체</button>
                            <button className={`st_basic ${selectedLanguage === LangType.ko ? 'on' : undefined}`} style={{ fontSize: '16px' }} onClick={() => this.setLanguageValue(LangType.ko)}>한국어</button>
                            <button className={`st_basic ${selectedLanguage === LangType.en ? 'on' : undefined}`} style={{ fontSize: '16px' }} onClick={() => this.setLanguageValue(LangType.en)}>영어</button>
                            <button className={`st_basic ${selectedLanguage === LangType.ja ? 'on' : undefined}`} style={{ fontSize: '16px' }} onClick={() => this.setLanguageValue(LangType.ja)}>일본어</button>
                            <button className={`st_basic ${selectedLanguage === LangType.zh_CN ? 'on' : undefined}`} style={{ fontSize: '16px' }} onClick={() => this.setLanguageValue(LangType.zh_CN)}>중국어 간체</button>
                            <button className={`st_basic ${selectedLanguage === LangType.zh_TW ? 'on' : undefined}`} style={{ fontSize: '16px' }} onClick={() => this.setLanguageValue(LangType.zh_TW)}>중국어 번체</button>
                            <button className={`st_basic ${selectedLanguage === LangType.de ? 'on' : undefined}`} style={{ fontSize: '16px' }} onClick={() => this.setLanguageValue(LangType.de)}>독일어</button>
                            <button className={`st_basic ${selectedLanguage === LangType.ru ? 'on' : undefined}`} style={{ fontSize: '16px' }} onClick={() => this.setLanguageValue(LangType.ru)}>러시아어</button>
                            <button className={`st_basic ${selectedLanguage === LangType.fr ? 'on' : undefined}`} style={{ fontSize: '16px' }} onClick={() => this.setLanguageValue(LangType.fr)}>프랑스어</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="tab_contents">
                  {this.state.isProgress === true ? (
                    <div>
                      <div>

                        {openThemeModal && openThemeModal === "detail" && (
                          this.renderThemeModal()
                        )}

                        {openDetailModal && openDetailModal === "detail" && (
                          this.renderModalView()
                        )}
                        {/* {openDetailModal === true ? this.renderModalView() : null} */}
                      </div>
                      {/* <div ref={this.contentsList}> */}
                      <div>

                        <span style={{ fontSize: '15px' }}>
                          <a className='formal_list_bt' ref={this.formalListRef} onClick={() => { this.onClickTab(TabValue.TOURISM); }} style={totalCount > 4 ? { display: 'block', cursor: 'pointer' } : { display: 'none' }}>
                            <img src="/rms-conlab/images/icon_move_previous_s.png" alt="icon" style={{ position: 'relative', top: '-1px' }} />
                            목록으로
                          </a>
                        </span>

                        {/*<!-- 관광정보 -->*/}
                        {selectedTab === TabValue.TOURISM && (
                          <div className={`tab_con ${selectedTab === TabValue.TOURISM ? 'on' : undefined}`}>
                            {isEmptyResult ? this.renderResultNotFound() : this.renderInfoCards()}
                          </div>
                        )}
                        {/*<!-- 관광정보 끝 -->*/}

                        {/*<!-- 여행기사 -->*/}
                        {selectedTab === TabValue.CASUAL && (
                          <div className={`tab_con ${selectedTab === TabValue.CASUAL ? 'on' : undefined}`}>
                            {isEmptyResult ? this.renderResultNotFound() : this.renderSearchCasualList()}
                          </div>
                        )}
                        {/*<!-- 여행기사 끝 -->*/}

                        {/*<!-- 이미지 -->*/}
                        {selectedTab === TabValue.IMAGE && (
                          <div className={`tab_con ${selectedTab === TabValue.IMAGE ? 'on' : undefined}`}>
                            {isEmptyResult ? this.renderResultNotFound() : this.renderSearchImageList()}
                          </div>
                        )}
                        {/*<!-- 이미지 끝 -->*/}

                        {/*<!-- 테마 -->*/}
                        {selectedTab === TabValue.THEME && (
                          <div className={`tab_con ${selectedTab === TabValue.THEME ? 'on' : undefined}`}>
                            {isEmptyResult ? this.renderResultNotFound() : this.renderThemeList()}
                          </div>
                        )}
                        {/*<!-- 테마 끝 -->*/}

                        {/*<!-- 코스 -->*/}
                        {selectedTab === TabValue.COURSE && (
                          <div className={`tab_con ${selectedTab === TabValue.COURSE ? 'on' : undefined}`}>
                            {isEmptyResult ? this.renderResultNotFound() : this.renderSearchCourseList()}
                          </div>
                        )}
                        {/*<!-- 코스 -->*/}

                        {/*<!-- 동영상 -->*/}
                        {selectedTab === TabValue.VIDEO && (
                          <div className={`tab_con ${selectedTab === TabValue.VIDEO ? 'on' : undefined}`}>
                            {isEmptyResult ? this.renderResultNotFound() : this.renderSearchVideoList()}
                          </div>
                        )}
                        {/*<!-- 동영상 끝 -->*/}

                        {/*<!-- 파일 -->*/}
                        {selectedTab === TabValue.FILE && (
                          <div className={`tab_con ${selectedTab === TabValue.FILE ? 'on' : undefined}`}>
                            {isEmptyResult ? this.renderResultNotFound() : this.renderFileList()}
                          </div>
                        )}
                        {/*<!-- 파일 -->*/}
                      </div>
                    </div>
                  ) : (
                    <div style={{ padding: '15% 35%' }}>
                      <CircularProgress />
                    </div>
                  )
                  }

                </div>
              </div>
            </div>
          </section>
        </div>

        {isEmptyKeyword && <Alert message="키워드를 입력해주세요." eventCloseModal={this.closeModal} />}
      </>
    );
  }
}

export default SearchMainPage;
