import React from "react";

class CopyRightText extends React.Component {
  render() {
    return (
      <div className="consent-bx" style={{ height: 380 }}>
        <p>
          홈페이지 이용에 불편이 없도록 안내해 드립니다.
          <br />
          본 정책의 목적은 한국관광공사 홈페이지를 통하여 제고되는 정보가
          정보출처 밝히지 않고
          <br />
          무단사용, 변조, 상업적인 용도 등으로 사용되어 정보 이용자에게 피해를
          끼치는 사례를
          <br />
          방지하기 위함입니다.
        </p>
        <br />
        <p>
          공사 홈페이지에서 제공하는 모든 자료는 저작권법에 의하여 보호받는
          저작물로서 이용자는 아래의 저작권 보호정책을 준수하여야 합니다.
        </p>
        <br />
        <ul>
          <li>
            ① 저작권법 제24조의2에 따라 한국관광공사에서 저작재산권의 전부를
            보유한 저작물의 경우에는 별도의 이용허락 없이 무료로 자유이용이
            가능합니다.
            <br />
            <p color="red">
              {" "}
              단, 자유이용이 가능한 저작물은 `공공저작물 자유이용허락 표시
              기준(공공누리,KOGL) 제1유형`
              <img width="70px" src="/images/icon/img_1.png" />
              부착하여 개방하고 있으므로 공공누리 표시가 부착된 저작물인지를
              확인한 이후에 자유이용하시기 바랍니다. 자유이용의 경우에는 반드시
              저작물의 출처를 구체적으로 표시하여야 합니다.
            </p>
          </li>
          <li>
            ② 한국관광공사 홈페이지에는 공사가 저작권 전부를 갖고 있지 아니한
            자료도 제공되고 있습니다. 또한 국내 관광지나 관광상품, 행사 등을
            홍보하고 활성화하고자 개인이나 기관, 단체 등에서 무상으로 제공받은
            자료들도 많이 있으므로 이러한 자료를 자유롭게 이용하기 위해서는
            반드시 해당 저작권자의 허락을 받으셔야 합니다.
            <br /> <br />
            즉, 공공누리가 부착되지 않은 자료들을 사용하고자 할 경우에는
            담당자와 사전에 협의한 이후에 이용하여 주시기 바랍니다.
          </li>
          <li>
            ③ 다른 인터넷 사이트 상의 화면에서 공사 홈페이지의 저작물을 직접
            링크시킬 경우에는 링크 이용자가 본 인터넷 저작권 정책을 간과할 수
            있으므로 본 인터넷 저작권 정책도 함께 링크해 주시기 바랍니다.
          </li>
          <li>
            ④ 홈페이지에서 개방 중인 자료 중 공사가 저작권 전부를 갖고 있지
            아니한 자료(다른 저작자와 저작권을 공유한 자료 등)의 경우에는 저작권
            침해의 소지가 있으므로 단순 열람 외에 무단 변경, 복제&middot;배포,
            개작 등의 이용은 금지되며 이를 위반할 경우 관련법에 의거 법적 처벌을
            받을 수 있음을 알려드립니다. 공사는 모든 국민이 안전하게 관광 정보를
            활용하실 수 있도록 지속적으로 노력하겠습니다.
          </li>
        </ul>
      </div>
    );
  }
}

export default CopyRightText;
