import ImageSize from './ImageSize';

export const depotUrl = '/api/depot/public/depot-flow/query/download-image/';

export function makeDepotImageUrl(depotImagePath?: string, downloadType: ImageSize | string = '') {
  //
  if (!depotImagePath) {
    return '';
  }

  return depotImagePath + '/' + downloadType;
}

export function makeThemeDepotImageUrl(depotImagePath?: string) {
  //
  if (!depotImagePath) {
    return '';
  }

  return depotImagePath + '/'
}

export function makeImageUrlCutPipe(ImagePath?: string, downloadType: ImageSize | string = '') {
  //
  if (!ImagePath) {
    return '';
  }

  let retImageUrl = ImagePath;

  if (ImagePath.includes('|')) {
    const depotImageUrl = ImagePath.substring(ImagePath.indexOf('|')).replace('|', '');

    if (depotImageUrl == null || depotImageUrl.includes('null')) {

      retImageUrl = ImagePath.substring(0, ImagePath.indexOf('|')).replace('image3_1', 'image2_1');

      return retImageUrl;
    } else {

      retImageUrl = window.location.origin + depotImageUrl + '/' + downloadType;

      return retImageUrl;
    }


  } else {
    retImageUrl = ImagePath.replace('image3_1', 'image2_1');

    return retImageUrl;
  }

}

export function  defaultListCard() {
  const defaultImage = `/rms-conlab/images/alternative.png`;
  return defaultImage;
}
