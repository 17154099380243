
import { computed } from 'mobx';
import fromDomain from '../../../lib/decorator/fromDomain';
import makeExtendedObservable from '../../../lib/decorator/makeExtendedObservable';


@fromDomain
class Name {
  // TODO: accent Name 변경
  language: string = '';
  givenName: string = '';
  middleName: string = '';
  familyName: string = '';

  constructor(name?: Name) {
    //
    if (name) {
      this.language = name.language;
      this.givenName = name.givenName;
      this.middleName = name.middleName;
      this.familyName = name.familyName;
    }

    makeExtendedObservable(this);
  }

  static fromDomain(domain: Name): Name {
    //
    return new Name(domain);
  }

  static fromDomains(domains: Name[]): Name[] {
    //
    return domains.map(domain => this.fromDomain(domain));
  }

  // @computed
  // get displayFamilyNameFirst(): string {
  //   //
  //   const familyName = this.familyName || '';
  //   const givenName = this.givenName || '';
  //   const middleName = this.middleName || ' ';

  //   return `${familyName} ${middleName}${givenName}`;
  // }

  // @computed
  // get displayFamilyNameLast(): string {
  //   //
  //   const familyName = this.familyName || '';
  //   const givenName = this.givenName || '';
  //   const middleName = this.middleName || ' ';

  //   return `${givenName} ${middleName}${familyName}`;
  // }
}

export default Name;
