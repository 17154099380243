import React from "react";

interface Props {
    closeSelectBox: () => void;
    setRegionCode : (regionCode : number) => void
    setRegionName : (regionName : string) => void
    regionTitle: string;
}

interface State {}

class RegionSelectBoxMobile extends React.Component<Props, State> {
  state: State = {};

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
    snapshot?: any
  ): void {}

  componentDidMount() {}

  setRegionCode(sidoCode: number, sidoName: string){
    this.props.setRegionCode(sidoCode);
    this.props.setRegionName(sidoName);
    }

  render() {
    const { regionTitle } = this.props;
    return (
      <>
        <div className="region_select_box">
          <ul>
            <li 
              onClick={() => {this.setRegionCode(1, '서울특별시'); this.props.closeSelectBox();}} 
              style={regionTitle === '서울특별시' ? {backgroundColor: 'black'} : {backgroundColor: 'unset'}}
            >
              <a style={regionTitle === '서울특별시' ? {color: 'white'} : {color: 'unset'}}>서울특별시</a>
            </li>
            <li 
              onClick={() => {this.setRegionCode(31, '경기도'); this.props.closeSelectBox();}}
              style={regionTitle === '경기도' ? {backgroundColor: 'black'} : {backgroundColor: 'unset'}}
            >
              <a style={regionTitle === '경기도' ? {color: 'white'} : {color: 'unset'}}>경기도</a>
            </li>
            <li 
              onClick={() => {this.setRegionCode(2, '인천광역시'); this.props.closeSelectBox();}}
              style={regionTitle === '인천광역시' ? {backgroundColor: 'black'} : {backgroundColor: 'unset'}}
            >
              <a style={regionTitle === '인천광역시' ? {color: 'white'} : {color: 'unset'}}>인천광역시</a>
            </li>
            <li 
              onClick={() => {this.setRegionCode(6, '부산광역시'); this.props.closeSelectBox();}}
              style={regionTitle === '부산광역시' ? {backgroundColor: 'black'} : {backgroundColor: 'unset'}}
            >
              <a style={regionTitle === '부산광역시' ? {color: 'white'} : {color: 'unset'}}>부산광역시</a>
            </li>
            <li 
              onClick={() => {this.setRegionCode(3, '대전광역시'); this.props.closeSelectBox();}}
              style={regionTitle === '대전광역시' ? {backgroundColor: 'black'} : {backgroundColor: 'unset'}}
            >
              <a style={regionTitle === '대전광역시' ? {color: 'white'} : {color: 'unset'}}>대전광역시</a>
            </li>
            <li 
              onClick={() => {this.setRegionCode(5, '광주광역시'); this.props.closeSelectBox();}}
              style={regionTitle === '광주광역시' ? {backgroundColor: 'black'} : {backgroundColor: 'unset'}}
            >
              <a style={regionTitle === '광주광역시' ? {color: 'white'} : {color: 'unset'}}>광주광역시</a>
            </li>
            <li 
              onClick={() => {this.setRegionCode(32, '강원특별자치도'); this.props.closeSelectBox();}}
              style={regionTitle === '강원특별자치도' ? {backgroundColor: 'black'} : {backgroundColor: 'unset'}}
            >
              <a style={regionTitle === '강원특별자치도' ? {color: 'white'} : {color: 'unset'}}>강원특별자치도</a>
            </li>
            <li 
              onClick={() => {this.setRegionCode(33, '충청북도'); this.props.closeSelectBox();}}
              style={regionTitle === '충청북도' ? {backgroundColor: 'black'} : {backgroundColor: 'unset'}}
            >
              <a style={regionTitle === '충청북도' ? {color: 'white'} : {color: 'unset'}}>충청북도</a>
            </li>
            <li 
              onClick={() => {this.setRegionCode(34, '충청남도'); this.props.closeSelectBox();}}
              style={regionTitle === '충청남도' ? {backgroundColor: 'black'} : {backgroundColor: 'unset'}}
            >
              <a style={regionTitle === '충청남도' ? {color: 'white'} : {color: 'unset'}}>충청남도</a>
            </li>
            <li 
              onClick={() => {this.setRegionCode(35, '경상북도'); this.props.closeSelectBox();}}
              style={regionTitle === '경상북도' ? {backgroundColor: 'black'} : {backgroundColor: 'unset'}}
            >
              <a style={regionTitle === '경상북도' ? {color: 'white'} : {color: 'unset'}}>경상북도</a>
            </li>
            <li 
              onClick={() => {this.setRegionCode(36, '경상남도'); this.props.closeSelectBox();}}
              style={regionTitle === '경상남도' ? {backgroundColor: 'black'} : {backgroundColor: 'unset'}}
            >
              <a style={regionTitle === '경상남도' ? {color: 'white'} : {color: 'unset'}}>경상남도</a>
            </li>
            <li 
              onClick={() => {this.setRegionCode(4, '대구광역시'); this.props.closeSelectBox();}}
              style={regionTitle === '대구광역시' ? {backgroundColor: 'black'} : {backgroundColor: 'unset'}}
            >
              <a style={regionTitle === '대구광역시' ? {color: 'white'} : {color: 'unset'}}>대구광역시</a>
            </li>
            <li 
              onClick={() => {this.setRegionCode(7, '울산광역시'); this.props.closeSelectBox();}}
              style={regionTitle === '울산광역시' ? {backgroundColor: 'black'} : {backgroundColor: 'unset'}}
            >
              <a style={regionTitle === '울산광역시' ? {color: 'white'} : {color: 'unset'}}>울산광역시</a>
            </li>
            <li 
              onClick={() => {this.setRegionCode(37, '전라북도'); this.props.closeSelectBox();}}
              style={regionTitle === '전라북도' ? {backgroundColor: 'black'} : {backgroundColor: 'unset'}}
            >
              <a style={regionTitle === '전라북도' ? {color: 'white'} : {color: 'unset'}}>전라북도</a>
            </li>
            <li 
              onClick={() => {this.setRegionCode(38, '전라남도'); this.props.closeSelectBox();}}
              style={regionTitle === '전라남도' ? {backgroundColor: 'black'} : {backgroundColor: 'unset'}}
            >
              <a style={regionTitle === '전라남도' ? {color: 'white'} : {color: 'unset'}}>전라남도</a>
            </li>
            <li 
              onClick={() => {this.setRegionCode(39, '제주특별자치도'); this.props.closeSelectBox();}}
              style={regionTitle === '제주특별자치도' ? {backgroundColor: 'black'} : {backgroundColor: 'unset'}}
            >
              <a style={regionTitle === '제주특별자치도' ? {color: 'white'} : {color: 'unset'}}>제주특별자치도</a>
            </li>
            <li 
              onClick={() => {this.setRegionCode(8, '세종특별자치시'); this.props.closeSelectBox();}}
              style={regionTitle === '세종특별자치시' ? {backgroundColor: 'black'} : {backgroundColor: 'unset'}}
            >
              <a style={regionTitle === '세종특별자치시' ? {color: 'white'} : {color: 'unset'}}>세종특별자치시</a>
            </li>
          </ul>
        </div>
        <div className="backdrop" onClick={() => this.props.closeSelectBox()}></div>
      </>
    );
  }
}

export default RegionSelectBoxMobile;
