import React from 'react';
import axios from 'axios';
import { LabelList, ComposedChart, Bar, XAxis, YAxis, CartesianGrid } from 'recharts';

interface Props {
    categoryName: string;
}

interface State {
    chartData : []
    lastYear: string
    yearChartJan : string
}

class CategoryAccumulateChart extends React.Component<Props, State> {

    state: State = {
        chartData : [],
        lastYear : '',
        yearChartJan : ''
    };

    constructor(props: any) {
        super(props);
        this.renderCustomText = this.renderCustomText.bind(this);
    }

    componentDidMount() {
        this.categoryAccumulateChart();
    }

    async categoryAccumulateChart() {
        try {
            const response = await axios.get(`/api/naomi/v2/statistics/category-accumulate-chart/${this.props.categoryName}`,{});
            this.setState({chartData: response.data.queryResult, lastYear: response.data.queryResult[response.data.queryResult.length-1].year});
            
        } catch (e) {
            console.error(e);
        }
    }

    renderCustomizedLabel(props : any) {
        const { x, y, value, width } = props;

        return (
                <text x={x + width / 2} y={y-10} fill="black" textAnchor="middle" dominantBaseline="middle">
                    <tspan>{value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</tspan>
                </text>
            );
        };

    renderCustomText(props : any){
        const {chartData} = this.state;
        const {width, height, payload, x, y} = props;

        if(chartData.length - 1 == props.index) {
            props.fill = '#1240da';
        }

        return (
            <text orientation="bottom" style={chartData.length - 1 == props.index ? {fontWeight: 'bold'} : undefined} width={width} height={height} type="category" x={x} y={y} stroke="none" fill={props.fill} className="recharts-text recharts-cartesian-axis-tick-value" textAnchor="middle">
                <tspan x={x} dy="0.71em" dx="3.5em" fill={props.fill}>
                    {payload.value}
                </tspan>
            </text>
        );
    }

        render() {
            return(
                <div className='category-accumulate-chart-view' style={{position: 'relative', left: '5px'}}>
                    <ComposedChart width={320} height={400} data={this.state.chartData} margin={{top: 20, right: 20, bottom: 20, left: 20,}}>
                        <CartesianGrid stroke="#f5f5f5"/>
                        <XAxis dataKey="year" scale="band" tick={this.renderCustomText} tickLine={false}/>
                        {/* TODO domain dataMin -10000을 동적으로 설정할 수 있도록 수정할 것 */}
                        {/*@ts-ignore*/}
                        <YAxis tickFormatter={tick => {return tick.toLocaleString();}} tickCount={7} domain={[dataMin => (Math.ceil(dataMin / 1000) * 1000) - 10000, 'auto']}/>
                        <Bar className='category-accumulate-bar' style={{cursor:'unset'}} name="누적 콘텐츠" dataKey="accumulateContent" barSize={20} fill="#92d89c">
                            <LabelList dataKey="accumulateContent" content={this.renderCustomizedLabel} position="top"/>
                        </Bar>
                        {/* <Line name="전년대비 증감률" type="monotone" dataKey="increase" stroke="#5687fe">
                            <LabelList dataKey="increase" position="top"/>
                        </Line> */}
                    </ComposedChart>
                </div>
            )
        }
    }

export default CategoryAccumulateChart;

