import React from "react";
import axios from "axios";
import CardBannerView from "../../conlab/main-theme/CardBannerView";
import ModalView from "../../conlab/main-theme/ModalView";

interface Props {}

interface State {
  themeDetailList: object[];
  listBannerObject: { id: string , title:string, subTitle:string, type: string ,seq: number, moreLink: string, moreLinkYn: string, cid: string, categoryId:string, description:string}[];
  openDetailModal: undefined | 'detail';
  modalType: string;
  categoryId: string;
  themeDetailObject: {actorId:string, audienceId:string, bannerImgPath:string, children:string, cineroomId:string, cineroomName:string, citizenId:string, cmsCategoryId:string, elementTypeId:string, entityVersion:string,
    existChild:boolean, folderType:boolean, id:string, idPaths:[], imagePath:string, langUsed:boolean, lastUpdateTime:number, modificationTime:number, lastUpdater: {id:string, name:string}, marketingType:string,
    name:string, registrationTime:number, type:string, used:boolean, writer:{id:string, name:string}, pavilionId: string, parentId: string, originCategoryId: string, originFolderId: string, seq: number, publicType: boolean
  };
  imageDetailBase : {
    copyright: string,
    imageProperty: string,
    provider: string,
    depotId: string,
    imagePath: string,
    enTitle: string,
    cid:string,
    tid:string
  },
  categoryPath:[],
  categoryAddress:{},
  openThemeModal: undefined | "detail";
}

class RecommendedContentPage extends React.Component<Props, State> {
  static defaultProps: any = {};

  constructor(props:any) {
    super(props);
    this.onClickDetailModal = this.onClickDetailModal.bind(this);
    this.onClickCloseModal = this.onClickCloseModal.bind(this);
    this.onClickThemeCloseModal = this.onClickThemeCloseModal.bind(this);
  }

  componentDidMount() {
    this.getListBanner();
  }

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
    snapshot?: any
  ): void {}

  //@ts-ignore
  state: State = {
    themeDetailList: [],
    listBannerObject: [],
    openDetailModal: undefined,
    modalType: '',
    categoryId: '',
    themeDetailObject: {actorId:'', audienceId:'', bannerImgPath:'', children:'', cineroomId:'', cineroomName:'', citizenId:'', cmsCategoryId:'', elementTypeId:'', entityVersion:'',
    existChild:false, folderType:false, id:'', idPaths:[], imagePath:'', langUsed:false, lastUpdateTime:0, modificationTime:0, lastUpdater: {id:'', name:''}, marketingType:'',
    name:'', registrationTime:0, type:'', used:false, writer:{id:'', name:''}, pavilionId: '', parentId: '', originCategoryId: '', originFolderId: '', seq: 0, publicType: false
  },
  imageDetailBase : {
    copyright: '',
    imageProperty: '',
    provider: '',
    depotId: '',
    imagePath: '',
    enTitle: '',
    cid:'',
    tid:'',
  },
  categoryPath:[],
  categoryAddress:{},
  openThemeModal: undefined,
  };

  async getListBanner() {
    try {
      const response = await axios.get('/api/naomi/flow/banner-flow/query/find-list-banner', {});

      this.setState({listBannerObject: response.data.queryResult});

    } catch (e) {
        console.error(e);
    }
  }

  async getThemeDetailList(category_id:string) {

    try {
      const instance = axios.create();
      await instance.post('/api/data/flow/c736c5e9-8dd9-4e1f-a9a6-542f29e02017',{
        "responseName": "response",
        "categoryId": category_id,
      },
      {
      }).then((res) => {
        this.setState({themeDetailList: res.data.queryResult});
      });
    } catch (e) {
        console.error(e);
    }
  }

  async getThemeDetail(category_id:string) {
    try {
      const response = await axios.post('/api/data/flow/4592b5e8-9db0-4719-ada3-ffe812093317', {
        "responseName": "response",
        "categoryId": category_id,
      }).then((res) => {
        this.setState({themeDetailObject: res.data.queryResult});
      });

    } catch (e) {
        console.error(e);
    }
  }

  onClickDetailModal(modalType:string, category_id: string) {
    this.setState({ openDetailModal: 'detail' , modalType: modalType , categoryId : category_id});
    document.body.style.cssText = "overflow: hidden !important";
    switch(modalType) {
      case "theme" :
        this.getThemeDetail(category_id);
        this.getThemeDetailList(category_id);
        break;
    }
  }

  onClickCloseModal() {
    this.setState({ openDetailModal: undefined});
    document.body.style.cssText = "overflow: unset";
  }

  onClickThemeCloseModal() {
    this.setState({ openThemeModal: undefined });
    document.body.style.cssText = "overflow: scroll";
  }

  renderListBanner() {
    const {
      listBannerObject,
    } = this.state;
    
    const listBanner = listBannerObject?.map((obj:any, i:any) => {
      let tempContents: any;

      // if (i !== 2) {
      //   return null;
      // }

      // if(obj.type !== 'band' && obj.type !== 'button'){
      //   tempContents = (
      //     <CardBannerView key={obj.id} cardType={obj.type} listBannerObject = {listBannerObject[i]} onClickDetailModal = {this.onClickDetailModal}/>
      //   )
      // }

      if(obj.type === 'theme3' && obj.seq === 2){
        tempContents = (
          <div className="con_box">
            <CardBannerView key={obj.id} cardType={obj.type} listBannerObject = {listBannerObject[i]} onClickDetailModal = {this.onClickDetailModal}/>
          </div>
        )
      } else if(obj.type === 'theme4' && obj.seq === 3) {
        tempContents = (
          <div className="con_box">
            <CardBannerView key={obj.id} cardType={obj.type} listBannerObject = {listBannerObject[i]} onClickDetailModal = {this.onClickDetailModal}/>
          </div>
        )
      } else if(obj.type === 'theme3' && (obj.seq === 4 || obj.seq === 5)) {
        tempContents = (
          <div className="con_box">
            <CardBannerView key={obj.id} cardType={obj.type} listBannerObject = {listBannerObject[i]} onClickDetailModal = {this.onClickDetailModal}/>
          </div>
        )
      }
      return tempContents
    })

    return (
      <>
        {listBanner}
      </>
    )
  }
  

  render() {

    const { openDetailModal } = this.state 

    return (
      <>
        <div id="contents" className="tourism_recommend">
          <div className="center nav_wrap">
            <ul className="nav">
              <li>
                <i className="icon home"></i>
              </li>
              <li>
                관광콘텐츠
                {/* <i className="icon down_circle"></i> */}
              </li>
              <li>
                <span>추천 관광콘텐츠</span>
                {/* <i className="icon up_circle"></i> */}
              </li>
            </ul>
          </div>

          <section className="tr_list">
            <div className="center">
                {this.renderListBanner()}
            </div>
          </section>
          
          {openDetailModal && openDetailModal === 'detail' && (
            <ModalView 

              onCloseModal={this.onClickCloseModal}
              onClickThemeCloseModal={this.onClickThemeCloseModal}
              modalType={this.state.modalType}
              themeDetailObject={this.state.themeDetailObject}
              themeDetailList={this.state.themeDetailList}
              imageDetailBase = {this.state.imageDetailBase}
              categoryPath = {this.state.categoryPath}
              categoryAddress = {this.state.categoryAddress}
              categoryId = {this.state.categoryId}
            />
        )}
        
        </div>
      </>
    );
  }
}

export default RecommendedContentPage;
