import makeExtendedObservable from "../decorator/makeExtendedObservable";
import LangStrings from "../domain/lang/LangStrings";

class Field {
  name: string;
  value: string;
  descriptions: LangStrings | null = null;

  constructor(name: string, value: string) {
    this.name = name;
    this.value = value;

    makeExtendedObservable(this);
  }

  static fromDomain(domain: Field): Field {
    const field = new Field(
      domain.name,
      domain.value,
    );

    field.descriptions = domain.descriptions;
    return field;
  }

  static fromDomains(domains: Field[]): Field[] {
    return domains.map(domain => this.fromDomain(domain));
  }
}

export default Field;
