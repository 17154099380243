import React, { ContextType } from "react";
import "../../css/common.css";
import ImageSize from "../../shared/ImageSize";
import ContentBuilder from "../../model/builder/ContentBuilder";
import { KoglType } from "../../model/type/KoglType";
import axios from "axios";
import { changeKogTypeToKoglTypeKorDetail } from "../../shared/Function/util/KoglUtil";
import { download } from "../../shared/Function/util/fileUtil";
import BookMarkButton from "../../components/BookMarkButton";
import CommonContext from "../../context/CommonContext";
import InterestsType from "../../model/type/interestsType";
import { registerUsage } from "../../shared/Function/util/RegisterUtil";
import { SearchType } from "../../model/type/SearchType";
import { UsageType } from "../../model/type/UsageType";
import { ContentType } from "../../../src/model/type/ContentType";
import { downloadPDF } from "..";
import { CircularProgress } from "@mui/material";

interface Props {
  cid: string;
  interestsType: InterestsType;
  upDateImageSummaryDate: {
    categoryPath: string,
    cid: string,
    contentsName: string,
    imagePath: string,
    imageType: string,
    koglType: string,
    modificationTime: string,
    score: number,
    tid: string
  }[];
  viewWidth: number | undefined;
  noPrev?: boolean;
  onCloseModal: () => void;
  onClickThemeCloseModal: () => void;
  eventSetSelected: () => void;
  contentsType: string;
  showImage?: () => void;
  scrollToTop?: () => void;
}

interface State {
  contentDetailData: any;
  showShareAlert: boolean;
  isloading: boolean;
  modifyDate: string;
  lastStatusUpdaterCineroomName: string;
}

class ImageContentsDetails extends React.Component<Props, State> {

  static defaultProps: any = {
    noPrev: false
  };

  static contextType = CommonContext;

  context!: ContextType<typeof CommonContext>;

  constructor(props: any) {
    super(props);
    this.downloadPDF = this.downloadPDF.bind(this);
    this.setLoading = this.setLoading.bind(this);
    this.initContentDetailDataByCid = this.initContentDetailDataByCid.bind(this);
    this.updateViewCount = this.updateViewCount.bind(this);
    this.updateDownloadCount = this.updateDownloadCount.bind(this);
    this.onClickOriginalImageDownload = this.onClickOriginalImageDownload.bind(this);
    this.onClickShare = this.onClickShare.bind(this);
  }

  state: State = {
    contentDetailData: {},
    showShareAlert: false,
    isloading: false,
    modifyDate: '',
    lastStatusUpdaterCineroomName: '',
  };

  componentDidMount() {
    this.initContentDetailDataByCid(this.props.cid);
    this.updateViewCount();
  }

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
    snapshot?: any
  ): void {
    if (prevProps.cid !== this.props.cid) {
      this.initContentDetailDataByCid(this.props.cid);
      this.updateViewCount();
    }

    if(prevProps.upDateImageSummaryDate !== this.props.upDateImageSummaryDate){
      this.setState({contentDetailData : {}})
    }
  }

  async initContentDetailDataByCid(cid: string) {
    const { scrollToTop } = this.props;
    const contentBuilder = new ContentBuilder(cid);
    await contentBuilder.buildImageContent();
    const contentDetailData = contentBuilder.getContentDetailInfo();
    console.log('contentDetailData: ', contentDetailData);
    await contentBuilder.findLastStatusCineroomName();
    console.log('lastStatusUpdaterCineroomName: ', contentBuilder.lastStatusUpdaterCineroomName);
    this.setState({ lastStatusUpdaterCineroomName: contentBuilder.lastStatusUpdaterCineroomName });
    
    this.setState({contentDetailData: contentDetailData});
    
    if (scrollToTop) {
      scrollToTop();
    }
    const modifyDate = contentBuilder.contentDetail.element.modificationTime;
    if (modifyDate) {
      const currentDate = new Date(modifyDate);
      const year = currentDate.getFullYear();
      const month = String(currentDate.getMonth() + 1).padStart(2, '0');
      const day = String(currentDate.getDate()).padStart(2, '0');
      this.setState({modifyDate: `${year}년 ${month}월 ${day}일`})
    }
  }

  updateViewCount() {
    const { cid } = this.props;
    const { actorIdentity, citizenUser, accessibleCinerooms } = this.context;
    if (!cid) {
      return;
    }
    const currentActorId = actorIdentity?.id;
    const email = actorIdentity?.email;
    const cineroomId = citizenUser?.cineroomIds[0];
    const cineroomName = accessibleCinerooms[0]?.name;
    registerUsage(cid, currentActorId, email, cineroomId, cineroomName, SearchType.Image, UsageType.ViewCount);
  }

  updateDownloadCount() {
    const { cid } = this.props;
    const { actorIdentity, citizenUser, accessibleCinerooms } = this.context;
    const currentActorId = actorIdentity?.id;
    const email = actorIdentity?.email;
    const cineroomId = citizenUser?.cineroomIds[0];
    const cineroomName = accessibleCinerooms[0]?.name;
    registerUsage(cid, currentActorId, email, cineroomId, cineroomName, SearchType.Image, UsageType.ImageDownloaded);
  }

  async onClickOriginalImageDownload() {
    //
    const { openLoginModal, isLogin } = this.context;
    const { contentDetailData } = this.state;
    const url: string = contentDetailData.image[0].imageDepotUrl;
    const koglType = contentDetailData.registrationInfo.value.kogl.value;
    const fullContentsName = contentDetailData.baseInfo.value.title.value;
    const splitedUrl = url.split('/');
    const depotFileId = splitedUrl[splitedUrl.length - 1];

    if (!isLogin) {
      openLoginModal();
      return;
    }

    let fileName = '';

    const depotFile = await axios.post("/api/depot/secure/depot-file/query/", {
      "responseName": "response",
      "depotFileId": depotFileId
    }).then((response) => {
      return response.data.queryResult;
    });

    const fullFileName = depotFile.name;
    
    if (koglType) {
      let dotIdx = fullFileName.lastIndexOf('.');
      let ext = fullFileName.substring(dotIdx);
      fileName  = fullContentsName + '_' +  changeKogTypeToKoglTypeKorDetail(koglType) + ext;

    } else {
      fileName = depotFile.name;
    }

    download(`${url}/${ImageSize.it14}`, fileName);
    this.updateDownloadCount();
  }

  onClickShare(){

    const { cid, interestsType } = this.props;
    const { actorIdentity, citizenUser, accessibleCinerooms } = this.context;

    const currentActorId = actorIdentity?.id;
    const email = actorIdentity?.email;
    const cineroomId = citizenUser?.cineroomIds[0];
    const cineroomName = accessibleCinerooms[0]?.name;

    const textarea = document.createElement('textarea');
    document.body.appendChild(textarea);

    const base_url = window.document.location.host;
    let urlValue = '';
    urlValue = `${base_url}/rms-conlab/detail?` + "cid=" + this.props.cid + "&type=" + ContentType.ImageContents;

    textarea.value = urlValue;
    textarea.select();
    document.execCommand('copy');
    document.body.removeChild(textarea);

    this.setState({ showShareAlert: true });
    setTimeout(() => this.setState({ showShareAlert: false }), 2000);

    registerUsage(cid, currentActorId, email, cineroomId, cineroomName, interestsType, UsageType.LinkCreated);
  }
  // pdf download strat
  downloadPDF = async () => {
    const { openLoginModal, isLogin } = this.context;

    if (!isLogin) {
      openLoginModal();
      return;
    }
    
    this.setLoading(true);
    downloadPDF(this.setLoading, this.props.cid, ContentType.ImageContents);
  };

  setLoading(isLoading: boolean) {
    this.setState({ isloading: isLoading })
  }
  // pdf download end

  render() {
    const { cid, noPrev, viewWidth, onCloseModal,  eventSetSelected, onClickThemeCloseModal, showImage} = this.props;
    const { contentDetailData, showShareAlert, isloading, modifyDate, lastStatusUpdaterCineroomName } = this.state;

    if (Object.keys(contentDetailData).length === 0) {
      return;
    }

    return(
        <div className="rd_view_item" style={{paddingTop: 32}}>
          {/* @ts-ignore */}
          {viewWidth === undefined ? null :
            <div className="m_head">
            { noPrev === false && (<button className="rd_view_prev_bt" onClick={showImage ? ()=>{eventSetSelected(); showImage();} : () => (eventSetSelected())}><img src="/rms-conlab/images/icon_rd_view_prev.png" alt="close_icon"/></button>)}
                {this.props.contentsType === 'ThemeContents' ? null :
                    <button className="close_bt rd_view_close_bt" onClick={() => {onCloseModal(); onClickThemeCloseModal();}}><img src="/rms-conlab/images/icon_menu_close.svg" alt="close_icon"/></button>
                }
            </div>
          }

        <div className="contents_detail_page">
            <div className="cdp_tit_area">
                <h2 className="cdp_tit">{contentDetailData.baseInfo.value.title.value}</h2>
                <div className="bts">
                    {isloading ?
                        <CircularProgress color="secondary" className="pdf-progress" /> :
                        <button className="pdf-btn" onClick={() => this.downloadPDF()}></button>
                    }
                    <div className='content-modify-date' style={{display: 'none'}}>{modifyDate}</div>
                    <div className='content-cineroom-name' style={{display: 'none'}}>{lastStatusUpdaterCineroomName}</div>
                    <button className="share" onClick={() => this.onClickShare()} style={{position: 'relative', top: '6px'}}></button>
                    {
                        showShareAlert && <div className="share_copy"><span>URL이 복사되었습니다. 원하는 곳에 붙여 넣으세요.</span></div>
                    }
                </div>
                  <div className="bookmark-btn" style={{marginTop: '2px'}}>
                    <BookMarkButton
                      cid = {cid}
                      interestsType = {InterestsType.Image}
                    />
                  </div>
            </div>
            <div className="rd_view_img_area">

              {contentDetailData.image?.map((obj:any, i:any) =>
                <img
                    key={i}
                    style={{height: '500px'}}
                    className="layout-card-img"
                    onContextMenu={(e) => (e.preventDefault())}
                    src={`${obj.imageDepotUrl}/${ImageSize.it14}`}
                    alt=""
                />
              )}
            </div>

            <div className="contents_detail_info_area" style={contentDetailData.baseInfo.value.category.value === '' ? {marginTop: 'unset'} : {marginTop: '24px'}}>
                <div className="category">
                  {contentDetailData.baseInfo.value.category.value !== '' && contentDetailData.baseInfo.value.category.value !== null ?
                    <h3>{contentDetailData.baseInfo.value.category.title}</h3> : null
                  }
                    {contentDetailData.baseInfo.value.category.value !== '' && contentDetailData.baseInfo.value.category.value !== null ?
                    <ul>
                        <li>{contentDetailData.baseInfo.value.category.value}</li>
                    </ul> : null
                    }
                </div>
                <div className="contents_detail_info">
                    <div className="left">
                        <h4 className="cdi_tit">{contentDetailData.registrationInfo.value.kogl.title}</h4>

                        <div>
                          {contentDetailData.registrationInfo.value.kogl.value && contentDetailData.registrationInfo.value.kogl.value === KoglType.Type1 && (
                            <>
                              <p className="exp copyright">본 저작물은 "공공누리" 제1유형:출처표시 조건에 따라 이용 할 수 있습니다.</p>
                              <div className="dt-list-down">
                                <img className="copyright_img" src="/rms-conlab/images/img_opentype01.jpg" alt="copyright"/>
                              </div>
                            </>
                          )}
                          {contentDetailData.registrationInfo.value.kogl.value && contentDetailData.registrationInfo.value.kogl.value === KoglType.Type2 && (
                            <>
                              <p className="exp copyright">본 저작물은 “공공누리” 제2유형:출처표시+상업적 이용금지 조건에 따라 이용할 수 있습니다.</p>
                              <div className="dt-list-down">
                                <img className="copyright_img" src="/rms-conlab/images/img_opentype02.jpg" alt="copyright"/>
                              </div>
                            </>
                          )}
                          {contentDetailData.registrationInfo.value.kogl.value && contentDetailData.registrationInfo.value.kogl.value === KoglType.Type3 && (
                            <>
                              <p className="exp copyright">본 저작물은 “공공누리” 제3유형:출처표시+변경 금지 조건에 따라 이용할 수 있습니다.</p>
                              <div className="dt-list-down">
                                <img className="copyright_img" src="/rms-conlab/images/img_opentype03.jpg" alt="copyright"/>
                              </div>
                            </>
                          )}
                          {contentDetailData.registrationInfo.value.kogl.value && contentDetailData.registrationInfo.value.kogl.value === KoglType.Type4 && (
                            <>
                              <p className="exp copyright">본 저작물은 “공공누리” 제4유형:출처표시+상업적 이용금지+변경 금지 조건에 따라 이용할 수 있습니다.</p>
                              <div className="dt-list-down">
                                <img className="copyright_img" src="/rms-conlab/images/img_opentype04.jpg" alt="copyright"/>
                              </div>
                            </>
                          )}
                        </div>

                    </div>

                    <div className="right">
                        <ul className="visit_info">
                            {contentDetailData.registrationInfo.value.provider?.value && (
                            <li>
                                <i className="icon copyright"></i>
                                <div className="ex">
                                    <h5>{contentDetailData.registrationInfo.value.provider.title}</h5>
                                    <p>{contentDetailData.registrationInfo.value.provider.value}</p>
                                </div>
                            </li>)}
                            {contentDetailData.registrationInfo.value.owner?.value && (
                            <li>
                                <i className="icon maker_image"></i>
                                <div className="ex">
                                    <h5>{contentDetailData.registrationInfo.value.owner.title}</h5>
                                    <p>{contentDetailData.registrationInfo.value.owner.value}</p>
                                </div>
                            </li>)}
                            {contentDetailData.registrationInfo.value.photoAuthor?.value && (
                            <li>
                                <i className="icon maker_image"></i>
                                <div className="ex">
                                    <h5>{contentDetailData.registrationInfo.value.photoAuthor.title}</h5>
                                    <p>{contentDetailData.registrationInfo.value.photoAuthor.value}</p>
                                </div>
                            </li>)}
                        </ul>
                    </div>
                </div>

            </div>
            <div className="download_area" style={{marginTop: 24}}>
              <button className="st_point pdf-img-btn" onClick={() => this.onClickOriginalImageDownload()}>
                다운로드 
                <img src="/rms-conlab/images/icon_link_download1.png" alt="icon"/>
              </button>
            </div>
        </div>
    </div>
        
    )
  }
}

export default ImageContentsDetails;
