import makeExtendedObservable from "../../../../../lib/decorator/makeExtendedObservable";
import Phone from "../../../../../lib/granule/Phone";
import validationUtils from "../../../../../lib/module/validationUtils/validationUtils";
import CitizenInvitation from "../CitizenInvitation";

class CitizenInvitationCdo {
  email: string;
  pavilionId: string;
  validThru: string;
  division: string;
  marketingTime: number;
  marketingYn: boolean;
  ssoId: string;
  displayName: string;
  phoneJson: Phone;

  constructor(email: string, pavilionId: string, validThru: string, division: string, 
    marketingTime: number, marketingYn: boolean, ssoId: string, displayName: string, phoneJson: Phone) {
    this.email = email;
    this.pavilionId = pavilionId;
    this.validThru = validThru;
    this.division = division;
    this.marketingTime = marketingTime;
    this.marketingYn = marketingYn;
    this.ssoId = ssoId;
    this.displayName = displayName;
    this.phoneJson = phoneJson;
    makeExtendedObservable(this);
  }

  static fromModel(domain: CitizenInvitation) {
    const params = validationUtils.checkNullableParams<CitizenInvitation, keyof CitizenInvitation>(
      domain,
      [
        'email',
        'pavilionId',
        'validThru',
        'division',
      ]
    );

    return new CitizenInvitationCdo(
      params.email,
      params.pavilionId,
      params.validThru,
      params.division,
      params.marketingTime,
      params.marketingYn,
      params.ssoId,
      params.displayName,
      params.phoneJson
    );
  }

}

export default CitizenInvitationCdo;
