
import Depot from '../Depot';



class DepotCdo {
  id: string;
  name: string;
  vaultId: string;
  ownerId: string;
  spaceId: string;

  constructor(id: string, name: string, vaultId: string, ownerId: string, spaceId: string) {
    this.id = id;
    this.name = name;
    this.vaultId = vaultId;
    this.ownerId = ownerId;
    this.spaceId = spaceId;
  }

  static fromModel(domain: Depot): DepotCdo {
    return new DepotCdo(
      domain.id,
      domain.name,
      domain.vaultId,
      domain.ownerId,
      domain.spaceId,
    );
  }

}

export default DepotCdo;
