import makeExtendedObservable from "../../lib/decorator/makeExtendedObservable";
import validationUtils from "../../lib/module/validationUtils/validationUtils";

// 수정필요
class CastleCdo {
  primaryEmail: string;

  constructor(primaryEmail: string) {
    this.primaryEmail = primaryEmail;
    makeExtendedObservable(this);
  }

  // static fromModel(domain: Castle) {
  //   const params = validationUtils.checkNullableParams<Castle, keyof Castle>(
  //     domain,
  //     [
  //       'primaryEmail',
  //     ]
  //   );

  //   return new CastleCdo(
  //     params.primaryEmail,
  //   );
  // }

}

export default CastleCdo;
