import React, { createRef } from 'react';
import { Button, Icon, TextField } from '@mui/material';
import "../../css/style.min.css";
import ReactComponent from '../../lib/module/ReactComponent/ReactComponent';
import autobind from '../../lib/decorator/autobind/autobindDecorator';
import { observer } from 'mobx-react';
import CommonModal from '../../conlab/CommonModal';
import LimitTimer from '../../shared/Timer/LimitTimer';

interface Props {
  // cancelEmail: () => void;
  reEmail: () => void;
  validationCodeInEmail: (email: string) => Promise<boolean>;
  onChangeMail: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onChangeCode: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onSubmit: () => void;
  validateEmail: boolean;
  tempEmail: string;
  isSent: boolean;
  code: string;
  showTimeOption: 'default' | 'timer' | 'timeOver';
  onTimeOver: () => void;
  emailCheck: 'default' | 'pass' | 'fail';

  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

interface State {
  openModal: boolean;
  modalText: string;
  isVerified: boolean;
}
  
@autobind
@observer  
class MailAuthFormView extends ReactComponent<Props, State> {
  
  state = {
    openModal: false,
    modalText: '',
    isVerified: false
  };

  time = 180;

  paddedFormat(num: number) {
    return num < 10 ? '0' + num : num;
  }
  
  displayCount(time: number) {
    const minutes = Math.floor(time / 60);
    const seconds = this.paddedFormat(time - (minutes * 60));
    return `${this.paddedFormat(minutes)}:${seconds} 초`;
  }

  onSendMail() {
    const { reEmail } = this.props;
    reEmail();
    if (!this.validationCheck()) {
      return;
    }
    this.props.onSubmit();
  }

  onEnter(e: React.KeyboardEvent<HTMLDivElement>) {
    if (e.key === 'Enter' && this.validationCheck()) {
      this.props.onSubmit();
    }
  }

  validationCheck() {
    //
    const { tempEmail, validateEmail } = this.props;

    if (!tempEmail.trim()) {
      this.modalCon('이메일이 입력 되지 않았습니다.');
      return false;
    }

    if (!validateEmail) {
      this.modalCon('입력하신 이메일이 이메일 형식에 맞지 않습니다.');
      return false;
    }

    if (tempEmail.endsWith('.kp')) {
      this.modalCon('입력하신 이메일은 사용하실 수 없습니다.');
      return false;
    }

    return true;
  }

  modalCon(txt: string) {
    this.setState({ openModal: true, modalText: txt });
  }
  
  closeModal() {
    this.setState({ openModal: false, modalText: '' });
  }

  renderSendingButton() {
    const { isVerified } = this.state;
    return (
      <>
        {isVerified ? (
          <button 
            className="btn-search"  
            style={{ backgroundColor: '#F0F0F5', color: '#222224' }}
          >
            <Icon style={{ fontSize: 14, color: '#222224' }}>checked</Icon>
            <span style={{ color: '#222224', fontSize: 14 }}>인증완료</span>
          </button>
        ) : 
        (
          <>
            <button className="btn-search" onClick={this.onSendMail}>
              <Icon style={{ fontSize: 16 }}>checked</Icon>
              <span style={{ color: 'white' }}>발송</span>
            </button>
          </>
        )}
      </>
    );
  }

  checkCode(email: string) {
    const { validationCodeInEmail } = this.props;
    const boo = validationCodeInEmail(email).then((result) => {
      if (result) {
        this.setState({isVerified: true});
      } else {
        this.setState({isVerified: false});
        return;
      }
    }).catch((error) => {
      // 에러 핸들링
      this.setState({isVerified: false});
      console.error(error);
    });
  }

  render() {
    const { emailCheck, isSent, showTimeOption, tempEmail, onChangeMail, onChange } = this.props;
    const { openModal, modalText, isVerified } = this.state;
    return (
      <>
        {
          openModal ? <CommonModal message={modalText} close={this.closeModal} /> : null
        }
        <div className="step step-1">
          <div className="step-title">
              <h2>이메일 인증</h2>
              <span className="point-comment">표는 필수 동의 항목입니다.</span>
          </div>
          <div className="step-content">
              <div className="info-list">
                  <label>이메일 <span className="required"></span></label>
                  <div className="input-group">
                      <input 
                        type="text" placeholder="이메일을 입력 하세요." className="input-box with-btn"
                        id="managerEmail"
                        name="managerEmail"
                        value={tempEmail}
                        onChange={onChangeMail}
                        autoComplete="off"
                        readOnly={ isVerified ? true : false }
                      />
                      {
                        !isSent ? (this.renderSendingButton())
                        :
                          <button className="btn-search" onClick={this.onSendMail}>
                            <Icon style={{ fontSize: 16 }}>send</Icon>
                            <span style={{color: 'white'}}>재발송</span>
                          </button>
                      }
                  </div>
                  <div className="add-text">{ emailCheck === 'default' ? '입력하신 이메일로 인증번호가 발송됩니다.' : '인증이 완료되었습니다.'}</div>
              </div>
              {
                isSent ?
                <div className="info-list">
                    <label>인증코드 <span className="required"></span></label>
                    <div className="input-group">
                        <input 
                          id="secretCode" name="secretCode"
                          type="text" placeholder="인증번호 입력" className="input-box with-btn auth-input"
                          value={this.props.code}
                          onChange={this.props.onChangeCode}
                        />
                        <button className="btn-search" onClick={() => this.checkCode(tempEmail)}>
                          <Icon style={{ fontSize: 16 }}>checked</Icon>
                          <span style={{ color: 'white'}}>확인</span>
                        </button>
                    </div>
                    <div className="add-text">
                      <Icon>schedule</Icon>
                      {
                      showTimeOption === 'timer' && (
                        <>
                          <span>인증까지 남은 시간</span>
                          <LimitTimer limitMin={3} onTimeOver={this.props.onTimeOver} />
                        </>
                      )
                    }
                    </div>
                </div>
                : null
              }
              <div className="info-list">
                  <input type="checkbox" id="checkbox-1" className="checkbox checkbox-all" name="newsChecked" onChange={onChange} />
                  <label htmlFor="checkbox-1">뉴스레터 수신동의</label>
              </div>
          </div>
        </div>
      </>
    );
  }
}
export default MailAuthFormView;
