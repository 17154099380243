import Cineroom from '../../comp/api/metro/cineroom/api-model/Cineroom';
import Cast from '../../comp/api/stage/Cast';
import Troupe from './Troupe';


class DockNode {
  //
  ancestorId: string;  // if Cineroom Node, pavilionId, else if Cast Node, TroupeId
  ancestorName: string;  // if Cineroom Node, pavilion name, else if Cast Node, Troupe name
  id: string;
  name: string;
  onClickAction?: (id: string) => Promise<void>;
  kollectionName: string = '';
  basePath: string = '';


  constructor(ancestorId: string, ancestorName: string, id: string, name: string, onClickAction?: (id: string) => Promise<void>) {
    //
    this.ancestorId = ancestorId;
    this.ancestorName = ancestorName;
    this.id = id;
    this.name = name;
    this.onClickAction = onClickAction;

  }

  static new(ancestorId: string, ancestorName: string, id: string, name: string, onClickAction: (id: string) => Promise<void>) {
    //
    return new DockNode(ancestorId, ancestorName, id, name, onClickAction);
  }

  static byCineroom(cineroom: Cineroom, onClickAction?: (id: string) => Promise<void>) {
    //
    return new DockNode(
      cineroom.pavilionId,
      '',
      cineroom.id,
      cineroom.name,
      onClickAction
    );
  }

  static byCast(troupe: Troupe, cast: Cast, onClickAction?: (id: string) => Promise<void>) {
    //
    const dockNode = new DockNode(
      troupe.id,
      troupe.name,
      cast.id,
      cast.name,
      onClickAction
    );

    dockNode.kollectionName = troupe.kollection ? troupe.kollection.name : '';
    dockNode.basePath = cast.basePath;

    return dockNode;
  }
}

export default DockNode;
