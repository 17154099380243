
enum Category {
    //
    Mobile = 'Mobile',
    Office = 'Office',
    Home = 'Home',
    Others = 'Others',
  }
  
class Phone {
  //

  countryCode: string = '';
  carrierCode: string = '';

  frontNumber: string = '';
  backNumber: string = '';

  // fullNumber: string = '';
  displayNumber: string = '';
}
  
  export default Phone;
  