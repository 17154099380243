import React from "react";
import "../css/common.css";
import { Button } from "@mui/material";
import { topSessionclear, removeSessionTopPopup } from ".";

interface Props {
    close: () => void,
    login: () => void,
}

interface State {}

class CommonModalView extends React.Component<Props, State> {
  static defaultProps: any = {};

  componentDidMount() {}

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
    snapshot?: any
  ): void {}

  close() {
    const { close } = this.props;
    topSessionclear();
    close();
  }

  join() {
    removeSessionTopPopup();
    window.location.href = '/rms-conlab/join';
  }

  //@ts-ignore
  state: State = {};

  render() {
    return (
        <div className="login_page">
            <div className="login_box_wrap">
            <div className="login_box">
            <button className="btn_close" style={{position: 'absolute', top: '3px', right: '8px', padding: '9px 3px'}} onClick={() => (this.close())}>
                <img src="/rms-conlab/images/btn_close.png" alt="btn_close" />
            </button>
            <form>
            <h2>
                <p>
                    계정을<br></br>확인해주세요
                </p>
                {/* <button className="btn_close" onClick={() => this.close()} style={{position:'absolute', top:'0', right:'0', background:'none', border:'0', outline:'0', padding:'5px 6px 0px 0px', zIndex:'9'}}> */}
                    {/* <img src="/rms-conlab/images/btn_close.png" alt="btn_close" /> */}
                {/* </button> */}
            </h2>
            {/* <div className="head" style={{height: '50px', backgroundColor: '#203864', width: '392px', position: 'relative', top: '-1px', left: '-1px'}}>
                <button className="btn_close" onClick={() => this.props.close()} style={{position:'absolute', top:'0', right:'0', background:'none', border:'0', outline:'0', padding:'5px 6px 0px 0px', zIndex:'9'}}>
                    <img src="/rms-conlab/images/btn_close.png" alt="btn_close" />
                </button>
            </div> */}
            <section className="top_desc">
                <ul>
                    <li style={{width: '340px'}}>
                    <p style={{textAlign: 'center'}}>한국관광콘텐츠랩<br></br>기존 계정이 있으신가요?</p>
                    </li>
                </ul>
            </section>

            <div className="body" style={{height: '80px'}}>
                <div className="footer" style={{display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '20px'}}>
                    <div>
                        <Button style={{backgroundColor: '#2658E2'}} variant="contained" onClick={() => this.props.login()} >네</Button>
                    </div>
                    <div>
                        <Button style={{backgroundColor: '#2658E2'}} variant="contained" onClick={() => this.join()}>아니오</Button>
                    </div>
                </div>
                <div className="image" style={{display: 'flex', justifyContent: 'center', paddingTop: '30px', paddingBottom: '30px'}}> 
                    {/* <img src="/rms-conlab/images/join_step1.svg" alt="btn_close" /> */}
                </div>
                <div className="explanation" style={{display: 'flex', justifyContent: 'center', height: '80px'}}>
                    {/* <span>한국관광콘텐츠랩 계정이 있으신가요?</span> */}
                </div>
            </div>
        </form>

        </div>
        </div>
            <div className="bg" style={{position:'fixed', top:'0', left:'0', width:'100%', height:'100%', overflow:'hidden', zIndex:'-1', background:'rgba(0,0,0,0.2)'}}></div>
        </div>
    );
  }
}

export default CommonModalView;
