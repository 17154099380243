export enum MarketingType {
    ALL = "ALL",
    NA = "NA", // 자연관광
    HS = "HS", // 역사관광
    EX = "EX", // 체험관광
    VE = "VE", // 문화관광
    EV = "EV", // 축제/공연/행사
    FD = "FD", // 음식
    AC = "AC", // 숙박
    SH = "SH", // 쇼핑
    LS = "LS" // 레저스포츠
}