import React from 'react';
// import { autobind, ReactComponent } from '@rms/prologue';
import axios from 'axios';
import { PieChart, Pie, Sector, Cell } from 'recharts';

interface Props {
    regionCode : number
    startYearMonth : string;
    endYearMonth : string;
    period : string;
    clickCount: number;
}

interface State {
    chartData : []
    activeIndex : number
}

// @autobind
class RegionSigunguChart extends React.Component<Props, State> {

    state: State = {
        chartData : [],
        activeIndex : 0
    };

    componentDidMount() {
        this.regionSigunguChart();
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
        if (this.props.period != prevProps.period || this.props.regionCode != prevProps.regionCode || this.props.clickCount != prevProps.clickCount){
            this.regionSigunguChart();
        }
    }

    async regionSigunguChart() {
        try {

            const response = await axios.get('/api/naomi/v2/statistics/region-sigungu-chart',{
                params : {
                    period : this.props.period,
                    beginYM : this.props.startYearMonth,
                    endYM : this.props.endYearMonth,
                    code : this.props.regionCode
                }
            });
            this.setState({chartData: response.data.queryResult});
        } catch (e) {
            console.error(e);
        }
    }

        render() {

            const COLORS = ["#5687fe",  "#fc75c6", "#5b6eb3", "#8669c4", "#eb348c", "#ea7081", "#92d89c", "#5fc6c5", "#5687fd", "#f3702a"];

            const renderActiveShape = (props: any) => {
                const RADIAN = Math.PI / 180;
                const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent, value } = props;
                const sin = Math.sin(-RADIAN * midAngle);
                const cos = Math.cos(-RADIAN * midAngle);
                const sx = cx + (outerRadius + 10) * cos;
                const sy = cy + (outerRadius + 10) * sin;
                const mx = cx + (outerRadius + 30) * cos;
                const my = cy + (outerRadius + 30) * sin;
                const ex = mx + (cos >= 0 ? 1 : -1) * 5;
                const ey = my;
                const textAnchor = cos >= 0 ? 'start' : 'end';

                return (
                <g>
                    <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
                        {payload.city}
                    </text>
                    <Sector
                        cx={cx}
                        cy={cy}
                        innerRadius={innerRadius}
                        outerRadius={outerRadius}
                        startAngle={startAngle}
                        endAngle={endAngle}
                        fill={fill}
                    />
                    <Sector
                        cx={cx}
                        cy={cy}
                        startAngle={startAngle}
                        endAngle={endAngle}
                        innerRadius={outerRadius + 6}
                        outerRadius={outerRadius + 10}
                        fill={fill}
                    />
                        <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
                        <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
                        <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#333">{` ${value.toLocaleString()}`}</text>
                        <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill="#999">
                        {/* {`(Rate ${(percent * 100).toFixed(2)}%)`} */}
                    </text>
                    </g>
                );
            };

                const { activeIndex } = this.state

                const onPieEnter = (_:any, index: any) => {
                    this.setState({
                    activeIndex: index,
                });
            };
            
            return(
                <div className='region-sigungu-chart-view' style={{width: '350px'}}>
                    <PieChart width={380} height={400}>
                        <Pie activeIndex={this.state.activeIndex} activeShape={renderActiveShape} data={this.state.chartData} cx="54%" cy="35%" innerRadius={60} outerRadius={100} dataKey="value" onMouseEnter={onPieEnter}>
                            {this.state.chartData.map((entry, index) => (<Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]}/>))}</Pie>
                    </PieChart>
                </div>
            )
        }
    }

export default RegionSigunguChart;

