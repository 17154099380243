import React from 'react';
import"../../css/terms.css";


interface Props {
}

interface State {
}


class TermsOfUse extends React.Component<Props, State> {
  //

  render() {
    //
    return (
      <>
        <div id='email' className='main' style={{ padding: "0 18% 15.4% 18% " }}>
          <div className="public-location layout-container-publicCenter">
            <div className="layout-container-publicCenter layout-public-contents">
              <div>

              <div className="public-title">
                <h2 className="title">
                  <strong className="b">전자우편무단수집거부</strong>
                </h2>
              </div>
              <div className="layout-lineHeight public-service-wrap layout-mt-20">
                <div className="layout-mt-36">
                  
                  <ul style={{ listStyle: 'inherit' }}>
                    <li>
                      본 웹사이트에 게시된 전자우편주소가 전자우편수집 프로그램이나 그 밖의 기술적 장치를 이용하여 무단으로 수집되는 것을 거부하며, 이를 위반시 정보통신망법에 의해 형사처벌됨을 유념하시기 바랍니다.
                      [게시일 2022년 03월 28일]
                    </li>
                    <li>
                      이메일을 기술적 장치를 사용하여 무단으로 수집·판매·유통하거나 이를 이용한 자는「정보통신망이용촉진및정보보호등에관한법률」 제50조의2 규정에 의하여 1천만원 이하의 벌금형에 처해집니다.
                    </li>
                    <li>
                      정보통신망법 제50조의2 (전자우편주소의 무단 수집행위 등 금지)<br />
                      <ul style={{ listStyle: 'none' }}>
                        <li>
                          - 누구든지 전자우편주소의 수집을 거부하는 의사가 명시된 인터넷 홈페이지에서 자동으로 전자우편주소를 수집하는 프로그램 그 밖의 기술적 장치를 이용하여 전자우편주소를 수집하여서는 아니된다.<br />
                        </li>
                        <li>
                          - 누구든지 제1항의 규정을 위반하여 수집된 전자우편주소를 판매·유통하여서는 아니된다.<br />
                        </li>
                        <li>
                          - 누구든지 제1항 및 제2항의 규정에 의하여 수집·판매 및 유통이 금지된 전자우편주소임을 알고 이를 정보전송에 이용하여서는 아니된다<br />
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      </>
    );
  }
}

export default TermsOfUse;