import React, { createRef, RefObject } from "react";
import "../../css/common.css";
import { ContentType } from "../../model/type/ContentType";
import FormalContentsDetails from "./FormalContentsDetails";
import ImageContentsDetails from "./ImageContentsDetails";
import VideoContentsDetail from "./VideoContentsDetail";
import CasualContentsDetails from "./CasualContentsDetails";
import CourseContentsDetails from "./CourseContentsDetails";
import FileContentsDetails from "./FileContentsDetails";

interface Props {
    themeDetailObject: {actorId:string, audienceId:string, bannerImgPath:string, children:string, cineroomId:string, cineroomName:string, citizenId:string, cmsCategoryId:string, elementTypeId:string, entityVersion:string,
        existChild:boolean, folderType:boolean, id:string, idPaths:[], imagePath:string, langUsed:boolean, lastUpdateTime:number, modificationTime:number, lastUpdater: {id:string, name:string}, marketingType:string,
        name:string, registrationTime:number, type:string, used:boolean, writer:{id:string, name:string} , pavilionId: string, parentId: string, originCategoryId: string, originFolderId: string, seq: number, publicType: boolean
    },
    cid: string;
    selectedContentType: string;
    onCloseModal: () => void;
    onClickThemeCloseModal: () => void;
    eventSetSelected: () => void;
    isSelected: boolean;
    viewWidth: number | undefined;
    contentsType: string;
    showImage?: () => void;
}

interface State {}

class ThemeContentsDetails extends React.Component<Props, State> {

  static defaultProps: any = {};
  private scrollDivRef: RefObject<HTMLDivElement>;

  constructor(props: Props) {
    super(props);
    this.scrollDivRef = createRef<HTMLDivElement>();
  }
  //@ts-ignore
  state: State = {};

  componentDidMount() {
  }

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
    snapshot?: any
  ): void {}

  renderContentDetail() {
    const { selectedContentType, onClickThemeCloseModal, onCloseModal, eventSetSelected, viewWidth, isSelected} = this.props;
    const { cid, contentsType, showImage } = this.props;

    switch(selectedContentType) {
      case ContentType.FormalContents:
        return <FormalContentsDetails scrollToTop={this.scrollToTop} 
            showImage={showImage} cid={cid} contentsType={contentsType} onCloseModal={onCloseModal} onClickThemeCloseModal={onClickThemeCloseModal}
            eventSetSelected={eventSetSelected} viewWidth={viewWidth} isSelected={isSelected}
          />
      case ContentType.ImageContents:
        return <ImageContentsDetails scrollToTop={this.scrollToTop} showImage={showImage} cid={cid} contentsType={contentsType} onCloseModal={onCloseModal} onClickThemeCloseModal={onClickThemeCloseModal} eventSetSelected={eventSetSelected} viewWidth={viewWidth} isSelected={isSelected}/>
      case ContentType.VideoContents:
        return <VideoContentsDetail scrollToTop={this.scrollToTop} showImage={showImage} cid={cid} contentsType={contentsType} onCloseModal={onCloseModal} onClickThemeCloseModal={onClickThemeCloseModal} eventSetSelected={eventSetSelected} viewWidth={viewWidth} isSelected={isSelected}/>
      case ContentType.CasualContents:
        return <CasualContentsDetails scrollToTop={this.scrollToTop} showImage={showImage} cid={cid} contentsType={contentsType} onCloseModal={onCloseModal} onClickThemeCloseModal={onClickThemeCloseModal} eventSetSelected={eventSetSelected} viewWidth={viewWidth} isSelected={isSelected}/>
      case ContentType.CourseContents:
        return <CourseContentsDetails scrollToTop={this.scrollToTop} showImage={showImage} cid={cid} contentsType={contentsType} onCloseModal={onCloseModal} onClickThemeCloseModal={onClickThemeCloseModal} eventSetSelected={eventSetSelected} viewWidth={viewWidth} isSelected={isSelected}/>
      // File 추가 작업 필요함
      // case ContentType.FileContents:
      //   return <FileContentsDetails/>
    }
  }

  renderThemeDetailView() {
    const { viewWidth, isSelected } = this.props
    return (
      <>
        {/* @ts-ignore */}
        <div className="right rd_view_content" style={isSelected && 1024 >= viewWidth ? {display: 'none'} : undefined}
          ref={this.scrollDivRef}
        >
          {this.renderContentDetail()}
        </div>
      </>
    )
  }

  scrollToTop = () => {
    if (this.scrollDivRef.current) {
      this.scrollDivRef.current.scrollTop = 0;
    }
  };

  renderThemeDetailPageView() {
    const { viewWidth, isSelected } = this.props
    return (
      <>
        {/* @ts-ignore */}
        <div className="right rd_view_content" style={isSelected && 1024 >= viewWidth ? {display: 'none'} : undefined}>
          {this.renderContentDetail()}
        </div>
      </>
    )
  }

  render() {
    const { contentsType } = this.props;
    return (
        <>
        {contentsType === 'ThemeContents' ? 
          this.renderThemeDetailPageView() : this.renderThemeDetailView()
        }
        </>
    )
  }
}

export default ThemeContentsDetails;
