import spec from './kollection.json';

export const StageRoleType = {
    AccountManager: spec.kollection.stageRoles.AccountManager.code,
    AccountReviewer: spec.kollection.stageRoles.AccountReviewer.code,
    GroupManager: spec.kollection.stageRoles.GroupManager.code,
    ContentsCreator: spec.kollection.stageRoles.ContentsCreator.code,
    TourismManager: spec.kollection.stageRoles.TourismManager.code,
    ContentsReviewer: spec.kollection.stageRoles.ContentsReviewer.code,
    ContentsApprover: spec.kollection.stageRoles.ContentsApprover.code,
    ContentsDeployer: spec.kollection.stageRoles.ContentsDeployer.code,
    ReferenceModelManager: spec.kollection.stageRoles.ReferenceModelManager.code,
    ChannelModelManager: spec.kollection.stageRoles.ChannelModelManager.code,
    ServiceManager: spec.kollection.stageRoles.ServiceManager.code,
    Developer: spec.kollection.stageRoles.Developer.code,
};