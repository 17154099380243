import RetainedVersion from '../../comp/api/filebox/api-model/vo/RetainedVersion';
import NameValueList from '../../model/NameValue/NameValueList';
import fromDomain from '../decorator/fromDomain';
import DomainEntity from '../domain/ddd/DomainEntity';


@fromDomain
class DepotFile extends DomainEntity {
  name: string;
  size: number;
  sequence: number;
  vaultFileId: string;
  retainedVersion: RetainedVersion | null = null;
  depotId: string;
  ownerId: string;
  time: number;
  modifyTime: number;
  deleted: boolean;

  /* for ui */
  path: string = '';
  selected: boolean = false;
  type: string = 'file';

  /* for ElasticSearch */
  deploy: boolean;

  constructor(
    name: string,
    size: number,
    sequence: number,
    vaultFileId: string,
    depotId: string,
    ownerId: string,
    time: number,
    modifyTime: number,
    deleted: boolean,
    deploy: boolean,
  ) {
    super();
    this.name = name;
    this.size = size;
    this.sequence = sequence;
    this.vaultFileId = vaultFileId;
    this.depotId = depotId;
    this.ownerId = ownerId;
    this.time = time;
    this.modifyTime = modifyTime;
    this.deleted = deleted;
    this.deploy = deploy;
  }

  static fromDomain(domain: DepotFile): DepotFile {
    const depotFile = new DepotFile(
      domain.name,
      domain.size,
      domain.sequence,
      domain.vaultFileId,
      domain.depotId,
      domain.ownerId,
      domain.time,
      domain.modifyTime,
      domain.deleted,
      domain.deploy,
    );

    depotFile.setDomainEntity(domain);
    depotFile.retainedVersion = domain.retainedVersion ? RetainedVersion.fromDomain(domain.retainedVersion) : null;
    return depotFile;
  }

  static toDepotFile(folder: any): DepotFile {
    const depotFile = new DepotFile(
      folder.name,
      folder.size,
      folder.sequence,
      folder.vaultFileId,
      folder.depotId,
      folder.ownerId,
      folder.time,
      folder.modifyTime,
      folder.deleted,
      folder.deploy,
    );

    depotFile.setDomainEntity(folder);
    depotFile.retainedVersion = folder.retainedVersion ? RetainedVersion.fromDomain(folder.retainedVersion) : null;
    depotFile.type = 'folder';

    return depotFile;
  }


  static fromDomains(domains: DepotFile[]): DepotFile[] {
    return domains.map(domain => this.fromDomain(domain));
  }

  static asNameValues(depotFile: DepotFile) {
    return NameValueList.fromModel(DepotFile, depotFile, {
      retainedVersion: JSON,
      sequence: String,
      name: String,
    });
  }

}

export default DepotFile;
