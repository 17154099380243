import { OrgType } from "../types/OrgType";

interface Props {
    step: number;
    orgType: OrgType | null;
}

export const JoinStep = (props: Props) => {

    const renderingStep = (step: number) => {
        switch(step) {
            case 0: 
                return (
                    <>
                        <div className="location location-1">
                            <div className="location-base"></div>
                            <ul>
                                <li></li>
                                <li></li>
                            </ul>
                        </div>
                    </>
                );
            case 1:
                return(
                    <>
                        <div className="location location-2">
                            <div className="location-base"></div>
                            <ul>
                                <li className="active"></li>
                                <li></li>
                                <li></li>
                            </ul>
                        </div>
                    </>
                );
            case 2:
                return(
                    <>
                        <div className="location location-2">
                            <div className="location-base"></div>
                            <ul>
                                <li className="active" style={{width: '50%'}}></li>
                                <li></li>
                            </ul>
                        </div>
                    </>
                );
            case 5:
                return(<>
                        <div className="location location-2">
                            <div className="location-base"></div>
                            <ul>
                                <li className="active" style={{width: '50%'}}></li>
                                <li className="active" style={{width: '50%'}}></li>
                            </ul>
                        </div>
                </>);
            case 6:
                return(<>{renderJoinComplete()}</>);
            default:
                return(
                    <>
                        <div className="location location-2">
                            <div className="location-base"></div>
                            <ul>
                                <li className="active"></li>
                                <li className="active"></li>
                                <li></li>
                            </ul>
                        </div>
                    </>
                );
        }
    }

    const renderJoinComplete = () => {
        return(
            <>
                <div className="location location-2">
                    <div className="location-base"></div>
                    <ul>
                        <li className="active"></li>
                        <li className="active"></li>
                        <li className="active"></li>
                    </ul>
                </div>
            </>
        );
    }
    
    return(
        <>
            {renderingStep(props.step)}
            {
                props.orgType === OrgType.None
                ? <div className="location-text">
                    <ul>
                        <li>약관동의</li>
                        <li>상세정보 입력</li>
                        <li>작성완료</li>
                    </ul>
                </div>
                : <div className="location-text">
                    <ul>
                        <li>약관동의</li>
                        <li>기업유형 선택</li>
                        <li>상세정보 입력</li>
                        <li>작성완료</li>
                    </ul>
                </div>
            }
        </>
    );
}