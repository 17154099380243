import makeExtendedObservable from "../decorator/makeExtendedObservable";

class GeoCoordinate {
  latitude: string;
  longitude: string;

  constructor(latitude: string, longitude: string) {
    this.latitude = latitude;
    this.longitude = longitude;
    makeExtendedObservable(this);
  }

  static fromDomain(domain: GeoCoordinate): GeoCoordinate {
    return new GeoCoordinate(
      domain.latitude,
      domain.longitude
    );
  }

  static fromDomains(domains: GeoCoordinate[]): GeoCoordinate[] {
    return domains.map(domain => this.fromDomain(domain));
  }

  static new() {
    return new GeoCoordinate('', '');
  }
}

export default GeoCoordinate;
