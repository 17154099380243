import React from "react";
import { ContentType } from "../model/type/ContentType";
import { MarketingType } from "../model/type/MarketingType";
import {SortDirection} from "../model/type/SearchType";

interface Props {
  title: string;
  totalCount: number;
  // type1 || type2
  type: string;
  infoObject: {
    cid: string,
    contentsName: string,
    category: string,
    categoryPath: string,
    modificationTime: string,
    language: string,
    imagePathList: string[],
    intro: string,
    address: string,
    phoneNumber: string,
    parking: string,
    usageFee: string,
    restDate: string,
    operatingTime: string,
    menu: string,
    saleProduct: string,
    checkInTime: string,
    checkOutTime: string,
    festivalPeriod: string,
    homepage: string
  }[];
  casualInfoObject: {
    cid: string,
    contentsName: string,
    tid: string,
    tourismName: string,
    categoryPath: string,
    imagePath: string
  }[];
  courseInfoObject: {
    cid: string,
    contentsName: string,
    totalDistance: string,
    duration: string,
    imagePath: string
  }[];
  setSelectedCid: (cid : string) => void;
  setSortDirection: (sortDirection: string) => void;
  contentsClickMoreView: (title: string, marketingType: string) => void;
  onClickDetailModal: () => void;
  moreInfoTitle: string;
  selectedSortDirection: string;
}

interface State {
  selectedCid: string;
  contentType: ContentType
}

class InfoCard extends React.Component<Props, State> {

  constructor(props:any) {
    super(props);
    this.openDetail = this.openDetail.bind(this)
    this.eventSetCategory = this.eventSetCategory.bind(this)
  }

  //@ts-ignore
  state: State = {
    selectedCid: '',
  };

  //@ts-ignore
  static defaultProps: Props = {
    title: '',
    totalCount: 0,
    type: "type1",
    infoObject: [],
    casualInfoObject: [],
    // contentsClickMoreView: () => {}
  };

  openDetail(){
    this.props.onClickDetailModal()
  }

  eventSetSelectedCid(cid : string) {
    this.props.setSelectedCid(cid);
  }

  eventSetSortDirection(sortDirection: string) {
    this.props.setSortDirection(sortDirection);
  }

  eventSetCategory(title : string, marketingType: string){
    this.props.contentsClickMoreView(title, marketingType)
  }

  getPlusButton() {
    const { title, totalCount } = this.props

    if(title === '숙박' && totalCount > 4) {
      return (
        <span className="view_more_bt" style={{fontSize: '16px', fontWeight: 500, color: '#313133', padding: '4px 0 0 0', cursor: 'pointer'}} onClick={() => this.eventSetCategory('숙박', MarketingType.AC)}>
          <a className="view_more_bt">{`더보기(${totalCount})`}<img src="/rms-conlab/images/icon_move_next_s.png" alt="" style={{position: 'relative', top: '-1px'}}/></a>
        </span>
      );
    } else if(title === '문화관광' && totalCount > 4) {
      return (
        <span className="view_more_bt" style={{fontSize: '16px', fontWeight: 500, color: '#313133', padding: '4px 0 0 0', cursor: 'pointer'}} onClick={() => this.eventSetCategory('문화관광', MarketingType.VE)}>
          <a className="view_more_bt">{`더보기(${totalCount})`}<img src="/rms-conlab/images/icon_move_next_s.png" alt="" style={{position: 'relative', top: '-1px'}}/></a>
        </span>
      );
    } else if(title === '체험관광' && totalCount > 4) {
      return (
        <span className="view_more_bt" style={{fontSize: '16px', fontWeight: 500, color: '#313133', padding: '4px 0 0 0', cursor: 'pointer'}} onClick={() => this.eventSetCategory('체험관광', MarketingType.EX)}>
          <a className="view_more_bt">{`더보기(${totalCount})`}<img src="/rms-conlab/images/icon_move_next_s.png" alt="" style={{position: 'relative', top: '-1px'}}/></a>
        </span>
      );
    } else if(title === '축제・공연・행사' && totalCount > 4) {
      return (
        <span className="view_more_bt" style={{fontSize: '16px', fontWeight: 500, color: '#313133', padding: '4px 0 0 0', cursor: 'pointer'}} onClick={() => this.eventSetCategory('축제・공연・행사', MarketingType.EV)}>
          <a className="view_more_bt">{`더보기(${totalCount})`}<img src="/rms-conlab/images/icon_move_next_s.png" alt="" style={{position: 'relative', top: '-1px'}}/></a>
        </span>
      );
    } else if(title === '음식' && totalCount > 4) {
      return (
        <span className="view_more_bt" style={{fontSize: '16px', fontWeight: 500, color: '#313133', padding: '4px 0 0 0', cursor: 'pointer'}} onClick={() => this.eventSetCategory('음식', MarketingType.FD)}>
          <a className="view_more_bt">{`더보기(${totalCount})`}<img src="/rms-conlab/images/icon_move_next_s.png" alt="" style={{position: 'relative', top: '-1px'}}/></a>
        </span>
      );
    } else if(title === '역사관광' && totalCount > 4) {
      return (
        <span className="view_more_bt" style={{fontSize: '16px', fontWeight: 500, color: '#313133', padding: '4px 0 0 0', cursor: 'pointer'}} onClick={() => this.eventSetCategory('역사관광', MarketingType.HS)}>
          <a className="view_more_bt">{`더보기(${totalCount})`}<img src="/rms-conlab/images/icon_move_next_s.png" alt="" style={{position: 'relative', top: '-1px'}}/></a>
        </span>
      )
    } else if(title === '레저스포츠' && totalCount > 4) {
      return (
        <span className="view_more_bt" style={{fontSize: '16px', fontWeight: 500, color: '#313133', padding: '4px 0 0 0', cursor: 'pointer'}} onClick={() => this.eventSetCategory('레저스포츠', MarketingType.LS)}>
          <a className="view_more_bt">{`더보기(${totalCount})`}<img src="/rms-conlab/images/icon_move_next_s.png" alt="" style={{position: 'relative', top: '-1px'}}/></a>
        </span>
      );
    } else if(title === '자연관광' && totalCount > 4) {
      return (
        <span className="view_more_bt" style={{fontSize: '16px', fontWeight: 500, color: '#313133', padding: '4px 0 0 0', cursor: 'pointer'}} onClick={() => this.eventSetCategory('자연관광', MarketingType.NA)}>
          <a className="view_more_bt">{`더보기(${totalCount})`}<img src="/rms-conlab/images/icon_move_next_s.png" alt="" style={{position: 'relative', top: '-1px'}}/></a>
        </span>
      );
    } else if(title === '쇼핑' && totalCount > 4) {
      return (
        <span className="view_more_bt" style={{fontSize: '16px', fontWeight: 500, color: '#313133', padding: '4px 0 0 0', cursor: 'pointer'}} onClick={() => this.eventSetCategory('쇼핑', MarketingType.SH)}>
          <a className="view_more_bt">{`더보기(${totalCount})`}<img src="/rms-conlab/images/icon_move_next_s.png" alt="" style={{position: 'relative', top: '-1px'}}/></a>
        </span>
      );
    }
    return null;
  }

  componentDidMount() {}

  renderInfoCard() {
    const { title, infoObject, type, moreInfoTitle, selectedSortDirection } = this.props;

    return (
      <>
        <div style={{display: 'flex', justifyContent: 'space-between', marginTop: '30px'}}>
          <h3 className="tourist_Info_tit">{title}</h3>
          {moreInfoTitle && (
              <>
                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end'}}>
                  <a className="st_icon" style={{
                    marginLeft: '20px',
                    cursor: 'pointer',
                    fontWeight: `${selectedSortDirection === 'Relevance' ? 'bold' : ''}`
                  }}
                          onClick={() => this.eventSetSortDirection(SortDirection.Relevance)}>▼ 관련도순
                  </a>
                  <a className="st_icon" style={{
                    marginLeft: '20px',
                    cursor: 'pointer',
                    fontWeight: `${selectedSortDirection === 'Latest' ? 'bold' : ''}`
                  }}
                          onClick={() => this.eventSetSortDirection(SortDirection.Latest)}>▼ 최신순
                  </a>
                </div>
              </>
          )}
          {this.getPlusButton()}
        </div>
        <div className={`tourist_Info_box_wrap ${type === "type2" ? 'divide2' : undefined}`}>
          {infoObject.map((obj: any, index: number) => {
            return (
                <div className="tourist_Info_box" key={index}>
                  <a>
                    <div className="Info_box" style={obj.imagePathList.length > 0 ? {width: '45%'} : {width: '100%'}}>
                      <div className="tit_area">
                        <h4 draggable={true} style={{
                          textOverflow: 'ellipsis',
                          overflow: 'hidden',
                          whiteSpace: 'nowrap',
                          width: '100%',
                          cursor: 'pointer',
                          userSelect: 'text'
                        }} onClick={() => {this.eventSetSelectedCid(obj.cid); this.openDetail();}}>{obj.contentsName}</h4>
                      <ul className="nav_info">
                        {obj.categoryPath?.split(">").map((obj: string, index: number) => {
                          return (
                            <li key={index}>{obj}</li>
                          );
                        })}
                      </ul>
                    </div>

                    <div className="info_list_wrap" style={{width: '100%%'}}>
                      <ul className="info_list">
                        {/* 공통 START */}
                        {obj.address && (
                        <li>
                          <p>
                            주소<span className="line"></span>{obj.address}
                          </p>
                        </li>)}
                        {obj.phoneNumber && (
                        <li>
                          <p>
                            전화번호<span className="line"></span>{obj.phoneNumber}
                          </p>
                        </li>)}
                        {/* 공통 END */}

                        {title === "축제・공연・행사" && obj.festivalPeriod && (
                        <li>
                          <p>
                          축제기간<span className="line"></span>{obj.festivalPeriod}
                          </p>
                        </li>)}

                        {obj.menu && (
                        <li>
                          <p>
                          대표메뉴<span className="line"></span>{obj.menu}
                          </p>
                        </li>)}

                        {obj.saleProduct && (
                        <li>
                          <p>
                          판매품목<span className="line"></span>{obj.saleProduct}
                          </p>
                        </li>)}

                        {obj.operatingTime && (
                        <li>
                          <p>
                            {title === "음식" || title === "쇼핑" || title === "숙박" ? "운영시간" : "이용시간"}
                            <span className="line"></span>{obj.operatingTime}
                          </p>
                        </li>)}
                      </ul>
                      <ul className="info_list">
                        {title !== "음식" && title !== "쇼핑" && title !== "숙박" && obj.parking && (
                        <li>
                          <p>
                            주차장 유무<span className="line"></span>
                            {obj.parking}
                          </p>
                        </li>)}
                        {title !== "음식" && title !== "쇼핑" && title !== "숙박" && title !== "레저스포츠" && title !== "문화관광" && obj.usageFee && (
                        <li>
                          <p>
                            이용요금<span className="line"></span>
                            {obj.usageFee}
                          </p>
                        </li>)}
                        {title === "역사관광" && obj.restDate && (
                        <li>
                          <p>
                            휴무일<span className="line"></span>{obj.restDate}
                          </p>
                        </li>)}
                      </ul>
                    </div>
                    {/* {type === "type1" && (
                      <p className="info_txt" style={obj.imagePathList.length > 0 ? {textOverflow: 'ellipsis'} : {textOverflow: 'unset', width: '100%'}}>
                        {obj.intro}
                      </p>
                    )} */}
                  </div>

                  <div className="img_box">

                    {obj.imagePathList.length === 0 && type === "type2" ? <img style={{width: '190.41px', height: '127px'}} src="/rms-conlab/images/alternative.svg"/> :
                      obj.imagePathList?.map((obj:any, index: number) => {
                        const { type } = this.props;

                        if (index > 2) {
                          return null;
                        }

                        if (type === "type2" && index > 0) {
                          return null;
                        }

                        return (
                          <img
                            key={index}
                            src={obj+'/it7'}
                            alt="img"
                            onContextMenu={(e) => (e.preventDefault())}
                            onError={(error) => {
                              error.currentTarget.src = this.defaultImage();
                            }}
                          />
                          );
                        })
                      }
                  </div>
                </a>
              </div>
            );
          })}
        </div>
      </>
    );
  }

  renderCasualInfoCard() {
    const { title, casualInfoObject, type, selectedSortDirection } = this.props;

    return (
      <>
        <div>
          <h3 className="tourist_Info_tit">{title}</h3>
          <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end'}}>
            <a className="st_icon" style={{
              marginLeft: '20px',
              cursor: 'pointer',
              fontWeight: `${selectedSortDirection === 'Relevance' ? 'bold' : ''}`
            }}
                    onClick={() => this.eventSetSortDirection(SortDirection.Relevance)}>▼ 관련도순
            </a>
            <a className="st_icon" style={{
              marginLeft: '20px',
              cursor: 'pointer',
              fontWeight: `${selectedSortDirection === 'Latest' ? 'bold' : ''}`
            }}
                    onClick={() => this.eventSetSortDirection(SortDirection.Latest)}>▼ 최신순
            </a>
          </div>
        </div>
        <div className={`tourist_Info_box_wrap divide2`}>
          {casualInfoObject.map((obj: any, index: number) => {
            return (
                <div className="tourist_Info_box" key={index}>
                  <a>
                    <div className="Info_box">
                      <div className="tit_area">
                        <h4 draggable={true} onClick={() => {
                          this.eventSetSelectedCid(obj.cid);
                          this.openDetail();
                        }} style={{cursor: 'pointer', userSelect: 'text'}}>{obj.contentsName}</h4>
                        <ul className="nav_info">
                          {obj.categoryPath?.split(">").map((obj: string, index: number) => {
                            return (
                                <li key={index}>{obj}</li>
                          );
                        })}
                      </ul>
                    </div>

                    <div className="info_list_wrap">
                      <ul className="info_list">
                        {obj.tourismName && (
                        <li>
                          <p>
                            관광지명<span className="line"></span>{obj.tourismName}
                          </p>
                        </li>)}
                      </ul>
                    </div>

                  </div>
                  <div className="img_box">
                    <img
                      src={obj.imagePath}
                      alt="img"
                      onContextMenu={(e) => (e.preventDefault())}
                      onError={(error) => {
                        error.currentTarget.src = this.defaultImage();
                      }}
                    />
                  </div>
                </a>
              </div>
            );
          })}
        </div>
      </>
    );
  }

  defaultImage() {
    const defaultImage = `/rms-conlab/images/alternative_283x188.svg`;
    return defaultImage;
  }

  renderCourseInfoCard() {
    const { title, courseInfoObject, type, selectedSortDirection } = this.props;

    return (
        <>
          <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end'}}>
            <a className="st_icon" style={{
              marginLeft: '20px',
              cursor: 'pointer',
              fontWeight: `${selectedSortDirection === 'Relevance' ? 'bold' : ''}`
            }}
                    onClick={() => this.eventSetSortDirection(SortDirection.Relevance)}>▼ 관련도순
            </a>
            <a className="st_icon" style={{
              marginLeft: '20px',
              cursor: 'pointer',
              fontWeight: `${selectedSortDirection === 'Latest' ? 'bold' : ''}`
            }}
                    onClick={() => this.eventSetSortDirection(SortDirection.Latest)}>▼ 최신순
            </a>
          </div>
          <h3 className="tourist_Info_tit">{title}</h3>
          <div className={`tourist_Info_box_wrap divide2`}>
            {courseInfoObject.map((obj: any, index: number) => {
              return (
                  <div className="tourist_Info_box" key={index}>
                    <a>
                      <div className="Info_box" style={{paddingBottom: '32px', paddingRight: '10px'}}>
                        <div className="tit_area">
                          <h4 draggable={true} style={{
                            userSelect: 'text',
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            width: '100%',
                            cursor: 'pointer'
                          }} onClick={() => {
                            this.eventSetSelectedCid(obj.cid);
                            this.openDetail();
                          }}>{obj.contentsName}</h4>
                          <ul className="nav_info">
                            {obj.categoryPath?.split(">").map((obj: string, index: number) => {
                              return (
                                  <li key={index}>{obj}</li>
                              );
                            })}
                          </ul>
                        </div>

                        <div className="info_list_wrap">
                          <ul className="info_list">
                            {obj.totalDistance && (
                                <li>
                                  <p>
                                    총거리<span className="line"></span>{obj.totalDistance}
                                  </p>
                                </li>)}
                            {obj.totalDistance && (
                                <li>
                                  <p>
                                    소요시간<span className="line"></span>{obj.duration}
                                  </p>
                                </li>)}
                          </ul>
                        </div>

                      </div>
                      <div className="img_box">
                        <img
                            src={obj.imagePath}
                            alt="img"
                            onContextMenu={(e) => (e.preventDefault())}
                            onError={(error) => {
                              error.currentTarget.src = this.defaultImage();
                            }}
                        />
                      </div>
                    </a>
                  </div>
              );
            })}
          </div>
        </>
    );
  }

  render() {
    const {infoObject, casualInfoObject, courseInfoObject, type} = this.props;
    const {selectedCid} = this.state;
    return (
        <>
          {(infoObject.length > 0 && type !== "type3") && this.renderInfoCard()}
          {(casualInfoObject.length > 0 && type === "type3") && (this.renderCasualInfoCard())}
          {(courseInfoObject?.length > 0) && (this.renderCourseInfoCard())}
        </>
    );
  }
}

export default InfoCard;
