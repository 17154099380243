import ApiMessage from '../core/model/api/ApiMessage';
import CommandResponse from './CommandResponse';


class CqrsCommand extends ApiMessage<CommandResponse> {
  //
  constructor() {
    super(CommandResponse, 'response');
  }

  get response(): CommandResponse | null {
    //
    return this.getNullableModel();
  }
}

export default CqrsCommand;
