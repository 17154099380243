/**
 * JusoAPI 검색시 요청 변수 정보를 담을 목적의 클래스입니다.
 */

import makeExtendedObservable from "../../../lib/decorator/makeExtendedObservable";

class AddressCode {
  admCd: string;      //행정구역코드
  rnMgtSn: string;    //도로명코드
  udrtYn: string;     //지하여부
  buldMnnm: number;   //건물본번
  buldSlno: number;   //건물부번

  constructor(
    admCd: string,
    rnMgtSn: string,
    udrtYn: string,
    buldMnnm: number,
    buldSlno: number
  ) {
    this.admCd = admCd;
    this.rnMgtSn = rnMgtSn;
    this.udrtYn = udrtYn;
    this.buldMnnm = buldMnnm;
    this.buldSlno = buldSlno;
    makeExtendedObservable(this);
  }

  static fromDomain(domain: AddressCode): AddressCode {
    return new AddressCode(
      domain.admCd,
      domain.rnMgtSn,
      domain.udrtYn,
      domain.buldMnnm,
      domain.buldSlno,
    );
  }

  static fromDomains(domains: AddressCode[]): AddressCode[] {
    return domains.map(domain => this.fromDomain(domain));
  }

}

export default AddressCode;
