import React from 'react';
import CitizenUser from '../model/User/CitizenUser';
import DockNode from '../shared/api-model/DockNode';
import ActorIdentity from '../model/User/ActorIdentity';
import { TopSns } from '../model/type/TopSnsType';

type CommonContextModel = {
    isLogin: boolean;
    isOpenLoginModal: boolean;
    citizenUser: CitizenUser;
    actorIdentity: ActorIdentity;
    handleLoginState: (value: boolean) => void;
    openLoginModal: () => void;
    closeLoginModal: () => void;
    refreshTopStorage: () => void;
    handleCheckProfileState: (value: boolean) => void;
    isCheckedProfile: boolean;
    accessibleCinerooms: DockNode[];
    accessibleCasts: DockNode[];
    loginSnsKey: TopSns | null;
    openModal: boolean;
    modalText: string;
    closeModal: () => void;
    open: undefined | 'open';
    closeTopLoginModal: () => void;
    onClickTopLoginModalOpen: () => void;
    openTopLoginModal: boolean;
    topLoginFail: boolean;
    onClickTopLogin: (event: any, id: string, pw: string) => void;
    loginFailText: string;
    closeLoginFailModal: () => void;
    topLoginCloseModal: () => void;
};

const CommonContext = React.createContext<CommonContextModel>({
    isLogin: false,
    isOpenLoginModal: false,
    citizenUser: CitizenUser.new(),
    actorIdentity: ActorIdentity.new(),
    handleLoginState: () => {},
    openLoginModal: () => {},
    closeLoginModal: () => {},
    refreshTopStorage: () => {},
    handleCheckProfileState: () => {},
    isCheckedProfile: false,
    accessibleCinerooms: [],
    accessibleCasts: [],
    loginSnsKey: null,
    openModal: false,
    modalText: '',
    closeModal: () => {},
    open: undefined,
    closeTopLoginModal: () => {},
    onClickTopLoginModalOpen: () => {},
    openTopLoginModal: false,
    topLoginFail: false,
    onClickTopLogin: (event: any, id: string, pw: string) => {}, 
    loginFailText: '',
    closeLoginFailModal: () => {},
    topLoginCloseModal: () => {},
});

export default CommonContext;
export type { CommonContextModel };
