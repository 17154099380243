export function fadeIn(el: any, display = "block") {
  el.style.opacity = 0;
  el.style.display = display || "block";
  (function fade() {
      let val = parseFloat(el.style.opacity);
      if (!((val += .1) > 1)) {
          el.style.opacity = val;
          requestAnimationFrame(fade);
      }
  })();
}

export function  fadeOut(el: any) {
  el.style.opacity = 1;
    (function fade() {
        if ((el.style.opacity -= .1) < 0) {
            el.style.display = "none";
        } else {
            requestAnimationFrame(fade);
        }
    })();
}