enum UsageType {
  ViewCount = 'ViewCount',
  InterestAdded = 'InterestAdded',
  InterestRemoved = 'InterestRemoved',
  PdfDownloaded = 'PdfDownloaded',
  LinkCreated = 'LinkCreated',
  ImageDownloaded = 'ImageDownloaded',
  ExcelDownloaded = 'ExcelDownloaded'
}

export { UsageType };

