import CqrsCommand from "../../../../../../lib/cqrs/command/CqrsCommand";
import makeExtendedObservable from "../../../../../../lib/decorator/makeExtendedObservable";
import NameValueList from "../../../../../../model/NameValue/NameValueList";
import Notie from "../Notie";

class PushPersonalNotieCommand extends CqrsCommand {
  //
  // meta info
  senderKey: string;       // citizenId || publicServantId
  senderName: string;      // name
  receiverKey: string;  // [citizenId || publicServantId] || [topicId]
  // payload
  title: string;
  content: string;
  additionalInfo: NameValueList;

  constructor(senderKey: string, senderName: string, receiverKey: string, title?: string, content?: string, additionalInfo?: NameValueList) {
    super();
    this.senderKey = senderKey;
    this.senderName = senderName;
    this.receiverKey = receiverKey;
    this.title = title || '';
    this.content = content || '';
    this.additionalInfo = additionalInfo || new NameValueList();
    makeExtendedObservable(this);
  }

  static fromNotie(notie: Notie) {
    const command = new PushPersonalNotieCommand(
      notie.senderKey,
      notie.senderName,
      notie.destinationKey,
      notie.title,
      notie.content,
      notie.additionalInfo
    );
    return command;
  }

}

export default PushPersonalNotieCommand;
