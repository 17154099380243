import React from 'react';

export function renderResultNotFound() {
  return (
    <div className="no_data_wrap">
      <div className="no_data">
        <img
          src="/rms-conlab/images/no_data.png"
          alt="검색 결과가 없습니다."
        />
        <p className="no_data_txt">검색 결과가 없습니다.</p>
      </div>
    </div>
  );
}
