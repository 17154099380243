import makeExtendedObservable from '../../../lib/decorator/makeExtendedObservable';
import AddressOn from '../../../lib/granule/AddressOn';
import Phone from '../../../lib/granule/Phone';
import validationUtils from '../../../lib/module/validationUtils/validationUtils';
import { OrgType } from '../../types/OrgType';
import OrgProposal from '../OrgProposal';



class OrgProposalCdo {
  name: string;
  licenceId: string;
  // representative: string;

  manager: string;
  managerEmail: string;
  emailKey: string;
  code: string;
  managerTel: Phone | null = null;

  address: AddressOn | null = null;
  orgTel: Phone | null = null;
  homePageUrl: string;

  department: string;
  departmentTel: Phone | null = null;

  requesterId: string;
  password: string;

  orgType: OrgType | null = null;

  fileboxId: string;
  marketingTime: number;
  marketingYn: boolean;
  // fileIds: string[];

  constructor(
    name: string,
    licenceId: string,
    manager: string,
    managerEmail: string,
    emailKey: string,
    code: string,
    managerTel: Phone,
    orgTel: Phone,
    address: AddressOn,
    homePageUrl: string,
    department: string,
    departmentTel: Phone,
    requesterId: string,
    password: string,
    fileboxId: string,
    // fileIds: string[],
    orgType: OrgType | null,
    marketingTime: number,
    marketingYn: boolean,
  ) {
    this.name = name;
    this.licenceId = licenceId;
    this.manager = manager;
    this.managerEmail = managerEmail;
    this.emailKey = emailKey;
    this.code = code;
    this.managerTel = managerTel;
    this.orgTel = orgTel;
    this.address = address;
    this.homePageUrl = homePageUrl;
    this.department = department;
    this.departmentTel = departmentTel;
    this.requesterId = requesterId;
    this.password = password;
    this.fileboxId = fileboxId;
    // this.fileIds = fileIds;
    this.orgType = orgType;
    this.marketingTime = marketingTime;
    this.marketingYn = marketingYn;
    makeExtendedObservable(this);
  }

  static fromModel(domain: OrgProposal) {
    const params = validationUtils.checkNullableParams<OrgProposal, keyof OrgProposal>(
      domain,
      [
        'name',
        'licenceId',
        'manager',
        'managerEmail',
        'emailKey',
        'code',
        'managerTel',
        'orgTel',
        'requesterId',
        'orgType',
      ]
    );
    params.code = domain.code;
    params.password = domain.password;
    params.emailKey = domain.emailKey;
    params.address = domain.address;
    params.homePageUrl = domain.homePageUrl;
    params.managerTel = domain.managerTel;
    params.orgTel = domain.orgTel;
    params.department = domain.department;
    params.departmentTel = domain.departmentTel;
    params.fileboxId = domain.fileboxId;
    params.marketingTime = domain.marketingTime;
    params.marketingYn = domain.marketingYn;
    // params.fileIds = domain.fileIds;

    return new OrgProposalCdo(
      params.name,
      params.licenceId,
      params.manager,
      params.managerEmail,
      params.emailKey,
      params.code,
      params.managerTel,
      params.orgTel,
      params.address,
      params.homePageUrl,
      params.department,
      params.departmentTel,
      params.requesterId,
      params.password,
      params.fileboxId,
      // params.fileIds,
      params.orgType,
      params.marketingTime,
      params.marketingYn,
    );
  }

}

export default OrgProposalCdo;
