import React from "react";

interface Props {
  message: string;
  eventCloseModal: () => void;
}

interface State {}

class Alert extends React.Component<Props, State> {

  componentDidMount() {}

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
    snapshot?: any
  ): void {}

  render() {
    const { eventCloseModal, message } = this.props;

    return (
      <div className="login_page">
        <div className="login_box_wrap">
          <div className="login_box_developer">
            <section className="alert_desc">
              <ul>
                <li>
                {message}
                </li>
              </ul>
            </section>
            <div className="login_bts">
              <button
                className="login_bt st_line ok"
                style={{display: 'block', margin: '0 auto'}}
              ><p style={{color: 'white'}} onClick={() => eventCloseModal()}>확인</p>
              </button>
            </div>
          </div>
          <div className="bg" onClick={() => eventCloseModal()}></div>
        </div>
      </div>
    );
  }
}

export default Alert;
