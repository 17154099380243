import React, { createRef } from "react";
// import "../../css/common.css";
import axios from "axios";
import moment, { Moment } from "moment";
import Button from "@mui/material/Button";
import ContentTotalCardView from "../tourism-statistics/ContentTotalCardView";
import CalendarModal from "../tourism-statistics/CalendarModal";
import RegionMapChart from "../tourism-statistics/chart/RegionMapChart";
import RegionSigunguChart from "../tourism-statistics/chart/RegionSigunguChart";
import RegionCategoryChart from "../tourism-statistics/chart/RegionCategoryChart";
import RegionStandardChart from "../tourism-statistics/chart/RegionStandardChart";
import CategoryAccumulateChart from "../tourism-statistics/chart/CategoryAccumulateChart";
import CategoryYearChart from "../tourism-statistics/chart/CategoryYearChart";
import LanguageContentChart from "../tourism-statistics/chart/LanguageContentChart";
import LanguageRegistrationChart from "../tourism-statistics/chart/LanguageRegistrationChart";
import { fadeIn } from "../../shared/Function/util/CssUtil";
import ContentTotalCardViewMobile from "./ContentTotalCardViewMobile";
import CategoryAccumulateChartMobile from "./chart/CategoryAccumulateChartMobile";
import CategoryYearChartMobile from "./chart/CategoryYearChartMobile";
import LanguageContentChartMobile from "./chart/LanguageContentChartMobile";
import LanguageRegistrationChartMobile from "./chart/LanguageRegistrationChartMobile";
import RegionSelectBoxMobile from "./chart/RegionSelectBoxMobile";
import CalendarModalMobile from "./CalendarModalMobile";
import RegionSigunguChartMobile from "./chart/RegionSigunguChartMobile";
import RegionCategoryChartMobile from "./chart/RegionCategoryChartMobile";
import RegionStandardChartMobile from "./chart/RegionStandardChartMobile";

interface Props {}

interface State {
  openCalendar: boolean;
  regionTitle: string;
  openDetailModal: undefined | "detail";
  modalType: string;
  categoryId: string;
  themeDetailObject: {
    actorId: string;
    audienceId: string;
    bannerImgPath: string;
    children: string;
    cineroomId: string;
    cineroomName: string;
    citizenId: string;
    cmsCategoryId: string;
    elementTypeId: string;
    entityVersion: string;
    existChild: boolean;
    folderType: boolean;
    id: string;
    idPaths: [];
    imagePath: string;
    langUsed: boolean;
    lastUpdateTime: number;
    modificationTime: number;
    lastUpdater: { id: string; name: string };
    marketingType: string;
    name: string;
    registrationTime: number;
    type: string;
    used: boolean;
    writer: { id: string; name: string };
    pavilionId: string;
    parentId: string;
    originCategoryId: string;
    originFolderId: string;
    seq: number;
    publicType: boolean;
  };
  recommendThemeObject: object[];
  themeDetailList: object[];
  imageDetailBase: {
    copyright: string;
    imageProperty: string;
    provider: string;
    depotId: string;
    imagePath: string;
    enTitle: string;
    cid: string;
    tid: string;
  };
  categoryPath: [];
  categoryAddress: {};
  detailFlag: boolean;
  detailListFlag: boolean;
  recommendFlag: boolean;
  categoryPathFlag: boolean;
  imageDetailBaseFlag: boolean;
  categoryAddressFlag: boolean;
  categoryName: string;
  languageName: string;
  regionCode: number;
  showShareAlert: boolean;

  regionMapData: [];

  regionSeoul: number;
  regionGangwon: number;
  regionGyeonggi: number;
  regionGyeongnam: number;
  regionGyeongbuk: number;
  regionGwangju: number;
  regionDaegu: number;
  regionDaejeon: number;
  regionBusan: number;
  regionUlsan: number;
  regionIncheon: number;
  regionJeonnam: number;
  regionJeonbuk: number;
  regionJeju: number;
  regionChungnam: number;
  regionChungbuk: number;
  regionSejong: number;
  period: string;
  startYear: string;
  endYear: string;
  startMonth: string;
  endMonth: string;
  startYearMonth: string;
  endYearMonth: string;
  clickMonthly: string;
  clickCount: number;
  swiperObject: any;
  swiperActiveIndex: number;
  languageRenewalContent: string;
  isShowToolip: boolean;

  tooltipTextRegion: string | null;
  tooltipTextCategory: string | null;
  tooltipTextLanguage: string | null;
  tapName: string;
  tapTitle: string;
  viewWidth: number | undefined;
  regionSelectBoxOpen: boolean;
  openCalendarMobile: boolean;
  tooltipPosition: {
    top: number;
  };
}

class ContentStatisticsPage extends React.Component<Props, State> {
  static defaultProps: any = {};

  regionRef = createRef<HTMLUListElement>();
  categoryRef = createRef<HTMLUListElement>();
  langRef = createRef<HTMLUListElement>();
  statisticsMenuRef = createRef<HTMLDivElement>();

  constructor(props: any) {
    super(props);
    this.setRegionName = this.setRegionName.bind(this);
    this.setRegionCode = this.setRegionCode.bind(this);
    this.setLanguageName = this.setLanguageName.bind(this);
    this.setRenewalContent = this.setRenewalContent.bind(this);
    this.clearYear = this.clearYear.bind(this);
    this.clearMonth = this.clearMonth.bind(this);
    this.closeCalendar = this.closeCalendar.bind(this);
    this.mapChartPeriod = this.mapChartPeriod.bind(this);
    this.setYear = this.setYear.bind(this);
    this.setMonth = this.setMonth.bind(this);
    this.setClickMonthly = this.setClickMonthly.bind(this);
    this.fireSearchButton = this.fireSearchButton.bind(this);
    this.setPeriod = this.setPeriod.bind(this);
    this.regionRef = React.createRef();
    this.categoryRef = React.createRef();
    this.langRef = React.createRef();
    this.statisticsMenuRef = React.createRef();

    addEventListener("resize", (event) => {
      this.changeViewWidth();
    });
  }

  state: State = {
    openCalendar: false,
    regionTitle: "서울특별시",
    openDetailModal: undefined,
    modalType: "",
    categoryId: "",
    themeDetailObject: {
      actorId: "",
      audienceId: "",
      bannerImgPath: "",
      children: "",
      cineroomId: "",
      cineroomName: "",
      citizenId: "",
      cmsCategoryId: "",
      elementTypeId: "",
      entityVersion: "",
      existChild: false,
      folderType: false,
      id: "",
      idPaths: [],
      imagePath: "",
      langUsed: false,
      lastUpdateTime: 0,
      modificationTime: 0,
      lastUpdater: { id: "", name: "" },
      marketingType: "",
      name: "",
      registrationTime: 0,
      type: "",
      used: false,
      writer: { id: "", name: "" },
      pavilionId: "",
      parentId: "",
      originCategoryId: "",
      originFolderId: "",
      seq: 0,
      publicType: false,
    },
    recommendThemeObject: [],
    themeDetailList: [],
    imageDetailBase: {
      copyright: "",
      imageProperty: "",
      provider: "",
      depotId: "",
      imagePath: "",
      enTitle: "",
      cid: "",
      tid: "",
    },
    categoryPath: [],
    categoryAddress: {},
    detailFlag: false,
    detailListFlag: false,
    recommendFlag: false,
    categoryPathFlag: false,
    imageDetailBaseFlag: false,
    categoryAddressFlag: false,
    categoryName: "자연관광",
    languageName: "ko",
    regionCode: 1,
    showShareAlert: false,
    regionMapData: [],
    regionSeoul: 0,
    regionGangwon: 0,
    regionGyeonggi: 0,
    regionGyeongnam: 0,
    regionGyeongbuk: 0,
    regionGwangju: 0,
    regionDaegu: 0,
    regionDaejeon: 0,
    regionBusan: 0,
    regionUlsan: 0,
    regionIncheon: 0,
    regionJeonnam: 0,
    regionJeonbuk: 0,
    regionJeju: 0,
    regionChungnam: 0,
    regionChungbuk: 0,
    regionSejong: 0,
    period: "entire",
    startYear: "",
    endYear: "",
    startMonth: "",
    endMonth: "",
    startYearMonth: "",
    endYearMonth: "",
    clickMonthly: "entire",
    clickCount: 0,
    swiperObject: {},
    swiperActiveIndex: 0,
    languageRenewalContent: "modifyKo",
    isShowToolip: false,

    tapName: "콘텐츠총건수",
    tooltipTextRegion: null,
    tooltipTextCategory: null,
    tooltipTextLanguage: null,
    tapTitle: "콘텐츠총건수",
    viewWidth: window.innerWidth,
    regionSelectBoxOpen: false,
    openCalendarMobile: false,
    tooltipPosition: {
      top: 0,
    },
  };

  componentDidMount() {
    this.mapChartPeriod("entire");
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
    if(prevState.viewWidth !== this.state.viewWidth){
      this.changeViewWidth();
    }
  }

  changeViewWidth() {
    this.setState({ viewWidth: this.statisticsMenuRef.current?.offsetWidth });
  }

  setRegionName(regionName: string) {
    this.setState({ regionTitle: regionName });
  }

  setRegionCode(regionCode: number) {
    this.setState({ regionCode: regionCode });
  }

  setCategoryName(category: string) {
    this.setState({ categoryName: category });
  }

  setLanguageName(language: string) {
    this.setState({ languageName: language });
  }

  setRenewalContent(renewalContent: string) {
    this.setState({ languageRenewalContent: renewalContent });
  }

  onClickPeriod(periodType: string) {
    if (periodType == "entire") {
      this.setState({ period: periodType, clickMonthly: "entire" });
      this.closeCalendar();
      this.mapChartPeriod(periodType);
    } else if (periodType == "monthly") {
      this.setState({ clickMonthly: "monthly" });
      this.openCalendar();
    }
  }

  onClickPeriodMobile(periodType: string) {
    if (periodType == "entire") {
      this.setState({ period: periodType, clickMonthly: "entire" });
      this.closeCalendarMobile();
      this.mapChartPeriod(periodType);
    } else if (periodType == "monthly") {
      this.setState({ clickMonthly: "monthly" });
      this.openCalendarMobile();
    }
  }

  setPeriod(periodType: string) {
    this.setState({ period: periodType });
  }

  setClickMonthly() {
    if (this.state.startYear == "" && this.state.endYear == "") {
      this.setState({ clickMonthly: "entire" });
    }
  }

  fireSearchButton() {
    this.setState({ clickCount: this.state.clickCount + 1 });
  }

  openCalendarMobile = () => {
    this.setState({openCalendarMobile : true})
    document.body.style.overflowY = 'hidden'
  }

  closeCalendarMobile = () => {
    this.setState({openCalendarMobile : false})
    document.body.style.overflowY = 'unset'
  }

  openCalendar() {
    this.setState({ openCalendar: true });
  }

  closeCalendar() {
    this.setState({ openCalendar: false });
  }

  onClickDetailModal(modalType: string, event: any) {
    this.setState({
      openDetailModal: "detail",
      modalType: modalType,
      categoryId: event.payload.cid,
    });
    document.body.style.cssText = "overflow: hidden !important";
  }

  onClickCloseModal() {
    this.setState({
      openDetailModal: undefined,
      detailFlag: false,
      detailListFlag: false,
      recommendFlag: false,
      categoryPathFlag: false,
      imageDetailBaseFlag: false,
      categoryAddressFlag: false,
    });
    document.body.style.cssText = "overflow: unset";
  }

  clearYear() {
    if (this.state.period != "monthly") {
      this.setState({ startYear: "", endYear: "" });
    }
  }

  clearMonth() {
    if (this.state.period != "monthly") {
      this.setState({ startMonth: "", endMonth: "" });
    }
  }

  //start end year 구분하고, 값을 다시 설정
  setYear(year: string) {
    if (this.state.startYear == "" && this.state.endYear == "") {
      this.setState({ startYear: year, endYear: "" });
    } else if (this.state.startYear !== "" && this.state.endYear == "") {
      this.setState({ endYear: year });
    } else if (this.state.startYear !== "" && this.state.endYear !== "") {
      this.setState({ startYear: "", endYear: "" }, () => {
        this.setYear(year);
      });
    }
  }

  //start end month 구분하고, 값을 다시 설정
  setMonth(month: string) {
    if (this.state.startMonth == "" && this.state.endMonth == "") {
      this.setState({ startMonth: month, endMonth: "" });
    } else if (this.state.startMonth !== "" && this.state.endMonth == "") {
      this.setState({ endMonth: month });
    } else if (this.state.startMonth !== "" && this.state.endMonth !== "") {
      this.setState({ startMonth: "", endMonth: "" }, () => {
        this.setMonth(month);
      });
    }
  }

  async mapChartPeriod(periodType: string) {
    try {
      let startYearMonth = this.state.startYear + this.state.startMonth;
      let endYearMonth = this.state.endYear + this.state.endMonth;

      const convertStartYM = parseInt(startYearMonth);
      const convertEndYM = parseInt(endYearMonth);

      // 유효성 검사, 1개월 단위로 조회
      if (startYearMonth !== "" && endYearMonth == "") {
        endYearMonth = startYearMonth;
        this.setYear(this.state.startYear);
        this.setMonth(this.state.startMonth);
      }

      // 12월~1월, 6월~1월 선택해도 1월~12월, 1월~6월로 구분하여 요청
      if (convertStartYM > convertEndYM) {
        startYearMonth = this.state.endYear + this.state.endMonth;
        endYearMonth = this.state.startYear + this.state.startMonth;
      }

      this.setState({
        startYearMonth: startYearMonth,
        endYearMonth: endYearMonth,
      });

      const response = await axios.get(
        "/api/naomi/v2/statistics/region-map-chart",
        {
          params: {
            period: periodType,
            beginYM: startYearMonth,
            endYM: endYearMonth,
          },
        }
      );

      this.setState({
        regionMapData: response.data.queryResult,
        regionSeoul: response.data.queryResult.find((el: any) => el.code == 1)
          .value,
        regionGangwon: response.data.queryResult.find(
          (el: any) => el.code == 32
        ).value,
        regionGyeonggi: response.data.queryResult.find(
          (el: any) => el.code == 31
        ).value,
        regionGyeongnam: response.data.queryResult.find(
          (el: any) => el.code == 36
        ).value,
        regionGyeongbuk: response.data.queryResult.find(
          (el: any) => el.code == 35
        ).value,
        regionGwangju: response.data.queryResult.find((el: any) => el.code == 5)
          .value,
        regionDaegu: response.data.queryResult.find((el: any) => el.code == 4)
          .value,
        regionDaejeon: response.data.queryResult.find((el: any) => el.code == 3)
          .value,
        regionBusan: response.data.queryResult.find((el: any) => el.code == 6)
          .value,
        regionUlsan: response.data.queryResult.find((el: any) => el.code == 7)
          .value,
        regionIncheon: response.data.queryResult.find((el: any) => el.code == 2)
          .value,
        regionJeonnam: response.data.queryResult.find(
          (el: any) => el.code == 38
        ).value,
        regionJeonbuk: response.data.queryResult.find(
          (el: any) => el.code == 37
        ).value,
        regionJeju: response.data.queryResult.find((el: any) => el.code == 39)
          .value,
        regionChungnam: response.data.queryResult.find(
          (el: any) => el.code == 34
        ).value,
        regionChungbuk: response.data.queryResult.find(
          (el: any) => el.code == 33
        ).value,
        regionSejong: response.data.queryResult.find((el: any) => el.code == 8)
          .value,
      });
    } catch (e) {
      console.error(e);
    }
  }

  handleTooltip(target: React.RefObject<HTMLUListElement>) {
    if (this.state.isShowToolip) {
      target.current!.style.display = "none";
    } else {
      fadeIn(target.current);
    }

    this.setState({ isShowToolip: !this.state.isShowToolip });
  }


  showTooltipRegion = (text: string, event: React.MouseEvent) => {
    const tooltipNode = event.currentTarget;
    const tooltipRect = tooltipNode.getBoundingClientRect();
    const newPosition = {
      top: tooltipRect.top + window.scrollY,
      left: tooltipRect.left + window.scrollX,
    };

    this.setState({
      tooltipTextRegion: text,
      tooltipPosition: newPosition,
    });
  };

  hideTooltipRegion = () => {
    this.setState({ tooltipTextRegion: null });
  };

  showTooltipCategory = (text: string, event: React.MouseEvent) => {
    const tooltipNode = event.currentTarget;
    const tooltipRect = tooltipNode.getBoundingClientRect();
    const newPosition = {
      top: tooltipRect.top + window.scrollY,
      left: tooltipRect.left + window.scrollX,
    };

    this.setState({
      tooltipTextCategory: text,
      tooltipPosition: newPosition,
    });
  };

  hideTooltipCategory = () => {
    this.setState({ tooltipTextCategory: null });
  };

  showTooltipLanguage = (text: string, event: React.MouseEvent) => {
    const tooltipNode = event.currentTarget;
    const tooltipRect = tooltipNode.getBoundingClientRect();
    const newPosition = {
      top: tooltipRect.top + window.scrollY,
      left: tooltipRect.left + window.scrollX,
    };

    this.setState({
      tooltipTextLanguage: text,
      tooltipPosition: newPosition,
    });
  };

  hideTooltipLanguage = () => {
    this.setState({ tooltipTextLanguage: null });
  };

  renderRegionTooltip = () => {
    const { tooltipTextRegion, tooltipPosition } = this.state;
    if (!tooltipTextRegion) return null;

    return (
      <div className="tooltip" style={{zIndex: 2, position: 'absolute', top: `${tooltipPosition.top - 200}px`}}>
        <ul className="dot_list_region" style={{ opacity: 1, display: "block" }}>
          <li dangerouslySetInnerHTML={{ __html: tooltipTextRegion }} />
        </ul>
      </div>
    );
  };

  renderCategoryTooltip = () => {
    const { tooltipTextCategory, tooltipPosition } = this.state;
    if (!tooltipTextCategory) return null;

    return (
      <div className="tooltip" style={{zIndex: 2, position: 'absolute', top: `${tooltipPosition.top - 200}px`}}>
        <ul className="dot_list_category" style={{ opacity: 1, display: "block" }}>
          <li dangerouslySetInnerHTML={{ __html: tooltipTextCategory }} />
        </ul>
      </div>
    );
  };

  renderLanguageTooltip = () => {
    const { tooltipTextLanguage, tooltipPosition } = this.state;
    if (!tooltipTextLanguage) return null;

    return (
      <div className="tooltip" style={{zIndex: 2, position: 'absolute', top: `${tooltipPosition.top - 200}px`}}>
        <ul className="dot_list_language" style={{ opacity: 1, display: "block" }}>
          <li dangerouslySetInnerHTML={{ __html: tooltipTextLanguage }} />
        </ul>
      </div>
    );
  };

  tapChangeColor = (name: string) => {
    this.setState({ tapName: name });
  };

  tapName = (tapName: string) => {
    this.setState({});
  };

  changeTap = (tapName: string) => {
    this.setState({ tapTitle: tapName })
  };

  openSelectBox = () => {
    this.setState({regionSelectBoxOpen: true})
    document.body.style.overflowY = 'hidden'
  }

  closeSelectBox = () => {
    this.setState({regionSelectBoxOpen: false})
    document.body.style.overflowY = 'unset'
  }

  // 지역별
  renderRegion = () => {
    const { regionSelectBoxOpen, openCalendarMobile, regionTitle } = this.state;
    return (
      <> 
        <div className="region_top_menu">

        <div className="region_txt_box" onClick={() => this.openSelectBox()}>
          <span className="region_title" style={{fontSize: '16px', fontWeight: 500}}>
            {regionTitle}
          </span>
          <span className="region_box_arrow"></span>
        </div>

          <div className="region_tap" style={{display: 'flex', gap: '10px', alignItems: 'center'}}>
            <p className="region_period">기간</p>
            <ul className="region_tabs_button" style={{display: 'flex', gap: '5px'}}>
              <Button
                style={
                  this.state.clickMonthly == "entire"
                    ? {
                        backgroundColor: "#437aff",
                        color: "#fff",
                      }
                    : {backgroundColor: "#ededed", color: '#000000'}
                }
                variant="contained"
                onClick={() => this.onClickPeriodMobile("entire")}
              >
                전체
              </Button>
              <Button
                style={
                  this.state.clickMonthly == "monthly"
                    ? {
                        backgroundColor: "#437aff",
                        color: "#fff",
                      }
                    : {backgroundColor: "#ededed", color: '#000000'}
                }
                variant="contained"
                onClick={() => this.onClickPeriodMobile("monthly")}
              >
                월별
              </Button>
            </ul>
            {/* 조회기간 표시 */}
            {/* <div style={{ display: "flex" }}>
              {this.state.period != "entire" && (
                <p
                  style={{
                    fontSize: "13px",
                    position: "relative",
                    top: "4px",
                  }}
                >
                  조회기간
                </p>
              )}
              {this.state.startYearMonth != "" &&
                this.state.endYearMonth != "" &&
                this.state.period != "entire" && (
                  <div
                    className="inquiry-period"
                    style={{
                      position: "relative",
                      left: "9px",
                      top: "1.9px",
                    }}
                  >
                    {moment(this.state.startYearMonth).format(
                      "YYYY.MM"
                    )}
                    ~
                    {moment(this.state.endYearMonth).format(
                      "YYYY.MM"
                    )}
                  </div>
                )}
            </div> */}
            <div className="calendar-hover"></div>
          </div>
        </div>

        <div style={{display: 'flex', justifyContent: 'center'}}>
          {openCalendarMobile && (
            <CalendarModalMobile
              setClickMonthly={this.setClickMonthly}
              endYear={this.state.endYear}
              endMonth={this.state.endMonth}
              setYear={this.setYear}
              setMonth={this.setMonth}
              startYear={this.state.startYear}
              startMonth={this.state.startMonth}
              mapChartPeriod={this.mapChartPeriod}
              closeCalendarMobile={this.closeCalendarMobile}
              fireSearchButton={this.fireSearchButton}
              setPeriod={this.setPeriod}
              clearYear={this.clearYear}
              clearMonth={this.clearMonth}
            />
          )}
        </div>

        <div>
          <p className="st-box-tit">시/군/구 콘텐츠</p>
          <div className="info-txt">
            <i className="ico ico-info-gy"></i>
            콘텐츠 건수가 많은 순으로 10개의 시/군/구를
            보여줍니다.
          </div>
        </div>

        <RegionSigunguChartMobile
          period={this.state.period}
          startYearMonth={this.state.startYearMonth}
          endYearMonth={this.state.endYearMonth}
          regionCode={this.state.regionCode}
          clickCount={this.state.clickCount}
          viewWidth={this.state.viewWidth}
        />

        <div>
          <p className="st-box-tit">유형별 콘텐츠</p>
          <div className="info-txt">
            <i className="ico ico-info-gy"></i>
            해당 지역의 유형별 콘텐츠 분포율을 보여줍니다.
          </div>
        </div>

        <RegionCategoryChartMobile
          period={this.state.period}
          startYearMonth={this.state.startYearMonth}
          endYearMonth={this.state.endYearMonth}
          regionCode={this.state.regionCode}
          clickCount={this.state.clickCount}
          viewWidth={this.state.viewWidth}
        />

        <div>
          <p className="st-box-tit" style={{paddingTop: '20px'}}>
            표준분류체계별 콘텐츠
          </p>
          <div className="info-txt">
            <i className="ico ico-info-gy"></i>
            표준분류체계별 콘텐츠의 전체 평균 건수와 해당
            지역의 건수를 보여줍니다. (단위: 건)
          </div>
        </div>

        <RegionStandardChartMobile
          period={this.state.period}
          startYearMonth={this.state.startYearMonth}
          endYearMonth={this.state.endYearMonth}
          regionTitle={this.state.regionTitle}
          regionCode={this.state.regionCode}
          clickCount={this.state.clickCount}
          viewWidth={this.state.viewWidth}
        />


        {regionSelectBoxOpen ? <RegionSelectBoxMobile regionTitle={this.state.regionTitle} closeSelectBox={this.closeSelectBox} setRegionCode={this.setRegionCode} setRegionName={this.setRegionName}/> : null }
      </>
    );
  };

  //유형별
  renderCategory = () => {
    const { viewWidth } = this.state;
    return (
      <div className="statistics_list_category_mobile">
        <div className="statistics_list_category_mobile">

          <div className="info_txt_mobile" style={{display: 'flex', alignItems: 'center', gap: '5px', fontSize: '12px', color: '#B4B4B4', justifyContent: 'start'}}>
            <i className="ico ico-info-gy"></i>
            유형별 누적 콘텐츠 및 전년대비 증감율을 보여줍니다.
          </div>

          {/* @ts-ignore */}
          <div className="statistics_item_category_mobile" >

            <div style={{textAlign: 'center'}}>
              <span className="st_box_tit_mobile" style={{marginLeft: '55px', fontSize: '18px', color: '#575757'}}>관광정보 콘텐츠 추이</span>
              <CategoryAccumulateChartMobile categoryName="관광정보" viewWidth={this.state.viewWidth}/>
            </div>

            <ul className="accumulate-color-guide" style={{display: 'flex', justifyContent: 'center', position: 'relative', top: '-30px', paddingTop: '20px'}}>
            <li>
              <i className="color-circle cFF58S9"></i>
              <span>누적 콘텐츠</span>
            </li>
          </ul>

            <div style={{textAlign: 'center'}}>
              <span className="st_box_tit_mobile" style={{marginLeft: '55px', fontSize: '18px', color: '#575757'}}>관광정보 콘텐츠 추이</span>
              <CategoryAccumulateChartMobile categoryName="이미지" viewWidth={this.state.viewWidth}/>
            </div>

            <ul className="accumulate-color-guide" style={{display: 'flex', justifyContent: 'center', position: 'relative', top: '-30px', paddingTop: '20px'}}>
              <li>
                <i className="color-circle cFF58S9"></i>
                <span>누적 콘텐츠</span>
              </li>
            </ul>
            
          </div>
        </div>
        <div className="info_txt_mobile" style={{display: 'flex', alignItems: 'center', gap: '5px', paddingBottom: '30px', paddingTop: '30px', fontSize: '12px', color: '#B4B4B4', justifyContent: 'start'}}>
          <i className="ico ico-info-gy"></i>
          12개월 기준 관광정보, 이미지 콘텐츠 건수를 보여줍니다.
          (단위: 건)
        </div>
        <CategoryYearChartMobile viewWidth={this.state.viewWidth}/>
        <ul className="graph-color-guide" style={{flexDirection: 'row', justifyContent: 'center', gap: '10px', position: 'relative', top: '10px'}}>
          <li>
            <i className="color-circle cFF58F8"></i>
            <span>관광정보</span>
          </li>
          <li>
            <i className="color-circle cFFCF52"></i>
            <span>이미지</span>
          </li>
          <li>
            <i className="color-circle c7C64FF"></i>
            <span>동영상</span>
          </li>
        </ul>
      </div>
    );
  };

  // 언어별
  renderLanguage = () => {
    return (
      <div className="statistics_list_language_mobile">
        <div className="statistics_list_language_mobile" style={{padding: 'unset', textAlign: 'center'}}>

          <span className="st_box_tit_mobile" style={{display: 'flex', fontSize: '18px', color: '#575757', paddingBottom: '10px'}}>전년동기 전체 콘텐츠</span>
          <div className="info_txt_mobile" style={{display: 'flex', gap: '5px', fontSize: '12px', color: '#B4B4B4', justifyContent: 'start'}}>
            <i className="ico ico-info-gy"></i>
            9개의 어권별 전년동기 대비 누적 콘텐츠 현황 및 증감 건수를 보여줍니다.
          </div>

          <div className="statistics_item_region_mobile" style={{display: 'flex', justifyContent: 'center'}}>
              <LanguageContentChartMobile
                languageName={this.state.languageName}
                setRenewalContent={this.setRenewalContent}
                setLanguageName={this.setLanguageName}
                viewWidth={this.state.viewWidth}
              />
          </div>

            <span className="st_box_tit_mobile" style={{display: 'flex', fontSize: '18px', color: '#575757', paddingBottom: '10px', paddingTop: '50px'}}>월별 등록 및 갱신 콘텐츠</span>
            <div className="info_txt_mobile" style={{display: 'flex', gap: '5px', fontSize: '12px', color: '#B4B4B4', justifyContent: 'start', paddingBottom: '10px'}}>
              <i className="ico ico-info-gy"></i>
              6개월 기준 어권별 등록 및 갱신 콘텐츠 건수를 보여줍니다.(단위:건)
            </div>

          <div className="statistics_item_language_mobile" style={{display: 'flex', justifyContent: 'center'}}>
              <LanguageRegistrationChartMobile
                languageRenewalContent={this.state.languageRenewalContent}
                languageName={this.state.languageName}
                viewWidth={this.state.viewWidth}
              />
          </div>
        </div>
      </div>
    );
  };

  render() {
    const { openCalendar, tapName, tapTitle, viewWidth, regionSelectBoxOpen } = this.state;

    return (
      <>
        <div id="contents" className="tourism_statistics" style={{paddingBottom: '50px'}}>
          <div className="center nav_wrap">
            <ul className="nav">
              <li>
                <i className="icon home"></i>
              </li>
              <li>
                관광콘텐츠<i className="icon down_circle"></i>
              </li>
              <li>
                <span>관광콘텐츠 통계</span>
                <i className="icon up_circle"></i>
              </li>
            </ul>
          </div>

          <section className="statistics_area">
            <div className="center" style={regionSelectBoxOpen === true ? {padding: 'unset'} : {}}>
              <div className="con_box">
                <h2 className="cb_tit">관광콘텐츠 통계</h2>
                <p>
                  전일기준 현재 콘텐츠랩에서 확인 가능한 콘텐츠 현황을
                  보여줍니다.
                </p>
                {/* 콘텐츠 총 건수, 모바일 탭 */}
                {/* @ts-ignore */}
                <div className="statistics_menu" ref={this.statisticsMenuRef} style={viewWidth >= 525 ? {overflow: 'hidden', borderBottom: '1px solid #ccc', paddingTop: '20px'} : {}}>
                  <ul style={{position: 'relative'}}>
                    <li
                      className="item1 active"
                      style={
                        tapName === "콘텐츠총건수"
                          ? {
                              color: "#2658E2",
                              fontWeight: 800,
                              display: "inline-flex",
                              alignItems: "center",
                            }
                          : {
                              color: "#222224",
                              display: "inline-flex",
                              alignItems: "center",
                            }
                      }
                      onClick={() => {
                        this.tapChangeColor("콘텐츠총건수");
                        this.changeTap("콘텐츠총건수");
                      }}
                    >
                      콘텐츠 현황
                    </li>

                    <li
                      className="item2"
                      style={
                        tapName === "지역별"
                          ? {
                              color: "#2658E2",
                              fontWeight: 800,
                              display: "inline-flex",
                              alignItems: "center",
                            }
                          : {
                              color: "#222224",
                              display: "inline-flex",
                              alignItems: "center",
                            }
                      }
                      onClick={() => {
                        this.tapChangeColor("지역별");
                        this.changeTap("지역별");
                      }}
                    >
                      지역별 현황
                      <i
                        className="icon tooltip"
                        onMouseEnter={(event) =>
                          this.showTooltipRegion(
                            "- 전국 17개의 시도를 기준으로 해당 지역의 시.군.구 콘텐츠건수, 유형별 분표율, 표준분류체계별 건수를 보여줍니다.<br>- 월별 기간 선택 시, 콘텐츠랩에 노출되고 있는 콘텐츠가 등록된 시점을 기준으로 현황을 보여줍니다.",
                            event
                          )
                        }
                        onMouseLeave={this.hideTooltipRegion}
                      />
                    </li>
                    <li
                      className="item3"
                      style={
                        tapName === "유형별"
                          ? {
                              color: "#2658E2",
                              fontWeight: 800,
                              display: "inline-flex",
                              alignItems: "center",
                            }
                          : {
                              color: "#222224",
                              display: "inline-flex",
                              alignItems: "center",
                            }
                      }
                      onClick={() => {
                        this.tapChangeColor("유형별");
                        this.changeTap("유형별");
                      }}
                    >
                      유형별 현황
                      <i
                        className="icon tooltip"
                        onMouseEnter={(event) =>
                          this.showTooltipCategory(
                            "- 누적 콘텐츠 현황을 기반으로 콘텐츠 추이를 제공합니다.",
                            event
                          )
                        }
                        onMouseLeave={this.hideTooltipCategory}
                      />
                    </li>

                    <li
                      className="item4"
                      style={
                        tapName === "어권별"
                          ? {
                              color: "#2658E2",
                              fontWeight: 800,
                              display: "inline-flex",
                              alignItems: "center",
                            }
                          : {
                              color: "#222224",
                              display: "inline-flex",
                              alignItems: "center",
                            }
                      }
                      onClick={() => {
                        this.tapChangeColor("어권별");
                        this.changeTap("어권별");
                      }}
                    >
                      어권별 현황
                      <i
                        className="icon tooltip"
                        onMouseEnter={(event) =>
                          this.showTooltipLanguage(
                            "- 9개의 어권별 전년동기 대비 전체 누적 콘텐츠 현황과 6개월 기준 월별 등록 콘텐츠 건수를 보여줍니다.<br> - 언어 선택 시 해당 언어의 월별 콘텐츠 등록 및 갱신 건수를 보여줍니다.",
                            event
                          )
                        }
                        onMouseLeave={this.hideTooltipLanguage}
                      />
                    </li>
                  </ul>
                {this.renderRegionTooltip()}
                {this.renderCategoryTooltip()}
                {this.renderLanguageTooltip()}
                </div>
                <div style={{position: 'relative', paddingTop: '20px'}}>
                  {tapTitle === "콘텐츠총건수" ? (
                    //@ts-ignore
                    viewWidth <= 728 && viewWidth !== 0 ? (
                      <ContentTotalCardViewMobile />
                    ) : (
                      <ContentTotalCardView />
                    )
                  ) : tapTitle === "지역별" ? (
                    //@ts-ignore
                    viewWidth <= 728 && viewWidth !== 0 ? (
                      this.renderRegion()
                    ) : (
                      <ContentTotalCardView />
                    )
                  ) : tapTitle === "유형별" ? (
                    //@ts-ignore
                    viewWidth <= 728 && viewWidth !== 0 ? (
                      this.renderCategory()
                    ) : (
                      <ContentTotalCardView />
                    )
                  ) : tapTitle === "어권별" ? (
                    //@ts-ignore
                    viewWidth <= 728 && viewWidth !== 0 ? (
                      this.renderLanguage()
                    ) : (
                      <ContentTotalCardView />
                    )
                  ) : null}
                </div>
              </div>

              <div className="statistics_graph_area">
                <h3 className="sga_tit">
                  지역별 현황
                  <div className="tooltip" style={{ zIndex: "2" }}>
                    <i
                      className="icon tooltip"
                      onMouseEnter={() => this.handleTooltip(this.regionRef)}
                      onMouseLeave={() => this.handleTooltip(this.regionRef)}
                    ></i>
                    <ul className="dot_list" ref={this.regionRef}>
                      <li>
                        전국 17개의 시도를 기준으로 해당 지역의 시.군.구
                        콘텐츠건수, 유형별 분표율, 표준분류체계별 건수를
                        보여줍니다.
                      </li>
                      <li>
                        월별 기간 선택 시, 콘텐츠랩에 노출되고 있는 콘텐츠가
                        등록된 시점을 기준으로 현황을 보여줍니다.
                      </li>
                    </ul>
                  </div>
                </h3>

                <div className="stts-wrap">
                  <div className="st-wrap mt-60px">
                    <div className="st-layer">
                      <div
                        className="st-box-wrap flex gap-hz5"
                        style={{ height: "900px" }}
                      >
                        <div className="st-box fx-grw">
                          <div className="st-box-top" style={{ zIndex: "1" }}>
                            <div className="tab-wrap gap-hz10">
                              <p>기간</p>
                              <ul className="tabs gap-hz10">
                                <Button
                                  style={
                                    this.state.clickMonthly == "entire"
                                      ? {
                                          backgroundColor: "#437aff",
                                          color: "#fff",
                                        }
                                      : {backgroundColor: "#ededed", color: '#000000'}
                                  }
                                  variant="contained"
                                  onClick={() => this.onClickPeriod("entire")}
                                >
                                  전체
                                </Button>
                                <Button
                                  style={
                                    this.state.clickMonthly == "monthly"
                                      ? {
                                          backgroundColor: "#437aff",
                                          color: "#fff",
                                        }
                                      : {backgroundColor: "#ededed", color: '#000000'}
                                  }
                                  variant="contained"
                                  onClick={() => this.onClickPeriod("monthly")}
                                >
                                  월별
                                </Button>
                              </ul>
                              <div style={{ display: "flex" }}>
                                {this.state.period != "entire" && (
                                  <p
                                    style={{
                                      fontSize: "13px",
                                      position: "relative",
                                      top: "4px",
                                    }}
                                  >
                                    조회기간
                                  </p>
                                )}
                                {this.state.startYearMonth != "" &&
                                  this.state.endYearMonth != "" &&
                                  this.state.period != "entire" && (
                                    <div
                                      className="inquiry-period"
                                      style={{
                                        position: "relative",
                                        left: "9px",
                                        top: "1.9px",
                                      }}
                                    >
                                      {moment(this.state.startYearMonth).format(
                                        "YYYY.MM"
                                      )}
                                      ~
                                      {moment(this.state.endYearMonth).format(
                                        "YYYY.MM"
                                      )}
                                    </div>
                                  )}
                              </div>
                              <div className="calendar-hover"></div>
                            </div>
                          </div>
                          <div>
                            {openCalendar && (
                              <CalendarModal
                                setClickMonthly={this.setClickMonthly}
                                endYear={this.state.endYear}
                                endMonth={this.state.endMonth}
                                setYear={this.setYear}
                                setMonth={this.setMonth}
                                startYear={this.state.startYear}
                                startMonth={this.state.startMonth}
                                mapChartPeriod={this.mapChartPeriod}
                                closeCalendar={this.closeCalendar}
                                fireSearchButton={this.fireSearchButton}
                                setPeriod={this.setPeriod}
                                clearYear={this.clearYear}
                                clearMonth={this.clearMonth}
                              />
                            )}
                          </div>

                          <div className="st-box-cnt">
                            <div className="flex js-sb">
                              <div className="info-txt">
                                <i className="ico ico-info-gy"></i>
                                지역을 선택하면 해당 지역의 상세통계를
                                보여줍니다. (단위: 건)
                              </div>
                            </div>
                            {/* <div className="flex p-rel map-default"> */}
                            <div>
                              <RegionMapChart
                                setRegionCode={this.setRegionCode}
                                setRegionName={this.setRegionName}
                                regionSeoul={this.state.regionSeoul}
                                regionGangwon={this.state.regionGangwon}
                                regionGyeonggi={this.state.regionGyeonggi}
                                regionGyeongnam={this.state.regionGyeongnam}
                                regionGyeongbuk={this.state.regionGyeongbuk}
                                regionGwangju={this.state.regionGwangju}
                                regionDaegu={this.state.regionDaegu}
                                regionDaejeon={this.state.regionDaejeon}
                                regionBusan={this.state.regionBusan}
                                regionUlsan={this.state.regionUlsan}
                                regionIncheon={this.state.regionIncheon}
                                regionJeonnam={this.state.regionJeonnam}
                                regionJeonbuk={this.state.regionJeonbuk}
                                regionJeju={this.state.regionJeju}
                                regionChungnam={this.state.regionChungnam}
                                regionChungbuk={this.state.regionChungbuk}
                                regionSejong={this.state.regionSejong}
                              />
                            </div>
                            {/* </div> */}
                          </div>
                        </div>
                        <div className="st-box fx-grw">
                          <div className="st-box-top blue js-c">
                            <p>{this.state.regionTitle}</p>
                          </div>
                          <div className="flex">
                            <div
                              className="st-box-cnt ccd-graph"
                              style={{ height: "400px" }}
                            >
                              <div>
                                <p className="st-box-tit">시/군/구 콘텐츠</p>
                                <div className="info-txt">
                                  <i className="ico ico-info-gy"></i>
                                  콘텐츠 건수가 많은 순으로 10개의 시/군/구를
                                  보여줍니다.
                                </div>
                              </div>
                              <RegionSigunguChart
                                period={this.state.period}
                                startYearMonth={this.state.startYearMonth}
                                endYearMonth={this.state.endYearMonth}
                                regionCode={this.state.regionCode}
                                clickCount={this.state.clickCount}
                              />
                            </div>
                            <div className="st-box-cnt content-type-graph fx-grw">
                              <div>
                                <p className="st-box-tit">유형별 콘텐츠</p>
                                <div className="info-txt">
                                  <i className="ico ico-info-gy"></i>
                                  해당 지역의 유형별 콘텐츠 분포율을 보여줍니다.
                                </div>
                              </div>
                              <RegionCategoryChart
                                period={this.state.period}
                                startYearMonth={this.state.startYearMonth}
                                endYearMonth={this.state.endYearMonth}
                                regionCode={this.state.regionCode}
                                clickCount={this.state.clickCount}
                              />
                            </div>
                          </div>
                          <div className="st-box-cnt standard-graph">
                            <div>
                              <p className="st-box-tit">
                                표준분류체계별 콘텐츠
                              </p>
                              <div className="info-txt">
                                <i className="ico ico-info-gy"></i>
                                표준분류체계별 콘텐츠의 전체 평균 건수와 해당
                                지역의 건수를 보여줍니다. (단위: 건)
                              </div>
                            </div>
                            <div className="flex item-center">
                              <RegionStandardChart
                                period={this.state.period}
                                startYearMonth={this.state.startYearMonth}
                                endYearMonth={this.state.endYearMonth}
                                regionTitle={this.state.regionTitle}
                                regionCode={this.state.regionCode}
                                clickCount={this.state.clickCount}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="st-layer mt-40px">
                <div
                  className="statistics_graph_area"
                  style={{ maxWidth: "1400px" }}
                >
                  <h3 className="sga_tit" style={{ marginBottom: "20px" }}>
                    유형별 현황
                    <div className="tooltip">
                      <i
                        className="icon tooltip"
                        onMouseEnter={() =>
                          this.handleTooltip(this.categoryRef)
                        }
                        onMouseLeave={() =>
                          this.handleTooltip(this.categoryRef)
                        }
                      ></i>
                      <ul className="dot_list" ref={this.categoryRef}>
                        <li>
                          누적 콘텐츠 현황을 기반으로 콘텐츠 추이를 제공합니다.
                        </li>
                      </ul>
                    </div>
                  </h3>
                </div>

                <div
                  className="st-box status-type-graph"
                  style={{ height: "540px" }}
                >
                  <div className="st-box-cnt left column">
                    <div>
                      <div
                        className="info-txt"
                        style={{ position: "relative", left: "41px" }}
                      >
                        <i className="ico ico-info-gy"></i>
                        유형별 누적 콘텐츠 및 전년대비 증감율을 보여줍니다.
                      </div>
                      <div className="flex js-sb">
                        <div>
                          <p
                            className="st-box-tit"
                            style={{ paddingLeft: "40px" }}
                          >
                            관광정보 콘텐츠 추이
                          </p>
                          <CategoryAccumulateChart categoryName="관광정보" />
                        </div>
                        <div>
                          <p
                            className="st-box-tit"
                            style={{ paddingLeft: "40px" }}
                          >
                            이미지 콘텐츠 추이
                          </p>
                          <CategoryAccumulateChart categoryName="이미지" />
                        </div>
                      </div>
                      <ul className="accumulate-color-guide row mt-40px">
                        <li>
                          <i className="color-circle cFF58S9"></i>
                          <span>누적 콘텐츠</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="st-box-cnt right column">
                    <div>
                      <div
                        className="info-txt"
                        style={{ position: "relative", left: "40px" }}
                      >
                        <i className="ico ico-info-gy"></i>
                        12개월 기준 관광정보, 이미지 콘텐츠 건수를 보여줍니다.
                        (단위: 건)
                      </div>
                      <div>
                        <CategoryYearChart />
                      </div>
                    </div>
                    <ul className="graph-color-guide row mt-40px">
                      <li>
                        <i className="color-circle cFF58F8"></i>
                        <span>관광정보</span>
                      </li>
                      <li>
                        <i className="color-circle cFFCF52"></i>
                        <span>이미지</span>
                      </li>
                      <li>
                        <i className="color-circle c7C64FF"></i>
                        <span>동영상</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="st-layer mt-40px">
                <div className="statistics_graph_area">
                  <h3 className="sga_tit" style={{ marginBottom: "20px" }}>
                    어권별 현황
                    <div className="tooltip">
                      <i
                        className="icon tooltip"
                        onMouseEnter={() => this.handleTooltip(this.langRef)}
                        onMouseLeave={() => this.handleTooltip(this.langRef)}
                      ></i>
                      <ul className="dot_list" ref={this.langRef}>
                        <li>
                          9개의 어권별 전년동기 대비 전체 누적 콘텐츠 현황과
                          6개월 기준 월별 등록 콘텐츠 건수를 보여줍니다.
                        </li>
                        <li>
                          언어 선택 시 해당 언어의 월별 콘텐츠 등록 및 갱신
                          건수를 보여줍니다.
                        </li>
                      </ul>
                    </div>
                  </h3>
                </div>

                <div
                  className="st-box fx-grw status-language"
                  style={{ height: "600px" }}
                >
                  <div className="st-box-cnt">
                    <div className="info-txt">
                      <p
                        className="st-box-tit"
                        style={{ position: "relative", left: "40px" }}
                      >
                        전년동기 전체 콘텐츠
                      </p>
                      <div
                        style={{
                          display: "flex",
                          position: "relative",
                          left: "38px",
                        }}
                      >
                        <i
                          className="ico ico-info-gy"
                          style={{
                            position: "relative",
                            width: "15px",
                            top: "21px",
                            height: "15px",
                            left: "-146px",
                          }}
                        ></i>
                        <p
                          style={{
                            position: "relative",
                            top: "22px",
                            left: "-146px",
                          }}
                        >
                          9개의 어권별 전년동기 대비 누적 콘텐츠 현황 및 증감
                          건수를 보여줍니다.
                        </p>
                      </div>
                    </div>
                    <div className="info-txt">
                      <p
                        className="st-box-tit"
                        style={{
                          position: "relative",
                          left: "680px",
                          top: "-60px",
                        }}
                      >
                        월별 등록 및 갱신 콘텐츠
                      </p>
                      <div
                        style={{
                          display: "flex",
                          position: "relative",
                          left: "100px",
                        }}
                      >
                        <i
                          className="ico ico-info-gy"
                          style={{
                            position: "relative",
                            width: "15px",
                            top: "-42px",
                            height: "15px",
                            left: "410px",
                          }}
                        ></i>
                        <p
                          style={{
                            position: "relative",
                            top: "-41px",
                            left: "410px",
                          }}
                        >
                          6개월 기준 어권별 등록 및 갱신 콘텐츠 건수를
                          보여줍니다.(단위: 건)
                        </p>
                      </div>
                    </div>
                    <div className="flex js-sb mt-40px">
                      <div>
                        <LanguageContentChart
                          languageName={this.state.languageName}
                          setRenewalContent={this.setRenewalContent}
                          setLanguageName={this.setLanguageName}
                        />
                      </div>
                      <div>
                        <LanguageRegistrationChart
                          languageRenewalContent={
                            this.state.languageRenewalContent
                          }
                          languageName={this.state.languageName}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </>
    );
  }
}

export default ContentStatisticsPage;
