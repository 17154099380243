import ProposalState from './vo/ProposalState';
import NameValueList from '../../model/NameValue/NameValueList';
import Address from '../../lib/granule/AddressOn';
import DomainEntity from '../../lib/domain/ddd/DomainEntity';
import makeExtendedObservable from '../../lib/decorator/makeExtendedObservable';
import Phone from '../../lib/granule/Phone';
import IdName from '../../lib/domain/ddd/datatype/idname/IdName';
import { OrgType } from '../types/OrgType';


class OrgProposal extends DomainEntity {
  name: string;
  licenceId: string;
  manager: string;
  managerEmail: string;
  emailKey: string;
  code: string;
  managerTel: Phone | null = null;
  address: Address | null = null;
  orgTel: Phone | null = null;
  homePageUrl: string;

  department: string;
  departmentTel: Phone | null = null;

  // requester: Requester | null = null;
  requesterId: string;
  password: string;

  reviewer: IdName | null = null;
  reviewedTime: number;
  approver: IdName | null = null;
  approvedTime: number;
  rejector: IdName | null = null;
  rejectedTime: number;
  rejectMessage: string;
  marketingTime: number;
  marketingYn: boolean;

  state: ProposalState | null = null;
  orgType: OrgType | null = null;

  registrationTime: number = 0;

  // file
  fileboxId: string;
  fileIds: string[];

  constructor(
    name: string,
    licenceId: string,
    manager: string,
    managerEmail: string,
    emailKey: string,
    code: string,
    homePageUrl: string,
    department: string,
    requesterId: string,
    password: string,
    reviewedTime: number,
    approvedTime: number,
    rejectedTime: number,
    rejectMessage: string,
    fileboxId: string,
    fileIds: string[],
    marketingTime: number,
    marketingYn: boolean,
  ) {
    super();
    this.name = name;
    this.licenceId = licenceId;
    this.manager = manager;
    this.managerEmail = managerEmail;
    this.emailKey = emailKey;
    this.code = code;
    this.homePageUrl = homePageUrl;
    this.department = department;
    this.requesterId = requesterId;
    this.password = password;
    this.reviewedTime = reviewedTime;
    this.approvedTime = approvedTime;
    this.rejectedTime = rejectedTime;
    this.rejectMessage = rejectMessage;
    this.fileboxId = fileboxId;
    this.fileIds = fileIds;
    this.marketingTime = marketingTime;
    this.marketingYn = marketingYn;
    makeExtendedObservable(this);
  }

  static fromDomain(domain: OrgProposal): OrgProposal {
    const orgProposal = new OrgProposal(
      domain.name,
      domain.licenceId,
      domain.manager,
      domain.managerEmail,
      domain.emailKey,
      domain.code,
      domain.homePageUrl,
      domain.department,
      domain.requesterId,
      domain.password,
      domain.reviewedTime,
      domain.approvedTime,
      domain.rejectedTime,
      domain.rejectMessage,
      domain.fileboxId,
      domain.fileIds,
      domain.marketingTime,
      domain.marketingYn,
    );

    orgProposal.setDomainEntity(domain);
    orgProposal.state = domain.state;
    orgProposal.registrationTime = domain.registrationTime;
    orgProposal.orgType = domain.orgType;
    orgProposal.address = domain.address;
    orgProposal.reviewer = domain.reviewer;
    orgProposal.approver = domain.approver;
    orgProposal.rejector = domain.rejector;
    orgProposal.managerTel = domain.managerTel;
    orgProposal.orgTel = domain.orgTel;
    orgProposal.departmentTel = domain.departmentTel;
    orgProposal.fileIds = domain.fileIds;
    orgProposal.marketingTime = domain.marketingTime;
    orgProposal.marketingYn = domain.marketingYn;
    return orgProposal;
  }

  static fromDomains(domains: OrgProposal[]): OrgProposal[] {
    return domains.map((domain) => this.fromDomain(domain));
  }

  static asNameValues(model: OrgProposal): NameValueList {
    return NameValueList.fromModel(OrgProposal, model, {
      reviewer: JSON.stringify(IdName),
      reviewedTime: String,
      approver: JSON.stringify(IdName),
      approvedTime: String,
      rejector: JSON.stringify(IdName),
      rejectedTime: String,
      rejectMessage: String,
      state: String,
    });
  }

  static new(): OrgProposal {
    const orgProposal = new OrgProposal( '', '', '', '', '', '', '', '', '', '', 0, 0, 0, '', '', [], Date.now(), false);

    orgProposal.address = new Address();
    orgProposal.managerTel = new Phone();
    orgProposal.orgTel = new Phone();
    orgProposal.departmentTel = new Phone();

    return orgProposal;
  }

  get groupName() {
    if (!this.department && !this.department.trim()) {
      return this.name;
    }
    else {
      return `${this.name} / ${this.department}`;
    }
  }

  formatPhoneNumber(number: string) {
    return number.replace(/[^0-9]/g, '')
      .replace(/(^02|^050[2-9]{1}|^1[0-9]{3}|^0[0-9]{2})([0-9]+)?([0-9]{4})$/, '$1-$2-$3')
      .replace('--', '-');
  }

  get phoneNumber() {
    if (this.orgType === OrgType.Kto) {
      return this.departmentTel ? this.formatPhoneNumber(this.departmentTel.displayNumber) : '';
    }
    else {
      return this.orgTel ? this.formatPhoneNumber(this.orgTel.displayNumber) : '';
    }
  }

  get departmentPhone() {
    if (!this.departmentTel) {
      return '';
    }
    return this.formatPhoneNumber(this.departmentTel.displayNumber);
  }

  get groupPhone() {
    if (!this.orgTel) {
      return '';
    }
    return this.formatPhoneNumber(this.orgTel.displayNumber);
  }

  get managerPhone() {
    if (!this.managerTel) {
      return '';
    }
    return this.formatPhoneNumber(this.managerTel.displayNumber);
  }

  get fullAddress() {
    if (!this.address) {
      return '';
    }
    else if (!this.address.street) {
      return this.address.zipAddress;
    }
    else {
      return `${this.address.zipAddress} ${this.address.street}`;
    }
  }

  get lastModifyInfo() {
    if (this.state === ProposalState.ApproveRejected || this.state === ProposalState.ReviewRejected) {
      return {
        modifier: this.rejector?.name,
        modifiedTime: this.rejectedTime,
      };
    }
    else if (this.state === ProposalState.Approved) {
      return {
        modifier: this.approver?.name,
        modifiedTime: this.approvedTime,
      };
    }
    else if (this.state === ProposalState.Reviewed) {
      return {
        modifier: this.reviewer?.name,
        modifiedTime: this.reviewedTime,
      };
    }
    else {
      return {
        modifier: this.manager,
        modifiedTime: this.registrationTime,
      };
    }
  }

  get zipCode() {
    //
    return this.address && this.address.zipCode || '';
  }

  get zipAddress() {
    //
    return this.address && this.address.zipAddress || '';
  }

  get street() {
    //
    return this.address && this.address.street || '';
  }

  set zipCode(zipCode: string) {
    //
    if (!this.address) {
      const address = new Address();

      address.zipCode = zipCode;

      this.address = address;
    }
    else {
      this.address.zipCode = zipCode;
    }
  }

  set zipAddress(zipAddress: string) {
    //
    if (!this.address) {
      const address = new Address();

      address.zipAddress = zipAddress;
      this.address = address;
    }
    else {
      this.address.zipAddress = zipAddress;
    }
  }

  setAddress(name: string, value: any) {
    (this.address as any)[name] = value;
  }

  get formattedLicenceId() {
    return this.licenceId.replace(/[^0-9]/g, '')
      .replace(/(^[0-9]{3})([0-9]+)?([0-9]{5})$/, '$1-$2-$3')
      .replace('--', '-');
  }

}

export default OrgProposal;
