import React, { ContextType } from "react";
import "../../css/common.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Scrollbar, Autoplay } from "swiper/modules";
import SwiperCore from "swiper";
import ContentBuilder from "../../model/builder/ContentBuilder";
import KakaoMap from "../../components/KakaoMap";
import KakaoMultiMap from "../../components/KakaoMultiMap";
import LocInfo from "../../model/LocInfo";
import CourseContentsInfo from "../../pages/search/CourseContentsInfo";
import { registerUsage } from "../../shared/Function/util/RegisterUtil";
import { SearchType } from "../../model/type/SearchType";
import { UsageType } from "../../model/type/UsageType";
import CommonContext from "../../context/CommonContext";
import { ContentType } from "../../../src/model/type/ContentType";
import { CircularProgress } from "@mui/material";
import InterestsType from "../../model/type/interestsType";
import { downloadPDF } from "..";

interface Props {
  cid: string;
  interestsType: InterestsType;
  eventSetSelected: () => void;
  viewWidth: number | undefined;
  noPrev?: boolean; 
  onCloseModal: () => void;
  onClickThemeCloseModal: () => void;
  selectedTab: string;
  upDateImageSummaryDate: {
      categoryPath: string,
      cid: string,
      contentsName: string,
      imagePath: string,
      imageType: string,
      koglType: string,
      modificationTime: string,
      score: number,
      tid: string
    }[];
    upDateFormalSummaryDate: {
      nature: {
        list: any[],
        score: number,
        totalCount: number
      },
      history: {
        list: any[],
        score: number,
        totalCount: number
      },
      experience: {
        list: any[],
        score: number,
        totalCount: number
      },
      culture: {
        list: any[],
        score: number,
        totalCount: number
      },
      festival: {
        list: any[],
        score: number,
        totalCount: number
      },
      food: {
        list: any[],
        score: number,
        totalCount: number
      },
      accommodation: {
        list: any[],
        score: number,
        totalCount: number
      },
      shopping: {
        list: any[],
        score: number,
        totalCount: number
      },
      leisure: {
        list: any[],
        score: number,
        totalCount: number
      }
    };
    upDateCourseSummaryDate: {
      cid: string,
      contentsName: string,
      totalDistance: string,
      duration: string,
      imagePath: string
    }[];
    upDateCasualSummaryDate: {
      cid: string,
      contentsName: string,
      tid: string,
      tourismName: string,
      categoryPath: string,
      imagePath: string
    }[];
    upDateVideoSummaryDate: {
      cid: string,
      tid: string,
      contentsName: string,
      thumbnailPath: string,
      koglType: string,
      categoryPath: string,
      modificationTime: string
    }[];
    upDateFileSummaryDate: {
      cid: string,
      rowNumber: 1,
      tourismName: string;
      contentsName: string;
      fileType: string;
      cineroomName: string;
      registrationTime: string
    }[];
    selectedCategoryId: string;
    contentsType: string;
    showImage?: () => void;
    scrollToTop?: () => void;
}

interface State {
  contentDetailData: any;
  selectedCid: string;
  openDetailModal: undefined | "detail";
  showShareAlert: boolean;
  isloading: boolean;
  modifyDate: string;
  lastStatusUpdaterCineroomName: string;
}

class CourseContentsDetails extends React.Component<Props, State> {

  constructor(props:any) {
    super(props);
    this.onClickCloseModal=this.onClickCloseModal.bind(this);
    this.downloadPDF=this.downloadPDF.bind(this);
    this.setLoading=this.setLoading.bind(this);
  }

  static defaultProps: any = {
    noPrev: false
  };
  static contextType = CommonContext;
  context!: ContextType<typeof CommonContext>;

  state: State = {
    contentDetailData: {},
    selectedCid: '',
    openDetailModal: undefined,
    showShareAlert: false,
    isloading: false,
    modifyDate: '',
    lastStatusUpdaterCineroomName: '',
  };

  componentDidMount() {
    this.initContentDetailDataByCid(this.props.cid);
    this.updateViewCount();
  }

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
    snapshot?: any
  ): void {
    if (prevProps.cid !== this.props.cid) {
      this.initContentDetailDataByCid(this.props.cid);
      this.updateViewCount();
    }

    if(prevProps.upDateCourseSummaryDate !== this.props.upDateCourseSummaryDate){
      this.setState({contentDetailData : {}});
    }
  }

  async initContentDetailDataByCid(cid: string) {
    const { scrollToTop } = this.props;
    const contentBuilder = new ContentBuilder(cid);
    await contentBuilder.buildCourseContent();
    const contentDetailData = contentBuilder.getContentDetailInfo();
    await contentBuilder.findLastStatusCineroomName();
    console.log('lastStatusUpdaterCineroomName: ', contentBuilder.lastStatusUpdaterCineroomName);
    this.setState({ lastStatusUpdaterCineroomName: contentBuilder.lastStatusUpdaterCineroomName });
    const modifyDate = contentBuilder.contentDetail.modificationTime;
    if (modifyDate) {
      const currentDate = new Date(modifyDate);
      const year = currentDate.getFullYear();
      const month = String(currentDate.getMonth() + 1).padStart(2, '0');
      const day = String(currentDate.getDate()).padStart(2, '0');
      this.setState({modifyDate: `${year}년 ${month}월 ${day}일`})
    }
    this.setState({ contentDetailData: contentDetailData });
    if (scrollToTop) {
      scrollToTop();
    }
  }

  updateViewCount() {
    const { cid } = this.props;
    const { actorIdentity, citizenUser, accessibleCinerooms } = this.context;
    if (!cid) {
      return;
    }
    const currentActorId = actorIdentity?.id;
    const email = actorIdentity?.email;
    const cineroomId = citizenUser?.cineroomIds[0];
    const cineroomName = accessibleCinerooms[0]?.name;
    registerUsage(cid, currentActorId, email, cineroomId, cineroomName, SearchType.Course, UsageType.ViewCount);
  }

  renderImageView() {
    SwiperCore.use([Navigation, Pagination, Autoplay]);
    return (
      <Swiper
        //@ts-ignore
        modules={[Navigation, Pagination, Scrollbar]}
        spaceBetween={0}
        slidesPerView={4}
        navigation={{
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        }}
        breakpoints={{
          1500: {
            slidesPerView: 4,
            spaceBetween: 1,
          },
        }}
      >
        {this.renderCourseCarousel()}
        <div
          className="swiper-button-next"
          style={{
            width: "60px",
            height: "60px",
            background: "#fff",
            borderRadius: "50%",
            boxShadow: "0px 4px 16px 0px #0000003D",
            marginTop: "-40px",
            outline: "0",
          }}
        >
          <img src="/rms-conlab/images/move_next_L.svg" alt="move_next" />
        </div>
        <div
          className="swiper-button-prev"
          style={{
            width: "60px",
            height: "60px",
            background: "#fff",
            borderRadius: "50%",
            boxShadow: "0px 4px 16px 0px #0000003D",
            marginTop: "-40px",
            outline: "0",
          }}
        >
          <img
            src="/rms-conlab/images/move_previous_L.svg"
            alt="move_previous"
          />
        </div>
      </Swiper>
    );
  }

  renderCourseCarousel() {
    const { contentDetailData } = this.state;

    const cardList = contentDetailData.baseInfo.value.tourismList.value?.map((obj: any, index: any ) => {

      let imageSrc = ''

      if (obj.imagePath) {
        const originUrl = obj.imagePath;
        //it6 -> it7(치환)
        const newUrl = originUrl.replace("it8", "it12");
        console.log(newUrl, 'newUrl');
        imageSrc = newUrl;
      }
      return(
        <SwiperSlide key={index}>
          <img
            onContextMenu={(e) => (e.preventDefault())}
            style={{ width: "95%", cursor: 'pointer' }}
            className="layout-card-img"
            src={`${imageSrc}`}
            alt=""
            onClick={() => window.open(`/rms-conlab/detail?cid=${obj.cid}&type=FormalContents`, '_blank')}
          />
        </SwiperSlide>
      )

    })
    return cardList;
  }

  courseContentsCid(cid : string){
    this.setState({selectedCid : cid})
  }

  onClickCloseModal() {
    this.setState({ openDetailModal: undefined });
    document.body.style.cssText = "overflow: scroll";
  }

  onClickDetailModal() {
    this.setState({ openDetailModal: "detail"});
    document.body.style.cssText = "overflow: hidden";
  }

  courseDetailView() {
    const { contentDetailData } = this.state;

    const cardList = contentDetailData.baseInfo.value.tourismList.value?.map ((obj: any, index: any ) => {


      let imageSrc = ''

      if (obj.imagePath) {
        const originUrl = obj.imagePath;
        //it6 -> it7(치환)
        const newUrl = originUrl.replace("it8", "it12");
        console.log(newUrl, 'newUrl');
        imageSrc = newUrl;
      }

      return (
        <li key={index}>
          <div className="inner">
            <div className="day_info">
              <span className="num">{index + 1}</span>
              <h4 onClick={() => {this.courseContentsCid(contentDetailData.baseInfo.value.tourismList.value[index].cid); this.onClickDetailModal()}} style={{cursor: 'pointer'}}>
                {obj.contentsName}
                <img src="/rms-conlab/images/icon_paging_next.png" alt="icon" />
              </h4>
              <p>{obj.locInfo.roadAddress}</p>
            </div>
            <div className="img_box">
              <img
                onContextMenu={(e) => (e.preventDefault())}
                // style={{height: '200px'}}
                className="layout-card-img"
                src={`${imageSrc}`}
                alt=""
              />
            </div>
          </div>
        </li>
      )
    })
    return cardList;
  }

  onClickShare(){

    const { cid, interestsType } = this.props;
    const { actorIdentity, citizenUser, accessibleCinerooms } = this.context;

    const currentActorId = actorIdentity?.id;
    const email = actorIdentity?.email;
    const cineroomId = citizenUser?.cineroomIds[0];
    const cineroomName = accessibleCinerooms[0]?.name;

    const textarea = document.createElement('textarea');
    document.body.appendChild(textarea);

    const base_url = window.document.location.host;
    let urlValue = '';
    urlValue = `${base_url}/rms-conlab/detail?` + "cid=" + this.props.cid + "&type=" + ContentType.CourseContents;

    textarea.value = urlValue;
    textarea.select();
    document.execCommand('copy');
    document.body.removeChild(textarea);

    this.setState({ showShareAlert: true });
    setTimeout(() => this.setState({ showShareAlert: false }), 2000);

    registerUsage(cid, currentActorId, email, cineroomId, cineroomName, interestsType, UsageType.LinkCreated);
  }

  // pdf download strat
  downloadPDF = async () => {
    const { openLoginModal, isLogin } = this.context;

    if (!isLogin) {
      openLoginModal();
      return;
    }
    this.setLoading(true);
    downloadPDF(this.setLoading, this.props.cid, ContentType.CourseContents);
  };

  setLoading(isLoading: boolean) {
    this.setState({ isloading: isLoading })
  }
  // pdf download end

  render() {
    const { contentDetailData, openDetailModal, showShareAlert, isloading, modifyDate, lastStatusUpdaterCineroomName } = this.state;
    const { viewWidth, noPrev, eventSetSelected, onCloseModal, onClickThemeCloseModal, showImage } = this.props;

    if (Object.keys(contentDetailData).length === 0) {
      return;
    }

    const tourismList: {
      cid: string;
      contentsName: string;
      intro: string;
      locInfo: LocInfo;
    }[] = contentDetailData.baseInfo.value.tourismList.value;

    let locationInfo: LocInfo[] = [];

    for (let i = 0; i < tourismList.length; i++) {
      locationInfo.push(tourismList[i].locInfo);
    }

    return (
      <div className="rd_view_item">
        {/* @ts-ignore */}
        {viewWidth === undefined ? null :
          <div className="m_head">
          { noPrev === false && (<button className="rd_view_prev_bt" onClick={showImage ? ()=>{eventSetSelected(); showImage();} : () => (eventSetSelected())}><img src="/rms-conlab/images/icon_rd_view_prev.png" alt="close_icon"/></button>)}
              {this.props.contentsType === 'ThemeContents' ? null :
                  <button className="close_bt rd_view_close_bt" onClick={() => {onCloseModal(); onClickThemeCloseModal();}}><img src="/rms-conlab/images/icon_menu_close.svg" alt="close_icon"/></button>
              }
          </div>
        }
          <div className="cdp_tit_area" style={{justifyContent: 'end'}}>
            <div className="bts" style={{position: 'relative',top: '37px', right: '29px'}}>
                {isloading ?
                  <CircularProgress color="secondary" className="pdf-progress" style={{marginTop:'-4px'}}/> :
                  <button className="pdf-btn" style={{marginTop:'-4px'}} onClick={() => this.downloadPDF()}></button>
                }
                <div className='content-modify-date' style={{display: 'none'}}>{modifyDate}</div>
                <div className='content-cineroom-name' style={{display: 'none'}}>{lastStatusUpdaterCineroomName}</div>
                <button className="share" onClick={() => this.onClickShare()}></button>
                {
                    showShareAlert && <div className="share_copy_casual"><span>URL이 복사되었습니다. 원하는 곳에 붙여 넣으세요.</span></div>
                }
            </div>
          </div>
        <div className="contents_detail_page">
          <div className="cdp_tit_area course">
            <h2 className="cdp_tit">
              {contentDetailData.baseInfo.value.courseName.value}
            </h2>
          </div>
          <div className="cdp_slide_wrap swiper course">
            {this.renderImageView()}
          </div>
          <p
            className="course_txt"
            dangerouslySetInnerHTML={{
              __html: contentDetailData.baseInfo.value.courseIntro.value,
            }}
          ></p>

          <div className="day_tab_wrap">
            <div className="day_tab_contents">
              <div className="dt_con on">
                <div className="map_img_box">
                  <KakaoMultiMap clickMarker={locationInfo} />
                </div>
                <ul className="day_info_wrap">{this.courseDetailView()}</ul>
              </div>
            </div>
          </div>
        </div>

        {openDetailModal && openDetailModal === "detail" && (
          <CourseContentsInfo
            onCloseModal={onCloseModal}
            onClickCloseModal={this.onClickCloseModal}
            onClickThemeCloseModal={onClickThemeCloseModal} 
            selectedCid={this.state.selectedCid}
            selectedTab={this.props.selectedTab}
            selectedCategoryId={this.props.selectedCategoryId}
            upDateImageSummaryDate={this.props.upDateImageSummaryDate}
            upDateFormalSummaryDate={this.props.upDateFormalSummaryDate}
            upDateCourseSummaryDate={this.props.upDateCourseSummaryDate}
            upDateCasualSummaryDate={this.props.upDateCasualSummaryDate}
            upDateVideoSummaryDate={this.props.upDateVideoSummaryDate}
            upDateFileSummaryDate={this.props.upDateFileSummaryDate}
          />
        )}
        
      </div>
    );
  }
}

export default CourseContentsDetails;
