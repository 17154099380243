import fromDomain from "../../../../../lib/decorator/fromDomain";
import makeExtendedObservable from "../../../../../lib/decorator/makeExtendedObservable";
import DomainEntity from "../../../../../lib/domain/ddd/DomainEntity";
import NameValue from "../../../../../model/NameValue/NameValue";
import NameValueList from "../../../../../model/NameValue/NameValueList";

@fromDomain
class Notie extends DomainEntity {
  // meta info
  senderKey: string;       // citizenId || publicServantId
  senderName: string;      // name
  destinationKey: string;  // [citizenId || publicServantId] || [topicId] as BASE64 encoded
  broadcast: boolean;
  sentTime: number;

  // payload
  title: string;
  content: string;
  additionalInfo: NameValueList;

  //readingTest
  checked: boolean = false;

  constructor(
    senderKey: string,
    senderName: string,
    destinationKey: string,
    broadcast?: boolean,
    title?: string,
    content?: string,
    additionalInfo?: NameValueList) {
    super();
    this.senderKey = senderKey;
    this.senderName = senderName;
    this.destinationKey = destinationKey;
    this.broadcast = broadcast || false;
    this.sentTime = Date.now();
    this.title = title || '';
    this.content = content || '';
    this.additionalInfo = additionalInfo || new NameValueList();
    makeExtendedObservable(this);
  }

  static fromDomain(domain: Notie): Notie {
    //
    const notie = new Notie(
      domain.senderKey,
      domain.senderName,
      domain.destinationKey,
      domain.broadcast,
      domain.title,
      domain.content,
      domain.additionalInfo
    );

    notie.sentTime = domain.sentTime;
    notie.checked = domain.checked;
    notie.setDomainEntity(domain);
    return notie;
  }

  static fromDomains(domains: Notie[]): Notie[] {
    return domains.map(domain => this.fromDomain(domain));
  }

  static asNameValues(model: Notie): NameValueList {
    return NameValueList.fromModel(Notie, model, {
    });
  }

  static meta(senderKey: string, senderName: string, destinationKey: string, broadcast: boolean = false) {
    //
    return new Notie(senderKey, senderName, btoa(destinationKey), broadcast);
  }

  payload(title: string, content: string, additionalInfo: NameValueList = new NameValueList()) {
    //
    this.title = title;
    this.content = content;
    this.additionalInfo = additionalInfo;
    return this;
  }

  addInfo(key: string, value: string) {
    //
    this.additionalInfo.nameValues.push(new NameValue(key, value));
    return this;
  }
}

export default Notie;
