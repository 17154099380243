import { observer } from "mobx-react";
import autobind from "../../../../../lib/decorator/autobind/autobindDecorator";
import ReactComponent from "../../../../../lib/module/ReactComponent/ReactComponent";
import ServiceInjector from "../../../../../lib/module/ServiceInjector/Serviceinjector";
import AddressStateKeeper from "../../../../state/Address/keeper/AddressStateKeeper";
import Address from "../../../../../lib/granule/Address";
import AddressesStateKeeper from "../../../../state/Address/keeper/AddressesStateKeeper";
import React from "react";
import Offset from "../../../../../lib/domain/offset/Offset";
import CommonModal from "../../../../../conlab/CommonModal";
import GeoCoordinate from "../../../../../lib/granule/GeoCoordinate";
import { Button, FormControl, Icon, InputLabel, MenuItem, Select, TablePagination, TextField } from "@mui/material";
import { findAddressByJusoAPI } from "../../../../../shared/Function/address/Function";

interface Props {
  onClick?: (event: React.MouseEvent, address: Address, jibunSelected: boolean) => void;
  onClose?: () => void;
  selectAddressType?: boolean;
}

interface State {
  searchingStart: boolean;
  page: number;
  rowsPerPage: number;
  totalCount: number;
  open: boolean;
  openModal: boolean;
  modalText: string;
}

interface InjectedProps {
  addressesStateKeeper: AddressesStateKeeper;
  addressStateKeeper: AddressStateKeeper;
}

@autobind
@observer
class ContentContainer extends ReactComponent<Props, State, InjectedProps> {
  //

  ref: React.RefObject<HTMLInputElement>;

  state: State = {
    searchingStart: false,
    page: 0,
    rowsPerPage: 10,
    totalCount: 0,
    open: false,
    openModal: false,
    modalText: '',
  };

  constructor(props: Props) {
    super(props);
    this.ref = React.createRef();
  }

  componentDidMount() {
    const { addressesStateKeeper } = this.injected;
    addressesStateKeeper.init();
  }

  async init() {
    const { addressesStateKeeper } = this.injected;
    const { page, rowsPerPage } = this.state;
    const { searchText, lang, setAddresses } = addressesStateKeeper;
    const offset = Offset.newAscending(page * rowsPerPage, rowsPerPage, 'streetAddressJson');
    
    const addresses = await findAddressByJusoAPI(offset, searchText);
    const arr: Address[] = [];
    for(let i = 0; i < addresses.queryResult.length; i++) {
        const add = new Address(
            addresses.queryResult[i].countryCode,
            addresses.queryResult[i].city,
            addresses.queryResult[i].state,
            addresses.queryResult[i].zipCode,
            addresses.queryResult[i].fullAddress,
            addresses.queryResult[i].addressBookId,
            addresses.queryResult[i].userId,
            addresses.queryResult[i].emdNm,
            addresses.queryResult[i].liNm,
            addresses.queryResult[i].basicAddress,
            addresses.queryResult[i].roadAddr,
        );
        add.setGeoCoordinate(addresses.queryResult[i].geoCoordinate);
        add.setStreetAddress(addresses.queryResult[i].streetAddress);
        add.setField(addresses.queryResult[i].field);
        add.setAddressCode(addresses.queryResult[i].addressCode);
        arr.push(add);
    }

    addressesStateKeeper.setAddresses(arr);

    this.setState({
      totalCount: addresses.offset.totalCount,
      open: false,
    });
  }

  initPage() {
    this.onChangePage(0);
  }

  onChangeFilter(name: string, value: number) {
    this.setState({
      ...this.state,
      [name]: value,
    }, () => {
      this.init();
    });
  }

  onChangePage(page: number) {
    this.setState({
      page,
    }, () => {
      this.init();
    });
  }

  onChangeRowsPerPage(rowsPerPage: number) {
    this.setState({
      rowsPerPage,
    });
  }

  onChangeSearchText(text: string) {
    const { addressesStateKeeper } = this.injected;

    addressesStateKeeper.setSearchText(text);
  }

  onChangeLang(event: React.ChangeEvent<{ name?: string; value: unknown }>) {
    const { addressesStateKeeper } = this.injected;

    addressesStateKeeper.setLang(event.target.value as string);
  }

  openModalFc(txt: string) {
    this.setState({ openModal: true, modalText: txt });
  }

  async validation(): Promise<boolean> {
    let validate = false;
    const { searchText } = this.injected.addressesStateKeeper;
    //특정문자열(sql예약어의 앞뒤공백포함) 제거
    const sqlArray = [
      //sql 예약어
      'OR', 'SELECT', 'INSERT', 'DELETE', 'UPDATE', 'CREATE', 'DROP', 'EXEC',
      'UNION', 'FETCH', 'DECLARE', 'TRUNCATE',
    ];

    //특수문자 제거
    const expText = /[%=><]/;

    if (expText.test(searchText) || !searchText.trim()) {
      this.openModalFc('특수 문자 및 빈 검색어는 입력 할 수 없습니다.');
      validate = true;
    }

    let regex;


    // eslint-disable-next-line no-restricted-syntax
    for (const sqlWord of sqlArray) {
      const regexText = '^(' + sqlWord + ')\\s+|\\s+(' + sqlWord + ')\\s+|\\s+(' + sqlWord + ')$';

      regex = new RegExp(regexText, 'gi');
      if (regex.test(searchText)) {
        this.openModalFc('특수 문자 및 빈 검색어는 입력 할 수 없습니다.');
        validate = true;
      }



    }

    return validate;
  }

  async onSearchBtnClick() {
    const { addressesStateKeeper } = this.injected;
    const { page, rowsPerPage } = this.state;
    const { searchText, lang } = addressesStateKeeper;

    if (await this.validation()) {
      return;
    }

    const offset = Offset.newAscending(page, rowsPerPage, 'streetAddressJson');

    if (offset.offset !== 0) {
      offset.offset = 0;
    }

    const addresses = await findAddressByJusoAPI(offset, searchText);
    const arr: Address[] = [];
    for(let i = 0; i < addresses.queryResult.length; i++) {
        const add = new Address(
            addresses.queryResult[i].countryCode,
            addresses.queryResult[i].city,
            addresses.queryResult[i].state,
            addresses.queryResult[i].zipCode,
            addresses.queryResult[i].fullAddress,
            addresses.queryResult[i].addressBookId,
            addresses.queryResult[i].userId,
            addresses.queryResult[i].emdNm,
            addresses.queryResult[i].liNm,
            addresses.queryResult[i].basicAddress,
            addresses.queryResult[i].roadAddr,
        );
        add.setGeoCoordinate(addresses.queryResult[i].geoCoordinate);
        add.setStreetAddress(addresses.queryResult[i].streetAddress);
        add.setField(addresses.queryResult[i].field);
        add.setAddressCode(addresses.queryResult[i].addressCode);
        arr.push(add);
    }
    addressesStateKeeper.setAddresses(arr);

    this.setState({
      searchingStart: true,
      totalCount: addresses.offset.totalCount,
      page: 0,
    });
    this.onClickDirectInputClose();
  }

  onClickDirectInputOpen(clickAddress: Address) {
    const { addressStateKeeper } = this.injected;

    const address = { ...clickAddress } as Address;

    address.geoCoordinate = GeoCoordinate.new();
    address.fullAddress = address.basicAddress;

    addressStateKeeper.setAddress(address);

    this.setState({ open: true });
  }

  onClickDirectInputClose() {
    const { addressStateKeeper } = this.injected;

    addressStateKeeper.setAddress(Address.new('', ''));
    this.setState({ open: false });
  }

  onChangAddress(key: string, value: string) {
    const { addressStateKeeper } = this.injected;
    const { address } = addressStateKeeper;

    if (key === 'fullAddress' && address) {
      // address.
    }
    addressStateKeeper.setAddressProp(key, value);
  }

  async onClickRegistration(event: React.MouseEvent) {
    const { onClick } = this.props;
    const { address } = this.injected.addressStateKeeper;

    const latitude = /^(\+|-)?(?:90(?:(?:\.0{1,40})?)|(?:[0-9]|[1-8][0-9])(?:(?:\.[0-9]{1,40})?))$/;
    const longitude = /^(\+|-)?(?:180(?:(?:\.0{1,40})?)|(?:[0-9]|[1-9][0-9]|1[0-7][0-9])(?:(?:\.[0-9]{1,40})?))$/;

    if (address && address.geoCoordinate) {
      if (!address.geoCoordinate.latitude.trim().length) {
        this.openModalFc('WGS84 (latitude) 좌표 값을 입력해 주세요.');
        return;
    }
    else if (!latitude.test(address.geoCoordinate.latitude)) {
        this.openModalFc(`잘못된 형식의 좌표 값입니다. \n WGS84 (latitude) 좌표 값을 다시 입력해 주세요.`);
        return;
    }
    
    if (!address.geoCoordinate.longitude.trim().length) {
        this.openModalFc('WGS84 (longitude) 좌표 값을 입력해 주세요.');
        return;
    }
    else if (!longitude.test(address.geoCoordinate.longitude)) {
        this.openModalFc(`잘못된 형식의 좌표 값입니다. \n WGS84 (longitude) 좌표 값을 다시 입력해 주세요.`);
        return;
      }
    }

    this.onClickDirectInputClose();
    if (onClick && address) {
      onClick(event, address, true);
    }
  }

  closeModal() {
    this.setState({ openModal: false, modalText: '' });
  }

  render() {
    const { addresses, searchText, lang } = this.injected.addressesStateKeeper;
    const { searchingStart, page, rowsPerPage, totalCount, open } = this.state;
    const { onClick, selectAddressType } = this.props;

    const searchingResult = (addressList: Address[]) => (addressList && addressList.length ? (
      <div className="search-result-address">
        <div className="tit">
          검색결과 <strong className="b txt-primary">{totalCount}</strong> 건
        </div>
        <div className="inner">
          {addressList.map((address) => (
            <Button
              className="button search-address-list"
              onClick={(evt) => (!selectAddressType && onClick ? onClick(evt, address, false) : null)}
              key={address.id}
            >
              <i className="icon active" />
              <div className="txt">
                <div>
                  <span className="i-bx active">도로명</span>
                  <strong>
                    {address.state + " " + address.city + " " + address.streetAddress?.street+ " " + address.streetAddress?.building}
                  </strong>
                </div>
                <div>
                  <span className="i-bx">지번</span>
                  {address.fullAddress}
                </div>
              </div>
              <div className="post-num">{address.zipCode}</div>
              {selectAddressType ?
                <>
                  <Button
                    className="btn-init fill primary medium space"
                    onClick={(evt) => (onClick ? onClick(evt, address, false) : null)}
                    key={address.roadAddress}
                    variant={'contained'}
                  >
                    도로명 등록
                  </Button>
                  <Button
                    className="btn-init fill primary medium space"
                    onClick={(evt) => (onClick ? onClick(evt, address, true) : null)}
                    key={address.fullAddress}
                    variant={'contained'}
                  >
                    지번 등록
                  </Button>
                  <Button
                    className="btn-init fill primary medium space"
                    onClick={() => this.onClickDirectInputOpen(address)}
                    key={'directInput'}
                    variant={'contained'}
                  >
                    직접 입력
                  </Button>
                </>
                : ''
                }
            </Button>
          ))}
        </div>
        <TablePagination
          id={'addressSearch'}
          component={'label'}
          style={{ float: 'right' }}
          count={totalCount}
          page={page}
          onPageChange={(evt, pageNum) => this.onChangePage(pageNum)}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={(evt) => this.onChangeFilter('rowsPerPage', evt.target.value as unknown as number)}
        />
      </div>
    )
      : (
        <>
          <div className="search-inf-wrap">
            <div>
              <span className="txt-primary">건물번호</span> 또는
              <span className="txt-primary">번지수</span>를 같이
              입력하면 더 빨라요!
            </div>
            <ul>
              <li>한강대로 416 (도로명 + 건물번호)</li>
              <li>반포자이아파트 (건물명, 아파트명)</li>
              <li>남대문로5가 541 (동/읍/면/리 + 번지)</li>
            </ul>
          </div>
          <div className="search-result-address">
            <div className="inner">
              <div className="empty" style={{ minHeight: 200 }}>
                <i className="icon">
                  <span> <strong className="b">검색 결과가 존재하지 않습니다.</strong></span>
                </i>
              </div>
            </div>
          </div>
        </>
      ));

    const { address } = this.injected.addressStateKeeper;
    return (
      <div className="modal-inner">
        <div className="layout-flex layout-gap layout-mt-10">
          {/* <FormControl variant="outlined" size="small">
            <InputLabel htmlFor="selectTourism">언어</InputLabel>
            <Select id="selectTourism" label="언어 선택" value={lang} onChange={() => this.onChangeLang}>
              <MenuItem value={'ko'} key={'1'}>한국어</MenuItem>
              <MenuItem value={'en'} key={'2'}>{'영어　'}</MenuItem>
            </Select>
          </FormControl> */}
          <TextField
            className="label-top label-hidden"
            inputRef={this.ref}
            fullWidth
            id="addressSearchText"
            label="검색"
            placeholder="도로명 + 건물번호, 건물명, 지번을 입력하세요"
            variant="outlined"
            style={{ width: 610 }}
            value={searchText}
            onChange={(event) => this.onChangeSearchText(event.target.value)}
            onKeyPress={(e) => e.key === 'Enter' && this.onSearchBtnClick()}
            // onKeyPress={
            //   (e) => {
            //     if (e.key === 'Enter') {
            //       this.onSearchBtnClick();
            //
            //     }
            //     // e.key === 'Enter' && this.onSearchBtnClick()
            //   }}
          />
          <Button
            className="btn-init outline primary icon space"
            style={{ minWidth: 100 }}
            onClick={this.onSearchBtnClick}
          >
            <Icon style={{ fontSize: 21 }}>search</Icon>
            <span style={{color: '#f3702a'}}>검색</span>
          </Button>
        </div>
        {
          open && address && address.geoCoordinate && (
            <>
              <div className="label-has line">
                <TextField
                  className="layout-flex line label-top label-hidden"
                  fullWidth
                  id="fullAddress"
                  label="fullAddress"
                  placeholder="주소"
                  variant="outlined"
                  style={{ marginTop: '10px' }}
                  value={address.fullAddress}
                  disabled
                />
              </div>
              <div className="label-has line">
                <TextField
                  className="layout-flex1 label-top label-hidden"
                  id="latitude"
                  label="latitude"
                  placeholder="WGS84 (latitude) 좌표 값 입력"
                  variant="outlined"
                  style={{ marginTop: '10px' }}
                  type={'number'}
                  value={address.geoCoordinate.latitude}
                  onChange={(event) => this.onChangAddress('geoCoordinate.latitude', event.target.value)}
                />
                <TextField
                  className="layout-flex1 label-top label-hidden"
                  id="longitude"
                  label="longitude"
                  placeholder="WGS84 (longitude) 좌표 값 입력"
                  variant="outlined"
                  style={{ marginTop: '10px' }}
                  type={'number'}
                  value={address.geoCoordinate.longitude}
                  onChange={(event) => this.onChangAddress('geoCoordinate.longitude', event.target.value)}
                />

                <Button
                  className="btn-init outline primary icon space"
                  style={{ minWidth: 100, marginTop: '10px' }}
                  onClick={(evt) => this.onClickRegistration(evt)}
                >
                  <Icon>keyboard_tab</Icon>
                  <span>등록</span>
                </Button>
              </div>

            </>
          )
        }

        { this.state.openModal ? <CommonModal message={this.state.modalText} close={this.closeModal} /> : null }

        {searchingStart ? searchingResult(addresses) :
        <>
          <div className="search-inf-wrap">
            <div>
              <span className="txt-primary">건물번호</span> 또는
              <span className="txt-primary">번지수</span>를 같이
              입력하면 더 빨라요!
            </div>
            <ul>
              <li>한강대로 416 (도로명 + 건물번호)</li>
              <li>반포자이아파트 (건물명, 아파트명)</li>
              <li>남대문로5가 541 (동/읍/면/리 + 번지)</li>
            </ul>
          </div>
          <div className="search-result-address">
            <div className="inner">
              <div className="empty" style={{ minHeight: 200 }}>
                <i className="icon"></i>
                <div>
                    <span> <strong className="b">검색할 주소</strong>를 입력해주세요. </span>
                </div>
              </div>
            </div>
          </div>
        </>
        }
      </div>
    );
  }

}

// eslint-disable-next-line react-hooks/rules-of-hooks
export default ServiceInjector.useContext(
    AddressesStateKeeper,
    AddressStateKeeper
  )(ContentContainer);
