import makeExtendedObservable from "../decorator/makeExtendedObservable";

class StreetAddress {
  street: string;
  building: string | null;

  constructor(street: string, building: string | null) {
    this.street = street;
    this.building = building;

    makeExtendedObservable(this);
  }

  static fromDomain(domain: StreetAddress): StreetAddress {
    return new StreetAddress(
      domain.street,
      domain.building
    );
  }

  static fromDomains(domains: StreetAddress[]): StreetAddress[] {
    return domains.map(domain => this.fromDomain(domain));
  }
}

export default StreetAddress;
