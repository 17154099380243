import React from 'react';
import axios from 'axios';
import { LabelList, ComposedChart, Line, Bar, XAxis, YAxis, CartesianGrid, Tooltip } from 'recharts';

interface Props {
    regionCode : number;
    regionTitle : string;
    startYearMonth : string;
    endYearMonth : string;
    period : string;
    clickCount: number;
    viewWidth: number | undefined;
}

interface State {
    chartData : []
}

class RegionStandardChartMobile extends React.Component<Props, State> {

    state: State = {
        chartData : [],
    };

    componentDidMount() {
        this.regionStandardChart();
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
        if (this.props.period != prevProps.period || this.props.regionCode != prevProps.regionCode || this.props.clickCount != prevProps.clickCount){
            this.regionStandardChart();
        } 
    }

    async regionStandardChart() {
        try {
            const response = await axios.get(`/api/naomi/v2/statistics/region-standard-chart` ,{    
                params : {
                    period : this.props.period,
                    beginYM : this.props.startYearMonth,
                    endYM : this.props.endYearMonth,
                    code : this.props.regionCode
                }
            });
            this.setState({chartData: response.data.queryResult});
        } catch (e) {
            console.error(e);
        }
    }
    

    renderCustomizedLabel(props : any) {
        const { x, y, value } = props;

        return (
            <text x={x} y={y-10} fill="black" textAnchor="middle">
                <tspan>{value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</tspan>
            </text>
        );
    };

    renderCustomText(props: any) {
        const { x, y, payload, width, height, } = props;

        if(payload.index === 4){
            payload.value = '레저'
        }

        if(payload.index === 5){
            payload.value = '축제'
        }
        
        // 문자열을 개별 문자로 분리하고 역순으로 배열
        const characters = payload.value.split('')

        // 각 문자에 대한 tspan 요소 생성
        const tspans = characters.map((char: any, index:any) => (
            <tspan x={x} y={y + index * 10}  dy="0.71em"  dx="2.0em" key={index}>
                {char}
            </tspan>
        ));
    
        return (
            <text className="recharts-text recharts-cartesian-axis-tick-value" fill={props.fill} width={width} height={height} x={x} y={y} type="category" stroke="none" orientation="bottom" textAnchor="middle">
                {tspans}
            </text>
        );
    }

        render() {

        //@ts-ignore
        const combine = this.props.viewWidth - 40

            return(
                <>
                <div className='region-standard-chart-view' style={{paddingTop: '30px', height:'350px'}}>

                    <ComposedChart width={combine} height={300} data={this.state.chartData} margin={{top: 20, right: 1, bottom: 20, left: 1,}}>
                        <CartesianGrid stroke="#f5f5f5"/>
                        <XAxis scale="band" dataKey="categoryName" interval={0} tick={this.renderCustomText} tickLine={false}/>
                        <YAxis type="number" tickFormatter={tick => {return tick.toLocaleString();}} tickCount={7}/>
                        <Tooltip formatter={(value : any) => value && value.toLocaleString()}/>
                        <Bar className='region-standard-bar' name={this.props.regionTitle} dataKey="area" barSize={20} fill="#5fc6c5"></Bar>
                        <Line name="전체평균" type="monotone" dataKey="average" stroke="#608bfd">
                            <LabelList content={this.renderCustomizedLabel} dataKey="average" position="top"/>
                        </Line>
                    </ComposedChart>

                    {/* {
                    this.props.viewWidth <= 360 ? 
                    <ComposedChart width={300} height={300} data={this.state.chartData} margin={{top: 20, right: 1, bottom: 20, left: 1,}}>
                        <CartesianGrid stroke="#f5f5f5"/>
                        <XAxis scale="band" dataKey="categoryName" interval={0} tick={this.renderCustomText}/>
                        <YAxis type="number" tickFormatter={tick => {return tick.toLocaleString();}} tickCount={7}/>
                        <Tooltip formatter={(value : any) => value && value.toLocaleString()}/>
                        <Bar className='region-standard-bar' name={this.props.regionTitle} dataKey="area" barSize={20} fill="#5fc6c5"></Bar>
                        <Line name="전체평균" type="monotone" dataKey="average" stroke="#608bfd">
                            <LabelList content={this.renderCustomizedLabel} dataKey="average" position="top"/>
                        </Line>
                    </ComposedChart>
                    : 
                        (this.props.viewWidth <= 585 ? 
                            <ComposedChart width={400} height={300} data={this.state.chartData} margin={{top: 20, right: 1, bottom: 20, left: 1,}}>
                            <CartesianGrid stroke="#f5f5f5"/>
                            <XAxis scale="band" dataKey="categoryName" interval={0} tick={this.renderCustomText}/>
                            <YAxis type="number" tickFormatter={tick => {return tick.toLocaleString();}} tickCount={7}/>
                            <Tooltip formatter={(value : any) => value && value.toLocaleString()}/>
                            <Bar className='region-standard-bar' name={this.props.regionTitle} dataKey="area" barSize={20} fill="#5fc6c5"></Bar>
                            <Line name="전체평균" type="monotone" dataKey="average" stroke="#608bfd">
                                <LabelList content={this.renderCustomizedLabel} dataKey="average" position="top"/>
                            </Line>
                        </ComposedChart>
                        : 
                        <ComposedChart width={600} height={300} data={this.state.chartData} margin={{top: 20, right: 1, bottom: 20, left: 1,}}>
                        <CartesianGrid stroke="#f5f5f5"/>
                        <XAxis scale="band" dataKey="categoryName" interval={0} tick={this.renderCustomText}/>
                        <YAxis type="number" tickFormatter={tick => {return tick.toLocaleString();}} tickCount={7}/>
                        <Tooltip formatter={(value : any) => value && value.toLocaleString()}/>
                        <Bar className='region-standard-bar' name={this.props.regionTitle} dataKey="area" barSize={20} fill="#5fc6c5"></Bar>
                        <Line name="전체평균" type="monotone" dataKey="average" stroke="#608bfd">
                            <LabelList content={this.renderCustomizedLabel} dataKey="average" position="top"/>
                        </Line>
                    </ComposedChart>
                        )
                    } */}
                </div>
                    <ul className="standard_color-guide" style={{display: 'flex', justifyContent: 'center', width: '100%', paddingTop: '20px'}}>
                        <li>
                            <i className="color-circle cFF58F9"></i>
                            <span>{this.props.regionTitle}</span>
                        </li>
                        <li>
                            <i className="color-circle cFFCF59"></i>
                            <span>전체평균</span>
                        </li>
                    </ul>
                </>
            )
        }
    }

export default RegionStandardChartMobile;

