
import React, { ContextType, Component } from 'react';
import { IReactComponent } from '../../../type/IReactComponent';
import { Constructor } from '../../../type/Constructor';
import { IWrappedReactComponent } from '../../../type/IWrappedReactComponent';
import reactUtils from './reactUtils/reactUtils';

import ServiceModel from '../model/ServiceModel';
import ServiceInjectorContext from '../context/ServiceInjectorContext';
import injectService from './injectService';


interface State<TProps> {
  InjectedComponent: IReactComponent<TProps> | null;
}

const useContext = (...ServiceTypes: Constructor[]) =>
  <TProps extends {}>(ReactComponent: IReactComponent<TProps>): IWrappedReactComponent<TProps> => {
    //
    class UseContextService extends Component<TProps, State<TProps>> {
      //
      static contextType = ServiceInjectorContext;

      context!: ContextType<typeof ServiceInjectorContext>;

      state: State<TProps> = {
        InjectedComponent: null,
      };

      componentDidMount() {
        //
        const { serviceInjector } = this.context;
        const usableServices: ServiceModel[] = serviceInjector.getUsableServices(ServiceTypes);

        this.setState({ InjectedComponent: injectService(usableServices)(ReactComponent) });
      }

      render() {
        //
        const { InjectedComponent } = this.state;

        if (!InjectedComponent) {
          return null;
        }

        return (
          <InjectedComponent
            {...this.props}
          />
        );
      }
    }

    return reactUtils.copyWrappedComponent(UseContextService, ReactComponent);
  };

export default useContext;
