import React, { useState } from 'react';
import "../../css/style.min.css";
import axios from 'axios';
import CastleCdo from '../../model/Castle/CastleCdo';
import NameValueList from '../../model/NameValue/NameValueList';
import NameValue from '../../model/NameValue/NameValue';
import { createTopCitizen } from '../../shared/Function/checkpoint/Function';
import CitizenIdentityCdo from '../../comp/api/pavillion/api-model/sdo/CitizenIdentityCdo';
import Name from '../../comp/shared/api-model/Name';
import FailureMessage from '../../lib/cqrs/core/model/api/FailureMessage';
import CommonModal from '../../conlab/CommonModal';
import { domainURL, failSsoId, getSsoId, topSessionclear } from '../../conlab';
import { apiURL } from '../../shared/Function';
import OrgProposal from '../api-model/OrgProposal';

interface Step3Props {
  onPrevStep: () => void;
  onNextStep: () => void;
  
  validateEmail: boolean;
  orgProposal: OrgProposal
}

export const IndividualFormView = (props: Step3Props ) => {
  const { onPrevStep, onNextStep, validateEmail, orgProposal } = props;
  const [submitBtn, setSubmitBtn] = useState(false);

  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  
  const [openModal, setOpenModal] = useState(false);
  const [modalText, setModalText] = useState('');

  const phoneValidate = (target: string) => {

    const pattern1 = new RegExp(/^\d{2,3}-\d{3,4}-\d{4}$/i);
    const pattern2 = new RegExp(/^\d{4}-\d{4}$/i);


    return pattern1.test(target) || pattern2.test(target);
  };

  const formatTelNumber = (value: string ) => value.replace(/[^0-9]/g, '')
    .replace(/(^02|^050[2-9]{1}|^1[0-9]{3}|^0[0-9]{2})([0-9]+)?([0-9]{4})$/, '$1-$2-$3')
    .replace('--', '-');


  const integratedValidationCheck = () => {
    if (!validateEmail || !orgProposal.managerEmail) {
      openModalTxt(`이메일은 필수입력 항목입니다. \n 이메일을 입력해주세요.`);
      return false;
    }
    if (name.length === 0) {
      openModalTxt(`성명은 필수입력 항목입니다. \n 성명을 입력해주세요.`);
      return false;
    }
    if (phone.length === 0) {
      openModalTxt(`연락처는 필수입력 항목입니다. \n 연락처를 입력해주세요.`);
      return false;
    }
    if (!phoneValidate(formatTelNumber(phone))) {
      openModalTxt('연락처 형식에 맞게 입력해주세요.');
      return false;
    }

    return true;
  };

  const onChangePhone = (e: any) => {
    const source = e.target.value as string;

    setPhone(source);

  };

  const onClickPrivateJoin = async (token: string) => {
    // 중간에 session을 삭제한 경우
    const ssoId = getSsoId();
    if (!ssoId) {
      openModalTxt(failSsoId);
      return;
    }
    const nameModel = new Name();
    nameModel.givenName = name;
    const data = {
      citizenIdentityCdo: new CitizenIdentityCdo('', '', nameModel, orgProposal.managerEmail, token, '', Date.now(), orgProposal.marketingYn),
      domainUrl: domainURL,
      loginId: orgProposal.managerEmail,
      password: null,
      additionalInfo: new NameValueList(new NameValue('phone', phone))
    }
    setSubmitBtn(true);
    await axios.post(apiURL+'/api/metro/flow/sign-up-flow/member/signup-citizen-by-private', data)
      .then(async response => {
        await createTopCitizen(response.data.entityIds[0], ssoId);
        topSessionclear();
        onNextStep();
      })
      .catch(err => {
        openModalTxt('가입에 실패했습니다. 관리자에게 문의하세요.');
      });
  }

  const onClickNext = async () => {
    if (integratedValidationCheck()) {
      const data = {
          castleCdo: new CastleCdo(''),
          castleCdos: [],
          multiCdo: null,
          castleId: null,
          nameValues: null,
          cqrsBaseCommandType: "Register"
      }

      type Result = {
        data: {
          entityIds: string[],
          result: boolean,
          requestFailed: boolean,
          failureMessage: FailureMessage,
        }
      };
      await axios.post(apiURL+'/api/metro/flow/sign-up-flow/castle/command', data)
          .then(async (response) => {
              const res = response as Result;
              const result = { result: false, castleId: '' };
              if (res.data.entityIds && res.data.entityIds.length) {
                  result.result = true;
                  result.castleId = res.data.entityIds[0];
              }
              await onClickPrivateJoin(result.castleId);
          });
    }
  }

  const closeModal = () => {
    setModalText('');
    setOpenModal(false);
  }

  const openModalTxt = (txt: string) => {
    setModalText(txt);
    setOpenModal(true);
  }
  
  return (
    <>
      {
        openModal ? <CommonModal message={modalText} close={closeModal} /> : null
      }
      <section className="step-wrap">
        <div className="step step-1">
          <div className="step-title">
              <h2>개인정보</h2>
          </div>
          <div className="step-content">
              <div className="info-list">
                  <label>담당자 성명 <span className="required"></span></label>
                  <input
                    type="text" placeholder="이름을 입력하세요." className="input-box"
                    id="manager" name="manager" onChange={e => setName(e.target.value)} required
                  />
              </div>
              <div className="info-list">
                  <label>연락처 <span className="required"></span></label>
                  <input
                    type={'tel'}
                    id="managerTel"
                    name="managerTel"
                    onChange={onChangePhone}
                    value={formatTelNumber(phone)}
                    required
                    placeholder="연락처를 입력 하세요." className="input-box" maxLength={13}
                  />
              </div>
          </div>

          <div className="step-btns">
            <button className="btn-outline" onClick={onPrevStep}>취소</button>
            <button className="btn-default" onClick={onClickNext} disabled={submitBtn}>다음</button>
          </div>
        </div>
      </section>
    </>
  );
};
