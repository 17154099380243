import { makeAutoObservable } from 'mobx';
import mobxService from '../../../../lib/decorator/mobxService/mobxService';
import Address from '../../../../lib/granule/Address';


@mobxService
class AddressesStateKeeper {
  static readonly instanceName: string = 'addressesStateKeeper';
  static instance: AddressesStateKeeper;

  addresses: Address[] = [];

  searchText: string = '';

  lang: string = 'ko';

  hasSearch: boolean = false;

  constructor() {
    makeAutoObservable(this);
  }

  init() {
    this.addresses = [Address.new('', '')];
  }

  setSearchText(value: string) {
    this.searchText = value;
  }

  setHasSearch(search: boolean) {
    this.hasSearch = search;
  }

  setLang(lang: string) {
    this.lang = lang;
  }

  setAddressProp(index: number, name: keyof Address, value: any) {
    if (!this.addresses || !this.addresses[index]) {
      throw new Error(`AddressesStateKeeper.setAddressProp -> addresses[${index}] is null`);
    }
    (this.addresses[index] as any)[name] = value;
  }

  setAddresses(addresses: Address[]) {
    this.addresses = addresses;
  }

  clear() {
    this.addresses = [];
  }
}

AddressesStateKeeper.instance = new AddressesStateKeeper();

export default AddressesStateKeeper;
