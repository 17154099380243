import React from "react";
import "../css/common.css";
import { Button } from "@mui/material";

interface Props {
    title?: string;
    close: () => void,
    message: string,
    confirmed?: { btn1: string, btn2: string | undefined} | undefined,
    onClickCofirmedBtn?: { btn1: () => void, btn2: () => void | undefined } | undefined
    commonCloseModal?: () => void;
}

interface State {}

class CommonModal extends React.Component<Props, State> {
  static defaultProps: any = {};

  componentDidMount() {}

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
    snapshot?: any
  ): void {}

  close() {
    const { close, commonCloseModal } = this.props;
    document.body.style.cssText = "overflow: unset";
    close();
    if (commonCloseModal !== undefined) {
        commonCloseModal();
    }
  }

  //@ts-ignore
  state: State = { ...this.props };


  render() {
    const { confirmed, message, onClickCofirmedBtn, title } = this.props;

    return (
        <div className="login_page">
            <div className="login_box_wrap">
        <div className="login_box" style={{height: '450px'}}>
            <button className="btn_close" style={{position: 'absolute', top: '3px', right: '8px', padding: '9px 3px'}} onClick={() => (this.close())}>
                <img src="/rms-conlab/images/btn_close.png" alt="btn_close" />
            </button>
            <form>
                <h2 className={title === '기업등록 유형을\n선택해주세요' ? '' : "check_modal"}>
                    <p>
                        {
                            title ?
                            title.split('\n').map((item, key) => {
                                return <div key={key}>{item}<br/></div>
                            })
                            : <>확인<br/>해주세요</>
                        }
                    </p>
                </h2>
                <section className="top_desc" style={{textAlign: 'center'}}>
                    <ul>
                        <li>
                            {message?.split('\n').map((item, key) => {
                                return <div key={key}>{item}<br/></div>
                            })}
                        </li>
                    </ul>
                </section>

                <div className="body">
                    {/* <div className="image" style={{display: 'flex', justifyContent: 'center', paddingTop: '30px', paddingBottom: '30px'}}>  */}
                        {/* <img src="/rms-conlab/images/join_step1.svg" alt="btn_close" /> */}
                    {/* </div> */}
                    {/* <div className="explanation" style={{display: 'flex', justifyContent: 'center', height: '80px', textAlign: "center"}}>
                        <div>{message?.split('\n').map((item, key) => {
                            return <div key={key}>{item}<br/></div>
                        })}
                        </div>
                    </div> */}
                    <div className="footer" style={{display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '20px'}}>
                        {
                            confirmed === undefined 
                            ? <div>
                                <Button variant="contained" onClick={() => this.close()} style={{backgroundColor: '#2658E2'}}>확인</Button>
                            </div> 
                            : (
                                confirmed.btn2 === undefined
                                ? <>
                                    <div>
                                        <Button variant="contained" onClick={onClickCofirmedBtn?.btn1} style={{backgroundColor: '#2658E2'}}>{confirmed.btn1}</Button>
                                    </div>
                                </>
                                : <>
                                    <div>
                                        <Button variant="contained" onClick={onClickCofirmedBtn?.btn1} style={{backgroundColor: '#2658E2'}}>{confirmed.btn1}</Button>
                                    </div>
                                    <div>
                                        <Button variant="contained" onClick={onClickCofirmedBtn?.btn2} style={{backgroundColor: '#2658E2'}}>{confirmed.btn2}</Button>
                                    </div>
                                </>
                            )
                        }
                        {/* CASE 1 */}
                        {/* <div>
                            <Button variant="contained" onClick={() => this.close()} style={{backgroundColor: '#2658E2'}}>확인</Button>
                        </div>  */}
                        {/* CASE 2 */}
                        {/* <div>
                            <Button variant="contained" onClick={onClickCofirmedBtn?.btn1} style={{backgroundColor: '#2658E2'}}>네</Button>
                        </div> */}
                        {/* CASE 3 */}
                        {/* <div>
                            <Button variant="contained" onClick={onClickCofirmedBtn?.btn1} style={{backgroundColor: '#2658E2'}}>네</Button>
                        </div>
                        <div>
                            <Button variant="contained" onClick={onClickCofirmedBtn?.btn2} style={{backgroundColor: '#2658E2'}}>아니오</Button>
                        </div> */}
                    </div>
                </div>
            </form>

        </div>
        </div>
            <div className="bg" style={{position:'fixed', top:'0', left:'0', width:'100%', height:'100%', overflow:'hidden', zIndex:'-1', background:'rgba(0,0,0,0.2)'}}></div>
        </div>
    );
  }
}

export default CommonModal;
