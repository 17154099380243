import React from 'react';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import moment, { Moment } from 'moment';
import { Alert, AlertTitle } from '@mui/material';

interface Props {
  closeCalendarMobile : () => void;
  mapChartPeriod : (periodType: string) => void;
    startYear : string;
    startMonth : string;
  endYear : string;
  endMonth : string;
  setYear : (year : string) => void;
  setMonth : (month : string) => void;
  setClickMonthly : () => void;
  fireSearchButton : () => void;
  setPeriod : (periodType: string) => void;
  clearYear : () => void;
  clearMonth : () => void;
}

interface State {
  calendar : any;
  isNextButtonDisabled: boolean;
  disabledMonthGroup: string[];
  monthChecked: string;
  searchChecked: string;
  openError: boolean;
}

class CalendarModal extends React.Component<Props, State> {

  private monthCode = ['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec'];

  state: State = {
    calendar : moment(),
    isNextButtonDisabled: true,
    disabledMonthGroup: ['', '', '', '', '', '', '', '', '', '', '', ''],
    monthChecked: '',
    searchChecked: '',
    openError: false
  };

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {

    const { calendar } = this.state
    const parseCalendar = parseInt(calendar.format('YYYY'));
    let parseStartYear = parseInt(this.props.startYear);

    this.applyStartEndColor();

    if(!Number.isNaN(parseStartYear)){
      if((parseCalendar !== parseStartYear) || (this.props.startMonth == '' && this.props.endMonth == '')){
        this.removeActiveColor();
      }
    }

      if((this.props.startYear !== '' && this.props.endYear !== '') || (this.props.startYear == '' && this.props.endYear == '')){
        this.monthColor()
      }
  }

  componentDidMount(){
    const { calendar } = this.state;
    this.props.clearYear();
    this.props.clearMonth();

    for (let i = Number(calendar.format('M')); i < this.monthCode.length; i++) {
      this.state.disabledMonthGroup[i] = this.monthCode[i]
    }
    this.setDisabledButtonGroup(this.state.disabledMonthGroup);
  }

  removeActiveColor() {
    let parseStartMonth = parseInt(this.props.startMonth);
    document.getElementById(this.monthCode[parseStartMonth -1])?.classList.remove('cal-month-active-color')
  }

  addActiveColor() {
    const { calendar } = this.state
    let parseStartYear = parseInt(this.props.startYear);
    let parseEndYear = parseInt(this.props.endYear);
    let parseStartMonth = parseInt(this.props.startMonth);

    if(calendar.format('YYYY') == parseStartYear && parseStartYear == parseEndYear) {
      //@ts-ignore
      document.getElementById(this.monthCode[parseStartMonth -1]).className = 'cal-month-active-color';
    }
  }

  onClickSearch(search: string){
    const { monthChecked } = this.state;

    this.setState({openError: true})
    this.setState({searchChecked : search})

    if(monthChecked !== ''){
      this.props.mapChartPeriod('monthly');
      this.props.fireSearchButton();
      this.props.closeCalendarMobile();
      this.props.setPeriod('monthly');
    } else if(monthChecked === ''){
      this.renderSearchErrorBox();
    }
  }

  renderSearchErrorBox = () => {
    const { monthChecked } = this.state;
    if(monthChecked === ''){
      return (
        <Alert severity="info" style={{zIndex: 1200, position: 'fixed', top: '285px', padding: '10px 10px', width: '90%'}} onClose={() => {this.closeError()}}>
          월을 선택 후 다시 조회해 주세요.
        </Alert>
      )
    }
  }

  closeError(){
    this.setState({openError: false})
  }

  closeCalendar(){
    this.props.closeCalendarMobile();
  }

  handleYearMonth(month: string){
    this.setState({monthChecked: month}, () => {console.log(this.state.monthChecked, 'monthChecked')})
    this.props.setYear(this.state.calendar._d.getFullYear());
    this.props.setMonth(month);
  }

  monthColor(){
    const { calendar } = this.state

    const parseCalendar = parseInt(calendar.format('YYYY'));
    let parseStartYear = parseInt(this.props.startYear);
    let parseEndYear = parseInt(this.props.endYear);

    let parseStartMonth = parseInt(this.props.startMonth);
    let parseEndMonth = parseInt(this.props.endMonth);

    let startYearMonth = this.props.startYear + this.props.startMonth
    let endYearMonth = this.props.endYear + this.props.endMonth

    const convertStartYM = parseInt(startYearMonth)
    const convertEndYM = parseInt(endYearMonth)

    // start month가 end 보다 큰 경우
    if(convertStartYM > convertEndYM){
      parseStartYear = parseInt(this.props.endYear);
      parseEndYear = parseInt(this.props.startYear);
      parseStartMonth = parseInt(this.props.endMonth)
      parseEndMonth = parseInt(this.props.startMonth)
    }

    // start month가 end 보다 크다, start month와 end가 같은 년도에 있는 경우 start 버튼 반대로 적용하고 찐한 파란색 추가
    if ((parseCalendar == parseStartYear) && (parseCalendar == parseEndYear)){
      if((!Number.isNaN(parseStartMonth) && !Number.isNaN(parseEndMonth)) && parseStartMonth != parseEndMonth){
        //@ts-ignore
        document.getElementById(this.monthCode[parseStartMonth -1]).className = 'cal-month-start-color';
      }
    // start month가 end 보다 크다, start month와 end가 같은 년도에 있는 경우 end 버튼 반대로 적용하고 찐한 파란색 추가
    if((!Number.isNaN(parseEndMonth) && !Number.isNaN(parseStartMonth)) && parseStartMonth != parseEndMonth){
      //@ts-ignore
      document.getElementById(this.monthCode[parseEndMonth -1]).className = 'cal-month-end-color';
    }
    // start month가 end 보다 크다, start month와 end가 같은 년도에 있는 경우 그 사이의 값 연한 파란색 추가
    for (let idx = 0; idx < this.monthCode.length; idx++) {
      if (parseEndMonth - 1 > idx && idx > parseStartMonth - 1) {
        //@ts-ignore
        document.getElementById(this.monthCode[idx]).className = 'cal-month-between-color';
      }
    }
    // start month가 end 보다 크다, 선택한 start year ~ end 사이의 값 연한 파란색 추가
    } else if (!Number.isNaN(parseStartYear) && !Number.isNaN(parseEndYear) && parseCalendar > parseStartYear && parseCalendar < parseEndYear) {
        for (let idx = 0; idx < this.monthCode.length; idx++) {
          //@ts-ignore
          document.getElementById(this.monthCode[idx]).className = 'cal-year-month-between-color';
        }
      }
    // start month가 end 보다 크다, start month와 end가 다른 년도에 있는 경우 start 버튼 반대로, start month 찐한 파란색, 사이 값 연한 파란색, 년도 이동 시 남아 있는 연한 파란색 삭제
    else if (parseCalendar == parseStartYear) {
        if((!Number.isNaN(parseStartMonth) && !Number.isNaN(parseEndMonth)) && parseStartMonth != parseEndMonth){
          //@ts-ignore
          document.getElementById(this.monthCode[parseStartMonth -1]).className = 'cal-month-start-color';
        }
        if (parseEndYear) {
          for (let idx = 0; idx < this.monthCode.length; idx++) {
            if (parseStartMonth - 1 < idx) {
              //@ts-ignore
              document.getElementById(this.monthCode[idx]).className = 'cal-month-between-color';
            } else {
              document.getElementById(this.monthCode[idx])?.classList.remove('cal-month-between-color', 'cal-year-month-between-color', 'cal-month-active-color')
            }
          }
        }
     // start month가 end 보다 크다, start month와 end가 다른 년도에 있는 경우 end 버튼 반대로 css, end month 찐한 파란색, 사이 값 연한 파란색, 년도 이동 시 남아 있는 연한 파란색 삭제
    } else if (parseCalendar == parseEndYear) {
        if((!Number.isNaN(parseEndMonth) && !Number.isNaN(parseStartMonth)) && parseStartMonth != parseEndMonth){
          //@ts-ignore
          document.getElementById(this.monthCode[parseEndMonth -1]).className = 'cal-month-end-color';
        }
        for (let idx = 0; idx < this.monthCode.length; idx++) {
          if (parseEndMonth - 1 > idx) {
            //@ts-ignore
            document.getElementById(this.monthCode[idx]).className = 'cal-month-between-color';
          } else {
            document.getElementById(this.monthCode[idx])?.classList.remove('cal-month-between-color', 'cal-year-month-between-color', 'cal-month-active-color')
          }
        }
    }

    // 년도가 같은 경우, 다른 년도로 넘기면 남아 있는 연한 파란색 삭제
    if(parseCalendar != parseStartYear && parseCalendar != parseEndYear){
      document.getElementById('jan')?.classList.remove('cal-month-between-color', 'cal-month-active-color')
      document.getElementById('feb')?.classList.remove('cal-month-between-color', 'cal-month-active-color')
      document.getElementById('mar')?.classList.remove('cal-month-between-color', 'cal-month-active-color')
      document.getElementById('apr')?.classList.remove('cal-month-between-color', 'cal-month-active-color')
      document.getElementById('may')?.classList.remove('cal-month-between-color', 'cal-month-active-color')
      document.getElementById('jun')?.classList.remove('cal-month-between-color', 'cal-month-active-color')
      document.getElementById('jul')?.classList.remove('cal-month-between-color', 'cal-month-active-color')
      document.getElementById('aug')?.classList.remove('cal-month-between-color', 'cal-month-active-color')
      document.getElementById('sep')?.classList.remove('cal-month-between-color', 'cal-month-active-color')
      document.getElementById('oct')?.classList.remove('cal-month-between-color', 'cal-month-active-color')
      document.getElementById('nov')?.classList.remove('cal-month-between-color', 'cal-month-active-color')
      document.getElementById('dec')?.classList.remove('cal-month-between-color', 'cal-month-active-color')
    }

    // 년도가 다른 경우, 다른 년도로 넘기면 남아 있는 start month, end month 찐한 파란색 삭제
    if(calendar.format('YYYY') != this.props.startYear && calendar.format('YYYY') != this.props.endYear){
      document.getElementById('jan')?.classList.remove('cal-month-start-color', 'cal-month-end-color')
      document.getElementById('feb')?.classList.remove('cal-month-start-color', 'cal-month-end-color')
      document.getElementById('mar')?.classList.remove('cal-month-start-color', 'cal-month-end-color')
      document.getElementById('apr')?.classList.remove('cal-month-start-color', 'cal-month-end-color')
      document.getElementById('may')?.classList.remove('cal-month-start-color', 'cal-month-end-color')
      document.getElementById('jun')?.classList.remove('cal-month-start-color', 'cal-month-end-color')
      document.getElementById('jul')?.classList.remove('cal-month-start-color', 'cal-month-end-color')
      document.getElementById('aug')?.classList.remove('cal-month-start-color', 'cal-month-end-color')
      document.getElementById('sep')?.classList.remove('cal-month-start-color', 'cal-month-end-color')
      document.getElementById('oct')?.classList.remove('cal-month-start-color', 'cal-month-end-color')
      document.getElementById('nov')?.classList.remove('cal-month-start-color', 'cal-month-end-color')
      document.getElementById('dec')?.classList.remove('cal-month-start-color', 'cal-month-end-color')
    }

    // 시작 년도가 다른 경우, start month 찐한 파란색 삭제
    if (calendar.format('YYYY') != parseStartYear) {
      document.getElementById(this.monthCode[parseStartMonth -1])?.classList.remove('cal-month-start-color')
    }

    // 종료 년도가 다른 경우, start month 찐한 파란색 삭제
    if (calendar.format('YYYY') != parseEndYear) {
      document.getElementById(this.monthCode[parseEndMonth -1])?.classList.remove('cal-month-end-color')
    }
}

applyStartEndColor(){

  const { calendar } = this.state

  // start month가 end 보다 작다, start month 찐한 파란색
  if(calendar.format('YYYY') == this.props.startYear && this.props.startMonth == '01'){
    //@ts-ignore
    document.getElementById('jan').className = 'cal-month-active-color';
  } else if(calendar.format('YYYY') == this.props.startYear && this.props.startMonth == '02'){
    //@ts-ignore
    document.getElementById('feb').className = 'cal-month-active-color';
  } else if(calendar.format('YYYY') == this.props.startYear && this.props.startMonth == '03'){
    //@ts-ignore
    document.getElementById('mar').className = 'cal-month-active-color';
  } else if(calendar.format('YYYY') == this.props.startYear && this.props.startMonth == '04'){
    //@ts-ignore
    document.getElementById('apr').className = 'cal-month-active-color';
  } else if(calendar.format('YYYY') == this.props.startYear && this.props.startMonth == '05'){
    //@ts-ignore
    document.getElementById('may').className = 'cal-month-active-color';
  } else if(calendar.format('YYYY') == this.props.startYear && this.props.startMonth == '06'){
    //@ts-ignore
    document.getElementById('jun').className = 'cal-month-active-color';
  } else if(calendar.format('YYYY') == this.props.startYear && this.props.startMonth == '07'){
    //@ts-ignore
    document.getElementById('jul').className = 'cal-month-active-color';
  } else if(calendar.format('YYYY') == this.props.startYear && this.props.startMonth == '08'){
    //@ts-ignore
    document.getElementById('aug').className = 'cal-month-active-color';
  } else if(calendar.format('YYYY') == this.props.startYear && this.props.startMonth == '09'){
    //@ts-ignore
    document.getElementById('sep').className = 'cal-month-active-color';
  } else if(calendar.format('YYYY') == this.props.startYear && this.props.startMonth == '10'){
    //@ts-ignore
    document.getElementById('oct').className = 'cal-month-active-color';
  } else if(calendar.format('YYYY') == this.props.startYear && this.props.startMonth == '11'){
    //@ts-ignore
    document.getElementById('nov').className = 'cal-month-active-color';
  } else if(calendar.format('YYYY') == this.props.startYear && this.props.startMonth == '12'){
    //@ts-ignore
    document.getElementById('dec').className = 'cal-month-active-color';
  }
}
  onClickNextButton() {
    const { calendar } = this.state;
    if (calendar.clone().add(1,'year').format('YYYY') == moment().format('YYYY')) {
      this.setState({isNextButtonDisabled: true});
      for (let i = Number(calendar.format('M')); i < this.monthCode.length; i++) {
        this.state.disabledMonthGroup[i] = this.monthCode[i]
      }
      this.setDisabledButtonGroup(this.state.disabledMonthGroup);
    }
    this.setState({calendar : calendar.clone().add(1,'year')});
  }

  onClickPrevButton() {
    const { calendar, isNextButtonDisabled } = this.state;
    if (isNextButtonDisabled) {
      this.setState({isNextButtonDisabled: false});
    }
    this.setState({calendar : calendar.clone().subtract(1,'year')});
    this.clearDisabledButtonGroup();
  }

  setAbledButtonGroup(monthGroup: string[])  {
    this.setState({disabledMonthGroup: monthGroup});
  }

  setDisabledButtonGroup(monthGroup: string[])  {
    this.setState({disabledMonthGroup: monthGroup});
  }

  clearDisabledButtonGroup() {
    const initMonthGroup = ['', '', '', '', '', '', '', '', '', '', '', ''];
    this.setState({disabledMonthGroup: initMonthGroup});
  }

  render() {

    const { calendar, isNextButtonDisabled, monthChecked, searchChecked, openError } = this.state

      return(
        <>
          <div className='calendar-fix' style={{position: 'absolute'}}>
            <div className='calendar-main' style={{ borderBottomLeftRadius: '24px', borderBottomRightRadius: '24px', borderTopLeftRadius: '20px', borderTopRightRadius:'20px', width: 266, height: 351, top: 15, backgroundColor: '#ffffff', zIndex: '100', position: 'relative'}}>
                <div className='calendar-head'>
                  <div className='cal-btn'>
                      <IconButton onClick={() => this.onClickPrevButton()}>
                        <ChevronLeftIcon/>
                      </IconButton>
                      <span className='calendar-year' style={{fontSize:'20px'}}>{calendar.format('YYYY')}</span>
                      <IconButton onClick={() => this.onClickNextButton()} disabled={isNextButtonDisabled}>
                        <ChevronRightIcon/>
                      </IconButton>
                  </div>
                  <div className='calendar-close-button'>
                      <IconButton aria-label="close" onClick={() => this.closeCalendar()}>
                        <CloseIcon/>
                      </IconButton>
                  </div>
                </div>
                <div className="cal-month">
                  <button className={`${this.state.disabledMonthGroup[0] == 'jan' ? 'MuiIconButton-root Mui-disabled' : undefined}`} type="button" id="jan" onClick={() => this.handleYearMonth('01')} disabled={this.state.disabledMonthGroup[0] == 'jan'}>
                    <span className="MuiButton-label">1월</span>
                  </button>
                  <button className={`${this.state.disabledMonthGroup[1] == 'feb' ? 'MuiIconButton-root Mui-disabled' : undefined}`} type="button" id="feb" onClick={() => this.handleYearMonth('02')} disabled={this.state.disabledMonthGroup[1] == 'feb'}>
                    <span className="MuiButton-label">2월</span>
                  </button>
                  <button className={`${this.state.disabledMonthGroup[2] == 'mar' ? 'MuiIconButton-root Mui-disabled' : undefined}`} type="button" id="mar" onClick={() => this.handleYearMonth('03')} disabled={this.state.disabledMonthGroup[2] == 'mar'}>
                    <span className="MuiButton-label">3월</span>
                  </button>
                  <button className={`${this.state.disabledMonthGroup[3] == 'apr' ? 'MuiIconButton-root Mui-disabled' : undefined}`} type="button" id="apr" onClick={() => this.handleYearMonth('04')} disabled={this.state.disabledMonthGroup[3] == 'apr'}>
                    <span className="MuiButton-label">4월</span>
                  </button>
                  <button className={`${this.state.disabledMonthGroup[4] == 'may' ? 'MuiIconButton-root Mui-disabled' : undefined}`}type="button" id="may" onClick={() => this.handleYearMonth('05')} disabled={this.state.disabledMonthGroup[4] == 'may'}>
                    <span className="MuiButton-label">5월</span>
                  </button>
                  <button className={`${this.state.disabledMonthGroup[5] == 'jun' ? 'MuiIconButton-root Mui-disabled' : undefined}`} type="button" id="jun" onClick={() => this.handleYearMonth('06')} disabled={this.state.disabledMonthGroup[5] == 'jun'}>
                    <span className="MuiButton-label">6월</span>
                  </button>
                  <button className={`${this.state.disabledMonthGroup[6] == 'jul' ? 'MuiIconButton-root Mui-disabled' : undefined}`} type="button" id="jul" onClick={() => this.handleYearMonth('07')} disabled={this.state.disabledMonthGroup[6] == 'jul'}>
                    <span className="MuiButton-label">7월</span>
                  </button>
                  <button className={`${this.state.disabledMonthGroup[7] == 'aug' ? 'MuiIconButton-root Mui-disabled' : undefined}`} type="button" id="aug" onClick={() => this.handleYearMonth('08')} disabled={this.state.disabledMonthGroup[7] == 'aug'}>
                    <span className="MuiButton-label">8월</span>
                  </button>
                  <button className={`${this.state.disabledMonthGroup[8] == 'sep' ? 'MuiIconButton-root Mui-disabled' : undefined}`} type="button" id="sep" onClick={() => this.handleYearMonth('09')} disabled={this.state.disabledMonthGroup[8] == 'sep'}>
                    <span className="MuiButton-label">9월</span>
                  </button>
                  <button className={`${this.state.disabledMonthGroup[9] == 'oct' ? 'MuiIconButton-root Mui-disabled' : undefined}`} type="button" id="oct" onClick={() => this.handleYearMonth('10')} disabled={this.state.disabledMonthGroup[9] == 'oct'}>
                    <span className="MuiButton-label">10월</span>
                  </button>
                  <button className={`${this.state.disabledMonthGroup[10] == 'nov' ? 'MuiIconButton-root Mui-disabled' : undefined}`} type="button" id="nov" onClick={() => this.handleYearMonth('11')} disabled={this.state.disabledMonthGroup[10] == 'nov'}>
                    <span className="MuiButton-label">11월</span>
                  </button>
                  <button className={`${this.state.disabledMonthGroup[11] == 'dec' ? 'MuiIconButton-root Mui-disabled' : undefined}`} type="button" id="dec" onClick={() => this.handleYearMonth('12')} disabled={this.state.disabledMonthGroup[11] == 'dec'}>
                    <span className="MuiButton-label">12월</span>
                  </button>
                </div>

                  <div className='calendar-select-button'>
                    <IconButton>
                      <span onClick={() => this.onClickSearch('조회')} className='calendar-select-title'>조회</span>
                    </IconButton>
                  </div>
            </div>
          </div>

          <div className="region_backdrop" onClick={ () =>  this.props.closeCalendarMobile()}></div>

          {/* {openError === true  && monthChecked === '' && searchChecked === '조회' ? this.renderSearchErrorBox() : null} */}
        </>
      )
  }
}

export default CalendarModal;

