import { checkKtoTop, removeKtoTop } from ".";
import CommonModal from "./CommonModal";
import { useLocation } from "react-router-dom";

const SsoFail = () => {

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const errorCode = queryParams.get('errorCode');
    const errorMsg = queryParams.get('errorMsg');
    const isKto = checkKtoTop();
    let modalText = '';
    let erroText = '';
    if (isKto) {
        modalText = '관광공사 SSO 연동에 실패했습니다.';
    } else {
        modalText = '투어원패스 연동에 실패했습니다.';
    }
    if (errorCode && errorMsg) {
        erroText = `\n(오류코드: ${errorCode}, 오류메세지: ${errorMsg})`;
    } else if (errorCode) {
        erroText = `\n(오류코드: ${errorCode})`;
    }

    const closeModal = () => {
        if (isKto) {
            removeKtoTop();
        }
        const nowUrl = window.sessionStorage.getItem('nara.loginUrl');
        window.sessionStorage.removeItem('nara.loginUrl');
        if (nowUrl) {
            window.location.href = nowUrl;
        } else {
            window.location.href = '/rms-conlab';
        }
    }

    return (
        <>
            <div style={{ backgroundColor: 'lightgray', height: '100%' }}></div>
            <CommonModal
                close={closeModal} 
                message={errorCode ? modalText+erroText : modalText} 
            />
        </>
    );
}

export default SsoFail;