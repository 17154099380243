import makeExtendedObservable from "../decorator/makeExtendedObservable";

class Address {
  language: string;
  country: string;
  zipCode: string;
  zipAddress: string;
  street: string;
  city: string;
  state: string;


  constructor(address?: Address) {
    //
    this.language = '';
    this.country = '';
    this.zipCode = '';
    this.zipAddress = ''; // 상세주소
    this.street = ''; // 도로명주소
    this.city = ''; // 도, 광역시
    this.state = '';// 시, 군구

    if (address) {
      Object.assign(this, address);
    }
    makeExtendedObservable(this);
  }

  static fromDomains(domains: Address[]): Address[] {
    return domains.map(domain => this.fromDomain(domain));
  }

  static fromDomain(domain: Address): Address {
    const address = new Address(
      domain
    );

    return address;
  }

  static getKtoAddress(): Address {
    const ktoAddress = new Address();

    ktoAddress.zipCode = '26464';
    ktoAddress.street = '강원 원주시 세계로 10';
    ktoAddress.city = '강원';
    ktoAddress.state = '원주시';
    return ktoAddress;
  }
}

export default Address;
