import { GridFeatureMode, GridLogicOperator, GridSortDirection } from '@mui/x-data-grid';
import makeExtendedObservable from '../../../lib/decorator/makeExtendedObservable';
import XGridFilter from '../XGridFilter';
import QueryParam from '../../../lib/domain/cqrs/query/QueryParam';
import Offset from '../../../lib/domain/offset/Offset';
// import EmptyRow from '../model/CustomComponent';
import mobxService from '../../../lib/decorator/mobxService/mobxService';


@mobxService
class XGridStateKeeper {
  static readonly instanceName: string = 'xGridStateKeeper';
  static readonly serviceName: string = 'prologue.xGrid.xGridStateKeeper';
  static instance: XGridStateKeeper;

  xGridFilter: XGridFilter;
  linkOperator: GridLogicOperator = GridLogicOperator.And;

  pageOptions: number[] = [];

  constructor(
  ) {
    this.xGridFilter = new XGridFilter(
      [],
      { field: 'id', sort: 'asc' },
      0,
      10,
    );
    makeExtendedObservable(this);
  }

  /**
   * Default pageOptions are 10, 20, 30
   */
  init(sortField: string, sortDirection: GridSortDirection, initPageOptions?: number[], initPage?: number, initPageSize?: number) {
    const page = initPage || 0;
    const pageOptions = initPageOptions && initPageOptions.length ? initPageOptions : [10, 20, 30];
    const pageSize = initPageSize || pageOptions[0];
    const sortInfo = { field: sortField, sort: sortDirection };

    this.xGridFilter = new XGridFilter(
      [],
      sortInfo,
      page,
      pageSize,
    );

    this.setPageOptions(pageOptions);
  }

  setTotalCount(totalCount: number) {
    this.xGridFilter.totalCount = totalCount;
  }

  changeFilter(name: keyof XGridFilter, value: any): boolean {
    if (name === 'sortInfo' && value.length && this.xGridFilter.sortInfo) {
      if (value[0].field === this.xGridFilter.sortInfo.field && value[0].sort === this.xGridFilter.sortInfo.sort) {
        return false;
      }
    }

    if (name === 'filterInfo' && !value.items.length) {
      return false;
    }

    switch (name) {
      case 'sortInfo':
        this.xGridFilter.sortInfo = value ? value[0] : null;
        return true;
      case 'filterInfo':
        if (value.linkOperator) {
          this.setLinkOperator(value.linkOperator);
        }
        this.xGridFilter.filterInfo = value.items;
        return true;
      case 'page':
        this.xGridFilter.page = value;
        return true;
      case 'pageSize':
        this.xGridFilter.pageSize = value;
        return true;

      case 'defaultSortInfo': case 'linkOperator':
        return false;
      default:
        this.xGridFilter[name] = value;
        return true;
    }
  }

  filterQueryParams(): QueryParam[] {
    //
    return this.xGridFilter.filterQueryParams();
  }

  filterOffset(): Offset {
    return this.xGridFilter.filterOffset();
  }

  get page(): number {
    return this.xGridFilter.page;
  }

  get totalCount(): number {
    return this.xGridFilter.totalCount;
  }

  get pageSize(): number {
    return this.xGridFilter.pageSize;
  }

  get defaultServerProps(): { filterMode: GridFeatureMode; sortingMode: GridFeatureMode; paginationMode: GridFeatureMode; sortingOrder: GridSortDirection[] } {
    return {
      filterMode: 'server',
      sortingMode: 'server',
      paginationMode: 'server',
      sortingOrder: ['asc', 'desc'],
    };
  }

  emptyRow(text: string, height?: number | 'auto') {
    // return { components: { NoRowsOverlay: ((props: any) => EmptyRow(props, text, height)) }};
  }

  private setLinkOperator(value: any) {
    this.linkOperator = value;
  }

  private setPageOptions(value: any) {
    this.pageOptions = value;
  }
}

XGridStateKeeper.instance = new XGridStateKeeper();

export default XGridStateKeeper;

