import autobind from "../../../../../lib/decorator/autobind/autobindDecorator";
import makeExtendedObservable from "../../../../../lib/decorator/makeExtendedObservable";
import DepotFile from "../../../../../lib/granule/DepotFile";
import DepotFileCdo from "../../../../api/depot/api-model/sdo/DepotFileCdo";

@autobind
class FileItemModel extends DepotFile {
  //
  file: File | null = null;

  constructor(
    name: string,
    size: number,
    sequence: number,
    vaultFileId: string,
    depotId: string,
    ownerId: string,
    time: number,
    modifyTime: number,
    deleted: boolean,
    deploy: boolean
  ) {
    super(name, size, sequence, vaultFileId, depotId, ownerId, time, modifyTime, deleted, deploy);
    makeExtendedObservable(this);
  }

  static fromFile(file: File) {
    //
    const fileView = new FileItemModel(
      file.name,
      file.size,
      0,
      '',
      '',
      '',
      0,
      0,
      false,
      false,
    );

    fileView.file = file;

    return fileView;
  }

  static fromFiles(files: File[]) {
    //
    return files.map(file => this.fromFile(file));
  }

  static fromDepotFiles(depotFiles: DepotFile[]) {
    //
    return depotFiles.map(depotFile => this.fromDepotFile(depotFile));
  }

  static fromDepotFile(depotFile: DepotFile) {
    //
    const fileView = new FileItemModel(
      depotFile.name,
      depotFile.size,
      depotFile.sequence,
      depotFile.vaultFileId,
      depotFile.depotId,
      depotFile.ownerId,
      depotFile.time,
      depotFile.modifyTime,
      depotFile.deleted,
      depotFile.deploy
    );

    fileView.id = depotFile.id;
    fileView.type = depotFile.type;

    return fileView;
  }

  static toCdo(fileView: FileItemModel, depotId: string): DepotFileCdo {
    //
    return new DepotFileCdo(
      '',
      fileView.name,
      fileView.size,
      0,
      false,
      0,
      depotId,
      '',
      fileView.ownerId
    );
  }
}

export default FileItemModel;
