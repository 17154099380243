import { Button, Icon } from '@mui/material';
import React from 'react';


export default function Custom500() {
  return (
    <div className="error-page-outer">
      <div className="error-page-wrap">
        <div className="error-logo">
          <a href="/rms-conlab">
            <i />
          </a>
        </div>
        <div className="error-page-cont">
          <i className="icon icon2" />
          <div className="page-tit">
            지금 이 서비스와{' '}
            <span className="txt-primary">연결할 수 없습니다.</span>
          </div>
          <div className="page-desc">
            기술적인 문제로 일시적으로 접속되지 않았습니다. 잠시 후 다시 이용 부탁
            드립니다. <br />
            이용에 불편을 드려 죄송합니다.
          </div>
        </div>

        <div className="error-page-btns">
          <Button className="btn-init outline icon large" onClick={() => window.history.back()}>
            <Icon style={{ fontSize: 18 }}>chevron_left</Icon>
            <span>이전페이지</span>
          </Button>
          <Button className="btn-init fill primary large" onClick={() => window.location.href = '/rms-conlab'}>
            <span>RMS 홈</span>
          </Button>
        </div>

        <div className="error-page-footer">
          Copyright © <strong className="b">KTO</strong>. ALL RIGHT RESERVED.
        </div>
      </div>
    </div>
  );
}
