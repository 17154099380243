import React, { ContextType, createRef } from "react";
import "../../css/common.css";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, Autoplay } from 'swiper/modules';
import SwiperCore from 'swiper';
import ImageSize from "../../shared/ImageSize";
import ContentBuilder from "../../model/builder/ContentBuilder";
import KakaoMap from "../../components/KakaoMap";
import BookMarkButton from "../../components/BookMarkButton";
import InterestsType from "../../model/type/interestsType";
import { LangType } from "../../model/type/LangType";
import { defaultListCard } from "../../shared/ImagePathUtil";
import { registerUsage } from "../../shared/Function/util/RegisterUtil";
import CommonContext from "../../context/CommonContext";
import { SearchType } from "../../model/type/SearchType";
import { UsageType } from "../../model/type/UsageType";
import PhoneIcon from '@mui/icons-material/Phone';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import EventIcon from '@mui/icons-material/Event';
import LocalParkingIcon from '@mui/icons-material/LocalParking';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import LanguageIcon from '@mui/icons-material/Language';
import RestaurantMenuIcon from '@mui/icons-material/RestaurantMenu';
import ElderlyIcon from '@mui/icons-material/Elderly';
import ArticleIcon from '@mui/icons-material/Article';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import BusinessIcon from '@mui/icons-material/Business';
import GradeIcon from '@mui/icons-material/Grade';
import InputIcon from '@mui/icons-material/Input';
import OutputIcon from '@mui/icons-material/Output';
import RestaurantIcon from '@mui/icons-material/Restaurant';
import BedIcon from '@mui/icons-material/Bed';
import PetsIcon from '@mui/icons-material/Pets';
import { ContentType } from "../../../src/model/type/ContentType";
import { downloadPDF } from "..";
import { CircularProgress } from "@mui/material";

interface Props {
  cid: string;
  interestsType: InterestsType;
  onCloseModal: () => void;
  eventSetSelected: () => void;
  onClickThemeCloseModal: () => void;
  viewWidth: number | undefined;
  upDateFormalSummaryDate: {
    nature: {
      list: any[],
      score: number,
      totalCount: number
    },
    history: {
      list: any[],
      score: number,
      totalCount: number
    },
    experience: {
      list: any[],
      score: number,
      totalCount: number
    },
    culture: {
      list: any[],
      score: number,
      totalCount: number
    },
    festival: {
      list: any[],
      score: number,
      totalCount: number
    },
    food: {
      list: any[],
      score: number,
      totalCount: number
    },
    accommodation: {
      list: any[],
      score: number,
      totalCount: number
    },
    shopping: {
      list: any[],
      score: number,
      totalCount: number
    },
    leisure: {
      list: any[],
      score: number,
      totalCount: number
    }
  };
  noPrev?: boolean;
  contentsType: string;
  showImage?: () => void;
  selectedLang?: string;
  scrollToTop?: () => void;
}

interface State {
  contentDetailData: any;
  selectedLang: LangType;
  showShareAlert: boolean;
  isloading: boolean;
  modifyDate: string;
  pdfCid: string;
  pdfBtn: boolean;
  lastStatusUpdaterCineroomName: string;
}

class FormalContentsDetails extends React.Component<Props, State> {

  refLangColor = createRef<HTMLButtonElement>();

  static defaultProps: any = {
    noPrev: false
  };
  static contextType = CommonContext;
  context!: ContextType<typeof CommonContext>;

  constructor(props:any) {
    super(props);
    this.refLangColor = React.createRef();
    this.downloadPDF=this.downloadPDF.bind(this);
    this.setLoading=this.setLoading.bind(this);
    this.selectedLang=this.selectedLang.bind(this);
  }

  state: State = {
    contentDetailData: {},
    selectedLang: LangType.ko,
    showShareAlert : false,
    isloading: false,
    modifyDate: '',
    pdfCid: '',
    pdfBtn: true,
    lastStatusUpdaterCineroomName: '',
  };

  componentDidMount() {
    if(this.props.cid){
        this.initContentDetailDataByCid(this.props.cid);
        this.updateViewCount();
    }
    if (this.props.selectedLang) {
        this.setState({ selectedLang: this.props.selectedLang as LangType });
    }
  }

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
    snapshot?: any
  ): void {
    if (prevProps.cid !== this.props.cid && this.props.cid) {
        this.initContentDetailDataByCid(this.props.cid);
        this.updateViewCount();
        if (this.props.selectedLang) {
            this.setState({ selectedLang: this.props.selectedLang as LangType });
        }
    }

    if(prevProps.upDateFormalSummaryDate !== this.props.upDateFormalSummaryDate){
        this.setState({contentDetailData : {}})
     }
  }

  selectedLang(data: any[]) {
    const { selectedLang } = this.state;
    let boo: boolean = false;
    for (let i = 0; i < data.length; i++) {
        const obj = data[i];
        if (obj.language === selectedLang) { 
            this.setState({ pdfCid: obj.cid });
        }
    }
  }

  async initContentDetailDataByCid(cid: string) {
    const { scrollToTop } = this.props;
    const contentBuilder = new ContentBuilder(cid);
    await contentBuilder.buildFormalContent();
    const contentDetailData = contentBuilder.getContentDetailInfo();
    await contentBuilder.findLastStatusCineroomName();
    console.log('lastStatusUpdaterCineroomName: ', contentBuilder.lastStatusUpdaterCineroomName);
    this.setState({ lastStatusUpdaterCineroomName: contentBuilder.lastStatusUpdaterCineroomName });
    if (contentBuilder.language) {
        this.setState({ selectedLang: contentBuilder.language as LangType }, () => {
            this.selectedLang(contentDetailData.lang)
        });
    }
    const date = contentDetailData.registrationInfo.value.modificationTime.value;
    if (date.includes('-')) {
        const dateList = date.split('-');
        this.setState({modifyDate: `${dateList[0]}년 ${dateList[1]}월 ${dateList[2]}일`});
    }
    this.setState({contentDetailData: contentDetailData, pdfBtn: true});
    if (scrollToTop) {
        scrollToTop();
      }
  }

  updateViewCount() {
    const { cid } = this.props;
    const { actorIdentity, citizenUser, accessibleCinerooms } = this.context;
    if (!cid) {
        return;
    }
    const currentActorId = actorIdentity?.id;
    const email = actorIdentity?.email;
    const cineroomId = citizenUser?.cineroomIds[0];
    const cineroomName = accessibleCinerooms[0]?.name;
    registerUsage(cid, currentActorId, email, cineroomId, cineroomName, SearchType.Formal, UsageType.ViewCount);
  }

  findAnotherLang(cid: string, lang: LangType) {
    // 다른 언어 컨텐츠 호출
    this.initContentDetailDataByCid(cid);
    this.setState({selectedLang: lang},() => {console.log(this.state.selectedLang)});
  }

  langNameToLangCode(langName : string){
    switch(langName){
        case 'ko':
            return '한국어'
        case 'en':
            return 'English'
        case 'ja':
            return '日本語'
        case 'zh_CN':
            return '中文(简体)'
        case 'zh_TW':
            return '中文(繁體)'
        case 'fr':
            return 'français'
        case 'es':
            return 'español'
        case 'de':
            return 'Deutsch'
        case 'ru':
            return 'Pусский'
        default : 
            return ''
        }
    }

    // body,h1,h2,h3,h4,h5,h6,input,textarea,select,option,button,pre
    // st_basic

    langFontStyleChange(event: any){

    if(event.target.innerText === "한국어"){
        let test =document.getElementsByClassName('rd_view_list');
        let elementsArray = Array.from(test);
        elementsArray.forEach(function(element) {
            //@ts-ignore
            element.style.fontFamily = "'Pretendard Variable','돋움',dotum,helvetica,sans-serif";
        });
    } else if(event.target.innerText === "日本語"){
        let test =document.getElementsByClassName('rd_view_list');
        let elementsArray = Array.from(test);
        elementsArray.forEach(function(element) {
            //@ts-ignore
            element.style.fontFamily = "'NotoSansJP', sans-serif";
        });
    } else if(event.target.innerText === "中文(简体)"){
        let test =document.getElementsByClassName('rd_view_list');
        let elementsArray = Array.from(test);
        elementsArray.forEach(function(element) {
            //@ts-ignore
            element.style.fontFamily = "'Microsoft Yahei', 'Microsoft YaHei UI', sans-serif";
        });
    } else if(event.target.innerText === "中文(繁體)"){
        let test =document.getElementsByClassName('rd_view_list');
        let elementsArray = Array.from(test);
        elementsArray.forEach(function(element) {
            //@ts-ignore
            element.style.fontFamily = "'NotoSansTC', sans-serif";
        });
    } else if(event.target.innerText === "English"){
        let test =document.getElementsByClassName('rd_view_list');
        let elementsArray = Array.from(test);
        elementsArray.forEach(function(element) {
            //@ts-ignore
            element.style.fontFamily = "'NotoSans', sans-serif";
        });
    } else if(event.target.innerText === "Deutsch"){
        let test =document.getElementsByClassName('rd_view_list');
        let elementsArray = Array.from(test);
        elementsArray.forEach(function(element) {
            //@ts-ignore
            element.style.fontFamily = "'NotoSans', sans-serif";
        });
    } else if(event.target.innerText === "français"){
        let test =document.getElementsByClassName('rd_view_list');
        let elementsArray = Array.from(test);
        elementsArray.forEach(function(element) {
            //@ts-ignore
            element.style.fontFamily = "'Roboto', sans-serif";
        });
    } else if(event.target.innerText === "español"){
        let test =document.getElementsByClassName('rd_view_list');
        let elementsArray = Array.from(test);
        elementsArray.forEach(function(element) {
            //@ts-ignore
            element.style.fontFamily = "'Roboto', sans-serif";
        });
    } else if(event.target.innerText === "Pусский"){
        let test =document.getElementsByClassName('rd_view_list');
        let elementsArray = Array.from(test);
        elementsArray.forEach(function(element) {
            //@ts-ignore
            element.style.fontFamily = "'Roboto', sans-serif";
        });
    }
}

    defaultImage() {
        const defaultImage = `/rms-conlab/images/alternative_283x188.svg`;
        return defaultImage;
    }

    renderLangList() {
    const { contentDetailData, selectedLang } = this.state;

    const langList = contentDetailData.lang?.map((obj:any, i:any) => {
        return (
            <li key={i} className="swiper-slide">
                <button className={`st_basic ${selectedLang === obj.language ? 'on' : undefined}`} onClick={(event) => {this.setState({ pdfBtn: false }); this.findAnotherLang(obj.cid, obj.language); this.langFontStyleChange(event);}}>{this.langNameToLangCode(obj.language)}</button>
            </li>
        );
    });
    return (
        <ul className="swiper-wrapper">
            {langList}
        </ul>
    );
  }

  renderImageView() {
    SwiperCore.use([Navigation, Pagination, Autoplay]);
    return (
        <>
        <div className="recommend_slide_wrap">
            <Swiper
                //@ts-ignore
                modules={[Navigation, Pagination, Scrollbar]}
                spaceBetween={0}
                slidesPerView={4}
                navigation = {{
                    nextEl: ".swiper-button-next",
                    prevEl: ".swiper-button-prev",
                }}
                breakpoints= {{
                    1600: {slidesPerView: 4},
                    1500: {slidesPerView: 3},
                    1400: {slidesPerView: 3},
                    1300: {slidesPerView: 3},
                    1200: {slidesPerView: 2},
                    1100: {slidesPerView: 2},
                    1025: {slidesPerView: 2},
                    1000: {slidesPerView: 4},
                    900: {slidesPerView: 4},
                    800: {slidesPerView: 3},
                    700: {slidesPerView: 3},
                    600: {slidesPerView: 3},
                    500: {slidesPerView: 2},
                    400: {slidesPerView: 2},
                    300: {slidesPerView: 1}
                }}
                >
                {this.renderImageCardSlideView()}
            </Swiper>
        </div>
            <div className="swiper-button-next" style={{width: '50px', height: '50px', background: '#fff', borderRadius: '50%', boxShadow: '0px 4px 16px 0px #0000003D', marginTop: '-40px', outline: '0'}}><img src="/rms-conlab/images/move_next_L.svg" alt="move_next"/></div>
            <div className="swiper-button-prev" style={{width: '50px', height: '50px', background: '#fff', borderRadius: '50%', boxShadow: '0px 4px 16px 0px #0000003D', marginTop: '-40px', outline: '0', position: 'absolute', left: '1px'}}><img src="/rms-conlab/images/move_previous_L.svg" alt="move_previous"/></div>
        </>
    )
  }

  renderImageCardSlideView() {
    const { contentDetailData } = this.state;
    if(contentDetailData.image?.imageDepotUrl !== ''){
        const cardList = contentDetailData.image?.map((obj:any, i:any) =>
            <SwiperSlide key={i}>
                <img
                    className="layout-card-img"
                    style={{width: '95%', height: '200px', cursor: 'pointer' }}
                    src={`${obj.imageDepotUrl}/${ImageSize.it12}`}
                    alt=""
                    onContextMenu={(e) => (e.preventDefault())}
                    onError={(error) => {
                        error.currentTarget.src = this.defaultImage();
                    }}
                    onClick={() => window.open(`/rms-conlab/detail?cid=${obj.cid}&type=ImageContents`, '_blank')}
                />
            </SwiperSlide>
        );
        return (
          cardList
        );
    } 
  }

  onClickShare() {

    const { cid, interestsType } = this.props;
    const { actorIdentity, citizenUser, accessibleCinerooms } = this.context;

    const currentActorId = actorIdentity?.id;
    const email = actorIdentity?.email;
    const cineroomId = citizenUser?.cineroomIds[0];
    const cineroomName = accessibleCinerooms[0]?.name;

    const textarea = document.createElement('textarea');
    document.body.appendChild(textarea);

    const base_url = window.document.location.host;
    let urlValue = '';
    urlValue = `${base_url}/rms-conlab/detail?` + "cid=" + this.props.cid + "&type=" + ContentType.FormalContents ;

    textarea.value = urlValue;
    textarea.select();
    document.execCommand('copy');
    document.body.removeChild(textarea);

    this.setState({ showShareAlert: true });
    setTimeout(() => this.setState({ showShareAlert: false }), 2000);

    registerUsage(cid, currentActorId, email, cineroomId, cineroomName, interestsType, UsageType.LinkCreated);
  }

  // pdf download strat
  downloadPDF = async () => {
    const { openLoginModal, isLogin } = this.context;

    if (!isLogin) {
      openLoginModal();
      return;
    }
    
    const { selectedLang, pdfCid } = this.state;
    this.setLoading(true);
    downloadPDF(
        this.setLoading, pdfCid === '' ? this.props.cid : pdfCid,
        ContentType.FormalContents, selectedLang
    );
  };

  setLoading(isLoading: boolean) {
    this.setState({ isloading: isLoading })
  }
  // pdf download end


  render() {
    const { contentDetailData, showShareAlert, isloading, modifyDate, pdfBtn, lastStatusUpdaterCineroomName } = this.state;
    const { eventSetSelected, onCloseModal, onClickThemeCloseModal, cid, viewWidth, noPrev = false, showImage } = this.props;

    if (Object.keys(contentDetailData).length === 0) {
      return;
    }

    return(
        <>
        {/* @ts-ignore */}
        {viewWidth === undefined ? null :
            <div className="m_head">
                { noPrev === false && (<button className="rd_view_prev_bt" onClick={showImage ? ()=>{eventSetSelected(); showImage();} : () => (eventSetSelected())}><img src="/rms-conlab/images/icon_rd_view_prev.png" alt="close_icon"/></button>)}
                    {this.props.contentsType === 'ThemeContents' ? null :
                        <button className="close_bt rd_view_close_bt" onClick={() => {onCloseModal(); onClickThemeCloseModal();}}><img src="/rms-conlab/images/icon_menu_close.svg" alt="close_icon"/></button>
                    }
            </div>
        }

        <div className="rd_view_list">
            <div className="rd_view_item active">
                <div className="language_list swiper">
                    {this.renderLangList()}
                </div>
                <div className="contents_detail_page">
                    <div className="cdp_tit_area">
                        <h2 className="cdp_tit" dangerouslySetInnerHTML={{ __html: contentDetailData.baseInfo.value.title.value }}></h2>
                        <div className="bts">
                            {isloading ?
                                <CircularProgress color="secondary" className="pdf-progress" /> :
                                (
                                    pdfBtn ? <button className="pdf-btn" onClick={() => this.downloadPDF()}></button> : null
                                )
                            }
                            <button className="share" onClick={() => this.onClickShare()} style={{position: 'relative', top: '6px'}}></button>
                            {
                                showShareAlert && <div className="share_copy"><span>URL이 복사되었습니다. 원하는 곳에 붙여 넣으세요.</span></div>
                            }
                        </div>
                        <div className="bookmark-btn" style={{marginTop: '2px'}}>
                            <BookMarkButton
                                cid = {cid}
                                interestsType = {InterestsType.Formal}
                            />
                        </div>
                    </div>
                    <div className="cdp_slide_wrap swiper">
                            {this.renderImageView()}
                    </div>
                    <div className="contents_detail_info_area">
                        {/** 다국어일 때, 카테고리 임시로 숨기기 */}
                        {
                            this.state.selectedLang === 'ko'
                            ? <div className="category">
                                <h3>{contentDetailData.baseInfo.value.category.title}</h3>
                                <ul>
                                    <li>{contentDetailData.baseInfo.value.category.value}</li>
                                </ul>
                            </div>
                            : null
                        }
                        <div className="contents_detail_info">
                            <div className="left">
                                <h4 className="cdi_tit">{contentDetailData.baseInfo.value.introduction.title}</h4>
                                <p className="exp"  dangerouslySetInnerHTML={{ __html: contentDetailData.baseInfo.value.introduction.value }}></p>
                                <h4 className="cdi_tit">{contentDetailData.baseInfo.value.address.title}<span>{contentDetailData.baseInfo.value.address.value}</span></h4>
                                <div className="map">
                                    <KakaoMap
                                        latitude = {contentDetailData.baseInfo.value.map.value.latitude}
                                        longitude =  {contentDetailData.baseInfo.value.map.value.longitude}
                                    />
                                </div>
                            </div>
                            <div className="right">
                                <ul className="visit_info" style={{ marginTop : '20px'}}>
                                    {/* 전화번호 */}
                                    {contentDetailData.usageInfo.value.telephone.value !== '' && contentDetailData.usageInfo.value.telephone.value !== null ?
                                    <li>
                                        {/* <i className="icon call"></i> */}
                                        <PhoneIcon/>
                                        <div className="ex">
                                            <h5>{contentDetailData.usageInfo.value.telephone.title}</h5>
                                            <p dangerouslySetInnerHTML={{ __html: contentDetailData.usageInfo.value.telephone.value }}></p>
                                        </div>
                                    </li> : null
                                    }
                                    {/* 이용시간 */}
                                    {contentDetailData.usageInfo.value.hoursofuse.value !== '' && contentDetailData.usageInfo.value.hoursofuse.value !== null ?
                                        <li>
                                            {/* <i className="icon time"></i> */}
                                            <AccessTimeIcon/>
                                            <div className="ex">
                                                <h5 dangerouslySetInnerHTML={{ __html: contentDetailData.usageInfo.value.hoursofuse.title}}></h5>
                                                <p dangerouslySetInnerHTML={{ __html: contentDetailData.usageInfo.value.hoursofuse.value }}></p>
                                            </div>
                                        </li> : null
                                    }
                                    {/* 휴무일 */}
                                    {contentDetailData.usageInfo.value.holiday.value !== '' && contentDetailData.usageInfo.value.holiday.value !== null ?
                                        <li>
                                            {/* <i className="icon closed"></i> */}
                                            <EventIcon/>
                                            <div className="ex">
                                                <h5>{contentDetailData.usageInfo.value.holiday.title}</h5>
                                                <p dangerouslySetInnerHTML={{ __html: contentDetailData.usageInfo.value.holiday.value }}></p>
                                            </div>
                                        </li> : null
                                    }
                                    {/* 이용요금 */}
                                    {contentDetailData.usageInfo.value.usagefee.value !== '' && contentDetailData.usageInfo.value.usagefee.value !== null ?
                                    <li>
                                        {/* <i className="icon charge"></i> */}
                                        <CreditCardIcon/>
                                        <div className="ex">
                                            <h5>{contentDetailData.usageInfo.value.usagefee.title}</h5>
                                            <p dangerouslySetInnerHTML={{ __html: contentDetailData.usageInfo.value.usagefee.value }}></p>
                                        </div>
                                    </li> : null
                                    }
                                    {/* 주차장 */}
                                    {contentDetailData.usageInfo.value.parking.value !== '' && contentDetailData.usageInfo.value.parking.value !== null ?
                                    <li>
                                        {/* <i className="icon parking"></i> */}
                                        <LocalParkingIcon/>
                                        <div className="ex">
                                            <h5>{contentDetailData.usageInfo.value.parking.title}</h5>
                                            <p dangerouslySetInnerHTML={{ __html: contentDetailData.usageInfo.value.parking.value }}></p>
                                        </div>
                                    </li> : null
                                    }
                                    {/* 홈페이지 */}
                                    {contentDetailData.usageInfo.value.homepage.value !== '' && contentDetailData.usageInfo.value.homepage.value !== null?
                                        <li>
                                            {/* <i className="icon homepage"></i> */}
                                            <LanguageIcon/>
                                            <div className="ex">
                                                <h5>{contentDetailData.usageInfo.value.homepage.title}</h5>
                                                <p dangerouslySetInnerHTML={{ __html: contentDetailData.usageInfo.value.homepage.value }}></p>
                                            </div>
                                        </li> : null
                                    }
                                    {/* 음식 : 취급메뉴 */}
                                    {contentDetailData.usageInfo.value.menus.value !== '' && contentDetailData.usageInfo.value.menus.value !== null?
                                        <li>
                                            {/* <i className="icon homepage"></i> */}
                                            <RestaurantMenuIcon/>
                                            <div className="ex">
                                                <h5>{contentDetailData.usageInfo.value.menus.title}</h5>
                                                <p dangerouslySetInnerHTML={{ __html: contentDetailData.usageInfo.value.menus.value }}></p>
                                            </div>
                                        </li> : null
                                    }
                                    {/* 체험관광, 레포츠 : 체험가능연령 */}
                                    {contentDetailData.usageInfo.value.experienceage.value !== '' && contentDetailData.usageInfo.value.experienceage.value !== null?
                                        <li>
                                            {/* <i className="icon homepage"></i> */}
                                            <ElderlyIcon/>
                                            <div className="ex">
                                                <h5>{contentDetailData.usageInfo.value.experienceage.title}</h5>
                                                <p dangerouslySetInnerHTML={{ __html: contentDetailData.usageInfo.value.experienceage.value }}></p>
                                            </div>
                                        </li> : null
                                    }
                                    {/* 아이콘 예시 못찾음 */}
                                    {/* 체험관광, 레포츠 : 체험가능 프로그램 */}
                                    {contentDetailData.usageInfo.value.experienceprogram.value !== '' && contentDetailData.usageInfo.value.experienceprogram.value !== null?
                                        <li>
                                            {/* <i className="icon homepage"></i> */}
                                            <ArticleIcon/>
                                            <div className="ex">
                                                <h5>{contentDetailData.usageInfo.value.experienceprogram.title}</h5>
                                                <p dangerouslySetInnerHTML={{ __html: contentDetailData.usageInfo.value.experienceprogram.value }}></p>
                                            </div>
                                        </li> : null
                                    }
                                    {/* 축제/공연/헹사 : 기간 */}
                                    {contentDetailData.usageInfo.value.period.value !== '' && contentDetailData.usageInfo.value.period.value !== null?
                                        <li>
                                            {/* <i className="icon homepage"></i> */}
                                            <CalendarMonthIcon/>
                                            <div className="ex">
                                                <h5>{contentDetailData.usageInfo.value.period.title}</h5>
                                                <p dangerouslySetInnerHTML={{ __html: contentDetailData.usageInfo.value.period.value }}></p>
                                            </div>
                                        </li> : null
                                    }
                                    {/* 축제/공연/헹사 : 주관 */}
                                    {contentDetailData.usageInfo.value.sponsor.value !== '' && contentDetailData.usageInfo.value.sponsor.value !== null?
                                        <li>
                                            {/* <i className="icon homepage"></i> */}
                                            <BusinessIcon/>
                                            <div className="ex">
                                                <h5>{contentDetailData.usageInfo.value.sponsor.title}</h5>
                                                <p dangerouslySetInnerHTML={{ __html: contentDetailData.usageInfo.value.sponsor.value }}></p>
                                            </div>
                                        </li> : null
                                    }
                                    {/* 축제/공연/헹사 : 주최 */}
                                    {contentDetailData.usageInfo.value.host.value !== '' && contentDetailData.usageInfo.value.host.value !== null?
                                        <li>
                                            {/* <i className="icon homepage"></i> */}
                                            <BusinessIcon/>
                                            <div className="ex">
                                                <h5>{contentDetailData.usageInfo.value.host.title}</h5>
                                                <p dangerouslySetInnerHTML={{ __html: contentDetailData.usageInfo.value.host.value }}></p>
                                            </div>
                                        </li> : null
                                    }
                                    {/* 숙박 : 숙박요금 */}
                                    {contentDetailData.usageInfo.value.lodgingfare.value !== '' && contentDetailData.usageInfo.value.lodgingfare.value !== null?
                                    <li>
                                        {/* <i className="icon homepage"></i> */}
                                        <CreditCardIcon/>
                                        <div className="ex">
                                            <h5>{contentDetailData.usageInfo.value.lodgingfare.title}</h5>
                                            <p dangerouslySetInnerHTML={{ __html: contentDetailData.usageInfo.value.lodgingfare.value }}></p>
                                        </div>
                                    </li> : null
                                    }
                                    {/* 숙박 : 숙박시설등급 */}
                                    {contentDetailData.usageInfo.value.lodgegrade.value !== '' && contentDetailData.usageInfo.value.lodgegrade.value !== null?
                                    <li>
                                        {/* <i className="icon homepage"></i> */}
                                        <GradeIcon/>
                                        <div className="ex">
                                            <h5>{contentDetailData.usageInfo.value.lodgegrade.title}</h5>
                                            <p dangerouslySetInnerHTML={{ __html: contentDetailData.usageInfo.value.lodgegrade.value }}></p>
                                        </div>
                                    </li> : null
                                    }
                                    {/* 숙박 : 입실시간 */}
                                    {contentDetailData.usageInfo.value.checkingtime.value !== '' && contentDetailData.usageInfo.value.checkingtime.value !== null?
                                    <li>
                                        {/* <i className="icon homepage"></i> */}
                                        <InputIcon/>
                                        <div className="ex">
                                            <h5>{contentDetailData.usageInfo.value.checkingtime.title}</h5>
                                            <p dangerouslySetInnerHTML={{ __html: contentDetailData.usageInfo.value.checkingtime.value }}></p>
                                        </div>
                                    </li> : null
                                    }
                                    {/* 숙박 : 퇴실시간 */}
                                    {contentDetailData.usageInfo.value.checkouttime.value !== '' && contentDetailData.usageInfo.value.checkouttime.value !== null?
                                    <li>
                                        {/* <i className="icon homepage"></i> */}
                                        <OutputIcon/>
                                        <div className="ex">
                                            <h5>{contentDetailData.usageInfo.value.checkouttime.title}</h5>
                                            <p dangerouslySetInnerHTML={{ __html: contentDetailData.usageInfo.value.checkouttime.value }}></p>
                                        </div>
                                    </li> : null
                                    }
                                    {/* 숙박 : 조식서비스 */}
                                    {contentDetailData.usageInfo.value.breakfast.value !== '' && contentDetailData.usageInfo.value.breakfast.value !== null?
                                    <li>
                                        {/* <i className="icon homepage"></i> */}
                                        <RestaurantIcon/>
                                        <div className="ex">
                                            <h5>{contentDetailData.usageInfo.value.breakfast.title}</h5>
                                            <p dangerouslySetInnerHTML={{ __html: contentDetailData.usageInfo.value.breakfast.value }}></p>
                                        </div>
                                    </li> : null
                                    }
                                    {/* 아이콘 예시 못찾음 */}
                                    {/* 숙박 : 객실명 */}
                                    {contentDetailData.usageInfo.value.roomname.value !== '' && contentDetailData.usageInfo.value.roomname.value !== null?
                                    <li>
                                        {/* <i className="icon homepage"></i> */}
                                        <BedIcon/>
                                        <div className="ex">
                                            <h5>{contentDetailData.usageInfo.value.roomname.title}</h5>
                                            <p dangerouslySetInnerHTML={{ __html: contentDetailData.usageInfo.value.roomname.value }}></p>
                                        </div>
                                    </li> : null
                                    }
                                    {/* 아이콘 예시 못찾음 */}
                                    {/* 숙박 : 객실소개 */}
                                    {contentDetailData.usageInfo.value.roomcomment.value !== '' && contentDetailData.usageInfo.value.roomcomment.value !== null?
                                    <li>
                                        {/* <i className="icon homepage"></i> */}
                                        <BedIcon/>
                                        <div className="ex">
                                            <h5>{contentDetailData.usageInfo.value.roomcomment.title}</h5>
                                            <p dangerouslySetInnerHTML={{ __html: contentDetailData.usageInfo.value.roomcomment.value }}></p>
                                        </div>
                                    </li> : null
                                    }
                                    {/* 숙박 : 객실유형 */}
                                    {contentDetailData.usageInfo.value.roomtype.value !== '' && contentDetailData.usageInfo.value.roomtype.value !== null?
                                    <li>
                                        {/* <i className="icon homepage"></i> */}
                                        <BedIcon/>
                                        <div className="ex">
                                            <h5>{contentDetailData.usageInfo.value.roomtype.title}</h5>
                                            <p dangerouslySetInnerHTML={{ __html: contentDetailData.usageInfo.value.roomtype.value }}></p>
                                        </div>
                                    </li> : null
                                    }
                                    {/* 반려동물 : 동반가능동물 */}
                                    {contentDetailData.petCompanion.value.RangePossibilityAccompaniedPet.value !== '' 
                                        && contentDetailData.petCompanion.value.RangePossibilityAccompaniedPet.value !== null 
                                        && contentDetailData.petCompanion.value.RangePossibilityAccompaniedPet.value !== '없음' ?
                                        <li>
                                            {/* <i className="icon homepage"></i> */}
                                            <PetsIcon/>
                                            <div className="ex">
                                                <h5>{contentDetailData.petCompanion.value.RangePossibilityAccompaniedPet.title}</h5>
                                                <p dangerouslySetInnerHTML={{ __html: contentDetailData.petCompanion.value.RangePossibilityAccompaniedPet.value }}></p>
                                            </div>
                                        </li> : null
                                    }
                                    {/* 반려동물 : 동반시 필요사항 */}
                                    {contentDetailData.petCompanion.value.RequirementsForAccompany.value !== ''
                                        && contentDetailData.petCompanion.value.RequirementsForAccompany.value !== null
                                        && contentDetailData.petCompanion.value.RequirementsForAccompany.value !== '없음' ?
                                        <li>
                                            {/* <i className="icon homepage"></i> */}
                                            <PetsIcon/>
                                            <div className="ex">
                                                <h5>{contentDetailData.petCompanion.value.RequirementsForAccompany.title}</h5>
                                                <p dangerouslySetInnerHTML={{ __html: contentDetailData.petCompanion.value.RequirementsForAccompany.value }}></p>
                                            </div>
                                        </li> : null
                                    }
                                    {/* 반려동물 : 동반관련 상세사항 */}
                                    {contentDetailData.petCompanion.value.OtherInformation.value !== ''
                                        && contentDetailData.petCompanion.value.OtherInformation.value !== null
                                        && contentDetailData.petCompanion.value.OtherInformation.value !== '없음' ?
                                        <li>
                                            {/* <i className="icon homepage"></i> */}
                                            <PetsIcon/>
                                            <div className="ex">
                                                <h5>{contentDetailData.petCompanion.value.OtherInformation.title}</h5>
                                                <p dangerouslySetInnerHTML={{ __html: contentDetailData.petCompanion.value.OtherInformation.value }}></p>
                                            </div>
                                        </li> : null
                                    }
                                    {/* 반려동물 : 구비시설 */}
                                    {contentDetailData.petCompanion.value.FacilitiesForPets.value !== ''
                                        && contentDetailData.petCompanion.value.FacilitiesForPets.value !== null
                                        && contentDetailData.petCompanion.value.FacilitiesForPets.value !== '없음'?
                                        <li>
                                            {/* <i className="icon homepage"></i> */}
                                            <PetsIcon/>
                                            <div className="ex">
                                                <h5>{contentDetailData.petCompanion.value.FacilitiesForPets.title}</h5>
                                                <p dangerouslySetInnerHTML={{ __html: contentDetailData.petCompanion.value.FacilitiesForPets.value }}></p>
                                            </div>
                                        </li> : null
                                    }
                                    {/* 반려동물 : 사고 대비사항 */}
                                    {contentDetailData.petCompanion.value.AccidentPreparedness.value !== ''
                                        && contentDetailData.petCompanion.value.AccidentPreparedness.value !== null
                                        && contentDetailData.petCompanion.value.AccidentPreparedness.value !== '없음'?
                                        <li>
                                            {/* <i className="icon homepage"></i> */}
                                            <PetsIcon/>
                                            <div className="ex">
                                                <h5>{contentDetailData.petCompanion.value.AccidentPreparedness.title}</h5>
                                                <p dangerouslySetInnerHTML={{ __html: contentDetailData.petCompanion.value.AccidentPreparedness.value }}></p>
                                            </div>
                                        </li> : null
                                    }
                                    {/* 반려동물 : 비치 품목 */}
                                    {contentDetailData.petCompanion.value.ItemsProvidedForPets.value !== ''
                                        && contentDetailData.petCompanion.value.ItemsProvidedForPets.value !== null
                                        && contentDetailData.petCompanion.value.ItemsProvidedForPets.value !== '없음'?
                                        <li>
                                            {/* <i className="icon homepage"></i> */}
                                            <PetsIcon/>
                                            <div className="ex">
                                                <h5>{contentDetailData.petCompanion.value.ItemsProvidedForPets.title}</h5>
                                                <p dangerouslySetInnerHTML={{ __html: contentDetailData.petCompanion.value.ItemsProvidedForPets.value }}></p>
                                            </div>
                                        </li> : null
                                    }
                                    {/* 반려동물 : 렌탈 품목 */}
                                    {contentDetailData.petCompanion.value.RentalItems.value !== ''
                                        && contentDetailData.petCompanion.value.RentalItems.value !== null
                                        && contentDetailData.petCompanion.value.RentalItems.value !== '없음'?
                                        <li>
                                            {/* <i className="icon homepage"></i> */}
                                            <PetsIcon/>
                                            <div className="ex">
                                                <h5>{contentDetailData.petCompanion.value.RentalItems.title}</h5>
                                                <p dangerouslySetInnerHTML={{ __html: contentDetailData.petCompanion.value.RentalItems.value }}></p>
                                            </div>
                                        </li> : null
                                    }
                                    {/* 반려동물 : 구매 품목 */}
                                    {contentDetailData.petCompanion.value.PurchasedItems.value !== ''
                                        && contentDetailData.petCompanion.value.PurchasedItems.value !== null
                                        && contentDetailData.petCompanion.value.PurchasedItems.value !== '없음'?
                                        <li>
                                            {/* <i className="icon homepage"></i> */}
                                            <PetsIcon/>
                                            <div className="ex">
                                                <h5>{contentDetailData.petCompanion.value.PurchasedItems.title}</h5>
                                                <p dangerouslySetInnerHTML={{ __html: contentDetailData.petCompanion.value.PurchasedItems.value }}></p>
                                            </div>
                                        </li> : null
                                    }
                                    {/* 반려동물 : 동반 구분 */}
                                    {contentDetailData.petCompanion.value.CompanionSortation.value !== ''
                                        && contentDetailData.petCompanion.value.CompanionSortation.value !== null
                                        && contentDetailData.petCompanion.value.CompanionSortation.value !== '없음'?
                                        <li>
                                            {/* <i className="icon homepage"></i> */}
                                            <PetsIcon/>
                                            <div className="ex">
                                                <h5>{contentDetailData.petCompanion.value.CompanionSortation.title}</h5>
                                                <p dangerouslySetInnerHTML={{ __html: contentDetailData.petCompanion.value.CompanionSortation.value }}></p>
                                            </div>
                                        </li> : null
                                    }
                                </ul>
                        <div className="contents_detail_info">
                            <div className="left">
                                <h4 className="cdi_tit">{contentDetailData.registrationInfo.title}</h4>
                                <ul className="registration_info">
                                {/* 정보제공 */}
                                {contentDetailData.registrationInfo.value.copyright.value !== '' && contentDetailData.registrationInfo.value.copyright.value !== null ?
                                <li>
                                    <h5>{contentDetailData.registrationInfo.value.copyright.title}</h5>
                                    <p dangerouslySetInnerHTML={{ __html: contentDetailData.registrationInfo.value.copyright.value }}></p>
                                </li> : null
                                }
                                {/* 갱신일 */}
                                {contentDetailData.registrationInfo.value.modificationTime.value !== '' && contentDetailData.registrationInfo.value.modificationTime.value !== null ?
                                    <li>
                                        <h5>{contentDetailData.registrationInfo.value.modificationTime.title}</h5>
                                        <p>{contentDetailData.registrationInfo.value.modificationTime.value}</p>
                                    </li> : null
                                }
                            </ul>
                            </div>
                        </div>
                        <div className='content-modify-date' style={{display: 'none'}}>{modifyDate}</div>
                        <div className='content-cineroom-name' style={{display: 'none'}}>{lastStatusUpdaterCineroomName}</div>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
  }
}

export default FormalContentsDetails;
