/* eslint-disable camelcase */
import React from 'react';
import _ from 'lodash';

interface Props {
  color: string;
  number: number;
  width: number;
  height: number;
}

interface State {
}

class Marker extends React.Component<Props, State> {
  //
  static defaultProps = {
    color: '#4287f5'
  };

  state: State = {

  };

  render() {
    const { color, number, width, height } = this.props;

    return (
      <>
        <svg width={width} height={height} viewBox="0 0 48 61" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g filter="url(#filter0_d_1_14)">
            <circle cx="25.7078" cy="20.2168" r="19.2168" fill={color}/>
            <path d="M30.0106 50.6692C28.0745 53.9401 23.3411 53.9401 21.4051 50.6692L11.3753 33.7243C9.40247 30.3913 11.8049 26.1775 15.6781 26.1775L35.7376 26.1775C39.6107 26.1775 42.0132 30.3913 40.0403 33.7243L30.0106 50.6692Z" fill={color}/>
            <g filter="url(#filter1_i_1_14)">
              <circle cx="25.7078" cy="20.2168" r="13.5229" fill="white"/>
            </g>
          </g>
          <text textAnchor="middle" dominantBaseline="central" x="55%" y="32%" fill={color} fontWeight="bold" fontSize={16}>{number}</text>
          <defs>
            <filter id="filter0_d_1_14" x="0.491089" y="0" width="46.4335" height="60.1224" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
              <feFlood floodOpacity={0} result="BackgroundImageFix" />
              <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
              <feOffset dx="-2" dy="3"/>
              <feGaussianBlur stdDeviation="2"/>
              <feComposite in2="hardAlpha" operator="out"/>
              <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"/>
              <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1_14"/>
              <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1_14" result="shape"/>
            </filter>
            <filter id="filter1_i_1_14" x="12.1849" y="6.69385" width="28.0458" height="28.0458" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
              <feFlood floodOpacity={0} result="BackgroundImageFix"/>
              <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
              <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
              <feOffset dx="1" dy="1"/>
              <feGaussianBlur stdDeviation="2"/>
              <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
              <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
              <feBlend mode="normal" in2="shape" result="effect1_innerShadow_1_14"/>
            </filter>
          </defs>
        </svg>
      </>
    );
  }
}

export default Marker;
