
import mobxService from '../../../../../lib/decorator/mobxService/mobxService';
import makeExtendedObservable from '../../../../../lib/decorator/makeExtendedObservable';
import Filebox from '../../../../api/filebox/api-model/Filebox';


@mobxService
class FileboxStateKeeper {
  static readonly instanceName: string = 'fileboxStateKeeper';
  static readonly serviceName: string = 'depot.filebox.fileboxStateKeeper';
  static instance: FileboxStateKeeper;

  filebox: Filebox | null = null;

  constructor(
  ) {
    makeExtendedObservable(this);
  }

  setFileboxProp(name: keyof Filebox, value: any) {
    if (!this.filebox) {
      throw new DOMException('FileboxStateKeeper.setFileboxProp', 'filebox is null');
    }
    (this.filebox as any)[name] = value;
  }

  clear() {
    this.filebox = null;
  }
}

FileboxStateKeeper.instance = new FileboxStateKeeper();

export default FileboxStateKeeper;
