import _ from 'lodash';
import { runInAction } from 'mobx';
import AddressOn from '../../../../lib/granule/AddressOn';
import OrgProposal from '../../../../OrgProposalForm/api-model/OrgProposal';
import Phone from '../../../../lib/granule/Phone';
import DramaStateKeeper from '../../shared/DramaStateKeeper';
import DepotFile from '../../../../lib/granule/DepotFile';
import mobxService from '../../../../lib/decorator/mobxService/mobxService';


@mobxService
class OrgProposalStateKeeper extends DramaStateKeeper {
  static readonly instanceName: string = 'orgProposalStateKeeper';
  static readonly serviceName: string = 'onboarding.organization.orgProposalStateKeeper';
  static instance: OrgProposalStateKeeper;

  orgProposal: OrgProposal | null = null;
  additionalFiles: DepotFile[] = [];


  init() {
    this.orgProposal = OrgProposal.new();
  }

  setOrgProposalProp(name: keyof OrgProposal, value: any) {
    if (!this.orgProposal) {
      throw new DOMException('OrgProposalStateKeeper.setOrgProposalProp', 'orgProposal is null');
    }
    if (name === 'managerTel' || name === 'orgTel' || name === 'departmentTel') {
      this.setTelProp(name, this.formatTelNumber(value));
      return;
    }
    this.orgProposal = _.set(this.orgProposal, name, value);
  }

  setTelProp(name: keyof OrgProposal, value: any) {
    if (!this.orgProposal) {
      throw new DOMException('OrgProposalStateKeeper.setTelProp', 'orgProposal is null');
    }
    switch (name) {
      case 'managerTel':
        if (!this.orgProposal.managerTel) {
          this.orgProposal.managerTel = new Phone();
        }
        this.orgProposal.managerTel = {
          ...this.orgProposal.managerTel,
          displayNumber: value,
        } as Phone;
        break;
      case 'orgTel':
        if (!this.orgProposal.orgTel) {
          this.orgProposal.orgTel = new Phone();
        }
        this.orgProposal.orgTel = {
          ...this.orgProposal.orgTel,
          displayNumber: value,
        } as Phone;
        break;
      case 'departmentTel':
        if (!this.orgProposal.departmentTel) {
          this.orgProposal.departmentTel = new Phone();
        }
        this.orgProposal.departmentTel = {
          ...this.orgProposal.departmentTel,
          displayNumber: value,
        } as Phone;
        break;
    }
  }

  formatTelNumber(value: string ) {
    if (!value) {
      return;
    }
    const formattedNumber = value.replace(/[^0-9]/g, '').replace(/(^02|^050[2-9]{1}|^1[0-9]{3}|^0[0-9]{2})([0-9]+)?([0-9]{4})$/, '$1-$2-$3').replace('--', '-');

    return formattedNumber;
  }

  setAddressProp(name: keyof AddressOn, value: any) {
    if (!this.orgProposal) {
      throw new DOMException('OrgProposalStateKeeper.setOrgProposalProp', 'orgProposal is null');
    }
    if (!this.orgProposal.address) {
      this.orgProposal.address = new AddressOn();
    }
    this.orgProposal.address = _.set(this.orgProposal.address, name, value);
  }

  setFileId(fileId: string) {
    runInAction(() =>
      this.orgProposal?.fileIds.push(fileId));
  }

  deleteFileIds(fileIds: string[]) {
    runInAction(() =>
      fileIds.forEach((fileId) => this.orgProposal?.fileIds.splice(this.orgProposal?.fileIds.indexOf(fileId), 1))
    );
  }

  setAdditionalFiles(depotFiles: DepotFile[]) {
    this.additionalFiles = depotFiles;
  }

  clear() {
    this.orgProposal = null;
  }
}

OrgProposalStateKeeper.instance = new OrgProposalStateKeeper();

export default OrgProposalStateKeeper;
