import fromDomain from "../../../../../lib/decorator/fromDomain";
import makeExtendedObservable from "../../../../../lib/decorator/makeExtendedObservable";

@fromDomain
class RetainedVersion {
  targetVersion: number;

  constructor(targetVersion: number) {
    this.targetVersion = targetVersion;
    makeExtendedObservable(this);
  }

  static fromDomains(domains: RetainedVersion[]): RetainedVersion[] {
    return domains.map(domain => this.fromDomain(domain));
  }

  static fromDomain(domain: RetainedVersion): RetainedVersion {
    const retainedVersion = new RetainedVersion(
      domain.targetVersion,
    );

    return retainedVersion;
  }

}

export default RetainedVersion;