
export const hasAllDramaRoles = (rolePool: string[], dramaId: string, roles: string[]) => hasAll(rolePool, roles.map(role => `${dramaId}:${role}`));

export const hasAnyDramaRoles = (rolePool: string[], dramaId: string, roles: string[]) => hasAny(rolePool, roles.map(role => `${dramaId}:${role}`));

export const dramaRoles = (rolePool: string[], dramaId: string) => rolePool.filter(role => role.startsWith(`${dramaId}:`));

export const hasAll = (roleSet: string[], roles: string[]) => roles.every(role => roleSet.includes(role));

export const hasAny = (roleSet: string[], roles: string[]) => roles.findIndex(role => roleSet.includes(role)) > -1;

