import React from "react";

interface Props {}

interface State {}

class OnlineServicePage extends React.Component<Props, State> {
  static defaultProps: any = {};

  componentDidMount() {}

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
    snapshot?: any
  ): void {}

  //@ts-ignore
  state: State = { ...this.props };

  link1(event: any) {
    window.open("https://dangdangmap.net/pet", "_blank");
  }
  link2(event: any) {
    window.open("https://www.tourpass.kr/", "_blank");
  }
  link3(event: any) {
    window.open("https://korean1.visitkorea.or.kr/kor/tt/pr_gallery/new_photo_gallery/main/main_ssot.jsp", "_blank");
  }
  link4(event: any) {
    window.open("https://korean.visitkorea.or.kr/main/main.do", "_blank");
  }

  render() {
    return (
      <>
        <div id="contents" className="online_service">
          <div className="center nav_wrap">
            <ul className="nav">
              <li>
                <i className="icon home"></i>
              </li>
              <li>
                활용사례
                {/* <i className="icon down_circle"></i> */}
              </li>
              <li>
                <span>활용 사례</span>
                {/* <i className="icon up_circle"></i> */}
              </li>
            </ul>
          </div>
          <section className="page_tit_area">
            <div className="center">
              <h1>활용 사례</h1>
              <p>
                한국관광콘텐츠랩에서 제공하는 콘텐츠를 이용하여 온라인 서비스에
                활용할 수 있습니다.
              </p>
            </div>
          </section>

          <section className="os_list">
            <div className="center right_side_content">
              <ul className="service_link_list">
                {/* <li className="service_link online_service01">
                  <div className="info_box">
                    <h3>강원 댕댕여지도</h3>

                    <ul className="dot_list">
                      <li>강원관광재단에서 운영하는 댕댕여지도</li>
                      <li>관광정보 데이터 연동 및 이미지 서비스 연계 제공 중</li>
                      <li>강원지역의 지역별, 분야별 여행지 소개 및 공유</li>
                      <li>반려동물 동반 업체 홍보</li>
                      <li>여행 코스 계획 도움</li>

                    </ul>
                    <div className="bts">
                      <button className="st_point" onClick={(event) => this.link1(event)} style={{fontSize: 16}}>
                        사이트 바로가기{" "}
                        <img src="/rms-conlab/images/icon_move_newtab.png" alt="icon" />
                      </button>
                    </div>
                  </div>
                  <div className="img_box">
                    <img
                      src="/rms-conlab/images/online_service_img01.png"
                      alt="강원 댕댕여지도"
                    />
                  </div>
                </li>
                <li className="service_link online_service02">
                  <div className="info_box">
                    <h3>한국 투어패스</h3>
                    <ul className="dot_list">
                      <li>강원지역의 여행 상품 서비스 운영 중</li>
                      <li>
                        관광정보 데이터 연동 및 이미지 서비스 연계 제공 중
                      </li>
                      <li>강원 지역관광 상품패키지 및 데이터를 제공</li>
                      <li> 교통 , 결제 기능 제공</li>
                    </ul>
                    <div className="bts">
                      <button className="st_point" onClick={(event) => this.link2(event)} style={{fontSize: 16}}>
                        사이트 바로가기{" "}
                        <img src="/rms-conlab/images/icon_move_newtab.png" alt="icon" />
                      </button>
                    </div>
                  </div>
                  <div className="img_box">
                    <img
                      src="/rms-conlab/images/online_service_img02.png"
                      alt="한국 투어패스"
                    />
                  </div>
                </li> */}
                <li className="service_link online_service03">
                  <div className="info_box">
                    <h3>한국관광공사 포토코리아</h3>
                    <ul className="dot_list">
                      <li>전국의 여행지 사진을 제공함</li>
                      <li>
                        관광정보 데이터 연동 및 이미지 서비스 연계 제공 중
                      </li>
                    </ul>
                    <div className="bts">
                      <button className="st_point" onClick={(event) => this.link3(event)} style={{fontSize: 16}}>
                        사이트 바로가기{" "}
                        <img src="/rms-conlab/images/icon_move_newtab.png" alt="icon" />
                      </button>
                    </div>
                  </div>
                  <div className="img_box">
                    <img
                      src="/rms-conlab/images/online_service_img03.png"
                      alt="한국관광공사 사진갤러리"
                    />
                  </div>
                </li>
                <li className="service_link online_service04">
                  <div className="info_box">
                    <h3>대한민국 구석구석</h3>
                    <ul className="dot_list">
                      <li>대한민국 관광지 / 여행 정보 운영 중</li>
                      <li>
                        관광정보 데이터 연동 및 이미지 서비스 연계 제공 중
                      </li>
                    </ul>
                    <div className="bts">
                      <button className="st_point" onClick={(event) => this.link4(event)} style={{fontSize: 16}}>
                          사이트 바로가기{" "}
                        <img src="/rms-conlab/images/icon_move_newtab.png" alt="icon" />
                      </button>
                    </div>
                  </div>
                  <div className="img_box">
                    <img
                      src="/rms-conlab/images/online_service_img04.png"
                      alt="대한민국 구석구석"
                    />
                  </div>
                </li>
              </ul>
            </div>
          </section>
        </div>

        {/* <!-- 서비스 상세 팝업 -->*/}
        <div className="service_detail_popup service1">
          <div className="center">
            <div className="scroll_area">
              <button className="btn_close">
                <img src="/rms-conlab/images/btn_close.png" alt="btn_close" />
              </button>
              <h2>강원 댕댕여지도</h2>

              <div className="sdp_con">
                <div className="img_box">
                  <img src="/rms-conlab/images/sdp_img01.png" alt="img" />
                </div>
                <div className="visit_info_wrap">
                  <ul className="visit_info">
                    <li>
                      <i className="icon homepage"></i>
                      <div className="ex">
                        <h5>URL</h5>
                        <p>
                          <a href="https://www.tourpass.kr/" target="_blank">
                            https://www.tourpass.kr/
                          </a>
                          <button className="copy">
                            <img src="/rms-conlab/images/icon_copy.png" alt="" />
                          </button>
                        </p>
                      </div>
                    </li>
                    <li>
                      <i className="icon copyright"></i>
                      <div className="ex">
                        <h5>운영기관</h5>
                        <p>한국투어패스</p>
                      </div>
                    </li>
                    <li>
                      <i className="icon interests"></i>
                      <div className="ex">
                        <h5>서비스분류</h5>
                        <p>여행상품 제공</p>
                      </div>
                    </li>
                    <li>
                      <i className="icon calendar_add_on"></i>
                      <div className="ex">
                        <h5>등록일</h5>
                        <p>2023.07.01</p>
                      </div>
                    </li>
                    <li>
                      <i className="icon call"></i>
                      <div className="ex">
                        <h5>고객센터</h5>
                        <p>
                          <a href="tel:070-8845-9990">070-8845-9990</a>
                        </p>
                      </div>
                    </li>
                    <li>
                      <i className="icon code_blocks"></i>
                      <div className="ex">
                        <p>・강원 지역 관광 API</p>
                        <p>・강원 이미지 API</p>
                        <p>・동영상 API 서비스</p>
                      </div>
                    </li>
                  </ul>
                  <div className="service_intro">
                    <h4 className="cdi_tit">서비스 소개</h4>
                    <p className="exp">
                      한국투어패스는 지역관광 상품패키지&데이터를 제공하는
                      서비스로써, 현재 강원지역을 대상으로 서비스 제공중 입니다.
                      지역의 관광자원과 관련 시설에 교통과 결재 기능을 묶어
                      관광객들이 카드 한장으로 편리하게 관광시설 전반을 이용 할
                      수 있도록 제공하는 서비스 입니다.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="bg"></div>
        </div>

        <div className="service_detail_popup service2">
          <div className="center">
            <div className="scroll_area">
              <button className="btn_close">
                <img src="/rms-conlab/images/btn_close.png" alt="btn_close" />
              </button>
              <h2>한국투어패스 강원패스</h2>

              <div className="sdp_con">
                <div className="img_box">
                  <img src="/rms-conlab/images/sdp_img02.png" alt="img" />
                </div>
                <div className="visit_info_wrap">
                  <ul className="visit_info">
                    <li>
                      <i className="icon homepage"></i>
                      <div className="ex">
                        <h5>URL</h5>
                        <p>
                          <a href="https://www.tourpass.kr/" target="_blank">
                            https://www.tourpass.kr/
                          </a>
                          <button className="copy">
                            <img src="/rms-conlab/images/icon_copy.png" alt="" />
                          </button>
                        </p>
                      </div>
                    </li>
                    <li>
                      <i className="icon copyright"></i>
                      <div className="ex">
                        <h5>운영기관</h5>
                        <p>한국투어패스</p>
                      </div>
                    </li>
                    <li>
                      <i className="icon interests"></i>
                      <div className="ex">
                        <h5>서비스분류</h5>
                        <p>여행상품 제공</p>
                      </div>
                    </li>
                    <li>
                      <i className="icon calendar_add_on"></i>
                      <div className="ex">
                        <h5>등록일</h5>
                        <p>2023.07.01</p>
                      </div>
                    </li>
                    <li>
                      <i className="icon call"></i>
                      <div className="ex">
                        <h5>고객센터</h5>
                        <p>
                          <a href="tel:070-8845-9990">070-8845-9990</a>
                        </p>
                      </div>
                    </li>
                    <li>
                      <i className="icon code_blocks"></i>
                      <div className="ex">
                        <p>・강원 지역 관광 API</p>
                        <p>・강원 이미지 API</p>
                        <p>・동영상 API 서비스</p>
                      </div>
                    </li>
                  </ul>
                  <div className="service_intro">
                    <h4 className="cdi_tit">서비스 소개</h4>
                    <p className="exp">
                      한국투어패스는 지역관광 상품패키지&데이터를 제공하는
                      서비스로써, 현재 강원지역을 대상으로 서비스 제공중 입니다.
                      지역의 관광자원과 관련 시설에 교통과 결재 기능을 묶어
                      관광객들이 카드 한장으로 편리하게 관광시설 전반을 이용 할
                      수 있도록 제공하는 서비스 입니다.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="bg"></div>
        </div>

        <div className="service_detail_popup service3">
          <div className="center">
            <div className="scroll_area">
              <button className="btn_close">
                <img src="/rms-conlab/images/btn_close.png" alt="btn_close" />
              </button>
              <h2>한국관광공사 사진갤러리</h2>

              <div className="sdp_con">
                <div className="img_box">
                  <img src="/rms-conlab/images/sdp_img03.png" alt="img" />
                </div>
                <div className="visit_info_wrap">
                  <ul className="visit_info">
                    <li>
                      <i className="icon homepage"></i>
                      <div className="ex">
                        <h5>URL</h5>
                        <p>
                          <a href="https://www.tourpass.kr/" target="_blank">
                            https://www.tourpass.kr/
                          </a>
                          <button className="copy">
                            <img src="/rms-conlab/images/icon_copy.png" alt="" />
                          </button>
                        </p>
                      </div>
                    </li>
                    <li>
                      <i className="icon copyright"></i>
                      <div className="ex">
                        <h5>운영기관</h5>
                        <p>한국투어패스</p>
                      </div>
                    </li>
                    <li>
                      <i className="icon interests"></i>
                      <div className="ex">
                        <h5>서비스분류</h5>
                        <p>여행상품 제공</p>
                      </div>
                    </li>
                    <li>
                      <i className="icon calendar_add_on"></i>
                      <div className="ex">
                        <h5>등록일</h5>
                        <p>2023.07.01</p>
                      </div>
                    </li>
                    <li>
                      <i className="icon call"></i>
                      <div className="ex">
                        <h5>고객센터</h5>
                        <p>
                          <a href="tel:070-8845-9990">070-8845-9990</a>
                        </p>
                      </div>
                    </li>
                    <li>
                      <i className="icon code_blocks"></i>
                      <div className="ex">
                        <p>・강원 지역 관광 API</p>
                        <p>・강원 이미지 API</p>
                        <p>・동영상 API 서비스</p>
                      </div>
                    </li>
                  </ul>
                  <div className="service_intro">
                    <h4 className="cdi_tit">서비스 소개</h4>
                    <p className="exp">
                      한국투어패스는 지역관광 상품패키지&데이터를 제공하는
                      서비스로써, 현재 강원지역을 대상으로 서비스 제공중 입니다.
                      지역의 관광자원과 관련 시설에 교통과 결재 기능을 묶어
                      관광객들이 카드 한장으로 편리하게 관광시설 전반을 이용 할
                      수 있도록 제공하는 서비스 입니다.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="bg"></div>
        </div>

        <div className="service_detail_popup service4">
          <div className="center">
            <div className="scroll_area">
              <button className="btn_close">
                <img src="/rms-conlab/images/btn_close.png" alt="btn_close" />
              </button>
              <h2>대한민국 구석구석</h2>

              <div className="sdp_con">
                <div className="img_box">
                  <img src="/rms-conlab/images/sdp_img04.png" alt="img" />
                </div>
                <div className="visit_info_wrap">
                  <ul className="visit_info">
                    <li>
                      <i className="icon homepage"></i>
                      <div className="ex">
                        <h5>URL</h5>
                        <p>
                          <a href="https://www.tourpass.kr/" target="_blank">
                            https://www.tourpass.kr/
                          </a>
                          <button className="copy">
                            <img src="/rms-conlab/images/icon_copy.png" alt="" />
                          </button>
                        </p>
                      </div>
                    </li>
                    <li>
                      <i className="icon copyright"></i>
                      <div className="ex">
                        <h5>운영기관</h5>
                        <p>한국투어패스</p>
                      </div>
                    </li>
                    <li>
                      <i className="icon interests"></i>
                      <div className="ex">
                        <h5>서비스분류</h5>
                        <p>여행상품 제공</p>
                      </div>
                    </li>
                    <li>
                      <i className="icon calendar_add_on"></i>
                      <div className="ex">
                        <h5>등록일</h5>
                        <p>2023.07.01</p>
                      </div>
                    </li>
                    <li>
                      <i className="icon call"></i>
                      <div className="ex">
                        <h5>고객센터</h5>
                        <p>
                          <a href="tel:070-8845-9990">070-8845-9990</a>
                        </p>
                      </div>
                    </li>
                    <li>
                      <i className="icon code_blocks"></i>
                      <div className="ex">
                        <p>・강원 지역 관광 API</p>
                        <p>・강원 이미지 API</p>
                        <p>・동영상 API 서비스</p>
                      </div>
                    </li>
                  </ul>
                  <div className="service_intro">
                    <h4 className="cdi_tit">서비스 소개</h4>
                    <p className="exp">
                      한국투어패스는 지역관광 상품패키지&데이터를 제공하는
                      서비스로써, 현재 강원지역을 대상으로 서비스 제공중 입니다.
                      지역의 관광자원과 관련 시설에 교통과 결재 기능을 묶어
                      관광객들이 카드 한장으로 편리하게 관광시설 전반을 이용 할
                      수 있도록 제공하는 서비스 입니다.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="bg"></div>
        </div>
      </>
    );
  }
}

export default OnlineServicePage;
