import React from 'react';
import"../../css/terms.css";
import { Box } from '@mui/material';


interface Props {
}

interface State {
}



class TermsOfUse extends React.Component<Props, State> {
  //

  render() {
    //
    return (
        <>
       <div id="terms-of-use" className='main' style={{padding : "0 18% 3% 18% " }}>
        <div className="public-location layout-container-publicCenter">
          <div className="layout-container-publicCenter layout-public-contents">
            <div>

              <div className="public-title">
                <h2 className="title">
                  <strong className="b">이용약관</strong>
                </h2>
              </div>

              <div className="layout-lineHeight public-service-wrap layout-mt-20">
                
                <div className="layout-mt-36">
                  <div className="size-txt20">
                    <strong className="b">
                      제1장 총칙
                    </strong>
                  </div>
                </div>


                <div className="size-txt16 layout-mt-20">
                  <strong className="b">
                    제1조(목적)
                  </strong>
                </div>

                본 이용약관(이하 “약관”)은 한국관광공사(이하 "공사")가 제공하는 한국관광콘텐츠랩(이하 콘텐츠랩) 서비스 이용계약에서 공사와 이용자의 권리 및 의무 등에 관한 사항을 규정함을 목적으로 합니다.<br />


                <div className="size-txt16 layout-mt-20">
                  <strong className="b">
                    제2조(용어의 정의)
                  </strong>
                </div>

                ① 본 약관에서 사용하는 용어의 뜻은 다음과 같습니다.<br />
                1. 이용자: 공사가 제공하는 콘텐츠랩 서비스를 이용하는 회원 및 비회원<br />
                2. 이용계약 : 콘텐츠랩 서비스 이용과 관련하여 공사와 이용자간에 체결하는 계약<br />
                3. 회원: 공사와 콘텐츠랩 이용계약을 체결하고 회원아이디(ID)(서비스 이용에 대한 계정)을 부여받은 이용자<br />
                4. 비회원: 회원이 아닌 이용자<br />
                5. 기업회원 : 투어원패스(TOURONEPASS) 인증 후 콘텐츠랩 서비스에 단체회원으로 가입 신청하고, 공사로부터 가입 승인을 받은 법인/개인사업자 등 사업자등록증을 보유한 단체 대표회원 및 단체 소속회원<br />
                - 기업 대표회원 : 단체의 대표 또는 단체 최초 개설자로서 단체 개‧폐, 소속 회원가입 승인 등의 권한을 가진 회원<br />
                - 기업 소속회원 : 개설된 단체의 소속회원으로서 단체 대표회원의 가입 승인이 필요한 회원<br />
                6. 개인회원 : 투어원패스(TOURONEPASS) 인증 후 콘텐츠랩 서비스에 개인회원으로 가입한 이용자<br />
                7. 디지털콘텐츠 : 부호‧문자‧도형‧색체‧음성‧음향‧이미지‧ 및 영상 등(이들의 복합체를 포함)의 자료 또는 정보로서 그 보존 및 이용의 효용을 높일 수 있도록 디지털 형태로 제작하거나 처리한 것<br />
                8. 온라인 서비스 : 콘텐츠랩에서 제공하는 일체의 서비스<br />
                9. 회원아이디(ID) : 콘텐츠랩에서 회원을 식별하기 위해 부여되는 식별자로, 콘텐츠랩 가입을 위해 개인인증한 전자우편주소<br />
                10. 해지(탈퇴): 공사 또는 회원이 이용계약을 해약하는 것<br />
                11. 패밀리사이트 : 회원이 콘텐츠랩 이용을 위해 가입한 투어원패스(TOURONEPASS) 인증정보를 공유하여, 별도의 추가 인증 절차 없이 맞춤형 서비스를 이용할 수 있도록 제공하는 사이트<br />
                ② 본 약관에서 사용하는 용어의 정의는 제1항에서 정하는 것을 제외하고는 관계법령, 개별서비스에 대한 별도약관 및 이용규정에서 정의합니다.<br />


                <div className="size-txt16 layout-mt-20">
                  <strong className="b">
                    제3조(약관의 변경 등)
                  </strong>
                </div>

                ① 공사는 「콘텐츠산업진흥법」, 「전자상거래 등에서의 소비자보호에 관한 법률」, 「약관의 규제에 관한 법률」 등 관련법령을 위반하지 않는 범위에서 이 약관을 변경할 수 있습니다.<br />
                ② 제1항에 따라 약관을 변경할 경우, 공사는 시행일 및 변경사유를 명시하여 개정 약관(현행 약관과의 비교표를 포함합니다)을 콘텐츠랩 초기화면에서 다음 각 호에서 정하는 기간 및 시행일로부터 상당한 기간 동안 공지합니다. 다만, 정당한 사유가 있는 경우, 개정약관 등을 확인할 수 있는 링크 등을 제공할 수 있습니다.<br />
                1. 변경하고자 하는 약관의 내용이 중대하거나 회원에게 불리한 경우 : 시행일로부터 30일 전부터 시행일까지<br />
                2. 제1호에 해당하지 않는 경우 : 시행일로부터 7일 전부터 시행일까지<br />
                ③ 제1항에 따라 약관을 변경할 경우에 공사는 회원에게 다음 각 호에서 정하는 기한까지 변경 약관(현행 약관과의 비교표를 포함합니다) 및 변경사유를 전자우편주소로 전송하고, 동의를 받습니다.<br />
                1. 변경하고자 하는 약관의 내용이 중대하거나 회원에게 불리한 경우 : 시행일로부터 30일 전까지<br />
                2. 제1호에 해당하지 않는 경우 : 시행일로부터 7일 전까지<br />
                ④ 변경 약관은 제3항에 따라 동의한 회원에게 적용됩니다. 다만, 공사가 제3항에 따라 회원에게 고지하면서 『기한 내에 변경 약관의 적용에 대해 거절의 의사를 표시하지 않으면 동의한 것으로 간주합니다』라는 사실을 명확히 표시한 경우에 그 기한까지 거절의 의사를 표시하지 않은 회원에게도 적용됩니다.<br />
                ⑤ 공사는 변경 약관의 적용을 거절한 회원에게 변경전 약관을 적용합니다. 다만, 변경전 약관을 적용할 수 없는 정당한 사유 또는 불가피한 사유가 있는 경우에는 그러하지 않습니다.<br />
                ⑥ 제5항 단서의 경우에 회원은 이용계약을 해지할 수 있습니다.<br />


                <div className="size-txt16 layout-mt-20">
                  <strong className="b">
                    제4조(약관의 해석 등)
                  </strong>
                </div>

                ① 공사는 신의성실의 원칙에 따라 공정하게 이 약관을 해석하며, 이용자에 따라 다르게 해석하지 않습니다.<br />
                ② 공사는 이 약관의 뜻이 명백하지 아니한 경우에 이용자에게 유리하게 해석합니다.<br />
                ③ 이 약관에서 정하지 아니한 사항에 대해서는 「콘텐츠산업진흥법」, 「전자상거래 등에서의 소비자보호에 관한법률」, 「약관의 규제에 관한 법률」「개인정보 보호법」 등 관련법 또는 공사와 이용자간 합의에서 정한 바에 따릅니다.<br />


                <div className="size-txt16 layout-mt-20">
                  <strong className="b">
                    제5조(개별 약정의 우선 등)
                  </strong>
                </div>


                ① 공사와 이용자가 약관의 내용과 다르게 합의한 사항이 있을 때에는 그 합의 사항은 이 약관보다 우선하여 적용됩니다.<br />
                ② 제1항에 따른 개별 약정이 「콘텐츠산업진흥법」, 「전자상거래 등에서의 소비자보호에 관한 법률」, 「약관의규제에 관한 법률」 등 관련법령의 강행규정을 위반하여 무효인 경우에 이 약관에서 정한 바에 따릅니다.<br />

                <div className="size-txt16 layout-mt-20">
                  <strong className="b">
                    제6조(신의성실의 원칙)
                  </strong>
                </div>


                공사와 이용자는 상대방의 신의에 좇아 성실하게 이용계약에 따른 권리를 행사하고, 의무를 이행합니다.<br />

                <Box mt={3} />
                <div className="size-txt20">
                  <strong className="b">
                    제2장 콘텐츠랩 이용계약 및 개인정보보호
                  </strong>
                </div>

                <div className="size-txt16 layout-mt-20">
                  <strong className="b">
                    제1절 콘텐츠랩 이용계약
                  </strong>
                </div>

                <div className="size-txt16 layout-mt-20">
                  <strong className="b">
                    제7조(공사의 관리의무)
                  </strong>
                </div>

                공사는 「정보통신망 이용촉진 및 정보보호 등에 관한 법률」, 「개인정보 보호법」 등 관련 법률에 따라 콘텐츠랩의 안정성 유지 및 이용자의 개인정보보호 등을 위한 보안시스템을 구축하고, 운영합니다.<br />

                <div className="size-txt16 layout-mt-20">
                  <strong className="b">
                    제8조(허위정보등의 제공 등 및 이용계약의 성립)
                  </strong>
                </div>

                ① 이용자가 사실과 다른 개인정보나 타인의 정보를 사용한 경우 또는 만14세 미만인 경우, 공사는 콘텐츠랩 이용계약을 승낙하지 않거나 승낙을 유보할 수 있습니다.<br />
                ② 이용자의 본 약관 내용 및 개인정보수집에 대한 동의와 이용 신청에 대하여 공사의 승낙으로 이용계약이 성립되면서 서비스 이용을 위한 식별자가 부여됩니다.<br />


                <div className="size-txt16 layout-mt-20">
                  <strong className="b">
                    제9조(로그인 정보 관리의무 등)
                  </strong>
                </div>

                ① 회원은 로그인 정보를 선량한 관리자의 주의의무로 관리하며, 제3자에게 이용 허락할 수 없습니다.<br />
                ② 제1항 본문을 위반한 경우에 공사는 회원에게 발생한 손해에 대해 책임을 지지 않습니다. 다만, 회원의 손해발생에 대해 공사에게 책임이 있는 경우에 공사는 그 범위내에서 책임을 부담합니다.<br />
                ③ 회원은 로그인 정보가 도용되거나 제3자에 의해 사용되고 있음을 알게 된 경우에 즉시 공사에게 그 사실을 통지하고, 공사의 안내에 따라야 합니다.<br />
                ④ 제3항의 경우, 회원이 공사에게 그 사실을 통지하지 않거나 통지한 경우에도 공사의 안내에 따르지 않아 발생한 회원의 손해에 대해 공사는 책임을 지지 않습니다. 다만, 공사에게 책임있는 경우에는 그 범위내에서 책임을 부담합니다.<br />


                <div className="size-txt16 layout-mt-20">
                  <strong className="b">
                    제10조(회원에 대한 통지)
                  </strong>
                </div>

                ① 개별 회원에 대한 통지를 하는 경우, 공사는 회원이 지정한 전자우편주소로 그 내용을 전송합니다.<br />
                ② 전체 회원에게 통지할 경우, 공사는 회원이 쉽게 알 수 있도록 콘텐츠랩 게시판 등에 7일 이상 그 내용을 게시합니다. 다만, 회원의 콘텐츠랩 이용계약과 관련하여 영향을 미치는 사항에 대하여는 제1항에 따른 통지를 합니다.<br />
                ③ 제1항 및 제2항 단서에도 불구하고, 회원이 지정한 전자우편주소가 없는 경우 또는 회원이 지정한 전자우편주소로 통지할 수 없는 경우에 공사는 제2항 본문에서 정한 방법으로 통지할 수 있습니다. 이 경우, 공사가 회원의 전화번호를 알고 있는 때에는 그 내용을 확인할 수 있는 방법을 전화 또는 문자로 안내합니다.<br />


                <div className="size-txt16 layout-mt-20">
                  <strong className="b">
                    제11조(이용시간 및 일시 이용 중지 등)
                  </strong>
                </div>

                ① 공사는 컴퓨터 등 정보통신설비의 보수점검, 교체 및 고장, 통신두절 또는 운영상 상당한 이유가 있는 경우 콘텐츠랩 이용을 일시적으로 중지할 수 있습니다.<br />
                ② 제1항의 경우, 사업자는 제10조에 따라 회원에게 통지합니다. 다만, 공사가 사전에 통지할 수 없는 부득이한 사유가 있는 경우 사후에 통지할 수 있습니다.<br />
                ③ 공사는 원활한 콘텐츠랩 운영을 위해 필요한 경우에 정기점검을 실시할 수 있으며, 정기점검일로부터 최소 7일 이전에 정기점검시간을 콘텐츠랩 초기화면에 게시합니다. 다만, 공사가 통제할 수 없는 사유로 인한 긴급한 점검사항 발생 시(시스템관리자의 고의, 과실 없는 디스크장애, 시스템다운 등)에는 사전에 통지하지 않을 수 있습니다.<br />


                <div className="size-txt16 layout-mt-20">
                  <strong className="b">
                    제12조(정보의 제공 및 광고정보의 전송 등)
                  </strong>
                </div>

                ① 공사는 이용자가 콘텐츠랩의 이용에 필요한 정보를 콘텐츠랩의 게시판 등에 게시하거나 비상업용 정보에 한해 이용자의 전자우편주소로 전송 등의 방법으로 이용자에게 제공할 수 있습니다. 이 경우에 이용자는 언제든지 수신 거절할 수 있으며, 공사는 수신을 거절한 이용자에게 전화ㆍ전자우편 등으로 정보를 제공하지 않습니다.<br />
                ② 공사는 수신 동의한 이용자에 한해 전자우편 또는 휴대전화 문자 서비스 등을 이용하여 홍보성 정보를 전송할 수 있습니다.<br />
                ③ 제2항의 경우에 이용자는 그 동의를 언제든지 철회할 수 있습니다.<br />
                ④ 공사는 제1항에 따라 수신거절 또는 동의철회를 하고자 하는 이용자가 수신을 거절하거나 동의를 철회할 수 있도록 필요한 조치를 취합니다.<br />

                <div className="size-txt16 layout-mt-20">
                  <strong className="b">
                    제13조(저작권 등의 귀속 등)
                  </strong>
                </div>

                ① 공사가 창작한 저작물에 대한 저작권, 기타 지식재산권(이하 "저작권등"이라 합니다)은 공사가 갖습니다.<br />
                ② 이용자는 공사가 제공하는 서비스를 이용함으로써 얻은 정보에 대해 공사의 사전승낙 없이 복제, 전송, 출판, 배포 등을 하거나 제3자에게 이를 제공하지 않습니다. 다만, 「저작권법」등에 따라 허용되는 경우에는 그러하지 않습니다.<br />
                ③ 이용자는 「저작권법」등에 따라 제3자의 저작물을 저작권법에 위배하여 가공 및 게시할 수 없으며, 이용자가 창작한 저작물에 대한 저작권, 기타 지식재산권은 이용자에게 있습니다.<br />
                ④ 이용자가 창착한 저작물에 한하여, 이용자의 자발적 의사에 따른 콘텐츠 개방 신청 및 공사의 승인을 거쳐 공공으로 개방된 콘텐츠의 경우 제3자가 활용 및 재가공할 수 있으며, 이로 인한 문제 발생 시 공사는 책임을 지지 않습니다.<br />


                <div className="size-txt16 layout-mt-20">
                  <strong className="b">
                    제14조(불법정보 등의 게시금지 및 삭제)
                  </strong>
                </div>

                ① 이용자는 콘텐츠랩의 워크스페이스, 게시판 등을 이용하여 「청소년보호법」제2조제3호에 따른 청소년유해매체물 또는 이에 접속하거나 이용할 수 있는 정보를 보유 및 게시하지 않으며, 다음 각 호의 어느 하나에 해당하는 불법정보를 게시하거나 다른 이용자에게 제공하지 않습니다.<br />
                1. 음란한 부호ㆍ문언ㆍ음향ㆍ화상 또는 영상을 배포ㆍ판매ㆍ임대하거나 공공연하게 전시하는 내용의 정보<br />
                2. 사람을 비방할 목적으로 공공연하게 사실이나 거짓의 사실을 드러내어 타인의 명예를 훼손하는 내용의 정보<br />
                3. 공포심이나 불안감을 유발하는 부호ㆍ문언ㆍ음향ㆍ화상 또는 영상을 반복적으로 상대방에게 도달하도록 하는 내용의 정보<br />
                4. 정당한 사유 없이 정보통신시스템, 데이터 또는 프로그램 등을 훼손ㆍ멸실ㆍ변경ㆍ위조하거나 그 운용을 방해하는 내용의 정보<br />
                5. 「청소년 보호법」에 따른 청소년유해매체물로서 상대방의 연령 확인, 표시의무 등 법령에 따른 의무를 이행하지 아니하고 영리를 목적으로 제공하는 내용의 정보<br />
                6. 법령에 따라 금지되는 사행행위에 해당하는 내용의 정보<br />
                7. 개인정보 보호에 관한 법령을 위반하여 개인정보를 거래하는 내용의 정보<br />
                8. 총포ㆍ화약류(생명ㆍ신체에 위해를 끼칠 수 있는 폭발력을 가진 물건을 포함합니다)를 제조할 수 있는 방법이나 설계도 등의 정보<br />
                9. 법령에 따라 분류된 비밀 등 국가기밀을 누설하는 내용의 정보<br />
                10. 「국가보안법」에서 금지하는 행위를 수행하는 내용의 정보<br />
                11. 법률에서 금지하는 재화 또는 서비스에 대한 광고성 정보<br />
                12. 그 밖에 범죄를 목적으로 하거나 교사(敎唆) 또는 방조하는 내용의 정보<br />
                ② 이용자는 영리목적의 광고성 정보를 콘텐츠랩에 게시하려면 공사의 사전 동의를 받아야 합니다. 다만, 별도의 권한 없이 누구든지 쉽게 접근하여 글을 게시할 수 있는 공간의 경우에는 사전 동의를 받지 않고 게시할 수 있습니다.<br />
                ③ 제2항에도 불구하고, 공사가 명시적으로 게시 거부의사를 표시하거나 사전 동의를 철회한 경우에 이용자는 영리목적의 광고성 정보를 게시하지 않습니다.<br />
                ④ 제1항부터 제4항까지를 위반한 정보에 대해 공사는 그 정보를 게시한 자에게 통지하지 않고, 그 정보를 삭제할 수 있습니다. 이 경우에 공사는 그 정보의 삭제에 대해 책임을 지지 않습니다.<br />


                <div className="size-txt16 layout-mt-20">
                  <strong className="b">
                    제15조(임의의 임시조치)
                  </strong>
                </div>

                ① 공사는 콘텐츠랩의 워크스페이스, 게시판 등을 통해 게시된 정보가 사생활 침해 또는 명예훼손 등 타인의 권리를 침해한다고 인정되면 임의로 해당 정보에 대한 접근을 임시적으로 차단하는 조치(이하 "임시조치"라 합니다)를 취할 수 있습니다.<br />
                ② 제1항에 따른 임시조치를 취한 공사는 임시조치의 사실을 그 정보가 게시된 게시판 등에 공시하는 등의 방법으로 이용자가 알 수 있게 합니다.<br />
                ③ 제1항에 따른 임시조치의 기간은 30일 이내로 합니다.<br />


                <div className="size-txt16 layout-mt-20">
                  <strong className="b">
                    제16조(정보의 삭제요청 등)
                  </strong>
                </div>

                ① 콘텐츠랩을 통하여 일반에게 공개를 목적으로 제공된 정보로 사생활 침해나 명예훼손 등 타인의 권리가 침해된 경우, 그 침해를 받은 자는 해당 정보를 처리한 공사에게 침해사실을 소명하여 그 정보의 삭제 또는 반박내용의 게재(이하 "삭제 등"이라 합니다)를 요청할 수 있습니다.<br />
                ② 공사는 제1항에 따른 해당 정보의 삭제 등을 요청받으면 지체 없이 삭제ㆍ임시조치 등의 필요한 조치를 하고, 즉시 신청인 및 정보게재자에게 알립니다. 이 경우, 공사는 필요한 조치를 한 사실을 해당 게시판에 공시하는 등의 방법으로 이용자가 알 수 있도록 합니다.<br />
                ③ 공사는 콘텐츠랩에 「정보통신망 이용촉진 및 정보보호 등에 관한 법률」 제42조에 따른 표시방법을 지키지 아니하는 청소년유해매체물이 게시되어 있거나 같은 법 제42조의2에 따른 청소년 접근을 제한하는 조치없이 청소년유해매체물을 광고하는 내용이 전시되어 있는 경우에는 지체없이 그 내용을 삭제합니다.<br />
                ④ 제1항에 따른 정보의 삭제요청에도 불구하고, 공사는 권리의 침해 여부를 판단하기 어렵거나 이해당사자 간에 다툼이 예상되는 경우에 제17조에 따른 임시조치를 취할 수 있습니다.<br />

                <div className="size-txt16 layout-mt-20">
                  <strong className="b">
                    제17조(이용계약의 해지 및 자격상실, 서비스 이용제한 등)
                  </strong>
                </div>

                ① 회원은 해지(탈퇴)를 희망할 경우 해지(탈퇴)를 요청할 수 있으며, 공사는 관련법 등이 정하는 바에 따라 이를 처리함으로써 이용계약의 해지(탈퇴)가 성립합니다.<br />
                ② 회원의 자발적 의사에 따라 이용계약의 해지(탈퇴)가 성립된 경우, 공사는 관련법 및 개인정보취급방침에 따라 회원의 개인정보 및 인증정보를 삭제합니다. 다만, 공사는 회원이 콘텐츠랩 이용기간 중 공사와의 협의 및 승인을 통해 게시한 정보(디지털콘텐츠)를 회원의 탈퇴 후에도 보유할 수 있으며, 공사와의 협의 및 승인을 통해 게시된 정보는 회원의 탈퇴 후에도 삭제되지 않습니다.<br />
                ③ 단체 소속회원의 자발적 의사에 따라 소속회원 탈퇴를 희망할 경우 단체 대표회원에게 탈퇴를 요청할 수 있으며, 단체 대표회원의 승인으로 소속회원 탈퇴가 성립됩니다. 단, 이 경우 개인회원으로 전환이 불가하며, 개인회원으로 이용계약을 희망할 경우 재가입 절차를 진행해야 합니다.<br />
                ④ 단체 대표회원의 자발적 의사에 따라 개설 단체 폐쇄 시, 공사는 관련법 및 개인정보취급방침에 따라 단체 대표회원 및 소속회원의 개인정보 및 인증정보를 삭제합니다. 다만, 공사는 단체 대표회원 및 소속회원이 콘텐츠랩 이용기간 중 공사와의 협의 및 승인을 통해 게시한 정보(디지털콘텐츠)를 개설 단체 폐쇄 후에도 보유할 수 있으며, 공사와의 협의 및 승인을 통해 게시된 정보는 개설 단체의 폐쇄 후에도 삭제되지 않습니다.<br />
                ⑤ 회원이 다음 각 호의 사유에 해당하는 경우, 공사는 회원자격을 제한 및 정지시킬 수 있습니다. 공사는 회원자격 및 서비스 이용을 제한‧정지 시킨 후, 그 사유가 시정되지 아니하는 경우 회원자격을 상실시켜 해지(탈퇴) 처리 할 수 있습니다.<br />
                1. 회원가입 신청 시에 허위 내용을 등록한 경우<br />
                2. 다른 사람의 명의를 사용하여 신청하였을 때<br />
                3. 다른 사람의 서비스 이용을 방해하거나 타인의 명예를 훼손하거나 그 정보를 도용하는 등 전자 거래 및 정보통신서비스 이용질서를 위협하는 경우<br />
                4. 법령과 본 약관이 금지하거나 사회의 안녕 질서 혹은 미풍양속에 저해하는 행위를 하는 경우<br />
                5. 이용자의 귀책사유로 인하여 승인이 불가능하거나 기타 규정한 제반 사항을 위반하며 신청하는 경우<br />
                ⑥ 공사는 제2항에 따라 회원자격의 제한 또는 정지, 자격상실 등의 조치를 취한 경우에 제10조에 따라 그 회원에게 그 사실 및 사유를 지체없이 통지하고, 다음 각 호에 따른 기간 동안 소명기회를 부여합니다.<br />
                1. 회원자격의 제한 또는 정지 등 : 1주일 이상<br />
                2. 자격상실 : 30일 이상<br />
                ⑦ 만 14세미만의 아동은 회원으로 가입할 수 없습니다.<br />


                <div className="size-txt16 layout-mt-20">
                  <strong className="b">
                    제18조(회원가입 성립 유보)
                  </strong>
                </div>

                공사는 다음 각 항에 해당하는 경우 그 사유가 해소될 때까지 회원가입 성립을 유보할 수 있습니다.<br />
                ① 기술상의 장애사유로 인한 서비스 중단의 경우(공사의 고의·과실 없는 통신장애, 디스크장애, 시스템 다운 등)<br />
                ② 전기통신사업법에 의한 기간통신공사가 전기통신 서비스를 중지하는 경우<br />
                ③ 전시, 사변, 천재지변 또는 이에 준하는 국가 비상사태가 발생하거나 발생할 우려가 있는 경우<br />
                ④ 긴급한 시스템 점검, 증설 및 교체설비의 보수 등을 위하여 부득이한 경우<br />
                ⑤ 서비스 설비의 장애 또는 서비스 이용의 폭주 등 기타 서비스를 제공할 수 없는 사유가 발생한 경우<br />


                <div className="size-txt16 layout-mt-20">
                  <strong className="b">
                    제19조(회원정보의 변경)
                  </strong>
                </div>

                회원은 회원정보(간편로그인 종류, 휴대전화번호, 전자우편주소, 소속 등)의 수정을 원할 경우 콘텐츠랩의 회원정보수정 메뉴를 통해 수정해야 하며, 변경하지 않은 정보로 인해 발생되는 문제의 책임은 회원에게 있습니다.<br />


                <div className="size-txt16 layout-mt-20">
                  <strong className="b">
                    제2절 개인정보보호
                  </strong>
                </div>

                <div className="size-txt16 layout-mt-20">
                  <strong className="b">
                    제20조(개인정보의 보호 및 사용)
                  </strong>
                </div>

                ① 공사는 개인정보의 수집ㆍ이용, 제공 등 개인정보의 처리 및 보호와 관련하여 「개인정보보호법」을 준수합니다.<br />
                ② 개인정보의 열람, 정정, 처리정지 등 이용자의 권리는 「개인정보 보호법」에 따라 보호받습니다.<br />

                <div className="size-txt16 layout-mt-20">
                  <strong className="b">
                    제21조(개인정보의 수집 및 사용)
                  </strong>
                </div>

                ① 개인정보는 다음과 같이 수집, 사용, 관리, 보호됩니다.<br />
                1. 개인정보의 수집 : 공사는 회원가입 시 회원이 제공하는 정보를 통하여 회원의 정보를 수집합니다.(이름, 휴대전화번호, 전자우편주소 등) 또한, 이용자의 동의 없이 주민등록번호, 외국인등록번호 등 고유식별정보를 수집·보관하지 않습니다.<br />
                2. 개인정보의 사용 : 국가기관의 요구 또는 기타 관계법령에서 정한 절차에 따른 요청이 있는 경우를 제외하고 서비스 제공과 관련하여 수집된 회원의 정보를 본인의 승낙 없이 제3자에게 누설, 배포하지 않습니다.<br />
                3. 개인정보의 관리 : 회원은 개인의 정보 보호 및 관리를 위하여 수시로 회원의 개인정보를 수정/삭제할 수 있습니다. 수신되는 정보 중 불필요하다고 생각되는 부분도 변경/조정할 수 있습니다.<br />
                4. 회원정보의 보호 : 회원의 개인정보는 오직 회원만이 열람/수정/삭제 할 수 있으며, 이는 전적으로 회원의 간편로그인 인증 및 전자우편주소를 이용한 개인인증을 통해 관리되고 있습니다. 따라서 타인에게 인증 정보를 알려주어서는 안되며, 서비스 이용 종료 시에는 반드시 로그아웃 하고, 웹 브라우저의 창을 닫아야 합니다.(이는 타인과 컴퓨터를 공유하는 인터넷 카페나 도서관 같은 공공장소에서 컴퓨터를 사용하는 경우에 회원의 정보보호를 위하여 필요한 사항입니다.)<br />
                ② 공사는 회원정보의 진위여부 및 소속기관의 확인 등을 위하여 확인절차를 거칠 수도 있습니다.<br />


                <div className="size-txt16 layout-mt-20">
                  <strong className="b">
                    제22조(손해배상책임)
                  </strong>
                </div>

                ① 이용자는 공사가 「개인정보 보호법」을 위반한 행위로 손해를 입으면 공사에게 손해배상을 청구할 수 있습니다. 이 경우, 공사는 고의 또는 과실이 없음을 증명한 경우에는 그러하지 않습니다.<br />
                ② 공사의 고의 또는 중대한 과실로 인하여 개인정보가 분실ㆍ도난ㆍ유출ㆍ위조ㆍ변조 또는 훼손된 경우로서 이용자에게 손해가 발생한 때에는 이용자는 그 손해액의 3배를 넘지 아니하는 범위에서 손해배상을 청구할 수 있습니다. 다만, 공사가 고의 또는 중대한 과실이 없음을 증명한 경우에는 그러하지 않습니다.<br />


                <div className="size-txt16 layout-mt-20">
                  <strong className="b">
                    제23조(법정손해배상의 청구)
                  </strong>
                </div>

                제22조제1항에도 불구하고, 이용자는 공사의 고의 또는 과실로 인하여 개인정보가분실ㆍ도난ㆍ유출ㆍ위조ㆍ변조 또는 훼손된 경우에는 300만원 이하의 범위에서 상당한 금액을 손해액으로 하여 배상을 청구할 수 있습니다. 다만, 공사가 고의 또는 과실 없음을 증명한 경우에는 그러하지 않습니다.<br />


                <Box mt={3} />
                <div className="size-txt20">
                  <strong className="b">
                    제3장 서비스의 이용 및 이용제한
                  </strong>
                </div>


                <div className="size-txt16 layout-mt-20">
                  <strong className="b">
                    제24조(콘텐츠랩의 이용시간 및 일시 이용 중지 등)
                  </strong>
                </div>

                ① 공사는 이용자가 연중 및 24시간 콘텐츠랩을 이용할 수 있도록 콘텐츠랩을 운영합니다.<br />
                ② 공사는 서비스별로 이용 가능한 시간을 별도로 정할 수 있으며 이 경우 그 내용을 사전에 공지합니다.<br />
                ③ 공사는 컴퓨터 등 정보통신설비의 보수점검, 교체 및 고장, 통신두절 또는 운영상 상당한 이유가 있는 경우 콘텐츠랩 이용을 일시적으로 중지할 수 있습니다.<br />
                ④ 제2항의 경우, 공사는 제11조에 따라 회원에게 통지합니다. 다만, 공사가 사전에 통지할 수 없는 부득이한 사유가 있는 경우 사후에 통지할 수 있습니다.<br />
                ⑤ 공사는 콘텐츠랩 이용이 일시적으로 중단됨으로 인하여 이용자에게 발생한 손해에 대해 배상합니다. 다만, 공사가 고의 또는 과실이 없음을 증명한 경우에는 그러하지 않습니다.<br />
                ⑥ 공사는 원활한 콘텐츠랩 운영을 위해 필요한 경우에 정기점검을 실시할 수 있으며, 정기점검일로부터 최소 1일 이전에 정기점검시간을 콘텐츠랩 초기화면에 게시합니다.<br />
                ⑦ 사업종목의 전환ㆍ폐업ㆍ파산 등의 이유로 공사가 콘텐츠랩을 운영할 수 없는 경우에 공사는 제11조에 따라 이용자에게 통지합니다.<br />

                <div className="size-txt16 layout-mt-20">
                  <strong className="b">
                    제25조(서비스의 변경 및 중지, 중지에 대한 공지)
                  </strong>
                </div>

                ① 공사는 상당한 이유가 있는 경우에 업무상, 운영상, 기술상의 필요에 따라 제공하고 있는 전부 또는 일부 서비스를 변경 및 중단 할 수 있으며, 이 경우 그 내용을 사전에 공지합니다.<br />
                ② 이용자가 콘텐츠랩 서비스에 보관/전송한 메시지 내용이 국가의 비상사태, 공사의 관리 범위 외의 서비스 설비 장애 및 기타 불가항력에 의하여 보관/삭제/전송/손실이 있을 경우에 공사는 관련 책임을 부담하지 아니합니다.<br />
                ③ 천재지변, 국가비상사태 등 공사가 정상적인 서비스 제공이 불가능할 경우 일시적으로 서비스를 제한, 중지시킬 수 있으며 사전 또는 사후 이용자에게 중지사유 및 기간을 공지합니다.<br />
                ④ 공사의 사정으로 서비스를 영구적으로 중단하여야 할 경우에는 사전 공지기간은 30일로 합니다.<br />
                ⑤ 공사는 긴급한 시스템 점검, 증설 및 교체 등 부득이한 사유로 인하여 예고 없이 일시적으로 서비스를 중단할 수 있으며, 새로운 서비스로의 교체 등 공사가 적절하다고 판단하는 사유에 의하여 현재 제공되는 서비스를 완전히 중단할 수 있습니다.<br />
                ⑥ 공사는 서비스를 특정범위로 분할하여 각 범위별로 이용가능시간을 별도로 지정할 수 있습니다. 다만 이 경우 그 내용을 공지합니다.<br />
                ⑦ 공사가 통제할 수 없는 사유로 인한 서비스 중단의 경우(시스템관리자의 고의, 과실 없는 디스크장애, 시스템다운 등)에 사전통지가 불가능하며, 타인(PC통신회사, 기간통신공사 등)의 고의, 과실로 인한 시스템중단 등의 경우에는 통지하지 않습니다.<br />
                ⑧ 공사는 회원이 본 약관의 내용에 위배되는 행동을 한 경우, 임의로 서비스 사용을 제한 및 중지하거나 회원의 동의 없이 이용계약을 해지할 수 있습니다. 이 경우 공사는 위 이용자의 접속을 금지할 수 있습니다.<br />

                <div className="size-txt16 layout-mt-20">
                  <strong className="b">
                    제26조(패밀리사이트 서비스 이용)
                  </strong>
                </div>

                ① 이용자는 회원가입과 동시에 패밀리사이트의 서비스 이용약관에도 동의한 것으로 보며, 회원가입시 이용한 간편로그인 인증으로 패밀리사이트도 로그인하여 이용이 가능합니다. 회원이 이용할 수 있는 패밀리사이트는 아래와 같습니다.<br />
                - 한국관광데이터랩<br />
                - 한국관광산업포털(투어라즈)<br />
                - 대한민국구석구석<br />
                - 고캠핑<br />
                - 한국관광 사진갤러리<br />
                - 열린관광지<br />
                - 푸드트립<br />
                - 관광e배움터<br />
                - Tour API<br />
                - 두루누비<br />
                - 기타 단일 로그인 서비스(SSO)를 사용하는 공사 보유 사이트<br />
                ② 공사는 이용자가 콘텐츠랩에서 회원가입 시 등록한 회원의 정보를 패밀리사이트와 공유하여 회원에게 맞춤형 서비스를 제공할 수 있습니다.<br />
                ③ 패밀리사이트는 특정 서비스를 제공하기 위해 회원에게 별도 또는 추가적인 정보를 요청할 수 있으며, 이 경우 해당 사이트의 이용약관이 본 약관보다 우선 적용됩니다.<br />


                <Box mt={3} />
                <div className="size-txt20">
                  <strong className="b">
                    제4장 의무 및 책임
                  </strong>
                </div>


                <div className="size-txt16 layout-mt-20">
                  <strong className="b">
                    제27조(공사의 의무)
                  </strong>
                </div>


                ① 공사는 관련법과 본 약관에서 금지하거나 미풍양속에 반하는 행위를 하지 않으며, 계속적이고 안정적으로 서비스를 제공하기 위하여 최선을 다하여 노력하며, 설비에 장애가 생기거나 멸실된 때에는 부득이한 사유가 없는 한 지체 없이 이를 수리 또는 복구합니다.<br />
                ② 이용자의 개인정보를 본인의 승낙 없이 제3자에게 누설, 배포하지 않습니다. 다만, 관계법령에 의해 국가기관의 요구 등 법률의 규정에 따른 적법한 절차에 의한 경우에는 그러하지 아니합니다.<br />
                ③ 공사는 서비스와 관련한 이용자의 의견이나 불만이 정당하다고 객관적으로 인정될 경우에는 적절한 절차를 거쳐 즉시 처리하여야 하며, 즉시 처리가 곤란한 경우 그 사유와 처리 일정을 해당 이용자에게 통지하여야 합니다.<br />
                ④ 공사는 서비스의 제공과 관련하여 알게 된 이용자의 개인정보를 본인의 승낙 없이 제3자에게 누설, 배포하지 않고, 이를 보호하기 위하여 노력합니다. 회원의 개인정보보호 및 사용에 대해서는 관련법 및 개인정보취급방침에 따릅니다.<br /><br />
                ⑤ 공사는 이용자의 귀책사유로 인한 서비스 이용 장애에 대하여 책임을 지지 않습니다.<br />

                <div className="size-txt16 layout-mt-20">
                  <strong className="b">
                    제28조(이용자의 의무)
                  </strong>
                </div>


                ① 회원가입 신청 및 회원정보 변경 시 요구되는 정보는 사실에 근거하여 기입하여야 합니다. 만약 허위 또는 타인의 정보를 등록할 경우 일체의 권리를 주장할 수 없습니다.<br />
                ② 이용자는 공사의 서비스를 이용하여 얻은 정보를 공사의 승인 또는 사전승낙 없이 복사, 복제, 변경, 번역, 출판, 방송 기타의 방법으로 사용하거나 이를 타인에게 제공할 수 없습니다.<br />
                ③ 이용자는 공사의 서비스 이용과 관련하여 다음 각 호의 행위를 하여서는 안됩니다<br />
                1. 회원가입 및 서비스 신청 또는 변경 시 허위내용을 기재하거나, 타인의 정보를 도용하여 부정 사용하는 행위,<br />
                2. 공사 운영진, 직원 또는 관계자를 사칭하는 행위<br />
                3. 공사가 게시한 정보를 변경하는 행위<br />
                4. 공사 서비스를 통하여 전송된 내용의 출처를 위장하는 행위<br />
                5. 공사로부터 권리를 부여 받지 않고, 공사 서비스의 프로그램을 변경하거나, 서버 해킹 및 컴퓨터바이러스 유포, 웹사이트 또는 게시된 정보의 일부분 또는 전체를 임의로 변경하는 행위<br />
                6. 공사 및 기타 제3자의 명예를 손상시키거나 업무를 방해하는 행위<br />
                7. 다른 사용자의 개인정보를 수집, 저장, 공개하는 행위<br />
                8. 범죄행위를 목적으로 하거나 기타 범죄행위와 관련된 행위<br />
                9. 공사와 기타 제3자의 저작권 등 지적재산권을 침해는 행위 및 침해할 수 있는 내용을 게시, 게재, 전자우편 또는 기타의 방법으로 전송하는 행위<br />
                10. 저속‧음란‧폭력‧모욕적‧위협적이거나, 타인의 프라이버스를 침해하거나, 기타 미풍양속에 반하는 등의 내용이 포함된 내용을 전송, 게시, 게재, 전자우편 또는 기타의 방법으로 전송하는 행위<br />
                11. 법률, 계약에 의해 이용할 수 없는 내용을 게시, 게재, 전자우편 또는 기타의 방법으로 전송하는 행위<br />
                12. 공사의 승인을 받지 아니한 광고, 판촉물, 스팸메일, 기타 다른 형태의 권유를 게시, 게재, 전자우편 또는 기타의 방법으로 전송하는 행위, 타인의 의사에 반하여 광고성 정보 등 일정한 내용을 지속적으로 전송하는 행위<br />
                13. 서비스의 안정적인 운영에 지장을 주거나 줄 우려가 있는 일체의 행위 및 본 약관을 포함하여 기타 공사가 정한 제반 규정 또는 이용 조건을 위반하는 행위, 기타 불법적이거나 부당한 행위<br />

                <Box mt={3} />
                <div className="size-txt20">
                  <strong className="b">
                    제5장 기타
                  </strong>
                </div>


                <div className="size-txt16 layout-mt-20">
                  <strong className="b">
                    제29조(양도 금지)
                  </strong>
                </div>

                회원은 서비스의 이용권한, 기타 이용 계약상 지위를 타인에게 양도, 증여할 수 없으며, 이를 담보로 제공할 수 없습니다.<br />


                <div className="size-txt16 layout-mt-20">
                  <strong className="b">
                    제30조(책임제한)
                  </strong>
                </div>

                ① 공사는 천재지변 또는 이에 준하는 불가항력으로 인하여 서비스를 제공할 수 없는 경우에는 서비스 제공에 관한 책임을 지지 않습니다.<br />
                ② 공사는 이용자의 귀책사유로 인한 서비스 이용의 장애에 대하여는 책임을 지지 않습니다.<br />
                ③ 공사는 이용자가 서비스와 관련하여 게재한 정보, 자료, 사실의 신뢰도, 정확성 등의 내용에 관하여는 책임을 지지 않습니다.<br />
                ④ 이용자가 제공하는 정보의 내용이 허위인 것으로 판명되거나, 허위가 있다고 의심할 만한 합리적인 사유가 발생할 경우 공사는 이용자의 본 서비스 사용을 일부 또는 전부 중지할 수 있으며, 이로 인해 발생하는 불이익에 대해 공사는 책임을 부담하지 아니합니다.<br />
                ⑤ 공사는 회원 간 또는 회원과 제3자 상호간에 서비스를 매개로 하여 거래 등을 한 경우에는 책임을 지지 않습니다.<br />


                <div className="size-txt16 layout-mt-20">
                  <strong className="b">
                    제31조(분쟁의 해결)
                  </strong>
                </div>

                본 약관은 대한민국법령에 의하여 규정되고 이행되며, 서비스 이용과 관련하여 공사와 회원간에 발생한 분쟁에 대해서는 민사소송법상의 주소지를 관할하는 법원을 관할로 합니다.<br />


                <div className="size-txt16 layout-mt-20">
                  <strong className="b">
                    제32조(규정의 준용)
                  </strong>
                </div>

                본 약관에 명시되지 않은 사항에 대해서는 관련법령에 의하고, 법에 명시되지 않은 부분에 대하여는 사회상규에 의합니다.<br />

                <br />
                ■ 부칙<br />
                본 약관은 2024년 1월 2일부터 적용됩니다.<br />

              </div>
            </div>
          </div>
        </div>
      </div>
      </>
    );
  }
}

export default TermsOfUse;