export enum LangType {
    ALL = "ALL",
    ko = "ko", // 한국어
    en = "en", // 영어
    ja = "ja", // 일본어
    zh_CN = "zh_CN", // 중어 간체
    zh_TW = "zh_TW", // 중어 번체
    de = "de", // 독알어
    ru = "ru", // 러시아어
    fr = "fr", // 프랑스어
    es = "es", // 스페인어
    id = "id", // 인디어
    ar = "ar" // 아랍어
}