import { Height } from "@mui/icons-material";
import {
    Page,
    Text,
    View,
    Font,
    Document,
    StyleSheet,
    Image,
    Link,
    pdf
} from "@react-pdf/renderer";

const coverImage = '/rms-conlab/static/media/conlabCoverPage.png';
const lineImage = '/rms-conlab/static/media/line.png';

const pretendardVariableBold = '/rms-conlab/font/Pretendard-Bold.ttf';
const NotoSans = '/rms-conlab/font/NotoSansKR-Bold.ttf';

const detailUrl = 'conlab.visitkorea.or.kr/rms-conlab/detail';



type Props = {
    category: any;
    marketingContentsDetails: any[];
};

// font 설정
Font.register({
    family: "PretendardBold",
    src: pretendardVariableBold,
    fontWeight: 900,
});

Font.register({
    family: "NotoSans",
    src: NotoSans,
    fontWeight: 900,
});

const formatDate = (time: number) => {
    let date = '';
    if (time) {
        const currentDate = new Date(time);
        const year = currentDate.getFullYear();
        const month = String(currentDate.getMonth() + 1).padStart(2, '0');
        const day = String(currentDate.getDate()).padStart(2, '0');
        date = `${year}년 ${month}월 ${day}일`;
    } else {
        date = '';
    }
    return date;
}

// 제목 줄 바꿈처리
const splitStringByWidth = (str: string, maxLineWidth: number) => {
    const words = str.split(' ');
    const lines = [];
    let currentLine = '';

    for (const word of words) {
        if ((currentLine + word).length <= maxLineWidth) {
            currentLine += word + ' ';
        } else {
            lines.push(currentLine.trim());
            currentLine = word + ' ';
        }
    }
    if (currentLine) {
        lines.push(currentLine.trim());
    }

    return lines;
};

const getType = (type: string) => {
    let contentType = '';
    switch (type) {
        case 'FormalContents':
            contentType = '관광정보';
            break;
        case 'CasualContents':
            contentType = '기사';
            break;
        case 'VideoContents':
            contentType = '동영상';
            break;
        case 'ImageContents':
            contentType = '이미지';
            break;
        default:
            contentType = '';
            break;
    }
    return contentType;
}

// 중어 및 일어는 NotoSans 폰트 사용
const isCJ = (char: string) => {
    return /[\u4E00-\u9FFF\u3400-\u4DBF\u20000-\u2A6DF\u2A700-\u2B73F\u3040-\u309F\u30A0-\u30FF]/.test(char) && 
    !/[0-9\[\]{}()!"#$%&'()*+,\-./:;<=>?@[\\\]^_`{|}~]/.test(char);
};

// 빈값 비노출 처리
const validationCheck = (value: string) => {
    return value !== null && value !== undefined && value !== '' && value.trim() !== '';
}

const contentText = (value: string) => {
    return (
        <Text><Text style={styles.contentText}>●</Text>{' ' + value}</Text>
    );
}

export const themePdf = async (category: any, marketingContentsDetails: any[], setPdfLoading: (pdfloading: boolean) => void ) => {
    const blob = await pdf(<DocumentPdf category={category} marketingContentsDetails={marketingContentsDetails} />).toBlob();

    // Blob을 다운로드하기 위해 URL 생성
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = `${category.name}.pdf`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    setPdfLoading(false);
}

export const DocumentPdf = ({ category, marketingContentsDetails }: Props) => {
    // 페이지당 3개의 객체로 나누기
    const contents = [];
    for (let i = 0; i < marketingContentsDetails.length; i += 3) {
        contents.push(marketingContentsDetails.slice(i, i + 3));
    }

    console.log('category: ', category);
    console.log('marketingContentsDetails: ', marketingContentsDetails);
    
    // 한글 정규화
    const normalizedString = category.name.normalize('NFC'); // NFC 또는 NFD 사용 가능
    const titleName = splitStringByWidth(normalizedString, 38);
    
    return (
        <Document style={styles.pretendardBold}>
            {/*표지*/}
            <Page size="A4">
                <View style={{ height: '100%', width: '100%' }}>
                    <View><Image src={coverImage} style={styles.coverImage}/></View>
                    {
                        titleName.map((title, index) => {
                            return(
                                <Text
                                    key={index}
                                    style={[styles.coverText, styles.title, { top: 220 + (index*20) } ]}
                                >
                                    {title.replace(/\s+$/, '').split('').map((char, index) => {
                                        // 중국어나 일본어면 NotoSans 폰트 사용
                                        return(
                                            <Text
                                                key={index}
                                                style={[isCJ(char) ? styles.NotoSans : styles.pretendardBold]}
                                            >
                                                {char}
                                            </Text>
                                        );
                                    })}
                                </Text>
                            );
                        })
                    }
                    <View style={[styles.coverText, styles.mainImage]}>
                        <Image
                            src={{ 
                                uri: category.bannerImgPath,
                                method: 'GET',
                                headers: { 
                                    'Cache-Control': 'no-cache',
                                }, 
                                body: ''
                            }} 
                        />
                    </View>
                    <View style={[styles.coverText, styles.modificationTime]}><Text>{formatDate(category.modificationTime)}</Text></View>
                    <View style={[styles.coverText, styles.department]}><Text>{category.cineroomName}</Text></View>
                    <Link src={`https://${detailUrl}?categoryId=${category.id}&type=ThemeContents`} style={[styles.coverText, styles.urlText, { top: 644 }]}>
                        <Text>{`URL: ${detailUrl}`}</Text>
                        <Text>{`?categoryId=${category.id}&type=ThemeContents`}</Text>
                    </Link>
                </View>
            </Page>
            {
                contents.map((content, index) => {
                    return (
                        <Page size="A4" key={index} fixed>
                            <View style={{ height: '100%', width: '100%' }} fixed>
                                <View style={{ height: '10%', width: '100%' }}><Image src={lineImage}></Image></View>
                                <View style={{ height: '90%', width: '100%' }}>
                                    {
                                        content.map((myContent, index) => {
                                            const {
                                                baseCategoryName, category2, category3, imagePathList, contentsName, cid,
                                                telephone, address, copyright, modificationTime, url, type
                                            } = myContent;

                                            // category format
                                            let categoryName = '';
                                            if (baseCategoryName && baseCategoryName !== '' && category2 && category2 !== '' && category3 && category3 !== '') {
                                                categoryName = `${baseCategoryName} > ${category2} > ${category3}`;
                                            } else if (baseCategoryName && baseCategoryName !== '' && category2 && category2 !== '') {
                                                categoryName = `${baseCategoryName} > ${category2}`;
                                            } else if (baseCategoryName && baseCategoryName !== '') {
                                                categoryName = baseCategoryName;
                                            }

                                            // hompage에 a태그가 존재할 경우 href 안의 내용만 출력
                                            let homepage: string = myContent.homepage;
                                            try {
                                                if (homepage.includes('href')) {
                                                    const hompegUrl = homepage.split('href');
                                                    if (hompegUrl.length > 1 && hompegUrl[1].includes('"')) {
                                                        const url = hompegUrl[1].split('"');
                                                        homepage = url.length > 1 ? url[1] : url[0] || '';
                                                    }
                                                }
                                            } catch(e) {
                                                console.log('parse error');
                                            }


                                            return (
                                                <>
                                                    <View key={index} style={styles.contentContainer}>
                                                        {
                                                            imagePathList.length > 0 && imagePathList[0].imageDepotUrl && imagePathList[0].imageDepotUrl !== '' ? (
                                                                <View style={styles.contentImage}>
                                                                    <Image src=
                                                                            {{ 
                                                                                uri: imagePathList[0].imageDepotUrl,
                                                                                method: 'GET',
                                                                                headers: { 
                                                                                    'Cache-Control': 'no-cache',
                                                                                }, 
                                                                                body: '' 
                                                                            }}
                                                                            style={{ width: '100%', height: '100%' }}
                                                                        />
                                                                </View>
                                                            ) : <View style={styles.contentImage}><Image src={'/rms-conlab/images/alternative.png'} style={{ width: '100%', height: '100%' }} /></View>
                                                        }
                                                        <View style={styles.textContainer}>
                                                            <Text>
                                                                {contentsName.replace(/\s+$/, '').split('').map((char: string, index: string) => {
                                                                    // 중어 및 일어는 NotoSans 폰트 사용
                                                                    return(
                                                                        <Text
                                                                            key={index}
                                                                            style={[isCJ(char) ? styles.NotoSans : styles.pretendardBold]}
                                                                        >
                                                                            {char}
                                                                        </Text>
                                                                    );
                                                                })}
                                                            </Text>
                                                            {validationCheck(categoryName) && <Text>{`[${categoryName}]`}</Text>}
                                                            <View style={{ marginTop: 8 }}></View>
                                                            {validationCheck(address) && contentText(`주소: ${address}`)}
                                                            {validationCheck(telephone) && contentText(`전화번호: ${telephone}`)}
                                                            {validationCheck(homepage) && contentText(`홈페이지: ${homepage}`)}
                                                            {validationCheck(copyright) && contentText(`정보제공: ${copyright}`)}
                                                            {validationCheck(formatDate(modificationTime)) && contentText(`갱신일: ${formatDate(modificationTime)}`)}
                                                            <Link src={`https://${detailUrl}?cid=${cid}&type=${type}`} style={styles.urlText}>
                                                                <Text><Text style={styles.contentText}>●</Text>{` 상세 URL: ${detailUrl}`}</Text>
                                                                <Text>{`?cid=${cid}&type=${type}`}</Text>
                                                            </Link>
                                                        </View>
                                                    </View>
                                                    {
                                                        myContent === content[1] && content.length === 2 && <View style={styles.contentContainer}><View style={styles.textContainer}></View></View>
                                                    }
                                                </>
                                            );
                                        })
                                    }
                                </View>
                            </View>
                            <View style={styles.pageNum} fixed><Text>{`${index+1}/${contents.length}`}</Text></View>
                        </Page>
                    );
                })
            }
        </Document>
    );
}


// style 설정
const styles = StyleSheet.create({
    page: {
        width: '210mm', height: '297mm'
    },
    pretendardBold: {
        fontFamily: "PretendardBold"
    },
    NotoSans: {
        fontFamily: "NotoSans"
    },
    coverImage: {
        width: '100%',
        height: '100%',
    },
    mainImage: {
        width: '400px',
        height: '200px',
        position: 'absolute',
        top: 340,
        left: 50,
    },
    coverText: {
        position: 'absolute',
        left: 50,
        fontSize: 10,
    },
    title: {
        fontSize: '16px',
    },
    modificationTime: {
        top: 620,
    },
    department: {
        top: 632,
    },
    contentImage: {
        flex: 0.8,
        width: '200px',
        height: '200px',
        marginLeft: '50px'
    },
    textContainer: {
        flex: 1.2,
        marginLeft: '24px',
        fontSize: 10
    },
    contentContainer: {
        display: 'flex',
        flexDirection: 'row',
        marginTop: '10px',
        width: '100%',
        height: '100%',
    },
    contentText: {
        fontSize: 6,
        paddingTop: '4px',
    },
    pageNum: {
        position: 'absolute',
        right: 25,
        bottom: 20,
        fontSize: 12,
        textAlign: 'right'
    },
    urlText: {
        textDecoration: 'none',
        color: 'black'
    }
});