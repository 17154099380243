
import { runInAction } from 'mobx';
import { v4 as uuidv4 } from 'uuid';
import { Constructor } from '../../../type/Constructor';


class ServiceModel {
  //
  serviceName: string = '';
  instanceName: string = '';


  constructor(serviceName: string, instanceName: string) {
    //
    this.serviceName = serviceName;
    this.instanceName = instanceName;
  }

  static newServices(ServiceTypes: Constructor[]): ServiceModel[] {
    //
    return ServiceTypes.map(ServiceType => ServiceModel.newService(ServiceType));
  }

  static newService(ServiceType: Constructor): ServiceModel {
    //
    const serviceName = this.getServiceName(ServiceType);
    const instanceName = `${serviceName}-${uuidv4()}`;

    return new ServiceModel(serviceName, instanceName);
  }

  static newServiceInstances(ServiceTypes: Constructor[]): Object[] {
    //
    return ServiceTypes.map(ServiceType =>
      runInAction(() => new ServiceType())
    );
  }

  static getServiceName(ServiceType: Constructor) {
    //
    const instanceName = (ServiceType as any).instanceName;

    if (!instanceName) {
      throw new DOMException('ServiceInjector', 'Mobx service must use the @mobxService and define static instanceName');
    }
    return instanceName;
  }
}

export default ServiceModel;
