import html2canvas from "html2canvas";
import { toActor, toAudience } from "../shared/Function/checkpoint/Function";
import jsPDF from "jspdf";

const topPopup = 'nara.TopPopup';
const ssoId = 'nara.ssoId';
const ktoSso = 'nara.isKtosso';
const orgType = 'nara.orgType';
const isLogin = 'nara.isLogin';

const coverImage = '/rms-conlab/static/media/conlabCoverPage.png';
const lineImage = '/rms-conlab/static/media/line.png';

export function checkTop(): string | null {
    const res = window.sessionStorage.getItem(topPopup);
    return res;
}

export function checkKtoTop(): string | null {
    const res = window.sessionStorage.getItem(ktoSso);
    return res;
}

export function removeKtoTop() {
    window.sessionStorage.removeItem(ktoSso);
}

export function getSsoId(): string {
    const res = window.sessionStorage.getItem(ssoId);
    return res ? res : '';
}

export function topSessionclear() {
    window.localStorage.removeItem(isLogin);
    window.localStorage.removeItem('nara.accessToken');
    window.localStorage.removeItem('nara.refreshToken');
    window.localStorage.removeItem('nara.cineroomIds');
    window.localStorage.removeItem('nara.casOfficeIds');
    window.localStorage.removeItem('nara.citizenUser');

    window.localStorage.removeItem('nara.cineroom');
    window.localStorage.removeItem('nara.audience');
    window.localStorage.removeItem('nara.cast');
    window.localStorage.removeItem('nara.actorIdentity');
    window.localStorage.removeItem('nara.actor');

    topSessionRemove();
    removeOrgType();
}

export function topSessionRemove() {
    removeTopClear();
    window.localStorage.removeItem('nara.isCheckedProfile');
    removeTopSns();
    removeSessionTopPopup();
    removeSessionSsoId();
    window.sessionStorage.removeItem('nara.isTop');
    window.sessionStorage.removeItem('nara.strategyResult');
    window.sessionStorage.removeItem('nara.loginUrl');
}

export function getLogin() {
    const res = window.localStorage.getItem(isLogin);
    return res;
}

export function getCitizen() {
    const citizen = window.localStorage.getItem('nara.citizenUser');
    return citizen;
}

export function removeSessionTopPopup() {
    window.sessionStorage.removeItem(topPopup);
}

export function removeSessionSsoId() {
    window.sessionStorage.removeItem(ssoId);
}

export function setTopClear() {
    window.sessionStorage.setItem('topClear', 'true');
}

export function removeTopClear() {
    window.sessionStorage.removeItem('topClear');
}

export const removeTopSns = () => {
    window.localStorage.removeItem('nara.topSns');
}

export function getAudienceId() {
    // @ts-ignore
    const audienceId: {id: string, name: string, usid: string} = JSON.parse(window.localStorage.getItem("nara.audience"));
    return audienceId.name;
}

export function settingLoginStorage(audienceId: string, actorIdentity: any) {
    window.localStorage.setItem('nara.audience', JSON.stringify(toAudience(audienceId)));
    window.localStorage.setItem('nara.actorIdentity', JSON.stringify(actorIdentity));
    window.localStorage.setItem('nara.actor', JSON.stringify(toActor('actorId', actorIdentity.id)));
}

export const currentUrlCheck = () =>  {
    const currentUrl = window.location.href;

    let splitUrl = currentUrl.split(window.location.host);
    if (splitUrl[1]) {
        window.sessionStorage.setItem('nara.loginUrl', splitUrl[1]);
    }
}

export const getOrgType = () => {
    const res = window.sessionStorage.getItem(orgType);
    return res ? res : '';
}

export const removeOrgType = () => {
    window.sessionStorage.removeItem(orgType);
}

export const domainURL = window.location.host;

export const failSsoId = "잘못된 접근입니다.";

export const expiredSession = "로그인 인증 유효 시간이 만료되었습니다.";

const delay = (milliseconds: number): Promise<void> => {
    return new Promise<void>((resolve) => {
        setTimeout(resolve, milliseconds);
    });
}

// detail iframe으로 변경
const loadIframe = (url: string): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
        const iframeTag = document.createElement('iframe');
        // iframeTag.style.height = '1000px';
        const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
        if (!isMobile) {
            iframeTag.style.width = '1440px';
        }
        iframeTag.style.position = 'absolute';
        iframeTag.style.top = '-99999999px';
        iframeTag.style.left = '-99999999px';
        iframeTag.src = url;
        iframeTag.id = 'pdf-iframe';
        document.documentElement.appendChild(iframeTag);
        const myIframe = document.getElementById('pdf-iframe');
        const iframe = myIframe as HTMLIFrameElement;
        if (iframe) {
            const checkElementLoaded = async () => {
                const iframeDocument = iframe.contentDocument || iframe.contentWindow?.document;

                const element = iframeDocument?.querySelector('.cdp_tit');
                const theGage = iframeDocument?.querySelector('#the-gage-container');
                const imgGage = theGage?.getElementsByTagName('img');
                // img
                const visit_info = iframeDocument?.querySelector('.visit_info');
                // course
                const map = iframeDocument?.querySelector('#map');
                const modifyDate = iframeDocument?.querySelector('.content-modify-date');
                const lastStatusCineroom = iframeDocument?.querySelector('.content-cineroom-name');
                console.log('registerDate: ', modifyDate);

                // @ts-ignore
                if (element && (imgGage?.length > 0 || map || visit_info) && lastStatusCineroom) {
                    // test 
                    const myElement = iframe?.contentDocument?.getElementsByClassName('rd_view_item')
                    if (myElement && myElement[0]) {
                        const rdViewItemTag = myElement[0] as HTMLElement;
                        rdViewItemTag.style.padding = '0px'
                        
                        const theGageTag = rdViewItemTag.querySelector('#the-gage-container');
                        if (theGageTag) {
                            const theGage = theGageTag as HTMLElement;
                            theGage.style.padding = '0px';
                        }
                    }
                    if (isMobile) {
                        iframeTag.style.width = '1440px';
                    }
                    // test
                    resolve(iframe);
                } else {
                    console.log('retry');
                    setTimeout(checkElementLoaded, 100); // 재귀적으로 요소가 로드될 때까지 확인
                }
            };
            iframe.onload = checkElementLoaded;
        } else {
            // iframe을 찾을 수 없으면 reject
            reject(new Error('iframe을 찾을 수 없습니다.'));
        }
    });
  }
// map course 선처럼 canvas가 흰색만 있을 경우 페이지 생성x

export const downloadPDF = async (setLoading: (isLoading: boolean) => void, cid: string, contentType: any, lang: string = 'ko') => {
    await delay(3000);
    const url = `${'/rms-conlab/detail?cid='+cid+'&type='+contentType+'&lang='+lang}`;
    console.log('url: ', url);
    await loadIframe(url)
        .then(async (iframe: HTMLIFrameElement) => {
            const myElement = iframe?.contentDocument?.getElementsByClassName('rd_view_item')
            if (myElement && myElement?.length > 0) {
                const element = myElement[0] as HTMLElement;
                await modifyImageSources(element)
                    .then(async () => {
                        await capture(element, setLoading, url, lang);
                    })
                    .catch(error => {
                        // 에러 처리
                        console.error('이미지 소스 변경 중 오류가 발생했습니다.', error);
                    });
            }
        })
    .catch((error) => {
        console.error('iframe 로드 중 오류 발생:', error);
    });
}

const svgToImg = (element: any): Promise<HTMLImageElement> => {
    return new Promise<HTMLImageElement>((resolve, reject) => {
        // svg는 UTF-8 인코딩 사용, 'btoa'함수는 Latin1 문자열 처리
        // -> SVG 문자열을 UTF-8에서 Latin1로 변환한 후에 'btoa'함수를 사용하여 Base64 인코딩
        const svgString = new XMLSerializer().serializeToString(element);
        const utf8Encoded = unescape(encodeURIComponent(svgString));
        const base64Encoded = btoa(utf8Encoded);

        const img = new Image();
        img.onload = () => {
            resolve(img);
        };
        img.onerror = (error) => {
            reject(error);
        };
        img.src = 'data:image/svg+xml;base64,' + base64Encoded;
    })
}

export const modifyImageSources = async (element: HTMLElement) => {
    return new Promise<void>(async (resolve, reject) => {
      const imgs = element.getElementsByTagName('img');
      for (let i = 0; i < imgs.length; i++) {
        if (imgs[i].src.includes('https://conlab.visitkorea.or.kr')) {
            imgs[i].src = imgs[i].src.replace('https://conlab.visitkorea.or.kr', '');
        }
      }
      resolve(); // 작업이 완료되면 resolve 호출
    });
}

const captureMap = (clone: HTMLElement, url: string) => {
    return new Promise<void>(async (resolve, reject) => {
        const mapTag = clone.querySelector('#map');
        if (mapTag) {
            const mapImgTags = mapTag.getElementsByTagName('img');
            for (let i = 0; i < mapImgTags.length; i++) {
                const imgSrcTran = mapImgTags[i].src.replace('http://map.daumcdn.net', 'https://conlab.visitkorea.or.kr/api/address/flow/kakaomap');
                mapImgTags[i].src = imgSrcTran.replace('https://map.daumcdn.net', 'https://conlab.visitkorea.or.kr/api/address/flow/kakaomap');
            }
            const svgTags = mapTag.getElementsByTagName('svg');
            if (svgTags) {
                for (let i = 0; i < svgTags.length; i++) {
                    const svgTag = svgTags[i];
                    // style 잠시 저장
                    const svgStyle = svgTag.style.cssText;
                    svgTag.removeAttribute('style');
                    svgTag.setAttribute('width', '3600px');
                    svgTag.setAttribute('height', '2400px');
                    await svgToImg(svgTag)
                        .then(async (img) => {
                            img.style.cssText = svgStyle;

                            const canvas = document.createElement('canvas');
                            const ctx = canvas.getContext('2d');

                            canvas.width = 3600;
                            canvas.height = 2400;

                            ctx?.drawImage(img, 0, 0);

                            const imageData = ctx?.getImageData(0, 0, canvas.width, canvas.height);
                            if (imageData) {
                                const data = imageData.data;

                                // rgba 설정
                                // rgb(0,0,0): black
                                // rgba(0,0,0,0): 투명
                                for (let i = 0; i < data.length; i += 4) {
                                    const red = data[i];
                                    const green = data[i + 1];
                                    const blue = data[i + 2];

                                    if (red === 0 && green === 0 && blue === 0) {
                                        data[i + 3] = 0;
                                    }
                                }
                                ctx?.putImageData(imageData, 0, 0);

                                const newDataUrl = canvas.toDataURL();
                                img.src = newDataUrl;
                            }
                            svgTags[i].parentElement?.appendChild(img);
                    })
                }
            }
        }
        if (url.includes('CourseContents')) {
            const swiperSlides = clone.getElementsByClassName('swiper-slide');
            for (let i = 0; i < swiperSlides.length; i++) {
                const swiperTag = swiperSlides[i] as HTMLElement;
                swiperTag.style.width = '195.75px';
                swiperTag.style.height = '123.98px';
            }
        }
        resolve();
    })
}

export const base64Encoding = (src: string): Promise<string> => {
    return fetch(src)
        .then(response => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response.blob();
        })
        .then(blob => {
            const reader = new FileReader();
            return new Promise<string>((resolve, reject) => {
                reader.onloadend = () => {
                    const base64data = reader.result as string;
                    resolve(base64data);
                };
                reader.onerror = reject;
                reader.readAsDataURL(blob);
            });
        })
        .catch(error => {
            console.error('Base64 인코딩 오류:', error);
            throw error;
        });
}

const encodeFontToBase64 = async (url: string): Promise<string> => {
    const data = await fetch(url);
    const blob = await data.blob();

    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = () => {
            // @ts-ignore
            const base64data = reader.result.split(',')[1];
            resolve(base64data ? base64data : '');
        };
        reader.onerror = () => {
            reject(new Error('Failed to read font file'));
        };
    });
}

export const capture = (element: HTMLElement, setLoading: (isLoading: boolean) => void, url: string, lang: string = 'ko'): Promise<any> => {
    return new Promise<void>(async (resolve, reject) => {
        const coverImg = document.createElement('img');
        await base64Encoding(coverImage)
            .then(base64data => {
                coverImg.src = base64data;
            })
            .catch(error => {
                console.error('Base64 인코딩 오류:', error);
            });

        const lineImg = document.createElement('img');
        await base64Encoding(lineImage)
            .then(base64data => {
                lineImg.src = base64data;
            })
            .catch(error => {
                console.error('Base64 인코딩 오류:', error);
            });

        const title = element.getElementsByClassName('cdp_tit');
        if (title.length > 0) {
            const tag = title[0] as HTMLElement;
            tag.innerText = tag.innerText.replace('\n', ' ');
            tag.style.whiteSpace = 'normal';
        }

        element.style.height = element.scrollHeight + 'px';

        await captureMap(element, url);
 
        html2canvas(element, {
            scale: 1,
            allowTaint : true,
            useCORS: true,
            height: element.scrollHeight,
            ignoreElements: function(element) {
                const excludedClasses = ['pdf-btn', 'share', 'pdf-progress', 'bookmark-btn', 'pdf-img-btn'];
                return excludedClasses.some(className => element.classList.contains(className));
            },
        }).then(async (canvas) => {
            const image = { type: 'png', quality: 0.98 };
            const margin = 0.7;
            
            const imgWidth = 8.5;
            const pageHeight = 11;
            
            // 이미지 사이즈, type, quality 설정
            const pdf = new jsPDF('p', 'in', [imgWidth, 11]);

            const innerPageWidth = imgWidth - margin * 2;
            let innerPageHeight = pageHeight - margin * 2;

            const pxFullHeight = canvas.height;
            const pxPageHeight = Math.floor(canvas.width * (pageHeight / imgWidth));
            let nPages = Math.ceil(pxFullHeight / pxPageHeight);

            // 설정한 크기로 그릴 내용 이미지 사이즈 설정
            const pageCanvas = document.createElement('canvas');
            const pageCtx = pageCanvas.getContext('2d');
            pageCanvas.width = canvas.width;
            pageCanvas.height = pxPageHeight;

            // 폰트 파일 경로
            const fontPath = '/rms-conlab/font/Pretendard-Bold.ttf';
            // 폰트 파일을 Base64로 인코딩한 데이터 받기
            try {
                const fileData = await encodeFontToBase64(fontPath);
                pdf.addFileToVFS(fontPath, fileData);
                pdf.addFont(fontPath, 'myFont', 'normal');
                pdf.setFont('myFont');
            } catch (error) {
                console.error(error);
            }

            const copyCanvas = canvas;
            const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
            // let nowPage = nPages;
            // if (!isMobile) {
            //     nowPage = await getPages(copyCanvas);
            // }
            let nowPage = await getPages(copyCanvas);
            for (let page = 0; page < nowPage; page++) {
                // 마지막 페이지의 높이가 캔버스의 높이와 정확히 맞지 않을 경우
                if (page === nPages - 1 && (pxFullHeight % pxPageHeight) !== 0) {
                    pageCanvas.height = pxFullHeight % pxPageHeight;
                    innerPageHeight = (pageCanvas.height * innerPageWidth) / pageCanvas.width;
                }

                // 설정한 사이즈로 이미지를 그림
                const w = pageCanvas.width;
                const h = pageCanvas.height;

                pdf.setTextColor(0, 0, 0);
                // cover 이미지 start
                if (page === 0) {
                    pdf.addImage(coverImg, image.type, 0, 0, imgWidth, pageHeight, undefined, "FAST", undefined);
                    // pdf title
                    const myTitleTag = element.getElementsByClassName('cdp_tit');
                    if (myTitleTag.length > 0) {
                        const myTitle = myTitleTag[0] as HTMLElement;
                        await titleCapture(myTitle, pdf);
                        
                        pdf.setFontSize(9);

                        const modifyDateTag = element.getElementsByClassName('content-modify-date');
                        const lastStatusCineroomTag = element.getElementsByClassName('content-cineroom-name');
                        if (modifyDateTag.length > 0) {
                            const modifiDate = modifyDateTag[0] as HTMLElement;
                            const date = modifiDate.innerText;
                            pdf.text(`${date}`, margin, 8);
                        }
                        if (lastStatusCineroomTag.length > 0) {
                            const lastStatusCineroom = lastStatusCineroomTag[0] as HTMLElement;
                            const cineroom = lastStatusCineroom.innerText;
                            console.log('cineroom: ', cineroom);
                            console.log('cineroom.length: ', cineroom.length);
                            if (cineroom.length !== 0) {
                                pdf.text(`${cineroom}`, margin, 8.2);
                            } else {
                                pdf.text('디지털콘텐츠팀', margin, 8.2);
                            }
                        } else {
                            pdf.text('디지털콘텐츠팀', margin, 8.2);
                        }
                        url = 'https://conlab.visitkorea.or.kr' + url;
                        pdf.text(`URL: ${url}`, margin, 8.4);
                    }
                }
                // cover 이미지 end
                if (pageCtx) {
                    pageCtx.fillStyle = "white";
                }
                pageCtx?.fillRect(0, 0, w, h);
                pageCtx?.drawImage(canvas, 0, page * pxPageHeight, w, h, 0, 0, w, h);
                const imgData = pageCanvas.toDataURL('image/' + image.type, image.quality); // image/png 로 설정할 경우 용량이 커짐

                pdf.addPage();
                pdf.addImage(lineImg, image.type, 0, 0, imgWidth, 0.5, undefined, "FAST", undefined);
                pdf.addImage(imgData, image.type, margin, margin, innerPageWidth, innerPageHeight, undefined, "FAST", undefined);
                // 페이지 번호 설정
                pdf.setFontSize(12);
                pdf.text(`${page+1}/${nowPage}`, 8, pageHeight-0.2);
            }
            const myElementTitle = element.getElementsByClassName('cdp_tit');
            if (myElementTitle.length > 0) {
                const titleTag = myElementTitle[0] as HTMLElement;
                const pdfFileName = sanitizeFilename(titleTag.innerText);
                await saveJsPdf(pdfFileName !== '' ? pdfFileName : '한국관광콘탠츠랩', setLoading, pdf);
            } else {
                await saveJsPdf('한국관광콘탠츠랩', setLoading, pdf);
            }
        });
        element.style.height = '';
        resolve(); // 작업이 완료되면 resolve 호출
    });
  }

  const sanitizeFilename = (filename: string) => {
    // 허용되지 않는 문자를 대체할 정규표현식
    var illegalRe = /[\/\?<>\\:\*\|"]/g;

    // 허용되지 않는 문자를 대체하여 적절한 파일명 생성
    var sanitizedFilename = filename.replace(illegalRe, '_');

    return sanitizedFilename;
}

  const getPages = (canvas: HTMLCanvasElement): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
        const image = { type: 'png', quality: 0.98 };
        const margin = 0.7;
        
        const imgWidth = 8.5;
        const pageHeight = 11;
        // 이미지 사이즈, type, quality 설정

        const innerPageWidth = imgWidth - margin * 2;
        let innerPageHeight = pageHeight - margin * 2;

        const pxFullHeight = canvas.height;
        const pxPageHeight = Math.floor(canvas.width * (pageHeight / imgWidth));
        let nPages = Math.ceil(pxFullHeight / pxPageHeight);

        // 설정한 크기로 그릴 내용 이미지 사이즈 설정
        const pageCanvas = document.createElement('canvas');
        const pageCtx = pageCanvas.getContext('2d');
        pageCanvas.width = canvas.width;
        pageCanvas.height = pxPageHeight;
        let calPages = nPages;
        for (let page = 0; page < nPages; page++) {
            // 마지막 페이지의 높이가 캔버스의 높이와 정확히 맞지 않을 경우
            if (page === nPages - 1 && (pxFullHeight % pxPageHeight) !== 0) {
                pageCanvas.height = pxFullHeight % pxPageHeight;
                innerPageHeight = (pageCanvas.height * innerPageWidth) / pageCanvas.width;
            }
    
            // 설정한 사이즈로 이미지를 그림
            const w = pageCanvas.width;
            const h = pageCanvas.height;

            if (pageCtx) {
                pageCtx.fillStyle = "white";
            }
            pageCtx?.fillRect(0, 0, w, h);
            pageCtx?.drawImage(canvas, 0, page * pxPageHeight, w, h, 0, 0, w, h);
            const imgData = pageCanvas.toDataURL('image/' + image.type, image.quality); // image/png 로 설정할 경우 용량이 커짐

            // 빈 페이지 제거 start
            const canvas2 = document.createElement('canvas');
            const ctx2 = canvas2.getContext('2d');
    
            ctx2?.drawImage(canvas, 0, page * pxPageHeight, w, h, 0, 0, w, h);
    
            const imgData2 = canvas2.toDataURL('image/' + image.type, image.quality); // image/png 로 설정할 경우 용량이 커짐
            const img = document.createElement('img');
            img.src = imgData2;
            img.width = w;
            img.height = h;
    
            ctx2?.drawImage(img, 0, 0);
    
            const myImageData = ctx2?.getImageData(0, 0, img.width, img.height);
            const data = myImageData?.data;
    
            let isWhite = true;
            if (data) {
                for (let i = 0; i < data.length; i += 4) {
                    const red = data[i];
                    const green = data[i + 1];
                    const blue = data[i + 2];

                    if (red !== 255 || green !== 255 || blue !== 255) {
                        isWhite = false;
                        break;
                    }
                }
                if (isWhite) {
                    console.log('이미지는 전부 하얀색입니다.');
                    calPages -= 1;
                } else {
                    console.log('이미지에 하얀색이 아닌 부분이 있습니다.');
                }
            }
        }
        resolve(calPages);
    })
  }

  const saveJsPdf = (pdfFileName: string, setLoading: (isLoading: boolean) => void, pdf: jsPDF): Promise<any> => {
    return new Promise<void>((resolve, reject) => {
        pdf.save(pdfFileName);
        try {
            setLoading(false);
            const iframeTag = document.getElementById('pdf-iframe');
            if (iframeTag) {
                if (iframeTag) {
                  iframeTag.parentNode?.removeChild(iframeTag);
                }
            }
        } catch(e) {

        }
        resolve();
    });
  }

  const titleCapture = (title: HTMLElement, pdf: jsPDF): Promise<void> => {
    return new Promise<void>((resolve, reject) => {
        console.log('title: ', title);
        title.style.textDecoration = 'none';
        html2canvas(title, {
            scale: 1.4
        }).then(async (titleCanvas) => {
            const image = { type: 'png', quality: 0.98 };
            
            const titlePageCanvas = document.createElement('canvas');
            const titlePageCtx = titlePageCanvas.getContext('2d');

            const height = 5;
            const width = 7;
            
            const pxPageHeight = Math.floor(titleCanvas.width * (5 / 7.5));
            titlePageCanvas.width = titleCanvas.width;
            titlePageCanvas.height = pxPageHeight;

            const w = titlePageCanvas.width;
            const h = titlePageCanvas.height;

            const innerPageWidth = 7.5 - 0.7 * 2;
            let innerPageHeight = 5 - 0.7 * 2;

            if (titlePageCtx) {
                titlePageCtx.fillStyle = "white";
            }
            titlePageCtx?.fillRect(0, 0, w, h);
            console.log('pxPageHeight: ', pxPageHeight);
            titlePageCtx?.drawImage(titleCanvas, 0, 0, w, h, 0, 0, w, h);
            const imgData = titlePageCanvas.toDataURL('image/' + image.type, image.quality); // image/png 로 설정할 경우 용량이 커짐
            pdf.addImage(imgData, image.type, 0.7, 3, innerPageWidth, innerPageHeight, undefined, "FAST", undefined);
            resolve();
        })
    })
  }

