import React from 'react';
// import "../../css/terms.css";
import styled from '@emotion/styled';
import { currentUrlCheck, getOrgType, getSsoId } from '../../conlab';
import { OrgType } from '../../OrgProposalForm/types/OrgType';
import Alert from '../../components/Alert';



interface Props {
}

interface State {
    isAlert: boolean;
    alertMsg: string;
}

class JoinPage extends React.Component<Props, State> {

    private isLoginStorageKey = "nara.isLogin";
    private actorIdentityStorageKey = "nara.actorIdentity";

    constructor(props: any) {
        super(props);
        this.topLogin = this.topLogin.bind(this);
        this.privateJoin = this.privateJoin.bind(this);
        this.orgJoin = this.orgJoin.bind(this);
        this.openAlert = this.openAlert.bind(this);
        this.closeAlert = this.closeAlert.bind(this);
        this.checkLogin = this.checkLogin.bind(this);
    }
    
    state: State = {
        isAlert: false,
        alertMsg: ''
    }

    async topLogin() {
        currentUrlCheck();
        // await axios.post("/api/checkpoint/sso/top/site-id")
        // .then((response) => {
        //   console.log(response.data.queryResult);
        //   console.log('linkSiteApiId : ', response.data.queryResult);

        //   const reqUrl = 'https://pass.knto.or.kr/front/login/login?linkSiteApiId=';
        //   const linkSiteApiId = response.data.queryResult;

        //   //투어원패스 팝업 셋팅
        //   const uri = reqUrl + linkSiteApiId;
        //   const popupName = '_blank';
        //   const options = 'width=560, height=600'; //팝업사이즈

        //   //투어원패스 팝업 호출
        //   const win = window.open(uri, popupName, options);
        //   return win;
        // });
        const reqUrl = "https://pass.knto.or.kr/front/login/login?linkSiteApiId=";

        //투어원패스 팝업 셋팅
        const uri = reqUrl + "NTVsTUyyO8O8WueBigtM5A";
        const popupName = "_blank";
        const options = "width=560, height=600"; //팝업사이즈

        //투어원패스 팝업 호출
        const win = window.open(uri, popupName, options);
        return win;
    }

    openAlert() {
        this.setState({ isAlert: true, alertMsg: '로그인 중 회원가입이 불가능합니다.' });
    }

    closeAlert() {
        this.setState({ isAlert: false });
    }

    checkLogin() {
        const actorIdentityToken = JSON.parse(window.localStorage.getItem(this.actorIdentityStorageKey) || '{}');
        const isLogin = window.localStorage.getItem(this.isLoginStorageKey);
        if (actorIdentityToken.id && isLogin) {
            return true;
        } else {
            return false;
        }
    }
    
    privateJoin() {
        window.sessionStorage.setItem('nara.orgType', OrgType.None);
        const ssoId = getSsoId();
        if (!ssoId && this.checkLogin()) {
            this.openAlert();
            return;
        } else if (!ssoId) {
            this.topLogin();
        }
        if (ssoId && getOrgType()) {
            window.location.href = '/rms-conlab/sign-up/by-top';
        }
    }
    
    orgJoin() {
        window.sessionStorage.setItem('nara.orgType', OrgType.General);
        const ssoId = getSsoId();
        if (!ssoId && this.checkLogin()) {
            this.openAlert();
            return;
        } else if (!ssoId) {
            this.topLogin();
        }
        if (ssoId && getOrgType()) {
            window.location.href = '/rms-conlab/sign-up/by-top';
        }
    }

    render() {
        const { isAlert, alertMsg } = this.state;
        return (
            <>  
                {isAlert && <Alert message={alertMsg} eventCloseModal={this.closeAlert}/>}
                <div id="contents" className="tourism_contents background_none">
                    <section className="memberjoin-wrap" 
                        style={{ backgroundPosition: '115% -250px' }}
                    >
                        <div className="memberjoin">
                            <div className="memberjoin-title">
                                <h1>회원가입</h1>
                            </div>
                            <div className="memberjoin-content">
                                <h2>간편로그인</h2>
                                <ul className="h2-comment">
                                    <li>한국관광콘텐츠랩은 한국관광공사에서 운영하는 관광분야 간편로그인(투어원패스)를 통해<br className="pc"/>서비스를 자유롭게 이용할 수 있습니다.</li>
                                    <li>간편 로그인을 통해 풍성한 관광콘텐츠를 즐겨보세요.</li>
                                </ul>
                                <h2>가입회원유형</h2>
                                <ul className="member-type">
                                    <li>
                                        <div className="member-box">
                                            <img src="/rms-conlab/images/membertype_2.png" />
                                            <h3>개인회원</h3>
                                            <div className="h3-comment">개인 및 일반사용자를 위한<br className="pc"/>서비스입니다.</div>
                                            <button className="btn-memberjoin" onClick={this.privateJoin}>회원가입</button>

                                        </div>
                                    </li>
                                    <li>
                                        <div className="member-box">
                                            <img src="/rms-conlab/images/membertype_1.png" />
                                            <h3>기업회원</h3>
                                            <div className="h3-comment">기업/단체 및 (공공)기관 등을 위한<br className="pc"/>전용 서비스입니다.</div>
                                            <button className="btn-memberjoin" onClick={this.orgJoin}>회원가입</button>
                                        </div>
                                    </li>
                                </ul>
                                <div className="alert-text">※ 동일 이메일로 개인과 기업 회원 동시 가입은 불가하므로, 가입을 원하실 경우 각각 다른 이메일 인증을 통해 이용이 가능합니다.</div>
                            </div>
                        </div>
                    </section>
                </div>
            </>
        );
    }
}

export default JoinPage;

