import { makeAutoObservable } from 'mobx';
import _ from 'lodash';
import mobxService from '../../../../lib/decorator/mobxService/mobxService';
import Address from '../../../../lib/granule/Address';



@mobxService
class AddressStateKeeper {
  static readonly instanceName: string = 'addressStateKeeper';
  static instance: AddressStateKeeper;

  address: Address | null = null;

  actorKey: string | null = null;

  constructor(
  ) {
    makeAutoObservable(this);
  }

  init(addressBookId: string, userId: string, actorKey: string) {
    this.address = Address.new(addressBookId, userId);
    this.actorKey = actorKey || null;
  }

  setAddress(address: Address) {
    this.address = address;
  }

  setAddressProp(name: string, value: any) {
    if (!this.address) {
      throw new DOMException('AddressStateKeeper.setAddressProp', 'address is null');
    }

    switch (name) {
      case 'street':
        this.setStreet(name, value);
        break;
      case 'building':
        this.setStreet(name, value);
        break;
      default:
        this.address = _.set(this.address, name, value);
        // (this.address as any)[name] = value;
        break;
    }
  }

  setStreet(key: string, value: any) {
    if (!this.address) {
      throw new DOMException('AddressStateKeeper.setStreet', 'address is null');
    }

    (this.address.streetAddress as any)[key] = value;
  }

  clear() {
    this.address = null;
  }
}

AddressStateKeeper.instance = new AddressStateKeeper();

export default AddressStateKeeper;
