enum ProposalState {
  Requested = 'Requested',
  Reviewed = 'Reviewed',
  Approved = 'Approved',
  ReviewRejected = 'ReviewRejected',
  ApproveRejected = 'ApproveRejected',

  Canceled = 'Canceled',
  Completed = 'Completed',
}

export default ProposalState;
