import React from "react";
import "../css/common.css";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { IconButton } from "@mui/material";


interface Props {
  login: (event: any, id: string, pw: string) => void;
  topCloseModal: () => void;
}

interface State {
  id: string;
  pw: string;
  visibility: boolean;
}

class TopLoginModal extends React.Component<Props, State> {
  static defaultProps: any = {};

  constructor(props: any) {
    super(props);
    this.handleIdChange = this.handleIdChange.bind(this);
    this.handlePwChange = this.handlePwChange.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  componentDidMount() {}

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
    snapshot?: any
  ): void {}

  //@ts-ignore
  state: State = {
    id: "",
    pw: "",
    visibility: false
  };

  handleIdChange(event: any) {
    this.setState({ id: event.target.value });
  };

  handlePwChange(event: any) {
    this.setState({ pw: event.target.value });
  };

  closeModal(){
    this.props.topCloseModal();
  }

  render() {
    const { login } = this.props;
    const { id, pw, visibility } = this.state;

    return (
      <>
        <div className="login_page" style={{display: 'block'}}>
          <div className="login_box_wrap">
            <div className="login_box" style={{marginBottom: '16px', height: '420px'}}>
            <button className="btn_close" style={{position: 'absolute', top: '3px', right: '8px', padding: '9px 3px'}} onClick={() => (this.closeModal())}>
                <img src="/rms-conlab/images/btn_close.png" alt="btn_close" />
              </button>
              <form action="">
                <h2 style={{marginBottom: '35px'}}>
                  계정을
                  <br />
                  연동해주세요
                </h2>
                <div className="input_box id">
                  <input type="text" placeholder="아이디" value={id} onChange={this.handleIdChange}/>

                  <button type="button" className="clear_bt" onClick={() => this.setState({ id: '' })} style={{ right: '16px' }}>
                    <img
                      src="/rms-conlab/images/icon_clear.png"
                      alt="icon_clear"
                    />
                  </button>
                </div>

                <div className="input_box pass">
                  <input
                    type={visibility ? 'text' : 'password'}
                    placeholder="비밀번호"
                    value={pw} onChange={this.handlePwChange}
                  />
                  <button type="button" className="clear_bt" onClick={() => this.setState({ pw: '' })}>
                    <img
                      src="/rms-conlab/images/icon_clear.png"
                      alt="icon_clear"
                    />
                  </button>
                  <IconButton 
                    style={{ position: 'absolute', right: '8px', top: '4px', color: 'gray' }} 
                    onClick={() => this.setState({ visibility: !this.state.visibility })} 
                  >
                    {
                      visibility 
                      ? <VisibilityOffIcon />
                      : <VisibilityIcon />
                    }
                  </IconButton>
                </div>
                <div className="login_bts">
                  <button className="login_bt st_line ok" style={{marginTop: '65px'}} onClick={(e) => login(e, id, pw)}>계정연동</button>
                </div>
              </form>
              
            </div>
            <div className="bg"></div>
          </div>
        </div>
      </>
    );
  }
}

export default TopLoginModal;
