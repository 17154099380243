import React, { useEffect, useRef, useState } from 'react';


interface Props {
  limitMin: number;
  onTimeOver: () => void;
}

const LimitTimer: React.FC<Props> = ({
  limitMin,
  onTimeOver,
}) => {
  //
  const [min, setMin] = useState<number>(limitMin);
  const [sec, setSec] = useState<number>(0);
  const time = useRef<number>(limitMin * 60);
  const timerId = useRef<NodeJS.Timeout>();

  useEffect(() => {
    timerId.current = setInterval(() => {
      setMin(parseInt(String(time.current / 60)));
      setSec(time.current % 60);
      time.current -= 1;
    }, 1000);

    return () => clearInterval(timerId.current as NodeJS.Timeout);
  }, []);

  useEffect(() => {
    if (time.current <= 0 && timerId.current) {
      clearInterval(timerId.current);
      onTimeOver();
    }
  }, [sec]);


  return (
    <strong className="b txt-primary">{min}:{sec > 10 ? sec : '0' + sec}초</strong>
  );
};

export default LimitTimer;
