import React from 'react';
import { observer } from 'mobx-react';
import autobind from '../../../../lib/decorator/autobind/autobindDecorator';
import ReactComponent from '../../../../lib/module/ReactComponent/ReactComponent';
import ServiceInjector from '../../../../lib/module/ServiceInjector/Serviceinjector';
import AddressesStateKeeper from '../../../state/Address/keeper/AddressesStateKeeper';
import AddressStateKeeper from '../../../state/Address/keeper/AddressStateKeeper';
import XGridStateKeeper from '../../../../model/xGridUtils/keeper/XGridStateKeeper';


interface Props {
    children: any;
}

interface State{
}

interface InjectedProps {
  addressesStateKeeper: AddressesStateKeeper;
  addressStateKeeper: AddressStateKeeper;
}

@autobind
@observer
class AddressListContainer extends ReactComponent<Props, State, InjectedProps> {
  //

  render() {
    //
    const { children } = this.props;

    return (
      <>
        { children }
      </>
    );
  }
}
export default ServiceInjector.withContext(
  AddressesStateKeeper,
  XGridStateKeeper,
  AddressStateKeeper
)(AddressListContainer);
