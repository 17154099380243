import axios from 'axios';
import FileboxCdo from '../../../comp/api/filebox/api-model/sdo/FileboxCdo';
import CommandResponse from '../../../lib/cqrs/command/CommandResponse';
import FileItemModel from '../../../comp/state/Depot/FileBox/model/FileItemModel';
import UserInfo from '../../../comp/api/filebox/model/UserInfo';
import DepotFileCdo from '../../../comp/api/depot/api-model/sdo/DepotFileCdo';
import { apiURL } from '..';

const url = apiURL+'/api/depot';
const secureDepotFlowUrl = '/secure/depot-flow';
const secureFileBoxUrl = '/secure/filebox-flow';

export const removeDepotFile = async (depotFileId: string) => {
    const data = {
        depotFileId
    }
    await axios.post(url+secureDepotFlowUrl+'/remove-depot-file', data);
}

export const registerFileBox = async (fileboxCdo: FileboxCdo) => {
    const data = {
        fileboxCdo
    }
    const response: CommandResponse = await axios.post(url+secureFileBoxUrl+'/register-filebox', data);
    return response;
}

export const uploadFile = async (fileItem: FileItemModel, depotId: string, userInfo?: UserInfo, memo? : string) => {
    if (!fileItem.file) {
        throw new DOMException('FileboxStateKeeper', 'File should not be null.');
      }
      const depotFileCdo = FileItemModel.toCdo(fileItem, depotId);
      return uploadFileFunc(depotFileCdo, fileItem.file, userInfo || UserInfo.new(), memo || '');
}

const uploadFileFunc = async (depotFileCdo: DepotFileCdo, file: File, userInfo: UserInfo, memo: string) => {
    const data = {
        depotFileCdo,
        userInfo,
        memo,
    };
    
    const formData: FormData = new FormData();

    formData.append('command', new Blob([JSON.stringify(data)], { type: 'application/json' }));
    formData.set('file', file);

    const response: CommandResponse = await axios.post(url+secureFileBoxUrl+'/upload-file', formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });

    return response;
}

export const removeFile = async (fileboxId: string) => {
    const data = {
        fileboxId,
    }
    await axios.post(url+'/secure/filebox/filebox/secure-command',data);
}