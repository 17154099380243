import axios from "axios";
import { NotieClientType, connectNotieWebSocket, findNotieWebSocketSpec } from "../pigeon/Function";
import { ActivationState } from "@stomp/stompjs";
import { NotieTitleType } from "../../Notie/vo/NotieTitleType";
import Notie from "../../../comp/api/pigeon/notie/api-model/Notie";
import PushPersonalNotieCommand from "../../../comp/api/pigeon/notie/api-model/command/PushPersonalNotieCommand";
import { apiURL } from "..";

const url = apiURL+'/api/stage';
const secureFlowUrl = '/secure/flow/kto-flow/query';
const flowContextQuery= '/flow/stage-context-flow/query';

export const findActorsByRole = async (stageRoleCode: string, troupeId: string) => {
    const data = {
        stageRoleCode,
        troupeId,
        aggregationTarget: 'Actor'
    };
    
    const response  = await axios.post(url+secureFlowUrl+'/find-tenants-by-role', data);
    return response;
}

export function genKeysFromActorId(actorId: string) {
    // actorId : 1@1:1:1-11
    actorIdValidation(actorId);

    const audienceId = actorId.substr(0, actorId.lastIndexOf('-'));
    const citizenId = audienceId.substr(0, audienceId.lastIndexOf(':'));
    const cineroomId = audienceId.substr(audienceId.indexOf('@') + 1);
    const pavilionId = cineroomId.substr(0, cineroomId.lastIndexOf(':'));

    const castId = actorId.substr(actorId.indexOf('@') + 1);
    const troupeId = castId.substr(0, castId.length - 1);

    return {
        pavilionId,
        citizenId,
        cineroomId,
        audienceId,
        troupeId,
        castId,
        actorId,
    };
}

function actorIdValidation(actorId: string) {
    const regEx = /.+@.+[:].+[:].+[-].{2}$/;

    if (!regEx.test(actorId)) {
        throw new Error(`given actorId (${actorId}) format not match with pattern${regEx}`);
    }
}

export const notieToDccAccountReviewer = async (groupName: string, managerName: string, receiverIds: string[], retry: number = 10) => {
    const wsSpec = await findNotieWebSocketSpec();

    // error 발생 코드
    const notieClient = await connectNotieWebSocket(
        wsSpec,
        false,
        [],
        () => {},
        {
        onConnect: () => {},
        onError: () => {
            setTimeout(async () => {
            console.log('retry... temporary socket connection', retry);
            await notieToDccAccountReviewer(groupName, managerName, receiverIds, retry - 1);
            }, 1000);
        },
        onClose: () => {
            console.log('temporary socket disconnected successfully');
        },
        }
    );

    setTimeout(() => {
        console.log(notieClient.stompClient);
        if (notieClient.stompClient.state === ActivationState.ACTIVE) {
        groupAlarm001(notieClient, receiverIds, groupName, managerName);
        notieClient.stompClient.disconnect();
        }
    }, 2000);
}

const groupAlarm001 = (
    notieClient: NotieClientType,
    // senderCitizenId: string,
    // senderName: string,
    receiverIds: string[],
    groupName: string,
    leaderName: string) => {
    const messageTitle = NotieTitleType.Alarm001;
    const message = `${groupName} ${leaderName}님이 단체개설 요청하였습니다.`;

    if (receiverIds.length) {
        receiverIds.forEach( (id) => {
        const notieData = Notie.meta('1@1:1', 'RMS', id, false).payload(messageTitle, message);

        sendNotie(notieClient, notieData);
        });
    }
}

const sendNotie = (notieClient: NotieClientType, notie: Notie) => {
    //
    const { stompClient, webSocketSpec } = notieClient;
    const { sendPrefix } = webSocketSpec;

    if (notie.broadcast) {
        throw new Error('broad cast notification service is not available yet... ');
    }

    const targetUrl = `${sendPrefix}/${notie.broadcast ? 'broadcast' : 'personal'}/${notie.destinationKey}`;
    const message = notie.broadcast ? null : PushPersonalNotieCommand.fromNotie(notie);

    stompClient.send(targetUrl, { }, JSON.stringify(message));
    console.log('######## NOTIE : SEND', message);
}

export const findStageChartById = async (stageChartId: string) => {
    const data = {
        stageChartId,
    };
    
    const res = await axios.post(url+'/aggregate/chart/stage-chart/query', data);
    return res.data;
}

export const defaultCast = async (cineasteId: string) => {
    const data = {
        cineasteId
    }
    const res = await axios.post(url + flowContextQuery + '/default-cast', data);
    return res.data;
}

export const modifyLatestCast = async (audienceId: string, latestCastId: string) => {
    const data = {
        audienceId,
        latestCastId
    }
    const res = await axios.post(url + '/flow/stage-context-flow' + '/modify-latest-cast', data);
    return res.data;
}

export const findCastByBasePathAndStageChart = async (basePath: string, stageChartId: string) => {
    const data = {
        basePath,
        stageChartId
    }
    const res = await axios.post(url+'/aggregate/chart/cast/query'+'/find-cast-by-path-and-chart', data);
    return res.data;
}

export const findActorIdentity = async (audienceId: string, stageChartId: string, kollectionName: string) => {
    if (kollectionName === '/rms-conlab') {
        kollectionName = 'conlab-app';
    }
    const data = {
        audienceId,
        stageChartId, // = cineroomID
        kollectionName,
    }
    const res = await axios.post(url + flowContextQuery + '/current-actorIdentity', data);
    return res.data;
}