import axios from 'axios';
import Address from '../../../lib/granule/Address';
import Offset from '../../../lib/domain/offset/Offset';
import { QueryResult } from '../responseType/QueryResult';
import { apiURL } from '..';

const url = apiURL+'/api/address';
const flowAddressFlow = '/flow/address/address/query';

type JusoResponseType = {
  data: {
    queryResult: [Address],
    offset: Offset,
  }
}

export enum UserType {
  Servant = 'SERVANT',
  Citizen = 'CITIZEN',
}

export const geoCoding = async (
  address: Address
) => {

  const data = {
    address
  }

  const res: QueryResult = await axios.post(url+flowAddressFlow+'/geocode', data);
  return res.data.queryResult;
}

export const findAddressByJusoAPI = async (offset: Offset, keyword: string) => {
  const data = {
    offset,
    keyword
  }

  const res: JusoResponseType = await axios.post(url+flowAddressFlow+'/juso', data);
  return res.data;
}