import { Button, Icon } from '@mui/material';
import React from 'react';


export default function Custom404() {
  return (
    <div className="error-page-outer">
      <div className="error-page-wrap">
        <div className="error-logo">
          <a href="/rms-conlab">
            <i />
          </a>
        </div>
        <div className="error-page-cont">
          <i className="icon" />
          <div className="page-tit">
            요청하신 페이지를{' '}
            <span className="txt-primary">찾을 수 없습니다.</span>
          </div>
          <div className="page-desc">
            방문하시려는 페이지의 주소가 잘못 입력되었거나, 페이지의 주소가 변경
            혹은 삭제되어 요청하신 페이지를 찾을 수 없습니다. <br />
            입력하신 주소가 정확한지 다시 한 번 확인해주시기 바랍니다.
          </div>
        </div>

        <div className="error-page-btns">
          <Button className="btn-init outline icon large" onClick={() => window.history.back()}>
            <Icon style={{ fontSize: 18 }}>chevron_left</Icon>
            <span>이전페이지</span>
          </Button>
          <Button className="btn-init fill primary large" onClick={() => window.location.href = '/rms-conlab'}>
            <span>CONLAB 홈</span>
          </Button>
        </div>

        <div className="error-page-footer">
          Copyright © <strong className="b">KTO</strong>. ALL RIGHT RESERVED.
        </div>
      </div>
    </div>
  );
}
