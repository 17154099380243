import React, { ReactNode, createRef } from 'react';
import "../../css/style.min.css";
import { Button, FormControl, Icon, InputLabel, MenuItem, Select, SelectChangeEvent, TextField } from '@mui/material';
import autobind from '../../lib/decorator/autobind/autobindDecorator';
import { observer } from 'mobx-react';
import OrgProposal from '../api-model/OrgProposal';
import { OrgType } from '../types/OrgType';
import XGridStateKeeper from '../../model/xGridUtils/keeper/XGridStateKeeper';
import ReactComponent from '../../lib/module/ReactComponent/ReactComponent';
import ServiceInjector from '../../lib/module/ServiceInjector/Serviceinjector';
import CommonModal from '../../conlab/CommonModal';
import LimitTimer from '../../shared/Timer/LimitTimer';
import { orgData } from '../../comp/api/onboarding/api-model/OrgData';

interface Props {
  renderMailForm?: () => React.ReactNode;
  orgProposal: OrgProposal;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onSubmit: (event: React.MouseEvent) => void;
  onChangeLicenceId: (event: React.ChangeEvent<HTMLInputElement>) => void;
  
  validateEmail: boolean;

  orgType: OrgType;

  onClickCancel: () => void;

  checkLicenceId: () => void;

  changeForm: boolean;
  originLicenceIdChecked: 'default' | 'pass' | 'fail';

  // 메일
  // cancelEmail: () => void;
  reEmail: () => void;
  validationCodeInEmail: (email: string) => Promise<boolean>;
  onChangeMail: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onChangeCode: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onSubmitMail: () => void;
  tempEmail: string;
  isSent: boolean;
  code: string;
  showTimeOption: 'default' | 'timer' | 'timeOver';
  onTimeOver: () => void;
  emailCheck: 'default' | 'pass' | 'fail';

  onChangeOrg: (event: SelectChangeEvent<string>, child: ReactNode) => void
  orgList: orgData[];
  selectOrg: string | undefined;
  submitBtn: boolean;
}

interface State {
  openRequiredAlert: boolean;
  alertText: string;

  validateEmail: boolean;
  checked: boolean;
  openModal: boolean;
  modalText: string;
  liceneId: any;
  isVerified: boolean;
}

interface InjectedProps {
  xGridStateKeeper: XGridStateKeeper;
}

@autobind
@observer
class OrgProposalFormView extends ReactComponent<Props, State, InjectedProps> {

  mngRef = createRef<HTMLInputElement>();
  mngTelRef = createRef<HTMLInputElement>();
  mngEmailRef = createRef<HTMLInputElement>();
  orgNameRef = createRef<HTMLInputElement>();
  orgTelRef = createRef<HTMLInputElement>();
  licenceRef = createRef<HTMLInputElement>();

  state = {
    open: false,
    openRequiredAlert: false,
    alertText: '',
    // passwordCheck: '',
    // isPasswordValid: false,
    validateEmail: true,
    isValidLicenceId: false,
    checked: false,
    selectedFileIds: [],
    fileDatas: [],
    openModal: false,
    modalText: '',
    liceneId: '',
    selectOrg: undefined,
    isVerified: false
  };

  getLicenceIdHelperText() {
    const { originLicenceIdChecked } = this.props;
    if (originLicenceIdChecked === 'default') {
      return '';
    }
    else if (originLicenceIdChecked === 'pass') {
      return '사용 가능한 사업자 번호입니다.';
    }
    else {
      return '존재하지 않는 사업자 번호입니다.';
    }
  }

  openModal(txt: string) {
    this.setState({ openModal: true, modalText: txt });
  }

  showRequiredAlert(alertText: string) {
    this.setState({ openRequiredAlert: true, alertText });
  }

  onSubmit(event: React.MouseEvent) {
    const { originLicenceIdChecked, onSubmit, orgProposal, changeForm } = this.props;
    if(!orgProposal.licenceId || originLicenceIdChecked !== 'pass' || orgProposal.id === '') {
      this.openModal('기업 정보는 필수입력 항목입니다. \n 사업자 번호 & 단체 등록번호를 통해 기업 정보를 입력해주세요.');
      this.licenceRef.current?.scrollIntoView({ behavior: 'smooth' });
      this.licenceRef.current?.focus();
    }
    else if (!orgProposal.managerEmail) {
      this.openModal('이메일은 필수입력 항목입니다. \n 이메일을 입력해주세요.');
      this.mngEmailRef.current?.scrollIntoView({ behavior: 'smooth' });
      this.mngEmailRef.current?.focus();
    }
    else if (!this.validateEmail(orgProposal.managerEmail)) {
      this.openModal('입력하신 이메일이 이메일 형식에 맞지 않습니다.');
      this.mngEmailRef.current?.scrollIntoView({ behavior: 'smooth' });
      this.mngEmailRef.current?.focus();
    }
    else if (!orgProposal.manager) {
      this.openModal('담당자 성명은 필수입력 항목입니다. \n 담당자 성명을 입력해주세요.');
      this.mngRef.current?.scrollIntoView({ behavior: 'smooth' });
      this.mngRef.current?.focus();
    }
    else if (!orgProposal.managerTel?.displayNumber) {
      this.openModal('연락처는 필수입력 항목입니다. \n 연락처를 입력해주세요.');
      this.mngTelRef.current?.scrollIntoView({ behavior: 'smooth' });
      this.mngTelRef.current?.focus();
    }
    else if (!this.validatePhone(orgProposal.managerTel?.displayNumber)) {
      this.openModal('연락처 형식에 맞게 입력해주세요.');
      this.mngTelRef.current?.scrollIntoView({ behavior: 'smooth' });
      this.mngTelRef.current?.focus();
    }
    else {
      onSubmit(event);
    }
  }

  validateEmail(inputEmail: string): boolean {
    const pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);

    const validateEmail = pattern.test(inputEmail);

    this.setState({ validateEmail });
    return validateEmail;

  }

  validatePhone(phone: string) {

    const pattern1 = new RegExp(/^\d{2,3}-\d{3,4}-\d{4}$/i);
    const pattern2 = new RegExp(/^\d{4}-\d{4}$/i);


    return pattern1.test(phone) || pattern2.test(phone);
  }


  checkId(id: string): boolean {
    const pattern = new RegExp(/^[A-Za-z0-9_\-.]{6,15}$/);
    const isValid = pattern.test(id);

    if (!isValid) {
      this.openModal('아이디 형식에 맞게 입력해주세요.');
      return false;
    }
    return true;
  }

  // 메일
  paddedFormat(num: number) {
    return num < 10 ? '0' + num : num;
  }

  onSendMail() {
    const { reEmail } = this.props;
    reEmail();
    if (!this.validationCheck()) {
      return;
    }
    this.props.onSubmitMail();
  }

  onEnter(e: React.KeyboardEvent<HTMLDivElement>) {
    if (e.key === 'Enter' && this.validationCheck()) {
      this.props.onSubmitMail();
    }
  }

  validationCheck() {
    //
    const { tempEmail, validateEmail } = this.props;

    if (!tempEmail.trim()) {
      this.modalCon('이메일이 입력 되지 않았습니다.');
      return false;
    }

    if (!validateEmail) {
      this.modalCon('입력하신 이메일이 이메일 형식에 맞지 않습니다.');
      return false;
    }

    if (tempEmail.endsWith('.kp')) {
      this.modalCon('입력하신 이메일은 사용하실 수 없습니다.');
      return false;
    }

    return true;
  }

  modalCon(txt: string) {
    this.setState({ openModal: true, modalText: txt });
  }
  
  closeModal() {
    this.setState({ openModal: false, modalText: '' });
  }

  checkCode(email: string) {
    const {validationCodeInEmail } = this.props;
    const boo = validationCodeInEmail(email).then((result) => {
      if (result) {
        this.setState({isVerified: true});
      } else {
        this.setState({isVerified: false});
        return;
      }
    }).catch((error) => {
      // 에러 핸들링
      this.setState({isVerified: false});
      console.error(error);
    });
  }

  renderSendingButton() {
    const { isVerified } = this.state;
    return (
      <>
        {isVerified ? (
          <button 
            className="btn-search"  
            style={{ backgroundColor: '#F0F0F5', color: '#222224' }}
          >
            <Icon style={{ fontSize: 14, color: '#222224' }}>checked</Icon>
            <span style={{ color: '#222224', fontSize: 14 }}>인증완료</span>
          </button>
        ) : 
        (
          <>
            <button className="btn-search" onClick={this.onSendMail}>
              <Icon style={{ fontSize: 16 }}>checked</Icon>
              <span style={{ color: 'white' }}>발송</span>
            </button>
          </>
        )}
      </>
    );
  }

  render() {

    const {
      orgProposal,
      onChange,
      onClickCancel,
      onChangeLicenceId,
      originLicenceIdChecked,
      emailCheck, isSent, showTimeOption, tempEmail, onChangeMail, validateEmail,
      onChangeOrg, orgList, selectOrg
    } = this.props;
    const { openModal, modalText, isVerified } = this.state;

    return (
      <>
        {
          openModal ? <CommonModal message={modalText} close={this.closeModal} /> : null
        }
        <section className="step-wrap">
          <div className="step step-1">
            <div className="step-title">
                <h2>기업 & 단체 정보</h2>
                <span className="point-comment">표는 필수 동의 항목입니다.</span>
            </div>

            <div className="step-content">
              <div className="info-list">
                  <label>사업자 번호 & 단체 등록번호 <span className="required"></span></label>
                  <div className="input-group">
                      <input 
                        type="tel" placeholder="'-'없이 숫자만 입력하세요" className="input-box with-btn"
                        value={orgProposal.formattedLicenceId}
                        // onError={originLicenceIdChecked === 'fail'}
                        onChange={onChangeLicenceId}
                        id="licenceId"
                        name="licenceId"
                        maxLength={12}
                        required
                        ref={this.licenceRef}
                      />
                      <button className="btn-search" onClick={this.props.checkLicenceId}>검색</button>
                      <div style={ originLicenceIdChecked === 'fail' ? { fontSize: '13px', color: 'crimson' } : { fontSize: '13px' }}>
                        {this.getLicenceIdHelperText()}
                      </div>
                  </div>
              </div>
              <div className="info-list">
                <label>기업명 <span className="required"></span></label>
                <FormControl variant="outlined" size="small" disabled={originLicenceIdChecked !== 'pass' ? true : false}  style={{ width: '100%' }}>
                  <Select id="name" name="name" label="기업명" sx={{border: 'solid 1px #cdcdcd', borderRadius: '5px',}} value={selectOrg === undefined ? '' : selectOrg} onChange={onChangeOrg}>
                  <MenuItem value={''} disabled hidden>기업명을 선택해주세요</MenuItem>
                  {orgList.map((org, index) => (
                    <MenuItem value={index.toString()} key={index}>
                      {org.name}
                    </MenuItem>
                  ))}
                  </Select>
                </FormControl>
              </div>
              <div className="info-list">
                  <label>기업 연락처</label>
                  <input 
                    type="tel" placeholder="기업 연락처" className="input-box"
                    value={orgProposal.orgTel?.displayNumber === undefined ? '' : orgProposal.orgTel?.displayNumber}
                    id="orgTel"
                    name="orgTel"
                    readOnly={true}
                    maxLength={13}
                  />
              </div>
              <div className="info-list">
                  <label>기업 주소</label>
                  <input 
                    type="text" placeholder="기업 주소" className="input-box"
                    value={orgProposal.address?.zipAddress === undefined ? '' : orgProposal.address?.zipAddress}
                    name="address"
                    readOnly={true}
                  />
              </div>
            </div>

          {/* -- email */}
          <div className="step-title mt-50">
              <h2>이메일 인증</h2>
          </div>
          <div className="step-content">
              <div className="info-list">
                  <label>이메일 <span className="required"></span></label>
                  <div className="input-group">
                      <input 
                        type="text" placeholder="이메일을 입력 하세요." className="input-box with-btn"
                        id="managerEmail"
                        name="managerEmail"
                        value={tempEmail}
                        onChange={onChangeMail}
                        autoComplete="off"
                        readOnly={ isVerified ? true : false }
                        ref={this.mngEmailRef}
                      />
                      {
                        !isSent ? (this.renderSendingButton())
                        :
                          <button className="btn-search" onClick={this.onSendMail}>
                            <Icon style={{ fontSize: 16 }}>send</Icon>
                            <span style={{color: 'white'}}>재발송</span>
                          </button>
                      }
                  </div>
                  <div className="add-text">{ emailCheck === 'default' ? '입력하신 이메일로 인증번호가 발송됩니다.' : '인증이 완료되었습니다.'}</div>
                  {/* <div className="add-text">인증코드가 발송되었습니다.</div> */}
              </div>
              {
                isSent ?
                <div className="info-list">
                    <label>인증코드 <span className="required"></span></label>
                    <div className="input-group">
                        <input 
                          id="secretCode" name="secretCode"
                          type="text" placeholder="인증번호 입력" className="input-box with-btn auth-input"
                          value={this.props.code}
                          onChange={this.props.onChangeCode}
                        />
                        <button className="btn-search" onClick={() => this.checkCode(tempEmail)}>
                          <Icon style={{ fontSize: 16 }}>checked</Icon>
                          <span style={{ color: 'white'}}>확인</span>
                        </button>
                    </div>
                    <div className="add-text">
                      <Icon>schedule</Icon>
                      {
                      showTimeOption === 'timer' && (
                        <>
                          <span>인증까지 남은 시간</span>
                          <LimitTimer limitMin={3} onTimeOver={this.props.onTimeOver} />
                        </>
                      )
                    }
                    </div>
                </div>
                : null
              }
              
              <div className="info-list">
                  <input type="checkbox" id="checkbox-1" className="checkbox checkbox-all" name="newsChecked" onChange={onChange} />
                  <label htmlFor="checkbox-1">뉴스레터 수신동의</label>
              </div>
          </div>

          <div className="step-title mt-50">
            <h2>개인정보</h2>
          </div>
          <div className="step-content">
              <div className="info-list">
                  <label>담당자 성명 <span className="required"></span></label>
                  <input
                    type="text" placeholder="이름을 입력하세요." className="input-box"
                    value={orgProposal.manager}
                    onChange={onChange}
                    id="manager"
                    name="manager"
                    maxLength={16}
                    required
                    ref={this.mngRef}
                  />
              </div>
              <div className="info-list">
                  <label>연락처 <span className="required"></span></label>
                  <input
                    type={'tel'}
                    id="managerTel"
                    name="managerTel"
                    value={orgProposal.managerTel?.displayNumber}
                    onChange={onChange}
                    placeholder="연락처를 입력 하세요."
                    maxLength={13}
                    required
                    className="input-box"
                    ref={this.mngTelRef}
                  />
              </div>
          </div>

          <div className="step-btns">
            <button className="btn-outline" onClick={onClickCancel}>취소</button>
            <button className="btn-default" onClick={this.onSubmit} disabled={this.props.submitBtn}>다음</button>
          </div>
          </div>
        </section>
      </>
    );
  }
}

export default ServiceInjector.withContext(
  XGridStateKeeper,
)(OrgProposalFormView);
