import FailureMessage from '../core/model/api/FailureMessage';

class CommandResponse {
  //
  entityIds: string[] = [];
  result: boolean = true;
  requestFailed: boolean = false;
  failureMessage: FailureMessage | null = null;

  static fromDomain(domain: CommandResponse): CommandResponse {
    //
    const response = new CommandResponse();

    response.entityIds = domain.entityIds;
    response.result = domain.result;
    response.requestFailed = domain.requestFailed;
    response.failureMessage = domain.failureMessage;

    return response;
  }

  getEntityId(): string {
    //
    if (!this.entityIds || !this.entityIds.length) {
      throw new DOMException('CommandResponse', 'entityId is null');
    }

    return this.entityIds[0];
  }

  get success(): boolean {
    //
    return !this.failureMessage;
  }

  get failed(): boolean {
    //
    return !!this.failureMessage;
  }

  getFailureMessage(): FailureMessage | null {
    //
    return this.failureMessage;
  }

  setFailureMessage(failureMessage: FailureMessage): void {
    //
    this.failureMessage = failureMessage;
  }

  [key: string]: any;
}

export default CommandResponse;
