import React from "react";
import axios from "axios";
import moment from "moment";
import { CircularProgress, Pagination } from "@mui/material";
import { renderResultNotFound } from "../../shared/Function/util/ResultUtil";
import { observer } from "mobx-react";

interface Props {
  totalCount: number;
}

interface State {
  noticeList: [];
  searchCategoryValue: string;
  searchValue: string;
  filterNoticeList: [];
  totalCount: number;
  currentPage: number;
  selectedPostId: string;
  noticeListMore: [];
  isConcat: boolean;
  isProgress: boolean;
}

class AnnouncementMainPage extends React.Component<Props, State> {

  static defaultProps: any = {};

  private pageLimit = 10;

  constructor(props: any) {
    super(props);
    this.search = this.search.bind(this);
    this.getNoticeList = this.getNoticeList.bind(this);
    this.showNoticeDetail = this.showNoticeDetail.bind(this);
    this.showMore = this.showMore.bind(this);
    this.searchNow = this.searchNow.bind(this);
    this.viewBoard = this.viewBoard.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
  }

  async componentDidMount() {
    const { currentPage } = this.state;
    const searchParams = new URLSearchParams(window.location.search);
    const searchValue = searchParams.get('searchValue');
    const searchType = searchParams.get('searchType');
    if (searchType && searchValue && !this.isBlank(searchType) && !this.isBlank(searchValue)) {
      this.setState({ searchCategoryValue: searchType, searchValue: searchValue, currentPage: 1 });
      this.search(1, searchType, searchValue);
    } else {
      this.getNoticeList(currentPage);
    }
  }

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
    snapshot?: any
  ): void { }

  //@ts-ignore
  state: State = {
    ...this.props,
    noticeList: [],
    searchCategoryValue: 'title',
    searchValue: '',
    filterNoticeList: [],
    totalCount: 0,
    currentPage: 1,
    selectedPostId: '',
    noticeListMore: [],
    isConcat: false,
    isProgress: false,
  }

  isBlank(str: string) {
    return /^\s*$/.test(str);
  }

  handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    const { searchCategoryValue, searchValue } = this.state;
    this.setState({ currentPage: value }, () => {
      if (this.state.searchValue && this.state.searchCategoryValue) {
        this.search(value, searchCategoryValue, searchValue);
      } else {
        this.getNoticeList(value);
      }
    });
  };

  showMore(event: React.ChangeEvent<unknown>) {
    // 더보기를 누르면
    // 다음 페이지 데이터를 가져온다.
    this.setState({ isConcat: true }, () => {
      const pageValue = this.state.currentPage + 1;
      this.handleChange(event, pageValue);
    })
  }

  setSearch = (event: any) => {
    this.setState({ searchValue: event.target.value });
  }

  setSearchCategory = (event: any) => {
    this.setState({ searchCategoryValue: event.target.value });
  }

  async getNoticeList(currentPage: any) {
    const { searchValue, totalCount, isConcat } = this.state;
    const pageLimit = this.pageLimit;
    const self = this;
    if (searchValue === '') {

      await axios.post('/api/board/flow/posts-flow/default-posts-title', {
        responseName: "response",
        queryParams: null,
        offset: {
          offset: this.calOffset(this.state.currentPage),
          limit: pageLimit,
          totalCount: totalCount,
          totalCountRequested: true,
          sortDirection: "DESCENDING",
          sortingField: "registrationTime"
        },
        role: "nonMember",
        manageable: false,
        scope: "portalScope",
        boardType: "NOTICE-BOARD",
      })
        .then((res) => {
          const noticeList = res.data.queryResult.posts;
          const totalCount = res.data.queryResult.totalCount
          const extractedIds = noticeList.forEach((item: { id: any; }) => {
            // this.setState();
          }, []);
          if (isConcat) {
            const newArray = this.state.noticeList.concat(noticeList);
            // @ts-ignore
            this.setState({ noticeList: newArray });
          } else {
            this.setState({ noticeList, totalCount });
          }

          if(res.status === 200){
            this.setState({ isProgress : true});
          }
        });
    }

    else {
      await axios.post('/api/board/flow/posts-flow/default-posts-title', {
        responseName: "response",
        queryParams: null,
        offset: {
          offset: this.calOffset(this.state.currentPage),
          limit: pageLimit,
          totalCount: totalCount,
          totalCountRequested: true,
          sortDirection: "DESCENDING",
          sortingField: "registrationTime"
        },
        role: "nonMember",
        manageable: false,
        scope: "portalScope",
        boardType: "NOTICE-BOARD",
      })
        .then((res) => {
          const noticeList = res.data.queryResult.posts;
          const totalCount = res.data.queryResult.totalCount
          const extractedIds = noticeList.forEach((item: { id: any; }) => {
            // this.setState();
          }, []);
          if (isConcat) {
            const newArray = this.state.noticeList.concat(noticeList);
            // @ts-ignore
            this.setState({ noticeList: newArray });
          } else {
            this.setState({ noticeList, totalCount });
          }

          if(res.status === 200){
            this.setState({ isProgress : true});
          }
        });
    }
  }

  
  showNoticeDetail(postId: string, startingTime: string, registrationTime: string) {
    const { selectedPostId, searchCategoryValue, searchValue } = this.state;
    let searchUrl = "/rms-conlab/announcement-detail?";

    searchUrl += "selectedPostId=" + encodeURIComponent(postId) + "&startingTime=" + encodeURIComponent(startingTime) + "&registrationTime=" + encodeURIComponent(registrationTime);
    searchUrl += "&searchType=" + encodeURIComponent(searchCategoryValue) + "&searchValue=" + encodeURIComponent(searchValue);


    window.location.href = searchUrl

  }

  calOffset(currentPage: number): number {
    return (currentPage - 1) * 10;
  }

  checkFixedPost(currentTime: number, startingTime: number, endingTime: number, title: string): string {
    const isFixed = currentTime > startingTime && currentTime < endingTime;
    if (isFixed) {
      title = `[공지] ${title}`;
    }
    return title;
  }

  async search(currentPage: number, searchType: string, searchValue: string) {
    this.setState({ currentPage });
    const { totalCount } = this.state;
    await axios.post('/api/board/aggregate/post/query/dynamic-multi',
      {
        "responseName": "response",
        "queryParams": {
          "queryParams": [
            {
              "fieldName": "boardId",
              "operation": "Equal",
              "value": "NOTICE-BOARD",
              "connector": "And"
            },
            {
              "fieldName": "displayPosting",
              "operation": "Equal",
              "value": "true",
              "connector": "And"
            },
            {
              "fieldName": "authorizationToReadJson",
              "operation": "Like",
              "value": "'%\"nonMember\":true%'",
              "connector": "And"
            },
            {
              "fieldName": "portalScope",
              "operation": "Equal",
              "value": "true",
              "connector": "And"
            },
            {
              "fieldName": searchType ? searchType : this.state.searchCategoryValue,
              "operation": "Like",
              "value": this.state.searchValue === '' ? `'%${searchValue}%'` : `'%${this.state.searchValue}%'`,
              "connector": "End"
            }
          ]
        },
        "offset": {
          "offset": this.calOffset(currentPage),
          "limit": 10,
          "totalCount": totalCount,
          "totalCountRequested": true,
          "sortDirection": "DESCENDING",
          "sortingField": "registrationTime"
        }
      })
      .then((res) => {
        const noticeList = res.data.queryResult;
        for (let i = 0; i < noticeList.length; i++) {
          noticeList[i].title = this.checkFixedPost(Date.now(), noticeList[i].startingTime, noticeList[i].endingTime, noticeList[i].title);
        }
        const totalCount = res.data.offset.totalCount;
        this.setState({ noticeList, totalCount });

        if(res.status === 200){
          this.setState({ isProgress : true});
        }
      });
  }

  searchNow() {
    const { searchCategoryValue, searchValue } = this.state;
    // this.setState({ currentPage: 1, totalCount: 10 });
    // this.search(1, searchCategoryValue, searchValue);
    if (!searchValue) {
      window.location.href = `/rms-conlab/announcement`;
    } else {
      window.location.href = `/rms-conlab/announcement?searchType=${searchCategoryValue}&searchValue=${searchValue}`;
    }
  }

  handleKeyPress(e: React.KeyboardEvent<HTMLInputElement>) {
    if (e.key === 'Enter') {
      this.searchNow();
    }
  };

  viewBoard(seq: any) {
    const { noticeList } = this.state; 
    return(<>
      {
        noticeList.length > 0 ? 
        (
          noticeList.map((notice: any, index) => (
            <tr draggable={false} style={{ userSelect: 'text' }} key={notice.id} onClick={() => {
              if (window.getSelection()?.type === 'Range') {
                return;
              }
              this.showNoticeDetail(notice.id, notice.startingTime, notice.registrationTime)}}>
              <td>{seq - index}</td>
              <td className="t_left_announcement" >
                <a>
                  {notice.title}{" "}
                  {notice.fileIds.length > 0 && (
                    <span className="icon">
                      <img
                        src="/rms-conlab/images/icon_attach_file.png"
                        alt="icon" />
                    </span>
                  )}
                </a>
              </td>
              {/* <td>{notice.writer.name}</td> */}
              <td>{moment(notice.registrationTime).format('YYYY.MM.DD')}</td>
              <td>{notice.readCount}</td>
            </tr>
          ))
        ) : <>
          <div style={{ width: '1000px', textAlign: 'center', fontSize: 'large', marginTop: '150px' }}>해당 게시물이 존재하지 않습니다.</div>
        </>
      }
    </>);
  }

  render() {
    const { noticeList, currentPage, totalCount, isConcat, searchCategoryValue, searchValue } = this.state;
    const pageCount = Math.ceil(totalCount / this.pageLimit);
    const offset = (this.state.currentPage - 1) * this.pageLimit;
    let seq: number = 0;

    if (isConcat) {
      seq = totalCount;
    } else {
      seq = this.state.totalCount - offset;
    }

    return (
      <>
        <div id="contents" className="notice">
          <div className="center nav_wrap">
            <ul className="nav">
              <li>
                <i className="icon home"></i>
              </li>
              <li>
                고객센터
                {/* <i className="icon down_circle"></i> */}
              </li>
              <li>
                <span>공지사항</span>
                {/* <i className="icon down_circle"></i> */}
              </li>
            </ul>
          </div>
          <section className="board_area">
            <div className="center">
              <h1 className="board_tit">공지사항</h1>

              <div className="board_wrap">
                <div className="b_search_box_wrap">
                  <select name="" id="" onChange={this.setSearchCategory}>
                    <option value="title" selected={searchCategoryValue == 'title'}>제목</option>
                    <option value="contents" selected={searchCategoryValue == 'contents'}>내용</option>
                    {/* <option value="등록자" >등록자</option>
                    <option value="조회수">조회수</option> */}
                  </select>
                  <div className="b_search_box">
                    <input type="text" placeholder="검색어 입력" value={searchValue} onChange={this.setSearch} onKeyDown={this.handleKeyPress}/>
                    <button className="st_point search_bt" onClick={this.searchNow}>검색</button>
                  </div>
                </div>
                {/* {noticeList.length === 0 ? */}
                {/* renderResultNotFound() */}
                {/* : ( */}
                <div>
                  <div className="pc_board">
                    <table className="board">
                      <tbody>
                        <tr className="b_tit" style={{ width: "100%" }}>
                          <th style={{ width: "15%" }}>번호</th>
                          <th className="t_left_annoncement" >제목</th>
                          {/* <th>등록자</th> */}
                          <th style={{ width: "25%" }}>등록일</th>
                          <th style={{ width: "20%" }}>조회수</th>
                        </tr>
                        {this.state.isProgress === true ? (
                            this.viewBoard(seq)
                           ) : (
                            <div style={{ padding : '90%', paddingLeft : '320%' , width : '100%'}}>
                             <CircularProgress />
                            </div>
                           )
                         }
                      </tbody>
                    </table>

                    <div className="paging">
                      <ul>
                        <li className="on">
                          <Pagination count={pageCount} page={currentPage} color="primary" onChange={this.handleChange} />
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="m_board">
                    {
                      noticeList.map((notice: any, index) => (
                        <ul key={notice.id} onClick={() => this.showNoticeDetail(notice.id, notice.startingTime, notice.registrationTime)}>
                          <li>
                            <a >{notice.title}{" "}
                              {notice.fileIds.length > 0 && (

                                <span className="icon">
                                  <img
                                    src="/rms-conlab/images/icon_attach_file.png"
                                    alt="icon"
                                  />
                                </span>
                              )}
                              <p className="date">{moment(notice.registrationTime).format('YYYY.MM.DD')}</p>
                            </a>
                          </li>
                        </ul>
                      ))}
                    {/* <Pagination count={pageCount} color="primary" onChange={this.handleChange}/> */}
                    {currentPage !== pageCount && (
                      <div className="center">
                        <button className="st_line4 more_board_bt" onClick={this.showMore}>더보기<img src="./images/icon_add_min.png" alt="icon_add" /></button>
                      </div>)}
                  </div>
                </div>
                {/* )} */}
              </div>
            </div>
          </section>
        </div>
      </>
    );
  }
}

export default AnnouncementMainPage;
